import React from "react";
import { classNames } from "../../common/utils/classnames";

const RuleExpressionEditor: React.FC<{
  expression?: string;
  disabled?: boolean;
  onChange: (val: string) => void;
}> = ({ expression, disabled, onChange }) => {
  const expressionResolved = expression ?? "";
  const disabledResolved = disabled ?? false;
  return (
    <div>
      <textarea
        value={expression}
        rows={expressionResolved.split("\n").length}
        className={classNames(
          "font-mono w-full",
          disabled ? "bg-gray-100 fg-gray-500" : ""
        )}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabledResolved}
      />
      <div className="text-xs">
        A fully integrated editor has not been built here. Visit{" "}
        <a
          href="https://rules.zenlist.dev"
          className="text-blue-600 underline hover:text-blue-500"
          target="_blank"
          rel="noreferrer"
        >
          rules.zenlist.dev
        </a>{" "}
        for live editing.
      </div>
    </div>
  );
};

export default RuleExpressionEditor;
