import React from "react";
import { classNames } from "../utils/classnames";

type PanelTitleType = React.FC<
  { actions?: React.ReactChild } & React.PropsWithChildren
>;
const PanelTitle: PanelTitleType = ({ children, actions }) => {
  return (
    <div className={classNames("px-4 py-2 sm:px-6", actions ? "flex" : "")}>
      <h3
        className={classNames(
          "text-lg leading-6 font-medium text-gray-900",
          actions ? "grow" : ""
        )}
      >
        {children}
      </h3>
      {actions && actions}
    </div>
  );
};

type PanelSubTitleType = React.FC<
  { actions?: React.ReactChild } & React.PropsWithChildren
>;
const PanelSubTitle: PanelSubTitleType = ({ children, actions }) => {
  return (
    <div className={classNames("mb-4", actions ? "flex" : "")}>
      <h4
        className={classNames(
          "text-base font-medium text-gray-900 underline decoration-gray-300",
          actions ? "grow" : ""
        )}
      >
        {children}
      </h4>
      {actions && actions}
    </div>
  );
};

type PanelBodyType = React.FC<
  {
    summary?: boolean;
    fullWidth?: boolean;
    constrainHeight?: boolean;
    className?: string;
  } & React.PropsWithChildren
>;
const PanelBody: PanelBodyType = ({
  children,
  summary,
  fullWidth,
  constrainHeight,
  className,
}) => {
  let padding = "";
  if (fullWidth) {
    padding = "";
  } else if (summary) {
    padding = "px-4 py-5 sm:px-6";
  } else {
    padding = "px-4 py-4 sm:px-6";
  }

  return (
    <div
      className={classNames(
        padding,
        "border-t border-gray-200",
        constrainHeight ? "h-96 overflow-auto" : "",
        className ?? ""
      )}
    >
      {children}
    </div>
  );
};

type PanelFooterType = React.FC<React.PropsWithChildren>;
const PanelFooter: PanelFooterType = ({ children }) => {
  return (
    <div className="min-w-full divide-y divide-gray-200">
      <div className="p-4">{children}</div>
    </div>
  );
};

const Panel: React.FC<React.PropsWithChildren> & {
  Title: PanelTitleType;
  SubTitle: PanelSubTitleType;
  Body: PanelBodyType;
  Footer: PanelFooterType;
} = ({ children }) => {
  return (
    <div className="bg-white shadow sm:mx-4 sm:rounded-md mt-4">{children}</div>
  );
};

Panel.Title = PanelTitle;
Panel.SubTitle = PanelSubTitle;
Panel.Body = PanelBody;
Panel.Footer = PanelFooter;

export default Panel;
