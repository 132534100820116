import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { AccountQuery } from "../../graphql/generated";
import AccountPaymentActions from "./paymentactions";

const AccountPayment: React.FC<{ account: AccountQuery["account"] }> = ({
  account,
}) => {
  return (
    <Panel>
      <Panel.Title actions={<AccountPaymentActions account={account} />}>
        Payment Details
      </Panel.Title>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem
            title="Stripe Customer ID"
            value={account.stripeCustomer?.id ?? ""}
            a={`https://dashboard.stripe.com/customers/${account.stripeCustomer?.id}`}
          />
          <DescriptionListItem
            title="Stripe Environment"
            value={account.stripeCustomer?.environment ?? ""}
          />

          {account?.enterpriseAgreements &&
            account?.enterpriseAgreements.map((agreement) => (
              <DescriptionListItem
                title="Enterprise Agreement(s)"
                value={agreement.name}
                link={`/enterprise_agreements/${agreement.id}`}
              />
            ))}
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default AccountPayment;
