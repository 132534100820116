import {
  IdentificationIcon,
  BuildingLibraryIcon,
  EnvelopeIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { ClipboardContext } from "../../common/context/clipboard";
import { AgentQuery } from "../../graphql/generated";
import AgentItemActions from "./itemactions";
import AgentPic from "./pic";

const AgentTitle: React.FC<{ agent?: AgentQuery["agent"] }> = ({ agent }) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <AgentPic agent={agent} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {agent?.name.trim() === "" ? "Empty Name" : agent?.name}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <IdentificationIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {agent?.mlsId}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <BuildingLibraryIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {agent?.mls?.name}
              </div>
            </div>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                <div
                  className="text-blue-900 cursor-pointer"
                  onClick={() => sendToClip(agent?.email!)}
                >
                  {agent?.email}
                </div>
                <Link
                  to={{
                    pathname: "/subetha/recipients",
                    search: `?email=${agent?.email}`,
                  }}
                >
                  <MagnifyingGlassIcon
                    className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                </Link>
              </div>
              {agent?.phone && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <PhoneIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                  <a href={`tel:${agent?.phone}`}>{agent?.phone}</a>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <AgentItemActions agent={agent} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentTitle;
