import React from "react";
import Panel from "../../../common/components/panel";
import {
  ResourceMetadataFieldFragment,
  RmFieldLookupValueFragment,
  RmLookupTwoSourceMappingTypeFragment,
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
  useResourceMetadataForFieldQuery,
  useRmMappingSetLookupTwoSourceTransformMutation,
  useRmProblemAcknowledgeMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import LookupValueSelector from "./LookupValueSelector";
import LoadingIcon from "../../../common/components/loadingicon";
import { diceCoefficient } from "dice-coefficient";
import ProblemStatus from "../problem-status";
import {
  ResourceMetadataLookupValueComponent,
  resourceMetadataLookupValueForValue,
} from "../resource-metadata/resource-metadata-lookup-value-component";
import { ResourceMetadataFieldComponent } from "../resource-metadata/resource-metadata-field-component";

const RMLookupTwoSourceMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmLookupTwoSourceMappingTypeFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping, mappingType, problems }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [primarySource, setPrimarySource] = React.useState<string>("");
  const [secondarySource, setSecondarySource] = React.useState<string>();
  const [destination, setDestination] = React.useState<string | null>(null);
  const options = mapping.destinationIsLocalLookup
    ? mapping.field.localLookupValues
    : mapping.field.standardLookupValues;

  const [{ fetching }, mutation] =
    useRmMappingSetLookupTwoSourceTransformMutation();
  const [{ data: resourceMetadataPrimary }] = useResourceMetadataForFieldQuery({
    variables: {
      id: mapping.field.rootResource.resourceMetadataId,
      fieldName: mappingType.primarySource,
    },
  });
  const [{ data: resourceMetadataSecondary }] =
    useResourceMetadataForFieldQuery({
      variables: {
        id: mapping.field.rootResource.resourceMetadataId,
        fieldName: mappingType.secondarySource,
      },
    });

  const resourceMetadataPrimaryField =
    resourceMetadataPrimary?.resourceMetadata.field ?? undefined;
  const resourceMetadataSecondaryField =
    resourceMetadataSecondary?.resourceMetadata.field ?? undefined;

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      primarySource,
      secondarySource,
      destination,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mapping,
    primarySource,
    secondarySource,
    destination,
    updateNotification,
  ]);

  return (
    <>
      <Panel>
        <Panel.Title>Two-source Lookup Mapping</Panel.Title>
        <Panel.Body>
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">
                  Source:{" "}
                  <ResourceMetadataFieldComponent
                    name={mappingType.primarySource}
                    field={resourceMetadataPrimaryField}
                  />
                </th>
                <th className="p-1">
                  Source:{" "}
                  <ResourceMetadataFieldComponent
                    name={mappingType.secondarySource}
                    field={resourceMetadataSecondaryField}
                  />
                </th>
                <th className="p-1">Destination: {mapping.field.name}</th>
              </tr>
            </thead>
            <tbody>
              {mappingType.transforms.map((transform, idx) => {
                return (
                  <tr key={idx} className="border-t">
                    <td className="p-1">
                      <ResourceMetadataLookupValueComponent
                        field={resourceMetadataPrimaryField}
                        value={transform.primaryFrom}
                      />
                    </td>
                    <td className="p-1">
                      {transform.secondaryFrom && (
                        <ResourceMetadataLookupValueComponent
                          field={resourceMetadataSecondaryField}
                          value={transform.secondaryFrom}
                        />
                      )}
                      {!transform.secondaryFrom && (
                        <span className="text-gray-400">null</span>
                      )}
                    </td>
                    <td className="p-1">
                      {transform.to && transform.to}
                      {!transform.to && (
                        <span className="text-gray-400">ignore</span>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr key="add" className="border-t">
                <td className="p-1">
                  <input
                    type="text"
                    value={primarySource}
                    onChange={(e) => setPrimarySource(e.target.value)}
                  />
                </td>
                <td className="p-1">
                  <input
                    type="text"
                    value={secondarySource}
                    onChange={(e) => setSecondarySource(e.target.value)}
                  />
                </td>
                <td className="p-1">
                  <LookupValueSelector
                    value={destination}
                    setValue={setDestination}
                    options={options}
                  />{" "}
                  {!fetching && (
                    <button onClick={() => setLookup()}>Set</button>
                  )}
                  {fetching && <LoadingIcon />}
                </td>
              </tr>
            </tbody>
          </table>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Suggestions</Panel.Title>
        <Panel.Body>
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">Source: {mappingType.primarySource}</th>
                <th className="p-1">Source: {mappingType.secondarySource}</th>
                <th className="p-1">Destination: {mapping.field.name}</th>
                <th className="p-1"></th>
              </tr>
            </thead>
            <tbody>
              {problems?.edges.map((edge, idx) => (
                <Suggestion
                  key={idx}
                  edge={edge}
                  mapping={mapping}
                  primaryField={resourceMetadataPrimaryField}
                  secondaryField={resourceMetadataSecondaryField}
                  options={options}
                />
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
};

const Suggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
  primaryField?: ResourceMetadataFieldFragment;
  secondaryField?: ResourceMetadataFieldFragment;
  options: RmFieldLookupValueFragment[];
}> = ({ edge, mapping, primaryField, secondaryField, options }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  const problem = edge.node;
  const primarySource = problem.data["primary_value"];
  const secondarySource = problem.data["secondary_value"];
  const secondaryDisplay = secondarySource ?? "(null)";
  const checkValues = [
    secondarySource ? `${primarySource} / ${secondarySource}` : primarySource,
  ];
  const isAcknowledged = problem.acknowledgedAt != null;

  const primaryLookupValue = resourceMetadataLookupValueForValue(
    primarySource,
    primaryField
  );
  const secondaryLookupValue = secondarySource
    ? resourceMetadataLookupValueForValue(secondarySource, secondaryField)
    : null;

  if (primaryLookupValue && secondaryLookupValue) {
    checkValues.push(
      `${primaryLookupValue.name} / ${secondaryLookupValue.name}`
    );
  } else if (primaryLookupValue) {
    checkValues.push(primaryLookupValue.name);
  } else if (secondaryLookupValue) {
    checkValues.push(secondaryLookupValue.name);
  }

  const suggestions = options
    .map((value) => {
      return {
        value: value.value,
        definition: value.definition,
        coefficient: checkValues
          .map((checkValue) => diceCoefficient(checkValue, value.value))
          .reduce((a, b) => Math.max(a, b)),
      };
    })
    .filter((suggestion) => suggestion.coefficient > 0.5);

  suggestions.sort((a, b) => b.coefficient - a.coefficient);
  suggestions.splice(3);

  const [destination, setDestination] = React.useState<string | null>(
    suggestions.length ? suggestions[0]?.value : null
  );

  const [{ fetching }, mutation] =
    useRmMappingSetLookupTwoSourceTransformMutation();
  const [, mutationAcknowledge] = useRmProblemAcknowledgeMutation();

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      primarySource,
      secondarySource,
      destination,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });

      mutationAcknowledge({
        problemId: problem.id,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mutationAcknowledge,
    mapping,
    problem,
    primarySource,
    secondarySource,
    destination,
    updateNotification,
  ]);

  return (
    <tr>
      <td>
        <ResourceMetadataLookupValueComponent
          field={primaryField}
          value={primarySource}
        />
      </td>
      <td>
        <ResourceMetadataLookupValueComponent
          field={secondaryField}
          value={secondaryDisplay}
        />
      </td>
      <td>
        <LookupValueSelector
          value={destination}
          setValue={setDestination}
          suggestions={suggestions}
          options={options}
          disabled={isAcknowledged}
        />
        {!fetching && (
          <button onClick={() => setLookup()} disabled={isAcknowledged}>
            Set
          </button>
        )}
        {fetching && <LoadingIcon />}
      </td>

      <td>
        <ProblemStatus problem={problem} />
      </td>
    </tr>
  );
};

export default RMLookupTwoSourceMappingTypeControl;
