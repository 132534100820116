import React, { Fragment } from "react";
import {
  extraShortDateFormat,
  shortDateFormat,
  longDateFormat,
  distanceDateFormat,
} from "../../common/utils/dateformat";
import { Popover } from "@headlessui/react";
import { Transition } from "@headlessui/react";

type TimestampFormat = "extra-short" | "short" | "long" | "distance";

const Timestamp: React.FC<{
  timestamp?: string | null;
  format: TimestampFormat;
  popover?: boolean;
}> = ({ timestamp, format, popover }) => {
  if (!timestamp) {
    return <>&mdash;</>;
  }

  let formatted = timestamp;
  switch (format) {
    case "extra-short":
      formatted = extraShortDateFormat(timestamp);
      break;
    case "short":
      formatted = shortDateFormat(timestamp);
      break;
    case "long":
      formatted = longDateFormat(timestamp);
      break;
    case "distance":
      formatted = distanceDateFormat(timestamp);
      break;
  }

  const datetime = new Date(timestamp);
  const localTz = datetime
    .toLocaleDateString(undefined, {
      timeZone: undefined,
      year: "numeric",
      timeZoneName: "short",
    })
    .replace(/^.*, /, "");
  const localDate = datetime.toLocaleDateString(undefined, {
    timeZone: undefined,
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const localTime = datetime.toLocaleTimeString(undefined, {
    timeZone: undefined,
    timeStyle: "short",
  });
  const utcTz = datetime
    .toLocaleDateString(undefined, {
      timeZone: "UTC",
      year: "numeric",
      timeZoneName: "short",
    })
    .replace(/^.*, /, "");
  const utcDate = datetime.toLocaleDateString(undefined, {
    timeZone: "UTC",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const utcTime = datetime.toLocaleTimeString(undefined, {
    timeZone: "UTC",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  if (!(popover ?? false)) {
    return <time dateTime={timestamp}>{formatted}</time>;
  }

  return (
    <Popover className="relative inline">
      {() => (
        <>
          <Popover.Button className="inline">
            <time
              dateTime={timestamp}
              className={`underline decoration-dashed decoration-blue-600 underline-offset-2`}
            >
              {formatted}
            </time>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-l w-64">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="bg-white p-4">
                  <div
                    className="grid auto-cols-min gap-2 items-baseline"
                    style={{ gridTemplateColumns: "auto auto 1fr" }}
                  >
                    <span>{localDate}</span> <span>{localTime}</span>{" "}
                    <span className="text-xs text-gray-600">{localTz}</span>{" "}
                    <span>{utcDate}</span> <span>{utcTime}</span>{" "}
                    <span className="text-xs text-gray-600">{utcTz}</span>{" "}
                    <div className="col-span-3 text-xs font-mono text-gray-400">
                      {timestamp}
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Timestamp;
