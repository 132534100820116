import { Switch } from "@headlessui/react";
import React from "react";
import { classNames } from "../../common/utils/classnames";

const CommonSwitch: React.FC<{
  label: string;
  enabled: boolean;
  toggle: () => void;
}> = ({ label, enabled, toggle }) => {
  return (
    <Switch.Group as="div" className="mx-2 flex items-center">
      <Switch
        checked={enabled}
        onChange={toggle}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-4" : "translate-x-0",
            "pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-1">
        <span className="text-sm font-medium">{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};

export default CommonSwitch;
