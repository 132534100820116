import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../common/utils/dateformat";
import { AccountFragment, LenderAgentCollaborationState } from "../../graphql/generated";
import AccountProfilePic from "./profilepic";

const AccountCard: React.FC<{ account?: AccountFragment, lenderAgentCollaborationState?: LenderAgentCollaborationState }> = ({ account, lenderAgentCollaborationState }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <AccountProfilePic account={account} />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/accounts/${account?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">
            {account?.name.trim() === "" ? "Empty Name" : account?.name}
            <span className="ml-1 text-xs text-gray-500">
              · {account?.email}
            </span>
          </p>
          <div className="grid grid-cols-2">
            <p className="text-xs text-gray-500 truncate">Last Active:</p>
            <p className="text-xs text-gray-500 truncate col-span-1">
              {longDateFormat(account?.activeAt)}
            </p>
            <p className="text-xs text-gray-500 truncate">Total Sessions:</p>
            <p className="text-xs text-gray-500 truncate col-span-1">
              {account?.sessionCount}
            </p>
            {lenderAgentCollaborationState ? <p className="text-xs text-gray-500 truncate">Lender/Agent Collaboration Status:</p> : null}
            {lenderAgentCollaborationState ? 
                  {
                    "ACTIVE": <p className="text-xs text-green-500 font-bold truncate col-span-1">{lenderAgentCollaborationState}</p>,
                    "ACCEPTED": <span className="text-xs text-blue-500 font-bold col-span-1">{lenderAgentCollaborationState}</span>,
                    "PENDING": <span className="text-xs text-yellow-500 font-bold col-span-1">{lenderAgentCollaborationState}</span>,
                    "CANCELLED": <span className="text-xs text-red-500 font-bold col-span-1">{lenderAgentCollaborationState}</span>,
                  }[lenderAgentCollaborationState] || null
             : null
            }
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AccountCard;
