import React from "react";
import { StripeProductPriceDetailsFragment } from "../../graphql/generated";
import DescriptionListItem from "../../common/components/descriptionlistitem";

const StripeProductPriceCard: React.FC<{
  stripeproductprice?: StripeProductPriceDetailsFragment;
}> = ({ stripeproductprice }) => {
  return (
    <div className="relative bg-white px-6 py-3 flex items-center space-x-3">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
        <DescriptionListItem
          title="Product"
          info="Internal ID for the Product"
          value={stripeproductprice?.zenlistProduct?.id}
          link={`/products/${stripeproductprice?.zenlistProduct?.id}`}
        />
        <DescriptionListItem
          title="Entitlement"
          info="Entitlement granted by this Product"
          value={stripeproductprice?.zenlistProduct?.entitlementGroup?.label}
        />
        <DescriptionListItem
          title="Stripe Monthly Price"
          info="Stripe price that is granted as part of this product"
          value={stripeproductprice?.stripeMonthlyPriceId}
          link={`https://dashboard.stripe.com/prices/${stripeproductprice?.stripeMonthlyPriceId}`}
        />
        {stripeproductprice?.stripeYearlyPriceId && (
          <DescriptionListItem
            title="Stripe Yearly Price"
            info="Optional stripe yearly price that is equivalent to the monthly price"
            value={stripeproductprice?.stripeYearlyPriceId}
            link={`https://dashboard.stripe.com/prices/${stripeproductprice?.stripeYearlyPriceId}`}
          />
        )}
      </dl>
    </div>
  );
};

export default StripeProductPriceCard;
