import React from "react";
import Panel from "../../common/components/panel";
import { AccountFragment, FeatureFlagFragment } from "../../graphql/generated";
import AccountCard from "./accountcard";
import AccountsActions from "./accountsactions";

const FeatureFlagAccounts: React.FC<{
  accounts?: AccountFragment[];
  flag?: FeatureFlagFragment;
  label: string;
  enabled?: boolean;
}> = ({ accounts, label, flag, enabled }) => {
  return (
    <Panel>
      <Panel.Title
        actions={<AccountsActions featureFlag={flag} enabled={enabled} />}
      >
        {label}{" "}
        <span className="text-xs text-gray-500">{accounts?.length}</span>
      </Panel.Title>
      <Panel.Body>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {accounts &&
            flag &&
            accounts.map((account) => (
              <AccountCard
                account={account}
                flag={flag}
                key={account.id}
                enabled={enabled}
              />
            ))}
        </ul>
      </Panel.Body>
    </Panel>
  );
};

export default FeatureFlagAccounts;
