import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { shortDateFormat } from "../../common/utils/dateformat";
import {
  ProductOfferingFragment,
  FeatureFlagFragment,
  useDisableProductOfferingForFeatureFlagMutation,
  useEnableProductOfferingForFeatureFlagMutation,
} from "../../graphql/generated";

const ProductOfferingCard: React.FC<{
  productOffering?: ProductOfferingFragment;
  flag?: FeatureFlagFragment;
  enabled?: boolean;
}> = ({ productOffering, flag, enabled }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [{ error: enableError, fetching: enableFetching }, enableMutation] =
    useEnableProductOfferingForFeatureFlagMutation();
  const [{ error: disableError, fetching: disableFetching }, disableMutation] =
    useDisableProductOfferingForFeatureFlagMutation();

  const fetching = React.useMemo(
    () => enableFetching || disableFetching,
    [disableFetching, enableFetching]
  );

  React.useEffect(() => {
    if (enableError) {
      updateNotification({
        notification: enableError.message,
        notificationType: NotificationType.Error,
      });
    } else if (disableError) {
      updateNotification({
        notification: disableError.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [enableError, disableError, updateNotification]);

  const handleMove = React.useCallback(async () => {
    if (!fetching) {
      if (enabled) {
        await disableMutation({
          productOfferingId: productOffering?.id ?? "",
          featureFlagId: flag?.id ?? "",
        });
      } else {
        enableMutation({
          productOfferingId: productOffering?.id ?? "",
          featureFlagId: flag?.id ?? "",
        });
      }
    }
  }, [
    productOffering,
    fetching,
    disableMutation,
    enableMutation,
    enabled,
    flag,
  ]);

  return (
    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {productOffering?.description?.trim() === ""
                ? "Empty Name"
                : productOffering?.description}
            </h3>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">
            {shortDateFormat(productOffering?.createdAt)}
          </p>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <div
              onClick={handleMove}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer"
            >
              <ArrowLongRightIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="ml-3">
                Send to {enabled ? "Disabled" : "Enabled"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProductOfferingCard;
