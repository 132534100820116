import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { shortDateFormat } from "../../common/utils/dateformat";
import { SavedFilterQuery } from "../../graphql/generated";

const SavedFilterSummary: React.FC<{
  filter?: SavedFilterQuery["savedFilter"];
}> = ({ filter }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
          <DescriptionListItem
            title="Owner"
            value={filter?.owner?.name}
            link={`/accounts/${filter?.owner?.id}`}
          />
          <DescriptionListItem
            title="Group"
            value={filter?.group?.nameLong!}
            link={`/groups/${filter?.group?.id}`}
          />
          <DescriptionListItem
            title="Archived"
            value={shortDateFormat(filter?.archivedAt)}
          />
          <DescriptionListItem
            title="Previous Filter"
            value={filter?.parent?.id}
            link={`/saved_filters/${filter?.parent?.id}`}
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default SavedFilterSummary;
