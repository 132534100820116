import React from "react";
import { useClient } from "urql";
import { classNames } from "../../common/utils/classnames";
import {
  AgentsDocument,
  EnterpriseAgreementQueryType,
  InternalListingBucketFragment,
  InternalListingBucketQueryType,
  OfficesDocument,
  useAgentsQuery,
  useOfficesQuery,
} from "../../graphql/generated";
import AgentCard from "../../agent/components/card";
import OfficeCard from "../../office/components/card";
import Panel from "../../common/components/panel";

const InternalListingBucketManualEnrollment: React.FC<{
  bucket?: InternalListingBucketFragment;
}> = ({ bucket }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data: officeData }] = useOfficesQuery({
    variables: {
      first: 2,
      internalListingBucketId: bucket?.id ?? "",
      internalListingBucketQueryType: InternalListingBucketQueryType.ManualOnly,
    },
  });

  const [{ data: agentData }] = useAgentsQuery({
    variables: {
      first: 2,
      internalListingBucketId: bucket?.id ?? "",
      internalListingBucketQueryType: InternalListingBucketQueryType.ManualOnly,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (
      officeData?.offices.pageInfo.hasNextPage ||
      agentData?.agents.pageInfo.hasNextPage
    ) {
      setLoading(true);
    }
    const promises = [];
    if (officeData?.offices.pageInfo.hasNextPage) {
      promises.push(
        graphqlClient
          .query(OfficesDocument, {
            first: 20,
            after: officeData.offices.pageInfo.endCursor,
            internalListingBucketId: bucket?.id ?? "",
            internalListingBucketQueryType:
              EnterpriseAgreementQueryType.ManualOnly,
          })
          .toPromise()
      );
    }
    if (agentData?.agents.pageInfo.hasNextPage) {
      promises.push(
        graphqlClient
          .query(AgentsDocument, {
            first: 20,
            after: agentData.agents.pageInfo.endCursor,
            internalListingBucketId: bucket?.id ?? "",
            internalListingBucketQueryType:
              EnterpriseAgreementQueryType.ManualOnly,
          })
          .toPromise()
      );
    }
    if (promises.length) {
      await Promise.all(promises);
      setLoading(false);
    }
  }, [officeData, agentData, graphqlClient, bucket]);

  return (
    <Panel>
      <Panel.Title>Manually enrolled</Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {officeData?.offices.edges?.map((office) => (
            <OfficeCard office={office?.node} key={office?.node.id} />
          ))}
          {agentData?.agents.edges?.map((agent) => (
            <AgentCard agent={agent?.node} key={agent?.node.id} />
          ))}
        </div>
      </Panel.Body>

      {((officeData &&
        officeData?.offices.total > (officeData?.offices.edges?.length ?? 0)) ||
        (agentData &&
          agentData?.agents.total >
            (agentData?.agents.edges?.length ?? 0))) && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default InternalListingBucketManualEnrollment;
