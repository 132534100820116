import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  IdentificationIcon,
  LockOpenIcon,
  XCircleIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { ChangePasswordDialog } from "./dialogs";
import { classNames } from "../../common/utils/classnames";
import {
  AccountFragment,
  AccountState,
  useDeactivateAccountMutation,
  useImpersonateMutation,
  useReactivateAccountMutation,
  useResetEntitlementUsageMutation,
  useResetAccountFeatureWalkthroughsMutation,
  useSetPasswordForAccountMutation,
  useSetDefaultProductOfferingMutation,
  useGetAvailableProductOfferingsForAccountQuery,
} from "../../graphql/generated";

const AccountItemActions: React.FC<{
  account?: AccountFragment;
}> = ({ account }) => {
  const [{ error, fetching }, mutation] = useImpersonateMutation();
  const { updateNotification } = React.useContext(NotificationContext);
  const navigate = useNavigate();

  const [deactivateAccountOpen, setDeactivateAccountOpen] =
    React.useState(false);
  const deactivateAccountCancelButtonRef = React.useRef(null);

  const [reactivateAccountOpen, setReactivateAccountOpen] =
    React.useState(false);
  const reactivateAccountCancelButtonRef = React.useRef(null);

  const [productOfferingOpen, setProductOfferingOpen] = React.useState(false);

  const [, setDefaultOfferingMutation] = useSetDefaultProductOfferingMutation();
  const [selected, setSelected] = React.useState<string>(
    account?.purchaseStoreConfiguration?.defaultOfferingId ?? ""
  );
  const [{ data: productOfferingData }] =
    useGetAvailableProductOfferingsForAccountQuery({
      variables: {
        accountId: account?.id ?? "",
      },
    });
  const designateOffering = React.useCallback(async () => {
    const { error } = await setDefaultOfferingMutation({
      accountId: account?.id ?? "",
      productOfferingId: selected,
    });

    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Default product offering changed.",
        notificationType: NotificationType.Success,
      });
    }
  }, [account?.id, setDefaultOfferingMutation, selected, updateNotification]);

  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);

  const [, deactivate] = useDeactivateAccountMutation();
  const deactivateAccount = React.useCallback(async () => {
    const { error } = await deactivate({ id: account?.id || "" });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Account has been deactivated",
        notificationType: NotificationType.Success,
      });
    }
    setDeactivateAccountOpen(false);
  }, [account?.id, deactivate, updateNotification]);

  const [, reactivate] = useReactivateAccountMutation();
  const reactivateAccount = React.useCallback(async () => {
    const { error } = await reactivate({ id: account?.id || "" });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Account has been reactivated",
        notificationType: NotificationType.Success,
      });
    }
    setReactivateAccountOpen(false);
  }, [account?.id, reactivate, updateNotification]);

  const [, setPasswordForAccount] = useSetPasswordForAccountMutation();
  const [initialPassword, setInitialPassword] = React.useState("");
  const changePassword = React.useCallback(
    async (newPassword: string) => {
      setInitialPassword(newPassword);

      const { error } = await setPasswordForAccount({
        id: account?.id || "",
        password: newPassword,
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Password has been updated",
          notificationType: NotificationType.Success,
        });
      }
      setChangePasswordOpen(false);
    },
    [
      account?.id,
      setChangePasswordOpen,
      updateNotification,
      setInitialPassword,
      setPasswordForAccount,
    ]
  );

  React.useEffect(() => {
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [error, updateNotification]);

  const [, resetUsageMutation] = useResetEntitlementUsageMutation();

  const resetEntitlementUsage = React.useCallback(async () => {
    const { error } = await resetUsageMutation({
      id: account?.id ?? "",
    });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      navigate(0);
    }
  }, [account?.id, resetUsageMutation, navigate]);

  const [, resetAccountFeatureWalkthroughsMutation] =
    useResetAccountFeatureWalkthroughsMutation();

  const resetAccountFeatureWalkthroughs = React.useCallback(async () => {
    const { error } = await resetAccountFeatureWalkthroughsMutation({
      account_id: account?.id ?? "",
    });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      navigate(0);
    }
  }, [account?.id, resetAccountFeatureWalkthroughsMutation, navigate]);

  const impersonate = React.useCallback(async () => {
    if (!fetching) {
      const { data } = await mutation({ id: account?.id || "" });
      if (data) {
        navigate(`/identities/${data.impersonate.id}`, {
          state: { password: data.impersonate.password },
        });
      }
    }
  }, [account, fetching, mutation, navigate]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={impersonate}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <IdentificationIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Impersonate
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setChangePasswordOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <LockOpenIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Change password
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setProductOfferingOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <ArrowRightOnRectangleIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Change Assigned Product Offering
                  </div>
                )}
              </Menu.Item>
              {account?.accountState === AccountState.Active && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => setDeactivateAccountOpen(true)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <XCircleIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Deactivate Account
                    </div>
                  )}
                </Menu.Item>
              )}
              {account?.accountState === AccountState.Deactivating && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => setReactivateAccountOpen(true)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <CheckCircleIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Reactivate Account
                    </div>
                  )}
                </Menu.Item>
              )}
            </div>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={resetEntitlementUsage}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <IdentificationIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Reset Entitlement Usage
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={resetAccountFeatureWalkthroughs}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <IdentificationIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Reset Account Feature Walkthroughs
                </div>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>

      <ChangePasswordDialog
        isOpen={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
        onSubmit={changePassword}
        initialPassword={initialPassword}
      />

      <Transition.Root show={productOfferingOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setProductOfferingOpen}
        >
          <div className="absolute inset-0 overflow-hidden h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-none">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Product Offerings
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setProductOfferingOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1  flex flex-col overflow-scroll">
                      <div className="px-4 sm:px-6 py-4 sm:py-6 mt-4 grow overflow-scroll">
                        <RadioGroup value={selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only">
                            Product Offering -- Was Agent
                          </RadioGroup.Label>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {productOfferingData?.getAvailableProductOfferingsForAccount.map(
                              (offering) => (
                                <RadioGroup.Option
                                  key={offering?.id}
                                  value={offering?.id}
                                  className={({ checked, active }) =>
                                    classNames(
                                      checked
                                        ? "border-transparent"
                                        : "border-gray-300",
                                      active ? "ring-2 ring-indigo-500" : "",
                                      "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <div className="flex items-center">
                                        <div className="text-sm">
                                          <RadioGroup.Label
                                            as="div"
                                            className="font-medium text-gray-900"
                                          >
                                            {offering?.description}{" "}
                                          </RadioGroup.Label>
                                          <RadioGroup.Description
                                            as="div"
                                            className="text-gray-500"
                                          >
                                            <p className="sm:inline">
                                              {offering?.offeringType
                                                .__typename ==
                                              "OfferingTypeIndividualAgentDetails"
                                                ? "Individual"
                                                : "Enterprise"}
                                            </p>
                                          </RadioGroup.Description>
                                        </div>
                                      </div>
                                      <div
                                        className={classNames(
                                          active ? "border" : "border-2",
                                          checked
                                            ? "border-indigo-500"
                                            : "border-transparent",
                                          "absolute -inset-px rounded-lg pointer-events-none"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </>
                                  )}
                                </RadioGroup.Option>
                              )
                            )}
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="pt-5 px-4 sm:px-6">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setProductOfferingOpen(false)}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={designateOffering}
                            disabled={!selected}
                            type="submit"
                            className={classNames(
                              selected
                                ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                                : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
                              "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                            )}
                          >
                            Enable Selected
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={deactivateAccountOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={deactivateAccountCancelButtonRef}
          onClose={setDeactivateAccountOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Deactivate account
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to deactivate this account?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={deactivateAccount}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setDeactivateAccountOpen(false)}
                    ref={deactivateAccountCancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={reactivateAccountOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={reactivateAccountCancelButtonRef}
          onClose={setReactivateAccountOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Reactivate account
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to reactivate this account?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={reactivateAccount}
                  >
                    Reactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setReactivateAccountOpen(false)}
                    ref={reactivateAccountCancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AccountItemActions;
