import React from "react";
import { Link } from "react-router-dom";
import Timestamp from "../../../common/components/timestamp";
import { SubethaMailFragment } from "../../../graphql/generated";
import MailPic from "./pic";

const MailCard: React.FC<{ mail?: SubethaMailFragment; last?: boolean }> = ({
  mail,
  last,
}) => {
  return (
    <li>
      <div className="relative pb-8">
        {!last && (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex space-x-3">
          <div>
            <MailPic mail={mail} timeline />
          </div>
          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div>
              <p className="text-sm text-gray-500">
                {mail?.subject}{" "}
                <Link
                  to={`/subetha/mails/${mail?.id}`}
                  className="font-medium text-gray-900"
                >
                  {mail?.latestEvent ?? "NOT SENT"}
                </Link>
              </p>
            </div>
            <div className="text-right text-sm whitespace-nowrap text-gray-500">
              <Timestamp timestamp={mail?.createdAt} format="long" popover />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MailCard;
