import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  EnterpriseAgreementFragment,
  useEnterpriseAddBilledPriceMappingMutation,
  useEnterpriseAgreementStripePriceUsageQuery,
  useEnterpriseRemoveBilledPriceMappingMutation,
} from "../../graphql/generated";
import { XMarkIcon } from "@heroicons/react/24/solid";

const BillingTableEntryActions: React.FC<{
  agreement?: EnterpriseAgreementFragment;
}> = ({ agreement }) => {
  const [agentStripePriceIdState, setAgentStripePriceId] = React.useState("");
  const [billedStripePriceId, setBilledStripePriceId] = React.useState("");
  const [selected, setSelected] = React.useState<string>();

  const [{ data }] = useEnterpriseAgreementStripePriceUsageQuery({
    variables: {
      enterpriseAgreementId: agreement?.id ?? "",
    },
  });

  const [{ error }, mutation] = useEnterpriseAddBilledPriceMappingMutation();
  const [{ error: removeError }, removeMutation] =
    useEnterpriseRemoveBilledPriceMappingMutation();

  const submitForm = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (agreement) {
        mutation({
          enterpriseAgreementId: agreement.id,
          agentPriceId: agentStripePriceIdState ?? undefined,
          billedPriceId: billedStripePriceId ?? undefined,
        });
        setEditOpen(!editOpen);
      }
    },
    [agreement, agentStripePriceIdState, billedStripePriceId, mutation]
  );

  const [editOpen, setEditOpen] = React.useState(false);
  const [removeOpen, setRemoveOpen] = React.useState(false);
  const { updateNotification } = React.useContext(NotificationContext);

  const removeBillEntry = React.useCallback(() => {
    if (selected) {
      removeMutation({
        enterpriseAgreementId: agreement?.id ?? "",
        agentPriceId: selected,
      });
    }
  }, [removeMutation, selected]);

  React.useEffect(() => {
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
    if (removeError) {
      updateNotification({
        notification: removeError.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [error, removeError, updateNotification]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setEditOpen(!editOpen)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add Automated Stripe Billing
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setRemoveOpen(!removeOpen)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Remove Automated Stripe Billing
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <Transition.Root show={editOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setEditOpen}
        >
          <div className="absolute inset-0 overflow-hidden h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="bg-white shadow overflow-hidden sm:mx-4 sm:rounded-md">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="flex-1 min-w-0">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                          Add Mapping - Agent Price to EA Billed Price
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="p-4">
                    <form
                      className="space-y-8 divide-y divide-gray-200"
                      onSubmit={submitForm}
                    >
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div>
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="agent_stripe_price_id"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Agent Stripe Price ID
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                  required
                                  type="text"
                                  name="agent_stripe_price_id"
                                  id="agent_stripe_price_id"
                                  value={agentStripePriceIdState}
                                  onChange={(e) =>
                                    setAgentStripePriceId(e.target.value)
                                  }
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="billed_stripe_price_id"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Billed Stripe Price ID
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                  required
                                  type="text"
                                  name="billed_stripe_price_id"
                                  id="billed_stripe_price_id"
                                  value={billedStripePriceId}
                                  onChange={(e) =>
                                    setBilledStripePriceId(e.target.value)
                                  }
                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setEditOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className={classNames(
                              // eslint-disable-next-line no-constant-condition
                              true
                                ? "text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                : "text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                              "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
                            )}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
                      <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="flex-1 min-w-0">
                          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                            Live Usage
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="pt-5 space-y-2">
                      {data &&
                        data.enterpriseAgreementStripePriceUsage?.map(
                          (usageEntry) => (
                            <div
                              className="flex-1 min-w-0"
                              key={
                                usageEntry.productOfferingId +
                                usageEntry.baseAgentPriceId
                              }
                            >
                              <div className="grid grid-cols-1 gap-1 sm:grid-cols-1 px-6">
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  Product Offering Id:{" "}
                                  <a
                                    href={`https://admin.zenlist.team/product_offerings/${usageEntry?.productOfferingId}`}
                                    className="text-blue-900 hover:underline"
                                    rel="noreferrer"
                                  >
                                    {usageEntry?.productOfferingId}
                                  </a>
                                </p>
                                <p className="text-sm font-medium text-gray-900 truncate">
                                  Agent Price Id:{" "}
                                  <a
                                    href={`https://dashboard.stripe.com/prices/${usageEntry?.baseAgentPriceId}`}
                                    className="text-blue-900 hover:underline"
                                    rel="noreferrer"
                                  >
                                    {usageEntry?.baseAgentPriceId}
                                  </a>
                                </p>
                                <p className="text-sm truncate">
                                  Active Occurrences:{" "}
                                  {usageEntry?.activeOccurrences}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={removeOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setRemoveOpen}
        >
          <div className="absolute inset-0 overflow-hidden h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-none">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Billing Table Mappings
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setRemoveOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1  flex flex-col overflow-scroll">
                      <div className="px-4 sm:px-6 py-4 sm:py-6 mt-4 grow overflow-scroll">
                        <RadioGroup value={selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only">
                            Billing Entry
                          </RadioGroup.Label>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {agreement?.enterpriseBilling.__typename ===
                              "EnterpriseBillingStripeSubscription" &&
                              agreement.enterpriseBilling.billingTable.map(
                                (entry) => (
                                  <RadioGroup.Option
                                    key={entry?.agentPrice.id}
                                    value={entry.agentPrice.id}
                                    className={({ checked, active }) =>
                                      classNames(
                                        checked
                                          ? "border-transparent"
                                          : "border-gray-300",
                                        active ? "ring-2 ring-indigo-500" : "",
                                        "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                                      )
                                    }
                                  >
                                    {({ active, checked }) => (
                                      <>
                                        <div className="flex items-center">
                                          <div className="text-sm">
                                            <RadioGroup.Label
                                              as="div"
                                              className="font-medium text-gray-900"
                                            >
                                              {entry.agentPrice.nickname ??
                                                entry.agentPrice
                                                  .productName}{" "}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                              as="div"
                                              className="text-gray-500"
                                            >
                                              <p className="sm:inline">
                                                {entry.agentPrice.id}{" "}
                                                {entry.billedPrice.nickname ??
                                                  entry.billedPrice.productName}
                                              </p>{" "}
                                            </RadioGroup.Description>
                                          </div>
                                        </div>
                                        <div
                                          className={classNames(
                                            active ? "border" : "border-2",
                                            checked
                                              ? "border-indigo-500"
                                              : "border-transparent",
                                            "absolute -inset-px rounded-lg pointer-events-none"
                                          )}
                                          aria-hidden="true"
                                        />
                                      </>
                                    )}
                                  </RadioGroup.Option>
                                )
                              )}
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="pt-5 px-4 sm:px-6">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setRemoveOpen(false)}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={removeBillEntry}
                            disabled={!selected}
                            type="submit"
                            className={classNames(
                              selected
                                ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                                : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
                              "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                            )}
                          >
                            Remove Selected
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default BillingTableEntryActions;
