import React from "react";
import Panel from "../../common/components/panel";
import { SavedFilterFragment } from "../../graphql/generated";
import SavedFilterCard from "../../saved-filter/components/card";

const GroupItemSavedFilters: React.FC<{ filters: SavedFilterFragment[] }> = ({
  filters,
}) => {
  return (
    <Panel>
      <Panel.Title>
        Searches Found In{" "}
        <span className="text-xs text-gray-500">{filters.length}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {filters.map((filter) => (
            <SavedFilterCard filter={filter} key={filter.id} />
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default GroupItemSavedFilters;
