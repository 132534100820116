import React from "react";
import { Link } from "react-router-dom";
import { OfficeFragment } from "../../graphql/generated";
import OfficePic from "./pic";

const OfficeCard: React.FC<{ office?: OfficeFragment }> = ({ office }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <OfficePic office={office} />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/offices/${office?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {office?.name?.trim() === "" ? "Empty Name" : office?.name} -{" "}
            {office?.mlsId}
          </p>
          <p className="text-sm text-gray-500 truncate">
            {office?.address?.city}, {office?.address?.state}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default OfficeCard;
