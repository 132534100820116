import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import OfficeLogo from "./logo";
import { Link } from "react-router-dom";
import {
  AgentMoveNotificationValue,
  AgentReferenceFragment,
  ExternalCommunicationState,
  InheritMode,
  ListingQueryPermissionValue,
  OfficeQuery,
} from "../../graphql/generated";
import { LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import Panel from "../../common/components/panel";

const OfficeSummary: React.FC<{ office?: OfficeQuery["office"] }> = ({
  office,
}) => {
  function externalCommunicationStateName(
    state?: ExternalCommunicationState
  ): string {
    if (state === ExternalCommunicationState.Allowed) {
      return "Allowed";
    } else if (state === ExternalCommunicationState.Restricted) {
      return "Restricted";
    } else {
      return state ?? "None";
    }
  }

  // function listingInputPermissionValueName(
  //   state?: ListingInputPermissionValue
  // ): string {
  //   if (state === ListingInputPermissionValue.Allowed) {
  //     return "Allowed";
  //   } else if (state === ListingInputPermissionValue.Restricted) {
  //     return "Restricted";
  //   } else {
  //     return state ?? "None";
  //   }
  // }

  function listingQueryPermissionValueName(
    value?: ListingQueryPermissionValue
  ): string {
    if (value === ListingQueryPermissionValue.AllListings) {
      return "All Listings";
    } else if (value === ListingQueryPermissionValue.PublicListingsOnly) {
      return "Public Listings";
    } else {
      return value ?? "None";
    }
  }

  function agentMoveNotificationValueName(
    value?: AgentMoveNotificationValue
  ): string {
    if (value === AgentMoveNotificationValue.All) {
      return "Join & Leave";
    } else if (value === AgentMoveNotificationValue.Join) {
      return "Join";
    } else if (value === AgentMoveNotificationValue.Leave) {
      return "Leave";
    } else if (value === AgentMoveNotificationValue.None) {
      return "None";
    } else {
      return value ?? "None";
    }
  }

  return (
    <>
      <Panel>
        <Panel.Title>MLS Data</Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListContainer title="ID">
              {office?.mls.shortName} #{office?.mlsId}
            </DescriptionListContainer>
            <DescriptionListItem
              title="Parent Office"
              value={
                office?.parentOffice
                  ? `${office.parentOffice.name ?? ""} - ${
                      office.parentOffice.address?.city
                    }, ${office.parentOffice.address?.state}`
                  : "None"
              }
              link={
                office?.parentOffice
                  ? `/offices/${office.parentOffice.id}`
                  : undefined
              }
            />
            <DescriptionListContainer
              title="Office broker"
              info="The agent responsible for this office or the primary contact for the office."
            >
              <AgentReference agent={office?.officeBroker} />
            </DescriptionListContainer>
            <DescriptionListContainer title="Address">
              {office?.address?.address1}
              <br />
              {office?.address?.address2 && (
                <>
                  {office?.address?.address2} <br />
                </>
              )}
              {office?.address?.city}, {office?.address?.state}{" "}
              {office?.address?.zipCode}
            </DescriptionListContainer>
            <DescriptionListContainer title="Logo">
              {office?.media && office?.name ? (
                <OfficeLogo media={office.media} altText={office.name} />
              ) : (
                "None"
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Searchable"
              info="Whether this office is included in searches."
            >
              {(office?.hidden ?? false) && (
                <>No ({office?.hiddenReason ?? "Unknown reason"})</>
              )}
              {!(office?.hidden ?? false) && <>Yes</>}
            </DescriptionListContainer>
          </dl>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Zenlist Data</Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListItem
              title="Brokerage"
              info="The office that this agent belongs to. This field is historical and almost never actually used."
              value={office?.brokerage?.name}
              link={`/brokerages/${office?.brokerage?.id}`}
            />
            <DescriptionListContainer title="Enterprise Agreement">
              {office?.enterpriseAgreement && (
                <Link
                  to={`/enterprise_agreements/${office.enterpriseAgreement.id}`}
                  className="text-blue-900 hover:underline"
                >
                  {office.enterpriseAgreement.name}
                </Link>
              )}
              {!office?.enterpriseAgreement && "None"}{" "}
              {office?.enterpriseAgreementMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.enterpriseAgreementMode === InheritMode.Manual && (
                <abbr title="Set for this office and child offices">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.enterpriseAgreementMode === InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>

            <DescriptionListContainer
              title="Display Name"
              info="A shortened name to display in the app, especially in the 'Zenlist brought to you by...' part of the sidebar."
            >
              {office?.displayName ?? "None"}{" "}
              {office?.displayNameMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.displayNameMode === InheritMode.Manual && (
                <abbr title="Set for this office and child offices">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.displayNameMode === InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="External Communication"
              info="Whether we send 'somebody viewed/liked/requested a tour on your listing' emails to agents to encourage them to join Zenlist."
            >
              {externalCommunicationStateName(office?.externalCommunication)}{" "}
              {office?.externalCommunicationMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.externalCommunicationMode === InheritMode.Manual && (
                <abbr title="Set for this office and child offices">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.externalCommunicationMode === InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Internal Listing Buckets"
              info="When this agent enters or searches for internal listings, what are they allowed to see?"
            >
              {office?.internalListingBuckets.length === 0 && "—"}
              {office?.internalListingBuckets.map((bucket, idx) => {
                return (
                  <span key={bucket.id}>
                    {idx > 0 && ", "}
                    <Link
                      to={`/internal_listing_buckets/${bucket.id}`}
                      className="text-blue-900 hover:underline"
                    >
                      {bucket.name}
                    </Link>
                  </span>
                );
              })}
              {office?.internalListingBucketsMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.internalListingBucketsMode === InheritMode.Manual && (
                <abbr title="Set for this office and child offices">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.internalListingBucketsMode ===
                InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Listing Query Permission"
              info="Are agents in this office allowed to query for all listings or only public listings?"
            >
              {listingQueryPermissionValueName(office?.listingQueryPermission)}{" "}
              {office?.listingQueryPermissionMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.listingQueryPermissionMode === InheritMode.Manual && (
                <abbr title="Set for this office and child offices">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.listingQueryPermissionMode ===
                InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Agent Move Notification"
              info="Whether a message gets sent to #cs_officemoves when agents join or leave this office."
            >
              {agentMoveNotificationValueName(office?.agentMoveNotification)}{" "}
              {office?.agentMoveNotificationMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.agentMoveNotificationMode === InheritMode.Manual && (
                <abbr title="Set for this office and child offices">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {office?.agentMoveNotificationMode === InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListItem
              title="Signup"
              info="Upon attempting to sign up for a Zenlist account, will the MLS ID of this agent, or agents in this office, be able to be looked up to complete the account creation process? Offices or agents that are not enabled likely require an MLS Authorization prior to being allowed to sign up."
              value={
                office?.enabled
                  ? `Enabled (${office?.enablementSource})`
                  : `Disabled (${office?.enablementSource})`
              }
            />
            <DescriptionListItem
              title="Authorization"
              value={
                office?.authorized
                  ? `Authorized (${office?.authorizationSource})`
                  : `Unauthorized (${office?.authorizationSource})`
              }
              info="Has the required MLS data authorization been completed and approved for this office or agent, allowing them to access MLS data in Zenlist?"
            />
            <DescriptionListContainer
              title="Authorization Document"
              info="Link to the required MLS data authorization that approves this office or agent for use of MLS data in Zenlist"
            >
              {office?.authorizationDocument ? (
                <a
                  href={`${office.authorizationDocument}`}
                  className="text-blue-900 hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Document
                </a>
              ) : (
                "None"
              )}
            </DescriptionListContainer>
          </dl>
        </Panel.Body>
      </Panel>
    </>
  );
};

const AgentReference: React.FC<{
  agent?: AgentReferenceFragment | null;
}> = ({ agent }) => {
  if (!agent) {
    return <>—</>;
  }
  if (agent && !agent.agent) {
    return <>{agent.id}</>;
  }
  return (
    <Link to={`/agents/${agent?.id}`} className="text-blue-900 hover:underline">
      {agent?.agent?.name}
    </Link>
  );
};

export default OfficeSummary;
