import React from "react";
import Panel from "../../common/components/panel";
import { longDateFormat } from "../../common/utils/dateformat";
import { EntitlementGrantFragment } from "../../graphql/generated";

const AccountEntitlementGrant: React.FC<{
  grants: Array<EntitlementGrantFragment>;
}> = ({ grants }) => {
  return (
    <Panel>
      <Panel.Title>Entitlement Grants</Panel.Title>

      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {grants &&
            grants.map((grant, i) => (
              <div
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3"
                key={i}
              >
                {grant.entitlementGrantSource.__typename ===
                  "EntitlementGrantSourceManual" && (
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900">
                      {grant.entitlementGroup.label} from Manual
                    </p>
                    {grant.productOfferingId && (
                      <p className="text-sm text-gray-900">
                        Provided from:{" "}
                        <a
                          href={`/product_offerings/${grant.productOfferingId}`}
                        >
                          {grant.productOfferingId}
                        </a>
                      </p>
                    )}
                    {grant.entitlementGrantSource.validUntilOption && (
                      <p className="text-sm text-gray-500 truncate">
                        Valid Until:{" "}
                        {longDateFormat(
                          grant.entitlementGrantSource.validUntilOption
                        )}
                      </p>
                    )}
                  </div>
                )}
                {grant.entitlementGrantSource.__typename ===
                  "EntitlementGrantSourceProvisional" && (
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900">
                      {grant.entitlementGroup.label} from Provisional
                    </p>
                    {grant.productOfferingId && (
                      <p className="text-sm text-gray-500 truncate">
                        Provided from:{" "}
                        <a
                          href={`/product_offerings/${grant.productOfferingId}`}
                        >
                          {grant.productOfferingId}
                        </a>
                      </p>
                    )}
                    <p className="text-sm text-gray-500 truncate">
                      Valid Until:{" "}
                      {longDateFormat(grant.entitlementGrantSource.validUntil)}
                    </p>
                  </div>
                )}
                {grant.entitlementGrantSource.__typename ===
                  "EntitlementGrantSourceSubscription" && (
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900">
                      {grant.entitlementGroup.label} from Subscription
                    </p>
                    {grant.productOfferingId && (
                      <p className="text-sm text-gray-500 truncate">
                        Provided from:{" "}
                        <a
                          href={`/product_offerings/${grant.productOfferingId}`}
                        >
                          {grant.productOfferingId}
                        </a>
                      </p>
                    )}
                    <p className="text-sm text-gray-500 truncate">
                      Internal Subscription:{" "}
                      <a
                        href={`/account_subscriptions/${grant.entitlementGrantSource.accountSubscriptionId}`}
                      >
                        {grant.entitlementGrantSource.accountSubscriptionId}
                      </a>
                    </p>
                    {grant.entitlementGrantSource.validUntilOption && (
                      <p className="text-sm text-gray-500 truncate">
                        Valid Until:{" "}
                        {longDateFormat(
                          grant.entitlementGrantSource.validUntilOption
                        )}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountEntitlementGrant;
