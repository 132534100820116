import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  UserPlusIcon,
  UserMinusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useClient } from "urql";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { SearchContext } from "../../common/context/search";
import { classNames } from "../../common/utils/classnames";
import {
  AccountsDocument,
  useAccountsQuery,
  useEnableAccountForFeatureFlagMutation,
  useDisableAccountForFeatureFlagMutation,
  FeatureFlagFragment,
} from "../../graphql/generated";
import { MlsContext } from "../../mls/context/mls";
import AccountProfilePic from "../../account/components/profilepic";
import { shortDateFormat } from "../../common/utils/dateformat";

const AccountsActions: React.FC<{
  featureFlag?: FeatureFlagFragment;
  enabled?: boolean;
}> = ({ featureFlag, enabled }) => {
  const graphqlClient = useClient();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<string>();

  const { mls } = React.useContext(MlsContext);
  const { search, setSearch, searchBounced } = React.useContext(SearchContext);
  const { updateNotification } = React.useContext(NotificationContext);

  const [{ data }] = useAccountsQuery({
    variables: {
      first: 20,
      search: searchBounced,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.accounts.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(AccountsDocument, {
          first: 20,
          after: data.accounts.pageInfo.endCursor,
          search: searchBounced,
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, searchBounced]);

  const [
    { error: enableAccountError, fetching: enableAccountFetching },
    enableAccountMutation,
  ] = useEnableAccountForFeatureFlagMutation();

  const [
    { error: disableAccountError, fetching: disableAccountFetching },
    disableAccountMutation,
  ] = useDisableAccountForFeatureFlagMutation();

  React.useEffect(() => {
    if (enableAccountError) {
      updateNotification({
        notification: enableAccountError.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [enableAccountError, updateNotification]);

  React.useEffect(() => {
    if (disableAccountError) {
      updateNotification({
        notification: disableAccountError.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [disableAccountError, updateNotification]);

  const enableAccount = React.useCallback(() => {
    if (!enableAccountFetching && selected) {
      enableAccountMutation({
        featureFlagId: featureFlag?.id ?? "",
        accountId: selected,
      });
    }
  }, [featureFlag, enableAccountFetching, enableAccountMutation, selected]);

  const disableAccount = React.useCallback(() => {
    if (!disableAccountFetching && selected) {
      disableAccountMutation({
        featureFlagId: featureFlag?.id ?? "",
        accountId: selected,
      });
    }
  }, [featureFlag, disableAccountFetching, disableAccountMutation, selected]);

  const action = enabled ? enableAccount : disableAccount;
  const text = enabled ? "Enable account" : "Disable account";

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setOpen(!open)}
                  >
                    {enabled ? (
                      <UserPlusIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <UserMinusIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                    {text}
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <Transition.Root show={open} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-none">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {text}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1  flex flex-col overflow-scroll">
                      <div className="px-4 sm:px-6">
                        <div className="mt-1 relative rounded-md shadow-sm flex-1">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                            placeholder={`Search for agents in ${mls?.shortName}`}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="px-4 sm:px-6 py-4 sm:py-6 mt-4 grow overflow-scroll">
                        <RadioGroup value={selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only">
                            Account
                          </RadioGroup.Label>
                          <div className="space-y-2">
                            {data?.accounts.edges?.map((account) => (
                              <RadioGroup.Option
                                key={account?.node.id}
                                value={account?.node.id}
                                className={({ checked, active }) =>
                                  classNames(
                                    checked
                                      ? "border-transparent"
                                      : "border-gray-300",
                                    active ? "ring-2 ring-indigo-500" : "",
                                    "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex items-center">
                                      <div className="w-full flex items-center justify-between space-x-6">
                                        <AccountProfilePic
                                          account={account?.node}
                                        />
                                        <div className="flex-1 truncate">
                                          <div className="flex items-center space-x-3">
                                            <h3 className="text-gray-900 text-sm font-medium truncate">
                                              {account?.node.name.trim() === ""
                                                ? "Empty Name"
                                                : account?.node.name}
                                            </h3>
                                            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                              {account?.node.accountType ===
                                              "AGENT"
                                                ? "Agent"
                                                : "Member"}
                                            </span>
                                          </div>
                                          <p className="mt-1 text-gray-500 text-sm truncate">
                                            {shortDateFormat(
                                              account?.node.createdAt
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={classNames(
                                        active ? "border" : "border-2",
                                        checked
                                          ? "border-indigo-500"
                                          : "border-transparent",
                                        "absolute -inset-px rounded-lg pointer-events-none"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>

                        <div className="min-w-full divide-y divide-gray-200">
                          <div className="p-4">
                            <button
                              className={classNames(
                                loading
                                  ? "bg-gray-500 text-white"
                                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                                "font-bold py-2 px-4 rounded"
                              )}
                              onClick={() => fetchMore()}
                              disabled={loading}
                            >
                              Fetch More
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="pt-5 px-4 sm:px-6">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setOpen(false)}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={action}
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AccountsActions;
