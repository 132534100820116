import React from "react";
import { useClient } from "urql";
import { classNames } from "../../common/utils/classnames";
import TourCard from "../../tour/components/card";
import {
  ToursDocument,
  useToursQuery,
  GroupFragment,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";

const GroupTours: React.FC<{ group?: GroupFragment }> = ({ group }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data }] = useToursQuery({
    variables: {
      first: 2,
      groupId: group?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.tours.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(ToursDocument, {
          first: 20,
          after: data.tours.pageInfo.endCursor,
          groupId: group?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, group]);

  return (
    <Panel>
      <Panel.Title>
        Tours <span className="text-xs text-gray-500">{data?.tours.total}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.tours.edges?.map((tour) => (
            <TourCard tour={tour?.node} key={tour?.node.id} />
          ))}
        </div>
      </Panel.Body>

      {data && data?.tours.total > (data?.tours.edges?.length ?? 0) && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default GroupTours;
