import React from "react";
import AvatarPlaceholder from "../../common/components/avatarplaceholder";
import { Status } from "../../common/components/status";
import { classNames } from "../../common/utils/classnames";
import { AccountFragment } from "../../graphql/generated";

const AccountProfilePic: React.FC<{
  account?: AccountFragment;
  large?: boolean;
  small?: boolean;
}> = ({ account, large, small }) => {
  const status = React.useMemo(() => {
    return Status.for(account);
  }, [account]);

  return (
    <>
      {account?.profilePicture && (
        <img
          className={classNames(
            status.color.picClasses(),
            large
              ? "h-20 w-20  border-2"
              : small
              ? "h-6 w-6  border"
              : "h-10 w-10  border-2",
            "rounded-full"
          )}
          src={account?.profilePicture}
          alt={account?.name}
        />
      )}
      {!account?.profilePicture && (
        <span
          className={classNames(
            status.color.picClasses(),
            large
              ? "h-20 w-20  border-2"
              : small
              ? "h-6 w-6  border"
              : "h-10 w-10  border-2",
            "inline-block rounded-full overflow-hidden"
          )}
        >
          <AvatarPlaceholder />
        </span>
      )}
    </>
  );
};

export default AccountProfilePic;
