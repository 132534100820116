import React from "react";
import Panel from "../../common/components/panel";
import { LenderAgentCollaborationFragment } from "../../graphql/generated";
import AccountCard from "../../account/components/card";

const LenderAgentCollaborations: React.FC<{
  lenderAgentCollaborations?: Array<LenderAgentCollaborationFragment>
}> = ({ lenderAgentCollaborations }) => {
  return (
    <Panel>
      <Panel.Title>
        Lender/Agent Collaborations: 
        <span className="text-xs text-gray-500"> {lenderAgentCollaborations?.length}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
        {
          lenderAgentCollaborations?.map((lenderAgentCollaboration) => {
              return <AccountCard account={lenderAgentCollaboration.agentAccount} key={lenderAgentCollaboration.agentAccount.id}  lenderAgentCollaborationState={lenderAgentCollaboration.state} />
          })
        }
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default LenderAgentCollaborations;
