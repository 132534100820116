import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Panel from "../../common/components/panel";
import { MlsFragment } from "../../graphql/generated";

const MlsDisclosure: React.FC<{ mls?: MlsFragment }> = ({ mls }) => {
  return (
    <Panel>
      <Panel.Title>Disclosure</Panel.Title>
      <Panel.Body summary>
        <ReactMarkdown
          children={mls?.disclosure ?? "None"}
          remarkPlugins={[remarkGfm]}
        />
      </Panel.Body>
    </Panel>
  );
};

export default MlsDisclosure;
