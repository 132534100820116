import { GraphQLError } from "graphql";
import React from "react";
import { CombinedError } from "urql";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";

const Error: React.FC<{ error: CombinedError | undefined }> = ({ error }) => {
  if (!error) {
    return <></>;
  }

  return (
    <Transition
      show={true}
      appear
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
    >
      <div className="max-w-7xl mx-auto py-4">
        <div className="bg-white shadow sm:mx-4 sm:rounded-lg">
          <div className="border border-red-300 rounded-lg px-4 py-5 sm:px-6">
            <h2 className="text-red-500 text-xl mb-4">
              {
                <ExclamationCircleIcon className="rounded-full h-10 w-10 inline" />
              }{" "}
              Errors
            </h2>

            {error.networkError && (
              <div>
                <h3>Error: {error.networkError?.message}</h3>
              </div>
            )}
            {error.graphQLErrors &&
              error.graphQLErrors.map((error) => (
                <GraphQLErrorComponent
                  error={error}
                  key={JSON.stringify(error.path)}
                />
              ))}
          </div>
        </div>
      </div>
    </Transition>
  );
};

const GraphQLErrorComponent: React.FC<{ error: GraphQLError }> = ({
  error,
}) => {
  let extensions;
  if (error.extensions) {
    extensions = JSON.stringify(error.extensions);
  }
  let path;
  if (error.path) {
    path = JSON.stringify(error.path);
  }

  return (
    <div>
      <h3 className="text-lg text-red-800">{error.message}</h3>
      {!!path && (
        <p className="text-xs text-gray-500">
          at <code>{path}</code>
        </p>
      )}
      {!!extensions && (
        <p className="text-xs text-gray-500">
          <code>{extensions}</code>
        </p>
      )}
    </div>
  );
};

export default Error;
