import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { PropertyTypeSelect, State, nanoid } from ".";
import Panel from "../../../common/components/panel";
import { classNames } from "../../../common/utils/classnames";
import React from "react";
import {
  ListingInputDefinitionCustomRuleFragment,
  ListingInputDefinitionCustomRuleInput,
  ListingInputDefinitionRuleAction,
} from "../../../graphql/generated";
import {
  ZenDialog,
  ZenDialogState,
} from "../../../common/components/zen-dialog";

export const CustomRulesPanel: React.FC<{
  state: State;
  setState: (state: State) => void;
  setIsModified: (isModified: boolean) => void;
}> = ({ state, setState, setIsModified }) => {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [addCustomRuleOpen, setAddCustomRuleOpen] = React.useState(false);

  const onAddCustomRuleSubmit = React.useCallback(
    (update: ListingInputDefinitionCustomRuleInput) => {
      state.customRuleUpdates.set(update.id, update);
      setState(state);
      setAddCustomRuleOpen(false);
      setIsModified(true);
    },
    [state, setState, setAddCustomRuleOpen, setIsModified]
  );

  const onCustomRuleUpdateSubmit = React.useCallback(
    (update: ListingInputDefinitionCustomRuleInput) => {
      state.customRuleUpdates.set(update.id, update);
      setState(state);
      setIsModified(true);
    },
    [state, setState, setIsModified]
  );

  return (
    <>
      <Panel>
        <Panel.Title>
          <span
            onClick={() => setIsPanelOpen(!isPanelOpen)}
            className="cursor-pointer"
          >
            {isPanelOpen && <ChevronDownIcon className="inline h-5 w-5" />}
            {!isPanelOpen && <ChevronRightIcon className="inline h-5 w-5" />}
            Custom rules
          </span>
        </Panel.Title>
        <Panel.Body summary className={classNames(isPanelOpen ? "" : "hidden")}>
          <div className="my-4">
            <button
              type="button"
              className="bg-zenlist-500 hover:bg-zenlist-700 text-white font-bold py-1 px-4 rounded mx-1"
              onClick={() => setAddCustomRuleOpen(true)}
            >
              Add custom rule
            </button>
          </div>
          <div>
            {Array.from(state.customRules).map(([, item]) => (
              <CustomRuleElement
                customRule={item}
                state={state}
                onCustomRuleUpdateSubmit={onCustomRuleUpdateSubmit}
                key={item.id}
              />
            ))}
          </div>
        </Panel.Body>
      </Panel>

      <NewCustomRuleDialog
        isOpen={addCustomRuleOpen}
        state={state}
        onClose={() => setAddCustomRuleOpen(false)}
        onSubmit={onAddCustomRuleSubmit}
      />
    </>
  );
};

const CustomRuleElement: React.FC<{
  customRule: ListingInputDefinitionCustomRuleFragment;
  state: State;
  onCustomRuleUpdateSubmit: (
    update: ListingInputDefinitionCustomRuleInput
  ) => void;
}> = ({ customRule, state, onCustomRuleUpdateSubmit }) => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const existingUpdate = state.customRuleUpdates.get(customRule.id);
  const isDeleted = !!existingUpdate && !!existingUpdate.delete;
  const isModified = !!existingUpdate && !existingUpdate.delete;

  const onEditSubmit = React.useCallback(
    (update: ListingInputDefinitionCustomRuleInput) => {
      onCustomRuleUpdateSubmit(update);
      setEditDialogOpen(false);
    },
    [setEditDialogOpen, onCustomRuleUpdateSubmit]
  );
  const onDeleteSubmit = React.useCallback(
    (update: ListingInputDefinitionCustomRuleInput) => {
      onCustomRuleUpdateSubmit(update);
      setDeleteDialogOpen(false);
    },
    [setDeleteDialogOpen, onCustomRuleUpdateSubmit]
  );

  return (
    <>
      <div className="p-1">
        <div
          className={classNames(
            "rounded-2 bg-gray-100 border border-gray-200 p-2 text-sm"
          )}
        >
          <div className="flex gap-1">
            <div className="grow">
              <div className="flex mt-1 gap-1">
                <span>{existingUpdate?.field ?? customRule.field}</span>
                <span className="text-xs bg-gray-200 border border-gray-300 px-1 py-0.5">
                  {existingUpdate?.action ?? customRule.action}
                </span>
                {isModified && (
                  <span className="text-xs bg-red-200 border border-red-300 px-1 py-0.5">
                    Modified
                  </span>
                )}
                {isDeleted && (
                  <span className="text-xs bg-red-200 border border-red-300 px-1 py-0.5">
                    Deleted
                  </span>
                )}
              </div>
              <div className="text-xs text-gray-500">
                {existingUpdate?.message ?? customRule.message}
              </div>
            </div>
            <div
              className="flex-none cursor-pointer"
              onClick={() => setEditDialogOpen(true)}
            >
              <PencilIcon className="w-5 h-5" />
            </div>
            <div
              className="flex-none cursor-pointer"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <TrashIcon className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>

      <EditCustomRuleDialog
        isOpen={editDialogOpen}
        customRule={customRule}
        state={state}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={onEditSubmit}
      />
      <DeleteCustomRuleDialog
        isOpen={deleteDialogOpen}
        customRule={customRule}
        state={state}
        onClose={() => setDeleteDialogOpen(false)}
        onSubmit={onDeleteSubmit}
      />
    </>
  );
};

const NewCustomRuleDialog: React.FC<{
  isOpen: boolean;
  state: State;
  onClose: () => void;
  onSubmit: (update: ListingInputDefinitionCustomRuleInput) => void;
}> = ({ isOpen, state, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);

  const [field, setField] = React.useState("");
  const [action, setAction] = React.useState(
    ListingInputDefinitionRuleAction.Warning
  );
  const [expression, setExpression] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [propertyTypes, setPropertyTypes] = React.useState<string[]>([]);

  const handleSubmit = React.useCallback(() => {
    const update: ListingInputDefinitionCustomRuleInput = {
      id: `customrule_${nanoid()}`,
      delete: false,
      field,
      action,
      expression,
      message,
      propertyTypes,
    };
    onSubmit(update);
  }, [onSubmit, field, action, expression, message, propertyTypes]);

  const isValid =
    field != "" &&
    expression != "" &&
    message != "" &&
    propertyTypes.length > 0;

  return (
    <ZenDialog
      show={isOpen}
      title="Add custom rule"
      icon={PlusCircleIcon}
      submit="Add"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
      state={isValid ? ZenDialogState.Displaying : ZenDialogState.Invalid}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">ID</div>
        <div className="font-bold">(new)</div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Field</div>
        <label className="block py-1">
          <input
            type="text"
            value={field}
            onChange={(event) => setField(event?.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Action</div>
        <label className="block py-1">
          <RuleActionSelect
            selected={action}
            onChange={(action) => setAction(action)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Message</div>
        <label className="block py-1">
          <input
            type="text"
            value={message}
            onChange={(event) => setMessage(event?.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Expression</div>
        <label className="block py-1">
          <input
            type="text"
            value={expression}
            onChange={(event) => setExpression(event?.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Property types</div>
        <PropertyTypeSelect
          state={state}
          selected={propertyTypes}
          onChange={(propertyTypes) => setPropertyTypes(propertyTypes)}
        />
      </div>
    </ZenDialog>
  );
};

const EditCustomRuleDialog: React.FC<{
  isOpen: boolean;
  customRule: ListingInputDefinitionCustomRuleFragment;
  state: State;
  onClose: () => void;
  onSubmit: (update: ListingInputDefinitionCustomRuleInput) => void;
}> = ({ isOpen, customRule, state, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);

  const [field, setField] = React.useState(customRule.field);
  const [action, setAction] = React.useState(customRule.action);
  const [expression, setExpression] = React.useState(customRule.expression);
  const [message, setMessage] = React.useState(customRule.message);
  const [propertyTypes, setPropertyTypes] = React.useState(
    customRule.propertyTypes
  );

  const handleSubmit = React.useCallback(() => {
    const update: ListingInputDefinitionCustomRuleInput = {
      id: customRule.id,
      delete: false,
      action,
      expression,
      message,
      propertyTypes,
    };
    onSubmit(update);
  }, [onSubmit, field, action, expression, message, propertyTypes]);

  return (
    <ZenDialog
      show={isOpen}
      title="Update custom rule"
      icon={PencilSquareIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">ID</div>
        <div className="font-bold">{customRule.id}</div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Field</div>
        <label className="block py-1">
          <input
            type="text"
            value={field}
            onChange={(event) => setField(event?.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Action</div>
        <label className="block py-1">
          <RuleActionSelect
            selected={action}
            onChange={(action) => setAction(action)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Message</div>
        <label className="block py-1">
          <input
            type="text"
            value={message}
            onChange={(event) => setMessage(event?.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Expression</div>
        <label className="block py-1">
          <input
            type="text"
            value={expression}
            onChange={(event) => setExpression(event?.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Value</div>
        <label className="block py-1">
          <PicklistValues
            fieldName={customRule.field}
            value={customRule.value}
            state={state}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Property types</div>
        <PropertyTypeSelect
          state={state}
          selected={propertyTypes}
          onChange={setPropertyTypes}
        />
      </div>
    </ZenDialog>
  );
};

const DeleteCustomRuleDialog: React.FC<{
  isOpen: boolean;
  customRule: ListingInputDefinitionCustomRuleFragment;
  state: State;
  onClose: () => void;
  onSubmit: (update: ListingInputDefinitionCustomRuleInput) => void;
}> = ({ isOpen, customRule, state, onClose, onSubmit }) => {
  const handleSubmit = React.useCallback(() => {
    const update: ListingInputDefinitionCustomRuleInput = {
      id: customRule.id,
      delete: true,
    };
    onSubmit(update);
  }, [onSubmit]);

  const existingUpdate = state.customRuleUpdates.get(customRule.id);

  return (
    <ZenDialog
      show={isOpen}
      title="Delete custom rule"
      icon={TrashIcon}
      submit="Delete"
      onSubmit={handleSubmit}
      onCancel={onClose}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">ID</div>
        <div className="font-bold">{customRule.id}</div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Field</div>
        <div className="font-bold">
          {existingUpdate?.field ?? customRule.field}
        </div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Action</div>
        <div className="font-bold">
          {existingUpdate?.action ?? customRule.action}
        </div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Message</div>
        <div className="font-bold">
          {existingUpdate?.message ?? customRule.message}
        </div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Expression</div>
        <div className="font-bold">
          {existingUpdate?.expression ?? customRule.expression}
        </div>
      </div>
    </ZenDialog>
  );
};

const RuleActionSelect: React.FC<{
  selected: ListingInputDefinitionRuleAction;
  onChange: (value: ListingInputDefinitionRuleAction) => void;
}> = ({ selected, onChange }) => {
  const actions = [
    ListingInputDefinitionRuleAction.Accept,
    ListingInputDefinitionRuleAction.Reject,
    ListingInputDefinitionRuleAction.Warning,
    ListingInputDefinitionRuleAction.SetRequired,
    ListingInputDefinitionRuleAction.SetReadOnly,
    ListingInputDefinitionRuleAction.SetDisplay,
    ListingInputDefinitionRuleAction.Set,
    ListingInputDefinitionRuleAction.SetDefault,
    ListingInputDefinitionRuleAction.SetPicklist,
    ListingInputDefinitionRuleAction.RestrictPicklist,
  ];

  return (
    <select
      onChange={(e) =>
        onChange(e.target.value as ListingInputDefinitionRuleAction)
      }
      value={selected}
    >
      {actions.map((action) => (
        <option value={action} key={action}>
          {action}
        </option>
      ))}
    </select>
  );
};

const PicklistValues: React.FC<{
  fieldName: string;
  value: string[] | null | undefined;
  state: State;
}> = ({ fieldName, value, state }) => {
  const field = state.fields.get(fieldName);

  return (
    <ul>
      {value &&
        value.map((value) => {
          const lookup = field?.lookups.find((lookup) => lookup.name === value);
          if (lookup) {
            return (
              <li key={value}>
                {value} ({lookup.sourceDisplayName ?? lookup.name})
              </li>
            );
          } else {
            return <li key={value}>{value}</li>;
          }
        })}
    </ul>
  );
};
