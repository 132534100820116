import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../common/utils/dateformat";
import { GroupState, GroupType, GroupWithMembersFragment } from "../../graphql/generated";
import GroupPic from "./pic";

const renderLabel = (groupType: GroupType | undefined) => {
  switch(groupType) {
    case GroupType.Agent:
      return "Agent:"
    case GroupType.Lender:
      return "Lender:"
    default:
      return ""
  }
}

const GroupCard: React.FC<{ group?: GroupWithMembersFragment }> = ({
  group,
}) => {
  const lastActiveMember = React.useMemo(() => {
    if (group && group.members.length > 0) {
      return group.members
        .sort((a, b) => ((a.activeAt ?? "") < (b.activeAt ?? "") ? 1 : -1))
        .at(0);
    }
  }, [group]);

  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <GroupPic group={group} />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/groups/${group?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {group?.nameLong ?? group?.groupState}
          </p>
          <div className="grid grid-cols-3">

            <p className="text-xs text-gray-500 truncate">{renderLabel(group?.groupType)}</p>
            <p className="text-xs text-gray-500 truncate col-span-2">
              {group?.owner.name}
            </p>
            {lastActiveMember && (
              <>
                <p className="text-xs text-gray-500 truncate">
                  Last Active Member:
                </p>
                <p className="text-xs text-gray-500 truncate col-span-2">
                  {lastActiveMember.name} on{" "}
                  {longDateFormat(lastActiveMember.activeAt)}
                </p>
              </>
            )}
            {group?.groupState === GroupState.Active && (
              <>
                <p className="text-xs text-gray-500 truncate">
                  Combined Sessions:
                </p>
                <p className="text-xs text-gray-500 truncate col-span-2">
                  {group &&
                    group.members.reduce(
                      (count, m) => (count += m.sessionCount),
                      0
                    )}
                </p>
              </>
            )}
            {group?.groupState === GroupState.Archived && (
              <>
                <p className="text-xs text-gray-500 truncate">Archived:</p>
                <p className="text-xs text-gray-500 truncate col-span-2">
                  {longDateFormat(group.archivedAt)}
                </p>
              </>
            )}
            {group?.groupState === GroupState.Deleted && (
              <>
                <p className="text-xs text-gray-500 truncate">Deleted:</p>
                <p className="text-xs text-gray-500 truncate col-span-2">
                  {longDateFormat(group.deletedAt)}
                </p>
              </>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default GroupCard;