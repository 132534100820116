import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import Timestamp from "../../common/components/timestamp";
import { ProductOfferingFragment } from "../../graphql/generated";
import StripeProductPriceCard from "./stripeproductprice";
import TierCard from "./tier";

const ProductOfferingSummary: React.FC<{
  productOffering?: ProductOfferingFragment;
}> = ({ productOffering }) => {
  return (
    <>
      <Panel>
        <Panel.Title>Product Offering</Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
            <DescriptionListItem title="Id" value={productOffering?.id} />

            <DescriptionListContainer
              title="Offering Type"
              info="The Entity that is providing this offering."
            >
              {productOffering?.offeringType.__typename ==
                "OfferingTypeEnterpriseAgentDetails" && <>Enterprise</>}
              {productOffering?.offeringType.__typename ==
                "OfferingTypeIndividualAgentDetails" && <>Individual Agents</>}
            </DescriptionListContainer>

            <DescriptionListContainer title="Created">
              <Timestamp
                timestamp={productOffering?.createdAt}
                format="long"
                popover
              />
            </DescriptionListContainer>

            <DescriptionListItem
              title="RC Offering"
              info="RevenueCat Offering Id"
              value={productOffering?.revenueCatOfferingId}
              link={`https://app.revenuecat.com/projects/3368be2e/offerings/${productOffering?.revenueCatOfferingId}`}
            />
            <DescriptionListContainer
              title="Test or Production"
              info="Whether the products and offerings are for test accounts or production accounts."
            >
              {(productOffering?.testProductsOnly ?? true) && <>Test</>}
              {!(productOffering?.testProductsOnly ?? false) && <>Production</>}
            </DescriptionListContainer>

            {productOffering?.offeringType.__typename ===
              "OfferingTypeEnterpriseAgentDetails" && (
              <DescriptionListItem
                title="Enterprise Agreement"
                info="The enterprise agreement that owns this offering."
                value={productOffering.offeringType.enterpriseAgreementId}
                link={`/enterprise_agreements/${productOffering.offeringType.enterpriseAgreementId}`}
              />
            )}
          </dl>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Title>Description of the Offering</Panel.Title>
        <Panel.Body summary fullWidth>
          <article className="prose p-4 border-t border-gray-200 px-4 my-2 sm:px-6">
            {productOffering?.description ?? ""}
          </article>
        </Panel.Body>
      </Panel>

      {productOffering?.offeringType.message && (
        <Panel>
          <Panel.Title>Message Presented To Agents</Panel.Title>
          <Panel.Body summary fullWidth>
            <article className="prose p-4 border-t border-gray-200 px-4 my-2 sm:px-6">
              {productOffering?.offeringType.message ?? ""}
            </article>
          </Panel.Body>
        </Panel>
      )}
      {productOffering?.grantedProduct?.__typename ===
        "StripeProductPriceDetails" && (
        <Panel>
          <Panel.Title>Product Granted To All Agents</Panel.Title>
          <Panel.Body summary fullWidth>
            <div className="relative bg-white px-6 py-3 flex items-center space-x-3">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-5">
                <DescriptionListItem
                  title="Custom Pill Name"
                  info="Custom upgrade Pill Name display to the agent."
                  value={productOffering?.grantedProductCustomerDisplayName}
                />

                <DescriptionListItem
                  title="Bubble Text"
                  info="Optional text that appears in a bubble above the tier name."
                  value={
                    productOffering?.grantedShortDocumentCustomization
                      ?.bubbleText
                  }
                />
                <DescriptionListItem
                  title="Subtitle Text"
                  info="Optional text that appears below the tier name."
                  value={
                    productOffering?.grantedShortDocumentCustomization
                      ?.subtitleText
                  }
                />
                <DescriptionListItem
                  title="Custom Short List"
                  info="Has the short list of features been customized?"
                  value={
                    productOffering?.grantedShortDocumentCustomization
                      ?.hasCustomRows == true
                      ? "Yes"
                      : "No"
                  }
                />
                <DescriptionListItem
                  title="Custom Long List"
                  info="Has the long list of features been customized?"
                  value={
                    productOffering?.grantedHasCustomizedLongDocumentRows ==
                    true
                      ? "Yes"
                      : "No"
                  }
                />
              </dl>
            </div>
            {productOffering?.grantedProduct?.__typename ===
              "StripeProductPriceDetails" && (
              <StripeProductPriceCard
                stripeproductprice={productOffering.grantedProduct}
              />
            )}
          </Panel.Body>
        </Panel>
      )}
      {productOffering?.grantedProduct?.__typename ===
        "GrantedProductZenlistGrantedProductDetails" && (
        <Panel>
          <Panel.Title>Product Granted To All Agents</Panel.Title>
          <Panel.Body summary fullWidth>
            <div className="relative bg-white px-6 py-3 flex items-center space-x-3">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
                <DescriptionListItem
                  title="Product"
                  info="Internal ID for the Product"
                  value={productOffering?.grantedProduct.productId}
                  link={`/products/${productOffering?.grantedProduct.productId}`}
                />
              </dl>
            </div>
          </Panel.Body>
        </Panel>
      )}

      <Panel>
        <Panel.Title>Upgrade Tiers</Panel.Title>
        <Panel.Body summary fullWidth>
          {productOffering?.tiers.map((tier) => (
            <TierCard tier={tier} />
          ))}
        </Panel.Body>
      </Panel>
    </>
  );
};

export default ProductOfferingSummary;
