import { EnvelopeIcon, EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import {
  ClientInvitationFragment,
  ClientInvitationState,
} from "../../graphql/generated";

const ClientInvitationPic: React.FC<{
  invitation?: ClientInvitationFragment;
  large?: boolean;
}> = ({ invitation, large }) => {
  return (
    <>
      {invitation?.state === ClientInvitationState.Accepted && (
        <EnvelopeOpenIcon
          className={classNames(
            "bg-green-100 border-green-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
      {invitation?.state === ClientInvitationState.Cancelled && (
        <EnvelopeIcon
          className={classNames(
            "bg-grey-100 border-grey-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
      {invitation?.state === ClientInvitationState.Declined && (
        <EnvelopeOpenIcon
          className={classNames(
            "bg-red-100 border-red-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
      {invitation?.state === ClientInvitationState.Pending && (
        <EnvelopeIcon
          className={classNames(
            "bg-yellow-100 border-yellow-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
    </>
  );
};

export default ClientInvitationPic;
