import React from "react";
import { useClient } from "urql";
import ConversationCard from "../components/card";
import { classNames } from "../../../common/utils/classnames";
import {
  ConversationsDocument,
  useConversationsQuery,
} from "../../../graphql/generated";
import ConversationActions from "../components/actions";
import Loading from "../../../common/components/loading";
import { Transition } from "@headlessui/react";
import Error from "../../../common/components/error";
import CommonSwitch from "../../../common/components/switch";
import { useTitle } from "../../../common/utils/title";

const BabelConversationList: React.FC = () => {
  useTitle("Conversations");
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();
  const [withGroup, setWithGroup] = React.useState<boolean>(true);

  const [{ data, error, fetching }, executeQuery] = useConversationsQuery({
    variables: {
      first: 20,
      withGroup,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.conversations.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(ConversationsDocument, {
          first: 20,
          after: data.conversations.pageInfo.endCursor,
          withGroup,
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, withGroup]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Conversations
                <span className="text-sm text-gray-500 px-2">
                  {fetching
                    ? "Loading..."
                    : `Total: ${data?.conversations.total}`}
                </span>
              </h2>
              <div className="mt-1 grid grid-cols-1 gap-0">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CommonSwitch
                    label="No Group"
                    enabled={!withGroup}
                    toggle={() => setWithGroup(!withGroup)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 flex sm:mt-0 sm:mr-4">
              <ConversationActions executeQuery={() => executeQuery()} />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {data?.conversations.edges?.map((conversation) => (
                  <ConversationCard
                    conversation={conversation?.node}
                    key={conversation?.node.id}
                  />
                ))}
              </div>
            </div>
          </div>
          {data &&
            data?.conversations.total >
              (data?.conversations.edges?.length ?? 0) && (
              <div className="min-w-full divide-y divide-gray-200">
                <div className="p-4">
                  <button
                    className={classNames(
                      loading
                        ? "bg-gray-500 text-white"
                        : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                      "font-bold py-2 px-4 rounded"
                    )}
                    onClick={() => fetchMore()}
                    disabled={loading}
                  >
                    Fetch More
                  </button>
                </div>
              </div>
            )}
        </div>
      </Transition>
    </>
  );
};

export default BabelConversationList;
