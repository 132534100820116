import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  ExternalCommunicationState,
  MlsFragment,
  UpdateMlsData,
} from "../../graphql/generated";
import CommonSwitch from "../../common/components/switch";

const EditMlsDialog: React.FC<{
  isOpen: boolean;
  mls?: MlsFragment;
  isMlsUpdating: boolean;
  onClose: () => void;
  onSubmit: (data: UpdateMlsData) => void;
}> = ({ isOpen, mls, isMlsUpdating, onClose, onSubmit }) => {
  if (!mls) {
    return <></>;
  }

  const [name, setName] = React.useState(mls.name);
  const [shortName, setShortName] = React.useState(mls.shortName);
  const [marketLocation, setMarketLocation] = React.useState(
    mls.marketLocation ?? ""
  );
  const [order, setOrder] = React.useState(mls.order);
  const [allowOnboarding, setAllowOnboarding] = React.useState(
    mls.allowOnboarding
  );
  const [enabled, setEnabled] = React.useState(mls.enabled);
  const [externalCommunication, setExternalCommunication] = React.useState(
    mls.externalCommunication
  );
  const [isMyListingsEnabled, setIsMyListingsEnabled] = React.useState(
    mls.isMyListingsEnabled
  );

  const inputRef = React.useRef(null);

  //   const handleChange = (e: { target: { value: string } }) => {
  //     setEditableFilter(e.target.value);
  //   };

  const handleSubmit = React.useCallback(() => {
    const data: UpdateMlsData = {
      allowOnboarding,
      enabled,
      externalCommunication,
      isMyListingsEnabled,
      order,
    };
    if (name.length > 0) {
      data.name = name;
    }
    if (shortName.length > 0) {
      data.shortName = shortName;
    }
    if (marketLocation.length > 0) {
      data.marketLocation = marketLocation;
    }
    onSubmit(data);
  }, [
    onSubmit,
    name,
    shortName,
    marketLocation,
    allowOnboarding,
    enabled,
    externalCommunication,
    isMyListingsEnabled,
  ]);

  const nameId = React.useId();
  const shortNameId = React.useId();
  const marketLocationId = React.useId();
  const orderId = React.useId();
  const externalCommunicationId = React.useId();

  return (
    <ZenDialog
      show={isOpen}
      title="Edit MLS"
      icon={PencilSquareIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      state={
        isMlsUpdating ? ZenDialogState.Submitting : ZenDialogState.Displaying
      }
      initialFocus={inputRef}
    >
      <div className="mt-1">
        <div>
          <label htmlFor={nameId}>Name</label>
        </div>
        <div>
          <input
            id={nameId}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={shortNameId}>Short name</label>
        </div>
        <div>
          <input
            id={shortNameId}
            type="text"
            value={shortName}
            onChange={(e) => setShortName(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={marketLocationId}>Market location</label>
        </div>
        <div>
          <input
            id={marketLocationId}
            type="text"
            value={marketLocation}
            onChange={(e) => setMarketLocation(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={orderId}>Order</label>
        </div>
        <div>
          <input
            id={orderId}
            type="number"
            value={order}
            onChange={(e) => setOrder(e.target.valueAsNumber)}
          />
        </div>
      </div>
      <div className="mt-1">
        <CommonSwitch
          label="Allow onboarding"
          enabled={allowOnboarding}
          toggle={() => setAllowOnboarding(!allowOnboarding)}
        />
      </div>
      <div className="mt-1">
        <CommonSwitch
          label="Enabled"
          enabled={enabled}
          toggle={() => setEnabled(!enabled)}
        />
      </div>
      <div className="mt-1">
        <CommonSwitch
          label="Is 'My Listings' enabled"
          enabled={isMyListingsEnabled}
          toggle={() => setIsMyListingsEnabled(!isMyListingsEnabled)}
        />
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={externalCommunicationId}>
            External communication
          </label>
        </div>
        <div>
          <select
            id={externalCommunicationId}
            value={externalCommunication}
            onChange={(e) =>
              setExternalCommunication(
                e.target.value as ExternalCommunicationState
              )
            }
          >
            <option value={ExternalCommunicationState.Allowed}>Allowed</option>
            <option value={ExternalCommunicationState.Restricted}>
              Restricted
            </option>
          </select>
        </div>
      </div>
    </ZenDialog>
  );
};

export default EditMlsDialog;
