import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth";

const AuthRequire: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authorized } = React.useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!authorized) {
      navigate("/login", { replace: true, state: { from: location } });
    }
  }, [authorized, location, navigate]);

  return <>{children}</>;
};

export default AuthRequire;
