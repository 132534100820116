import { UserIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import { LenderFragment } from "../../graphql/generated";

const LenderPic: React.FC<{ lender?: LenderFragment; large?: boolean }> = ({
  large,
}) => {
  return (
    <UserIcon
      className={classNames(
        "bg-green-100 border-green-300",
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2"
      )}
    />
  );
};

export default LenderPic;
