import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { BellSlashIcon } from "@heroicons/react/24/outline";

const ClearProblemThresholdDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isFetching: boolean;
}> = ({ isOpen, onClose, onSubmit, isFetching }) => {
  const handleSubmit = React.useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <ZenDialog
      show={isOpen}
      title="Clear problem threshold"
      icon={BellSlashIcon}
      submit="Clear"
      onSubmit={handleSubmit}
      onCancel={onClose}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">Never auto-pause because of problems?</div>
    </ZenDialog>
  );
};

export default ClearProblemThresholdDialog;
