import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../../common/utils/dateformat";
import {
  ConversationFragment,
  ParticipantFragment,
} from "../../../graphql/generated";
import GroupPic from "../../../group/components/pic";

const ConversationCard: React.FC<{
  conversation?: ConversationFragment;
  participant?: ParticipantFragment;
}> = ({ conversation, participant }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        {conversation?.group && <GroupPic group={conversation.group} />}
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/babel/conversations/${conversation?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">
            {conversation?.displayName ??
              "Group: " + conversation?.group?.nameLong}
          </p>
          <p className="text-sm text-gray-500 truncate">
            {conversation?.participantCount} Members{" "}
            {conversation?.messageCount} Messages {participant?.unreadCount}{" "}
            Unread
          </p>
          <p className="text-sm text-gray-500 truncate">
            Last Active: {longDateFormat(conversation?.messagedAt)}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ConversationCard;
