import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import Timestamp from "../../../common/components/timestamp";
import { classNames } from "../../../common/utils/classnames";
import { SubethaEventFragment } from "../../../graphql/generated";

const EventCard: React.FC<{ event?: SubethaEventFragment; last?: boolean }> = ({
  event,
  last,
}) => {
  return (
    <li>
      <div className="relative pb-8">
        {!last && (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex space-x-3">
          <div>
            <div>
              <span
                className={classNames(
                  "bg-cyan-500",
                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                )}
              >
                <CalendarIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
          </div>
          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div>
              <p className="text-sm text-gray-500">
                <Link
                  to={`/subetha/events/${event?.id}`}
                  className="text-blue-900 hover:underline"
                >
                  {event?.eventType}
                </Link>
              </p>
            </div>
            <div className="text-right text-sm whitespace-nowrap text-gray-500">
              <Timestamp timestamp={event?.createdAt} format="long" popover />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default EventCard;
