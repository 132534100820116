import React from "react";
import { Link } from "react-router-dom";
import { BrokerageFragment } from "../../graphql/generated";
import BrokeragePic from "./brokeragepic";

const BrokerageCard: React.FC<{ brokerage?: BrokerageFragment }> = ({
  brokerage,
}) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <BrokeragePic brokerage={brokerage} />
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/brokerages/${brokerage?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {brokerage?.name}
          </p>
          <div className="grid grid-cols-2">
            <p className="text-sm text-gray-500 truncate">
              Enabled: {brokerage?.enabledAgentsCount}
            </p>
            <p className="text-sm text-gray-500 truncate">
              Active: {brokerage?.activeAgentsCount}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BrokerageCard;
