import Panel from "../../../common/components/panel";
import { RmMappingDetailsFragment } from "../../../graphql/generated";

const RMUnsupportedMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
}> = () => {
  return (
    <Panel>
      <Panel.Body>
        <p>Editing this type of mapping is not currently supported.</p>
      </Panel.Body>
    </Panel>
  );
};

export default RMUnsupportedMappingTypeControl;
