import React from "react";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import { EntitlementFragment } from "../../graphql/generated";

const AccountEntitlements: React.FC<{
  entitlements: Array<EntitlementFragment>;
}> = ({ entitlements }) => {
  return (
    <Panel>
      <Panel.Title>Entitlements</Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {entitlements &&
            entitlements.map((entitlement) => (
              <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3">
                <StatusComponent entity={entitlement} />
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900">
                    {entitlement.slug}
                  </p>
                  {entitlement.entitlementGroupId && (
                    <p className="text-sm text-gray-900">
                      Provided from {entitlement.entitlementGroupId}
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountEntitlements;
