import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import {
  AccountSubscriptionQuery,
  AccountSubscriptionStatus,
  useAccountSubscriptionCreateOverrideMutation,
} from "../../graphql/generated";

enum InternalState {
  FirstStepInitial,
  FirstStepMutating,
}

export const CreateManualOverrideDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
  subscription?: AccountSubscriptionQuery["accountSubscription"];
}> = ({ isOpen, onClose, onUpdate, subscription }) => {
  const firstStepInputRef = React.useRef(null);
  const [state, setState] = React.useState(InternalState.FirstStepInitial);
  const { updateNotification } = React.useContext(NotificationContext);

  const [newStatus, setNewStatus] = React.useState<AccountSubscriptionStatus>(
    AccountSubscriptionStatus.Active
  );
  const [newReason, setNewReason] = React.useState("");
  const displayFirstStep =
    isOpen &&
    (state == InternalState.FirstStepInitial ||
      state == InternalState.FirstStepMutating);

  const [, mutation] = useAccountSubscriptionCreateOverrideMutation();

  const internalClose = React.useCallback(() => {
    setState(InternalState.FirstStepInitial);
    onClose();
  }, []);
  const internalUpdate = React.useCallback(() => {
    setState(InternalState.FirstStepInitial);
    onUpdate();
  }, []);

  const handleFirstStepSubmit = React.useCallback(async () => {
    setState(InternalState.FirstStepMutating);
    const result = await mutation({
      id: subscription?.id ?? "",
      status: newStatus ?? AccountSubscriptionStatus.Active,
      reason: newReason ?? undefined,
    });

    if (result.error) {
      updateNotification({
        notification: result.error.message,
        notificationType: NotificationType.Error,
      });
      internalClose();
    } else {
      updateNotification({
        notification: "Manual Override Created successfully",
        notificationType: NotificationType.Success,
      });
      internalUpdate();
    }
  }, [subscription, newStatus, newReason]);

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setNewStatus(value as AccountSubscriptionStatus);
  };

  return (
    <>
      <Transition show={displayFirstStep} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={firstStepInputRef}
          onClose={internalClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Manual Override
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-2">
                  <select
                    required
                    // id="newStatus"
                    // name="newStatus"
                    value={newStatus ?? subscription?.status}
                    onChange={handleStatusChange}
                    // onChange={(e) => setNewStatus(e.target.value)}
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value={AccountSubscriptionStatus.Active}>
                      {AccountSubscriptionStatus.Active}
                    </option>
                    <option value={AccountSubscriptionStatus.Canceled}>
                      {AccountSubscriptionStatus.Canceled}
                    </option>
                  </select>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="reason"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reason
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="reason"
                      id="reason"
                      value={newReason ?? ""}
                      onChange={(e) => setNewReason(e.target.value)}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleFirstStepSubmit}
                  >
                    {state == InternalState.FirstStepMutating
                      ? "Updating..."
                      : "Update"}
                  </button>

                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={internalClose}
                    ref={firstStepInputRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
