import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmTextBasicMappingTypeFragment,
} from "../../../graphql/generated";

const RMTextBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmTextBasicMappingTypeFragment;
}> = () => {
  return (
    <Panel>
      <Panel.Title>Text Mapping</Panel.Title>
      <Panel.Body>
        <div className="py-2">
          <p>
            Nothing to do here, really. A basic text mapping takes the text from
            the source field and puts it into the destination field.
          </p>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default RMTextBasicMappingTypeControl;
