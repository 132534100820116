import { Transition } from "@headlessui/react";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useIdentityQuery } from "../../graphql/generated";
import IdentitySummary from "../components/summary";
import IdentityTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const IdentityShow: React.FC = () => {
  const params = useParams();
  const location = useLocation();

  const password = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ((location.state as any)?.password as string) ?? "";
  }, [location.state]);

  const [{ data, error }] = useIdentityQuery({
    variables: { id: params.identityId ?? "" },
    pause: !params.identityId,
  });
  useTitle(
    data?.identity.name ?? data?.identity.account?.name ?? params.identityId,
    "Identities"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <IdentityTitle identity={data?.identity} />
          <IdentitySummary identity={data?.identity} password={password} />
        </div>
      </Transition>
    </>
  );
};

export default IdentityShow;
