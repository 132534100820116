import React from "react";
import { ListingFragment } from "../../graphql/generated";
import ListingActions from "./actions";

export const ListingTitle: React.FC<{
  listing?: ListingFragment;
  subtitle?: string;
}> = ({ listing, subtitle }) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {listing?.address.address1}, {listing?.address.city},{" "}
              {listing?.address.state} {listing?.address.zipCode}
              {subtitle && (
                <span className="text-sm text-gray-500 ml-1">{subtitle}</span>
              )}
            </h2>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            {listing && <ListingActions listing={listing} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingTitle;
