import React from "react";
import { useClient } from "urql";
import { classNames } from "../../common/utils/classnames";
import ConversationCard from "../../babel/conversation/components/card";
import {
  ConversationsDocument,
  GroupFragment,
  useConversationsQuery,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";

const GroupConversations: React.FC<{ group?: GroupFragment }> = ({ group }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data }] = useConversationsQuery({
    variables: {
      first: 20,
      groupId: group?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.conversations.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(ConversationsDocument, {
          first: 20,
          after: data.conversations.pageInfo.endCursor,
          groupId: group?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, group]);

  return (
    <Panel>
      <Panel.Title>
        Conversations{" "}
        <span className="text-xs text-gray-500">
          {data?.conversations.total}
        </span>
      </Panel.Title>

      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.conversations.edges?.map((conversation) => (
            <ConversationCard
              conversation={conversation?.node}
              key={conversation?.node.id}
            />
          ))}
        </div>
      </Panel.Body>

      {data &&
        data?.conversations.total >
          (data?.conversations.edges?.length ?? 0) && (
          <Panel.Footer>
            <button
              className={classNames(
                loading
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetchMore()}
              disabled={loading}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
    </Panel>
  );
};

export default GroupConversations;
