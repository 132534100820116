import React, { useCallback } from "react";
import ApiKeyCard from "../../api-key/components/card";
import Panel from "../../common/components/panel";
import { AccountFragment, useApiKeysQuery } from "../../graphql/generated";
import ApiKeyActions from "./apikeyactions";

const AccountAgents: React.FC<{
  account?: AccountFragment;
}> = ({ account }) => {
  const [{ data }, refresh] = useApiKeysQuery({
    variables: { accountId: account?.id ?? "" },
    pause: !account,
  });

  const refreshApiKeys = useCallback(() => {
    refresh({ requestPolicy: "network-only" });
  }, [refresh]);

  return (
    <Panel>
      <Panel.Title
        actions={<ApiKeyActions account={account} refresh={refreshApiKeys} />}
      >
        Api Keys
        <span className="text-xs text-gray-500">
          {data?.apiKeysForAccount.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.apiKeysForAccount.map((apiKey) => (
            <ApiKeyCard apiKey={apiKey} key={apiKey.id} />
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountAgents;
