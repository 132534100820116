import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  useEditInternalListingBucketMutation,
  useInternalListingBucketQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";

const InternalListingBucketEdit: React.FC = () => {
  const params = useParams();
  const [{ data: bucketData }] = useInternalListingBucketQuery({
    variables: { id: params.internalListingBucketId ?? "" },
    pause: !params.internalListingBucketId,
  });
  useTitle(
    "Edit",
    bucketData?.internalListingBucket.name ?? params.internalListingBucketId,
    "Internal Listing Buckets"
  );

  const navigate = useNavigate();
  const { updateNotification } = React.useContext(NotificationContext);

  const [nameState, setNameState] = React.useState(
    bucketData?.internalListingBucket.name ?? ""
  );

  React.useEffect(() => {
    if (bucketData?.internalListingBucket) {
      setNameState(bucketData.internalListingBucket.name ?? "");
    }
  }, [bucketData]);

  const [{ error, data, fetching }, mutation] =
    useEditInternalListingBucketMutation();

  const submitForm = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (bucketData?.internalListingBucket) {
        mutation({
          id: bucketData.internalListingBucket.id,
          details: {
            name: nameState ?? undefined,
          },
        });
      }
    },
    [bucketData, nameState, mutation]
  );

  React.useEffect(() => {
    if (!error && data && !fetching) {
      updateNotification({
        notification: "Internal listing bucket saved",
        notificationType: NotificationType.Success,
      });
      navigate(
        `/internal_listing_buckets/${bucketData?.internalListingBucket.id}`
      );
    } else if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [data, error, fetching, navigate, updateNotification, bucketData]);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Edit Internal Listing Bucket
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:mx-4 sm:rounded-md">
        <div className="p-4">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={submitForm}
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="id"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      ID
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        disabled
                        required
                        type="text"
                        name="id"
                        id="id"
                        value={bucketData?.internalListingBucket.id ?? ""}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="name"
                        id="name"
                        value={nameState}
                        onChange={(e) => setNameState(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  disabled={fetching}
                  type="submit"
                  className={classNames(
                    // eslint-disable-next-line no-constant-condition
                    true
                      ? "text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      : "text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                    "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
                  )}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default InternalListingBucketEdit;
