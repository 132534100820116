import { XCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { InternalListingBucketFragment } from "../../graphql/generated";
import { ZenDialog } from "../../common/components/zen-dialog";

export const DeleteInternalListingBucketDialog: React.FC<{
  bucket?: InternalListingBucketFragment;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ bucket, isOpen, onClose, onSubmit }) => {
  if (!bucket) {
    return <></>;
  }

  return (
    <ZenDialog
      show={isOpen}
      title="Delete Internal Listing Bucket"
      icon={XCircleIcon}
      submit="Delete"
      onSubmit={() => onSubmit()}
      onCancel={onClose}
    >
      <div className="mt-1">
        Are you sure you want to delete <strong>{bucket.name}</strong>?
      </div>
    </ZenDialog>
  );
};
