import React from "react";
import { useDebounce } from "use-debounce";
import { AccountState, AccountType, GroupState } from "../../graphql/generated";

interface ISearchContext {
  search?: string;
  searchBounced?: string;
  setSearch: (search: string) => void;
  accountTypes: AccountType[];
  accountTypesDispatch: React.Dispatch<{
    type: AccountType;
  }>;
  accountStates: AccountState[];
  accountStatesDispatch: React.Dispatch<{
    state: AccountState;
  }>;
  excludeTest: boolean;
  excludeTestDispatch: React.Dispatch<{ value: boolean }>;
  groupStates: GroupState[];
  groupStatesDispatch: React.Dispatch<{
    state: GroupState;
  }>;
}

export const SearchContext = React.createContext<ISearchContext>({
  search: undefined,
  searchBounced: undefined,
  accountTypes: [],
  accountStates: [],
  excludeTest: true,
  groupStates: [],
  setSearch: () => undefined,
  accountTypesDispatch: () => null,
  accountStatesDispatch: () => null,
  groupStatesDispatch: () => null,
  excludeTestDispatch: () => null,
});

const SearchProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [search, setSearch] = React.useState<string>();
  const [searchBounced] = useDebounce(search, 1000);

  // Account Specific
  const [accountTypes, accountTypesDispatch] = React.useReducer(
    (state: AccountType[], action: { type: AccountType }) => {
      if (state.indexOf(action.type) > -1) {
        return state.filter((at) => at !== action.type);
      }
      return [...state, action.type];
    },
    [
      AccountType.Agent,
      AccountType.Member,
      AccountType.Draft,
      AccountType.Lender,
    ]
  );

  const [accountStates, accountStatesDispatch] = React.useReducer(
    (state: AccountState[], action: { state: AccountState }) => {
      if (state.indexOf(action.state) > -1) {
        return state.filter((at) => at !== action.state);
      }
      return [...state, action.state];
    },
    [AccountState.Active, AccountState.Deactivating, AccountState.Unlinked]
  );

  const [excludeTest, excludeTestDispatch] = React.useReducer(
    (state: boolean, action: { value: boolean }) => action.value,
    true
  );

  // Group Specific
  const [groupStates, groupStatesDispatch] = React.useReducer(
    (state: GroupState[], action: { state: GroupState }) => {
      if (state.indexOf(action.state) > -1) {
        return state.filter((at) => at !== action.state);
      }
      return [...state, action.state];
    },
    [GroupState.Active, GroupState.Personal]
  );

  return (
    <SearchContext.Provider
      value={{
        search: search === undefined ? "" : search,
        searchBounced,
        setSearch: (search: string) =>
          search ? setSearch(search) : setSearch(undefined),
        accountTypes,
        accountTypesDispatch,
        accountStates,
        accountStatesDispatch,
        excludeTest,
        excludeTestDispatch,
        groupStates,
        groupStatesDispatch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
