import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  CanbyAnalysisShowFragment,
  useCanbyAnalysisQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import Panel from "../../common/components/panel";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Timestamp from "../../common/components/timestamp";
import AnalysisActions from "../components/analysis-actions";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import FieldsPanel from "../components/fields-panel";
import LoadingIcon from "../../common/components/loadingicon";

const CanbyAnalysis: React.FC = () => {
  const params = useParams();

  const canbyAnalysisId = params.canbyAnalysisId ?? "";

  const [{ data, error }, executeQuery] = useCanbyAnalysisQuery({
    variables: { id: canbyAnalysisId },
  });

  const isIngesting =
    data && data.canbyAnalysis.ingests.some((ingest) => !ingest.finishedAt);

  React.useEffect(() => {
    if (isIngesting) {
      // If we're doing an ingestion, keep reloading
      const interval = setInterval(() => {
        executeQuery({ requestPolicy: "network-only" });
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    } else {
      // No autorefresh required
      return () => null;
    }
  }, [isIngesting]);

  const resourceName = data?.canbyAnalysis.rmRootResource
    ? `${data.canbyAnalysis.rmRootResource.mls.shortName} ${data.canbyAnalysis.rmRootResource.name}`
    : undefined;
  const resourceLink = data?.canbyAnalysis.rmRootResource
    ? `/resource_mapping/roots/${data.canbyAnalysis.rmRootResource.id}`
    : undefined;

  useTitle(resourceName, "Analysis");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Panel>
            <Panel.Title
              actions={<AnalysisActions analysis={data?.canbyAnalysis} />}
            >
              Details
            </Panel.Title>
            <Panel.Body summary>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                <DescriptionListContainer title="Created at">
                  <Timestamp
                    timestamp={data?.canbyAnalysis.createdAt}
                    format="short"
                    popover
                  />
                </DescriptionListContainer>
                <DescriptionListContainer
                  title="Expires"
                  info="When this analysis will be auto-deleted. These analyses are resource-intensive, so when they are no longer needed, they will be auto-deleted. If you need this particular analysis to stick around longer, use the 'bump expiration' action."
                >
                  <Timestamp
                    timestamp={data?.canbyAnalysis.expiresAt}
                    format="distance"
                    popover
                  />
                </DescriptionListContainer>
                <DescriptionListContainer
                  title="Documents"
                  info="The number of resources/documents in this analysis"
                >
                  {isIngesting && <LoadingIcon />}
                  {data?.canbyAnalysis.documentCount.toLocaleString()}
                </DescriptionListContainer>
                <DescriptionListItem
                  title="OpenSearch index"
                  value={data?.canbyAnalysis.indexName}
                />
                <DescriptionListItem
                  title="Resource"
                  link={resourceLink}
                  value={resourceName}
                />
                <DescriptionListContainer
                  title="Ingests"
                  info="A list of all of the automatic ingests on this analysis. Note that manual ingests may have been performed that are not listed here."
                >
                  <ul>
                    {data?.canbyAnalysis.ingests.map((ingest) => (
                      <CanbyIngestListItem
                        key={ingest.createdAt}
                        ingest={ingest}
                      />
                    ))}
                    {(!data || data?.canbyAnalysis.ingests.length == 0) && "—"}
                  </ul>
                </DescriptionListContainer>
              </dl>
            </Panel.Body>
          </Panel>
          <FieldsPanel analysis={data?.canbyAnalysis} />
        </div>
      </Transition>
    </>
  );
};

const CanbyIngestListItem: React.FC<{
  ingest: CanbyAnalysisShowFragment["ingests"][number];
}> = ({ ingest }) => {
  let description = "";
  switch (ingest.type.__typename) {
    case "CanbyRandomSampleIngestType":
      description = `${ingest.type.samples.toLocaleString()} samples`;
      break;
    case "CanbyMostRecentIngestType":
      description = `${ingest.type.count.toLocaleString()} most recent`;
      break;
  }

  return (
    <li>
      {!ingest.finishedAt && (
        <>
          <LoadingIcon />{" "}
        </>
      )}
      <Timestamp timestamp={ingest.createdAt} format="short" popover /> —{" "}
      {description}
    </li>
  );
};

export default CanbyAnalysis;
