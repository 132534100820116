import { TruckIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import { TourFragment } from "../../graphql/generated";

const TourPic: React.FC<{
  tour?: TourFragment;
  large?: boolean;
}> = ({ large }) => {
  return (
    <TruckIcon
      className={classNames(
        "bg-green-100 border-green-300",
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2"
      )}
    />
  );
};

export default TourPic;
