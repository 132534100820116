import React from "react";
import {
  TourStopFragment,
  ListingTourStopFragment,
  AddressTourStopFragment,
  TourQuery,
} from "../../graphql/generated";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../common/utils/dateformat";
import Panel from "../../common/components/panel";

const TourStops: React.FC<{
  tour: TourQuery["tour"] | undefined;
}> = ({ tour }) => {
  return (
    <Panel>
      <Panel.Title>Stops</Panel.Title>
      <Panel.Body>
        {tour?.stops?.map((stop) => {
          return <TourStopComponent stop={stop} key={stop.id} />;
        })}
      </Panel.Body>
    </Panel>
  );
};

const TourStopComponent: React.FC<{
  stop: TourStopFragment & (AddressTourStopFragment | ListingTourStopFragment);
}> = ({ stop }) => {
  let stopDetails;
  if (stop.__typename === "ListingTourStop") {
    stopDetails = <ListingTourStopComponent stop={stop} />;
  } else if (stop.__typename === "AddressTourStop") {
    stopDetails = <AddressTourStopComponent stop={stop} />;
  } else {
    stopDetails = <></>;
  }

  return (
    <div className="my-4">
      <div className="text-sm text-gray-500">
        {stop.time ? longDateFormat(stop.time) : "Time not specified"}
      </div>
      {stopDetails}
    </div>
  );
};

const ListingTourStopComponent: React.FC<{
  stop: ListingTourStopFragment;
}> = ({ stop }) => {
  const listing = stop.groupItem.listing;
  return (
    <div>
      <div>
        <span className="font-bold">{listing?.address.address1}</span> &bull;{" "}
        <Link
          to={`/listings/${listing.id}`}
          className="text-blue-900 hover:underline"
        >
          {listing?.mls?.shortName} #{listing?.mlsId}
        </Link>
      </div>
      <div>
        {listing?.address.city}, {listing?.address.state}{" "}
        {listing?.address.zipCode}
      </div>
    </div>
  );
};

const AddressTourStopComponent: React.FC<{
  stop: AddressTourStopFragment;
}> = ({ stop }) => {
  const googlePlace = stop.googlePlace;
  return (
    <div>
      <div className="font-bold">{googlePlace?.name}</div>
      <div>{googlePlace?.formattedAddress}</div>
    </div>
  );
};

export default TourStops;
