import React from "react";
import { EnterpriseAgreementFragment } from "../../graphql/generated";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { ChartData } from "chart.js";
import { Transition } from "@headlessui/react";

const AgreementStats: React.FC<{
  agreements: EnterpriseAgreementFragment[];
}> = ({ agreements }) => {
  const chartData = React.useMemo<ChartData<"bar", number[], string>>(() => {
    const filteredData = [...agreements].filter((a) => a.activeAgentCount > 0);
    filteredData.sort((a, b) => {
      if (a.activeAgentCount < b.activeAgentCount) {
        return 1;
      }
      if (a.activeAgentCount > b.activeAgentCount) {
        return -1;
      }
      return 0;
    });

    return {
      labels: filteredData.map((a) => a.name),
      datasets: [
        {
          label: `Active Agents`,
          data: filteredData.map((a) => a.activeAgentCount),
          borderColor: [
            "rgb(239, 68, 68)",
            "rgb(52, 211, 153)",
            "rgb(14, 165, 233)",
          ],
          backgroundColor: [
            "rgba(239, 68, 68, 0.5)",
            "rgba(52, 211, 153, 0.5)",
            "rgba(14, 165, 233, 0.5)",
          ],
        },
      ],
    };
  }, [agreements]);

  return (
    <>
      <Transition
        appear
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="bg-white shadow overflow-hidden sm:rounded-md mt-4">
          <div className="px-4 my-2 sm:px-6 flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 grow">
              Enterprise Agreements
            </h3>
          </div>
          <div className="p-4 border-t border-gray-200">
            <div className="col-span-3 h-56">
              <Bar
                options={{
                  maintainAspectRatio: false,
                  interaction: { mode: "index" },
                  scales: {
                    y: {
                      type: "logarithmic",
                    },
                  },
                }}
                data={chartData}
              />
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default AgreementStats;
