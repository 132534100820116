import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  RmNormalizationDetailsFragment,
  RmProblemsForNormalizationDocument,
  useRmNormalizationDeleteMutation,
  useRmNormalizationDisableMutation,
  useRmNormalizationDisableProblemReportingMutation,
  useRmNormalizationEnableMutation,
  useRmNormalizationEnableProblemReportingMutation,
  useRmNormalizationQuery,
  useRmProblemsForNormalizationQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import { Navigation, NavigationItems } from "../components/nav";
import ResourceMappingTitle from "../components/resource-mapping-title";
import RMNormalizationTypeControl from "../components/normalization-types/RMNormalizationTypeControl";
import CommonSwitch from "../../common/components/switch";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { useClient } from "urql";
import Problems from "../components/problems";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../common/utils/classnames";

const RMNormalization: React.FC = () => {
  const graphqlClient = useClient();
  const params = useParams();
  const { updateNotification } = React.useContext(NotificationContext);
  const [{ data, error }] = useRmNormalizationQuery({
    variables: { id: params.normalizationId ?? "" },
  });

  const [{ data: problemData, error: problemError }] =
    useRmProblemsForNormalizationQuery({
      variables: { normalizationId: params.normalizationId ?? "" },
      pause: !params.normalizationId,
    });

  const [loading, setLoading] = React.useState(false);
  const canFetchMore =
    problemData?.rmProblemsForNormalization.pageInfo.hasNextPage ?? false;
  const fetchMore = React.useCallback(async () => {
    if (problemData?.rmProblemsForNormalization.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(RmProblemsForNormalizationDocument, {
          first: 50,
          after: problemData?.rmProblemsForNormalization.pageInfo.endCursor,
          normalizationId: params.normalizationId ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [problemData, params.mappingId, graphqlClient]);

  const name = React.useMemo(() => {
    if (data?.rmNormalization.field.name) {
      return `${data.rmNormalization.field.name} normalization`;
    } else {
      return undefined;
    }
  }, [data]);
  useTitle(
    name,
    data?.rmNormalization.field.rootResource.name,
    data?.rmNormalization.field.rootResource.mls.shortName,
    "Resource Mapping"
  );

  const nav = NavigationItems.withHome();
  nav.addMls(
    data?.rmNormalization.field.rootResource.mls.shortName,
    data?.rmNormalization.field.rootResource.mls.id
  );
  nav.addRoot(
    data?.rmNormalization.field.rootResource.name,
    data?.rmNormalization.field.rootResource.id
  );
  if (data && data.rmNormalization && data.rmNormalization.parentFields) {
    for (const parent of data.rmNormalization.parentFields) {
      nav.addField(parent.name, parent.id);
    }
  }
  nav.addNormalization("Normalization", data?.rmNormalization.id);

  const [, enableMutation] = useRmNormalizationEnableMutation();
  const [, disableMutation] = useRmNormalizationDisableMutation();

  const onEnabledToggleChanged = React.useCallback(async () => {
    if (data?.rmNormalization.isEnabled === true) {
      const { error } = await disableMutation({
        normalizationId: data?.rmNormalization.id || "",
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Mapping disabled",
          notificationType: NotificationType.Success,
        });
      }
    } else if (data?.rmNormalization.isEnabled === false) {
      const { error } = await enableMutation({
        normalizationId: data?.rmNormalization.id || "",
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Mapping enabled",
          notificationType: NotificationType.Success,
        });
      }
    } else {
      updateNotification({
        notification: "Mapping not updated",
        notificationType: NotificationType.Error,
      });
    }
  }, [data?.rmNormalization.id, data?.rmNormalization.isEnabled]);

  const [, enableProblemReportingMutation] =
    useRmNormalizationEnableProblemReportingMutation();
  const [, disableProblemReportingMutation] =
    useRmNormalizationDisableProblemReportingMutation();

  const onReportProblemsToggleChanged = React.useCallback(async () => {
    if (data?.rmNormalization.reportProblems === true) {
      const { error } = await disableProblemReportingMutation({
        normalizationId: data?.rmNormalization.id || "",
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Reporting problems disabled",
          notificationType: NotificationType.Success,
        });
      }
    } else if (data?.rmNormalization.reportProblems === false) {
      const { error } = await enableProblemReportingMutation({
        normalizationId: data?.rmNormalization.id || "",
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Reporting problems enabled",
          notificationType: NotificationType.Success,
        });
      }
    } else {
      updateNotification({
        notification: "Problem reporting not updated",
        notificationType: NotificationType.Error,
      });
    }
  }, [data?.rmNormalization.id, data?.rmNormalization.reportProblems]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle
            name={name}
            actions={
              <NormalizationActions normalization={data?.rmNormalization} />
            }
          >
            <CommonSwitch
              label="Enabled"
              enabled={data?.rmNormalization.isEnabled ?? false}
              toggle={onEnabledToggleChanged}
            />
            <CommonSwitch
              label="Report problems"
              enabled={data?.rmNormalization.reportProblems ?? false}
              toggle={onReportProblemsToggleChanged}
            />
          </ResourceMappingTitle>

          {data && data.rmNormalization && (
            <>
              <RMNormalizationTypeControl
                normalization={data.rmNormalization}
              />
            </>
          )}
          <Problems
            data={problemData?.rmProblemsForNormalization}
            error={problemError}
            fetch={{
              fetch: fetchMore,
              canFetch: canFetchMore,
              isFetching: loading,
            }}
          />
        </div>
      </Transition>
    </>
  );
};

const NormalizationActions: React.FC<{
  normalization?: RmNormalizationDetailsFragment;
}> = ({ normalization }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const navigate = useNavigate();
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);

  const [
    { fetching: deleteNormalizationFetching },
    deleteNormalizationMutation,
  ] = useRmNormalizationDeleteMutation();

  const deleteNormalizationCallback = React.useCallback(async () => {
    const { error, data } = await deleteNormalizationMutation({
      normalizationId: normalization?.id ?? "",
    });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
    if (data) {
      navigate(
        `/resource_mapping/roots/${normalization?.field.rootResource.id}`
      );
    }
  }, [normalization, deleteNormalizationMutation]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled={normalization?.isEnabled}>
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => setDeleteDialogIsOpen(true)}
                  >
                    <TrashIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete normalization...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <DeleteNormalizationDialog
        show={deleteDialogIsOpen}
        onCancel={() => setDeleteDialogIsOpen(false)}
        onSubmit={deleteNormalizationCallback}
        fetching={deleteNormalizationFetching}
      />
    </>
  );
};

const DeleteNormalizationDialog: React.FC<{
  show?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  fetching?: boolean;
}> = ({ show, onCancel, onSubmit, fetching }) => {
  return (
    <ZenDialog
      show={show}
      title="Delete normalization"
      icon={TrashIcon}
      submit="Delete"
      onSubmit={() => onSubmit()}
      onCancel={onCancel}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <p>Are you sure you want to permanently delete this normalization?</p>
      </div>
      <div className="mt-1">
        <p>
          <i>Note:</i> all problems associated with this mapping will be
          orphaned but will remain. It is suggested that you clean up all
          problems before deleting and that you only delete mappings that have
          been disabled for more than 5 minutes, so that orphaned problems are
          not created.
        </p>
      </div>
    </ZenDialog>
  );
};

export default RMNormalization;
