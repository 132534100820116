import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import Timestamp from "../../common/components/timestamp";
import { GroupQuery } from "../../graphql/generated";

const GroupSummary: React.FC<{ group?: GroupQuery["group"] }> = ({ group }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-5">
          <DescriptionListContainer title="Status">
            <StatusComponent entity={group} />
          </DescriptionListContainer>
          <DescriptionListItem title="Total Items" value={group?.itemsCount} />
          <DescriptionListItem title="Saved" value={group?.itemsPinnedCount} />
          <DescriptionListItem
            title="Tours Requested"
            value={group?.tourRequestsCount}
          />
          <DescriptionListItem title="Hidden" value={group?.itemsHiddenCount} />
          <DescriptionListItem
            title="Notes Sent"
            value={group?.notesSentCount}
          />
          <DescriptionListContainer title="Created">
            <Timestamp timestamp={group?.createdAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Modified">
            <Timestamp timestamp={group?.modifiedAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Archived">
            <Timestamp timestamp={group?.archivedAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Deleted">
            <Timestamp timestamp={group?.deletedAt} format="short" popover />
          </DescriptionListContainer>
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default GroupSummary;
