import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import {
  AccountQuery,
  AccountType,
  EntitlementGroupFragment,
} from "../../graphql/generated";
import Timestamp from "../../common/components/timestamp";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import Labels from "./labels";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";

const AccountSummary: React.FC<{ account?: AccountQuery["account"] }> = ({
  account,
}) => {
  function entitlementGroups(value?: Array<EntitlementGroupFragment>): string {
    if (value === undefined) return "";

    return Object.entries(value)
      .map((item) => {
        return item[1].label;
      })
      .join(" ");
  }

  function acceptLenderOutreach(value: boolean | null | undefined): string {
    if (value === undefined || value === null) {
      return "Unspecified";
    } else if (value) {
      return "Yes";
    } else {
      return "No";
    }
  }

  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
          <DescriptionListContainer title="Status">
            <StatusComponent entity={account} />
          </DescriptionListContainer>
          <DescriptionListContainer title="First Active">
            <Timestamp timestamp={account?.activatedAt} format="long" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Most Recent Active">
            <Timestamp timestamp={account?.activeAt} format="long" popover />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Sessions"
            info="The number of total, web, and mobile sessions this account has had since its inception."
            value={`${account?.sessionCounts?.total} · ${account?.sessionCounts?.web}w · ${account?.sessionCounts?.mobile}m`}
          />
          <DescriptionListItem
            title="Listings Saved"
            value={account?.itemsPinnedCount}
          />
          <DescriptionListItem
            title="Tours Requested"
            value={account?.tourRequestsCount}
          />
          <DescriptionListItem
            title="Listings Hidden"
            value={account?.itemsHiddenCount}
          />
          <DescriptionListItem
            title="Notes Sent"
            value={account?.notesSentCount}
          />
          <DescriptionListItem
            title="Listing Views"
            info="The total amount of times this account has viewed a listing, and the unique number of listings they have viewed. The total is typically larger than the unique because an account can view the same listing multiple times."
            value={`${account?.listingViewCounts?.total} · ${account?.listingViewCounts?.unique}`}
          />
          <DescriptionListContainer title="Suspended at">
            {!!account?.suspendedAt && (
              <NoSymbolIcon className="w-5 h-5 text-red-500 inline pr-1" />
            )}
            <Timestamp timestamp={account?.suspendedAt} format="long" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Will suspend at">
            <Timestamp timestamp={account?.suspendsAt} format="long" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Last notified about upcoming suspension">
            <Timestamp
              timestamp={account?.suspendsNotifiedAt}
              format="long"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListContainer
            title="Labels"
            info="Arbitrary labels that CS can add to accounts for searching, tracking, or information."
          >
            <Labels account={account} />
          </DescriptionListContainer>

          <DescriptionListItem
            title="Active Entitlements"
            info="Entitlement Groups that are currently actice."
            value={entitlementGroups(account?.activeEntitlementGroups)}
          />
          <DescriptionListItem
            title="Assigned Product Offering"
            info="The default product and upgrade tiers available to this account."
            value={account?.purchaseStoreConfiguration?.defaultOfferingId}
            link={`/product_offerings/${account?.purchaseStoreConfiguration?.defaultOfferingId}`}
          />
          {(account?.accountType == AccountType.Agent ||
            account?.accountType == AccountType.Lender) && (
            <DescriptionListItem
              title="Potential RC Subscriber"
              info="The RevenueCat Subscriber Id if one were to be created."
              value={account?.id}
              link={`https://app.revenuecat.com/customers/3368be2e/${account?.id}`}
            />
          )}
          {account?.intercomUserId && (
            <DescriptionListItem
              title="Intercom"
              info="The Intercom Id if one exists."
              value={account?.intercomUserId}
              link={`https://app.intercom.com/a/apps/in8v0k86/users/${account?.intercomUserId}`}
            />
          )}
          {account?.hubspotUserId && (
            <DescriptionListItem
              title="Hubspot"
              info="The Hubspot Id if one exists."
              value={account?.hubspotUserId}
              link={`https://app.hubspot.com/contacts/21517349/record/0-1/${account?.hubspotUserId}`}
            />
          )}
          {account?.accountType == AccountType.Member && (
            <DescriptionListItem
              title="Accepts Lender Outreach"
              info="Whether or not the client accepts warm lender outreach."
              value={acceptLenderOutreach(
                account?.preferences.acceptLenderOutreach
              )}
            />
          )}
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default AccountSummary;
