import { AtSymbolIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import { BrokerageFragment } from "../../graphql/generated";

const BrokeragePic: React.FC<{
  brokerage?: BrokerageFragment;
  large?: boolean;
}> = ({ brokerage, large }) => {
  return (
    <>
      {brokerage?.logoUrl ? (
        <img
          className={classNames(
            brokerage?.activeAgentsCount > 0
              ? "bg-green-100 border-green-300"
              : "bg-red-100 border-red-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "object-contain rounded-full border-2"
          )}
          src={brokerage?.logoUrl}
          alt={brokerage?.name}
        />
      ) : (
        <AtSymbolIcon
          className={classNames(
            brokerage && brokerage?.activeAgentsCount > 0
              ? "bg-green-100 border-green-300"
              : "bg-red-100 border-red-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
    </>
  );
};

export default BrokeragePic;
