import React from "react";
import { useParams } from "react-router-dom";
import { useGroupItemQuery } from "../../graphql/generated";
import Loading from "../../common/components/loading";
import { Transition } from "@headlessui/react";
import ListingTitle from "../../listing/components/title";
import GroupItemSummary from "../components/summary";
import GroupItemSavedFilters from "../components/savedfilters";
import Error from "../../common/components/error";
import { useTitle } from "../../common/utils/title";

const GroupItemShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useGroupItemQuery({
    variables: { id: params.groupItemId ?? "" },
    pause: !params.groupItemId,
  });
  useTitle(params.groupItemId, data?.groupItem.group?.nameLong, "Group Items");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ListingTitle
            listing={data?.groupItem.listing}
            subtitle={data?.groupItem.id}
          />
          <GroupItemSummary item={data?.groupItem} />
          {data?.groupItem.savedFilters && (
            <GroupItemSavedFilters filters={data?.groupItem.savedFilters} />
          )}
        </div>
      </Transition>
    </>
  );
};

export default GroupItemShow;
