import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import Timestamp from "../../common/components/timestamp";
import {
  IngestTaskFinishMode,
  IngestTaskFragment,
  IngestTaskProcessingMode,
  IngestTaskTarget,
} from "../../graphql/generated";

const IngestTaskSummary: React.FC<{ task?: IngestTaskFragment }> = ({
  task,
}) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem title="ID" value={task?.id} />
          <DescriptionListContainer
            title="Status"
            info="Whether the task is running or has finished"
          >
            <StatusComponent entity={task} />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Target"
            value={targetDisplay(task?.target)}
            info="Which part of the MLS entity is being ingested by this task"
          />
          <DescriptionListContainer
            title="Finish mode"
            info="What will happen when this task catches up to the current timestamp"
          >
            <FinishModeAbbr finishMode={task?.finishMode} />
          </DescriptionListContainer>
          <DescriptionListContainer
            title="Processing mode"
            info="The current state of the task. This is useful for backend engineers."
          >
            <ProcessingModeAbbr processingMode={task?.processingMode} />
          </DescriptionListContainer>
          <DescriptionListItem title="MLS" value={task?.mls.shortName} />
          <DescriptionListContainer title="Created at">
            <Timestamp format="long" timestamp={task?.createdAt} popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Ingested through">
            <Timestamp format="long" timestamp={task?.timestamp} popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Finished at">
            <Timestamp format="long" timestamp={task?.finishedAt} popover />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Current iteration"
            value={task?.v}
            info="Internal state that is useful for backend engineers."
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

function targetDisplay(target?: IngestTaskTarget): string {
  if (!target) {
    return "Unknown";
  }

  switch (target) {
    case IngestTaskTarget.Agent:
      return "Agents";
    case IngestTaskTarget.BrokerTour:
      return "Broker tours";
    case IngestTaskTarget.Office:
      return "Offices";
    case IngestTaskTarget.Openhouse:
      return "Open houses";
    case IngestTaskTarget.Property:
      return "Listings";
    case IngestTaskTarget.ValidListing:
      return "Valid listings";
  }
}

const FinishModeAbbr: React.FC<{ finishMode?: IngestTaskFinishMode }> = ({
  finishMode,
}) => {
  let name;
  let description;
  if (!finishMode) {
    return <>"Unknown"</>;
  }

  switch (finishMode) {
    case IngestTaskFinishMode.Finish:
      name = "Finish";
      description = "When caught up, this task will not do any more work";
      break;
    case IngestTaskFinishMode.Follow:
      name = "Follow";
      description = "Once caught up, this task will continue fetching new data";
      break;
  }

  return <abbr title={description}>{name}</abbr>;
};

const ProcessingModeAbbr: React.FC<{
  processingMode?: IngestTaskProcessingMode;
}> = ({ processingMode }) => {
  let name;
  let description;
  if (!processingMode) {
    return <>"Unknown"</>;
  }

  switch (processingMode) {
    case IngestTaskProcessingMode.Backfilling:
      name = "Backfilling";
      description = "Currently working through the backlog of all items";
      break;
    case IngestTaskProcessingMode.Following:
      name = "Following";
      description = "All caught up and actively ingesting new items";
      break;
    case IngestTaskProcessingMode.FollowingFellBehind:
      name = "Following (fell behind)";
      description =
        "Was recently all caught up, but has fallen a little bit behind";
      break;
    case IngestTaskProcessingMode.NightlyBackfilling:
      name = "Backfilling (nightly)";
      description = "Task created to perform a nighly or hourly backfill";
      break;
    case IngestTaskProcessingMode.Singular:
      name = "Singular";
      description = "Processing a single resource";
      break;
  }

  return <abbr title={description}>{name}</abbr>;
};

export default IngestTaskSummary;
