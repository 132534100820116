import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import { shortDateFormat } from "../../common/utils/dateformat";
import { SavedFilterFragment } from "../../graphql/generated";
import SavedFilterPic from "./savedfilterpic";

const SavedFilterTitle: React.FC<{
  filter?: SavedFilterFragment;
}> = ({ filter }) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <SavedFilterPic filter={filter} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {filter?.name.trim() === "" ? "Empty Name" : filter?.name}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {shortDateFormat(filter?.createdAt)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedFilterTitle;
