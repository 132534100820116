import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const Patch: React.FC<{
  forwardPatch: string;
  backwardPatch: string;
}> = ({ forwardPatch, backwardPatch }) => {
  return (
    <>
      <SyntaxHighlighter
        language="json"
        style={a11yDark}
        // className="h-32"
      >
        {JSON.stringify(JSON.parse(forwardPatch), null, 2)}
      </SyntaxHighlighter>
      <SyntaxHighlighter
        language="json"
        style={a11yDark}
        // className="h-32"
      >
        {JSON.stringify(JSON.parse(backwardPatch), null, 2)}
      </SyntaxHighlighter>
    </>
  );
};

export default Patch;
