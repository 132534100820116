import React from "react";
import { useClient } from "urql";
import AccountCard from "../../../account/components/card";
import Panel from "../../../common/components/panel";
import { classNames } from "../../../common/utils/classnames";
import {
  ConversationFragment,
  ParticipantsDocument,
  useParticipantsQuery,
} from "../../../graphql/generated";

const ConversationParticipants: React.FC<{
  conversation?: ConversationFragment;
}> = ({ conversation }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();
  const [{ data }] = useParticipantsQuery({
    variables: {
      first: 20,
      conversationId: conversation?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.participants.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(ParticipantsDocument, {
          first: 20,
          after: data.participants.pageInfo.endCursor,
          conversationId: conversation?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, conversation]);

  return (
    <Panel>
      <Panel.Title>
        Participants{" "}
        <span className="text-xs text-gray-500">
          {data?.participants.total}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.participants.edges?.map(
            (participant) =>
              participant?.node.account && (
                <AccountCard
                  account={participant.node.account}
                  key={participant.node.id}
                />
              )
          )}
        </div>
      </Panel.Body>

      {data &&
        data?.participants.total > (data?.participants.edges?.length ?? 0) && (
          <Panel.Footer>
            <button
              className={classNames(
                loading
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetchMore()}
              disabled={loading}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
    </Panel>
  );
};

export default ConversationParticipants;
