import React from "react";
import {
  CanbyAnalysisShowFragment,
  useCanbyAnalysisFieldsQuery,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";
import Field from "./field";
import LoadingIcon from "../../common/components/loadingicon";
import { InfoPill, OptionsPill } from "./pill";
import {
  BarsArrowDownIcon,
  DocumentIcon,
  FunnelIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import { Filter } from "./filter";

enum SortKey {
  ALPHABETIC,
  USAGE,
}

function sortKeyName(sortKey: SortKey): string {
  switch (sortKey) {
    case SortKey.ALPHABETIC:
      return "Alphabetic";
    case SortKey.USAGE:
      return "Usage";
  }
}

const FieldsPanel: React.FC<{ analysis?: CanbyAnalysisShowFragment }> = ({
  analysis,
}) => {
  const isFirstIngestRunning =
    analysis &&
    analysis.ingests.length > 0 &&
    analysis.ingests.every((ingest) => !ingest.finishedAt);

  const filter = Filter.useState();

  const isPaused =
    !analysis || analysis.documentCount === 0 || isFirstIngestRunning;
  const [sortKey, setSortKey] = React.useState(SortKey.ALPHABETIC);
  const [{ data, error, fetching }] = useCanbyAnalysisFieldsQuery({
    variables: {
      id: analysis?.id ?? "",
      filter: filter.filter,
    },
    pause: isPaused,
  });
  const sortedFields = React.useMemo(() => {
    if (!data) {
      return undefined;
    }
    const newArray = Array.from(data.canbyAnalysis.fields);
    function compareByName(a: string, b: string): number {
      if (a < b) {
        return -1;
      } else if (b < a) {
        return 1;
      } else {
        return 0;
      }
    }
    switch (sortKey) {
      case SortKey.ALPHABETIC:
        newArray.sort((a, b) => compareByName(a.name, b.name));
        break;
      case SortKey.USAGE:
        newArray.sort((a, b) => {
          const byCount = b.count - a.count;
          if (byCount === 0) {
            return compareByName(a.name, b.name);
          } else {
            return byCount;
          }
        });
        break;
    }

    return newArray;
  }, [data?.canbyAnalysis.fields, sortKey]);

  return (
    <Panel>
      <Panel.Body>
        <div className="mb-3 sticky top-16 bg-white z-50 py-4">
          {fetching && (
            <>
              <LoadingIcon />{" "}
            </>
          )}
          <InfoPill icon={TableCellsIcon}>
            {data && !fetching
              ? data.canbyAnalysis.fields.length.toLocaleString()
              : "..."}
          </InfoPill>{" "}
          <InfoPill icon={DocumentIcon}>
            {data && !fetching
              ? data.canbyAnalysis.documentCount.toLocaleString()
              : "..."}
          </InfoPill>{" "}
          <OptionsPill
            icon={BarsArrowDownIcon}
            options={[
              [SortKey.ALPHABETIC, sortKeyName(SortKey.ALPHABETIC)],
              [SortKey.USAGE, sortKeyName(SortKey.USAGE)],
            ]}
            onOptionSelected={setSortKey}
          >
            {sortKeyName(sortKey)}
          </OptionsPill>
          <FilterPills filter={filter} />
        </div>
        {isFirstIngestRunning && (
          <div>
            <LoadingIcon /> Fields will be available after first ingest has
            finished
          </div>
        )}
        {!isFirstIngestRunning && analysis && analysis.documentCount == 0 && (
          <div>Ingest data to view field</div>
        )}
        <ul>
          {error && <li className="text-red-500">Failed to load fields</li>}
          {!fetching &&
            sortedFields &&
            sortedFields.map((field) => (
              <li key={field.id}>
                <Field
                  field={field}
                  analysis={data?.canbyAnalysis}
                  filter={filter}
                />
              </li>
            ))}
        </ul>
      </Panel.Body>
    </Panel>
  );
};

const FilterPills: React.FC<{ filter: Filter }> = ({ filter }) => {
  return (
    <>
      {filter.filter.keywordMatch &&
        filter.filter.keywordMatch.map((match) => {
          const textArray = [];
          if (match.matches) {
            if (match.matches.length === 1) {
              textArray.push("1 match");
            } else if (match.matches.length > 1) {
              textArray.push(`${match.matches.length} matches`);
            }
          }
          if (match.excludes) {
            if (match.excludes.length === 1) {
              textArray.push("1 exclude");
            } else if (match.excludes.length > 1) {
              textArray.push(`${match.excludes.length} excludes`);
            }
          }
          const text = textArray.join(", ");
          return (
            <>
              {" "}
              <OptionsPill
                key={match.field}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearKeywordMatch(match.field)}
                active
              >
                <b>{match.field}</b>: {text}
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.numberRange &&
        filter.filter.numberRange.map((match) => {
          const output = [];
          if (match.gt !== undefined) {
            output.push(<>{match.gt?.toLocaleString()} &lt; </>);
          }
          if (match.gte !== undefined) {
            output.push(<>{match.gte?.toLocaleString()} ≤ </>);
          }
          output.push(
            <>
              <b>{match.field}</b>
            </>
          );
          if (match.lt !== undefined) {
            output.push(<> &lt; {match.lt?.toLocaleString()}</>);
          }
          if (match.lte !== undefined) {
            output.push(<> ≤ {match.lte?.toLocaleString()}</>);
          }
          return (
            <>
              {" "}
              <OptionsPill
                key={match.field}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearNumberRange(match.field)}
                active
              >
                {output}
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.dateRange &&
        filter.filter.dateRange.map((match) => {
          const output = [];
          if (match.gt) {
            output.push(<>{match.gt} &lt; </>);
          }
          if (match.gte) {
            output.push(<>{match.gte} ≤ </>);
          }
          output.push(
            <>
              <b>{match.field}</b>
            </>
          );
          if (match.lt) {
            output.push(<> &lt; {match.lt}</>);
          }
          if (match.lte) {
            output.push(<> ≤ {match.lte}</>);
          }
          return (
            <>
              {" "}
              <OptionsPill
                key={match.field}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearDateRange(match.field)}
                active
              >
                {output}
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.timestampRange &&
        filter.filter.timestampRange.map((match) => {
          const output = [];
          if (match.gt) {
            output.push(<>{match.gt.substring(0, 10)} &lt; </>);
          }
          if (match.gte) {
            output.push(<>{match.gte.substring(0, 10)} ≤ </>);
          }
          output.push(
            <>
              <b>{match.field}</b>
            </>
          );
          if (match.lt) {
            output.push(<> &lt; {match.lt.substring(0, 10)}</>);
          }
          if (match.lte) {
            output.push(<> ≤ {match.lte.substring(0, 10)}</>);
          }
          return (
            <>
              {" "}
              <OptionsPill
                key={match.field}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearTimestampRange(match.field)}
                active
              >
                {output}
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.exists &&
        filter.filter.exists.map((name) => (
          <>
            {" "}
            <OptionsPill
              key={name}
              icon={FunnelIcon}
              options={[["remove", "Remove"]]}
              onOptionSelected={() => filter.clearExists(name)}
              active
            >
              <b>${name}</b> exists
            </OptionsPill>
          </>
        ))}
      {filter.filter.notExists &&
        filter.filter.notExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearNotExists(name)}
                active
              >
                <b>{name}</b> not exists
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.booleanExists &&
        filter.filter.booleanExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearBooleanExists(name)}
                active
              >
                <b>{name}</b> is boolean
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.booleanNotExists &&
        filter.filter.booleanNotExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearBooleanNotExists(name)}
                active
              >
                <b>{name}</b> is not boolean
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.dateExists &&
        filter.filter.dateExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearDateExists(name)}
                active
              >
                <b>{name}</b> is date
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.dateNotExists &&
        filter.filter.dateNotExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearDateNotExists(name)}
                active
              >
                <b>{name}</b> is not date
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.timestampExists &&
        filter.filter.timestampExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearTimestampExists(name)}
                active
              >
                <b>{name}</b> is timestamp
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.timestampNotExists &&
        filter.filter.timestampNotExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearTimestampNotExists(name)}
                active
              >
                <b>{name}</b> is not timestamp
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.numberExists &&
        filter.filter.numberExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearNumberExists(name)}
                active
              >
                <b>{name}</b> is number
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.numberNotExists &&
        filter.filter.numberNotExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearNumberNotExists(name)}
                active
              >
                <b>{name}</b> is not number
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.keywordExists &&
        filter.filter.keywordExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearKeywordExists(name)}
                active
              >
                <b>{name}</b> is keyword
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.keywordNotExists &&
        filter.filter.keywordNotExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearKeywordNotExists(name)}
                active
              >
                <b>{name}</b> is not keyword
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.objectExists &&
        filter.filter.objectExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearObjectExists(name)}
                active
              >
                <b>{name}</b> is object
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.objectNotExists &&
        filter.filter.objectNotExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearObjectNotExists(name)}
                active
              >
                <b>{name}</b> is not object
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.listExists &&
        filter.filter.listExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearListExists(name)}
                active
              >
                <b>{name}</b> is list
              </OptionsPill>
            </>
          );
        })}
      {filter.filter.listNotExists &&
        filter.filter.listNotExists.map((name) => {
          return (
            <>
              {" "}
              <OptionsPill
                key={name}
                icon={FunnelIcon}
                options={[["remove", "Remove"]]}
                onOptionSelected={() => filter.clearListNotExists(name)}
                active
              >
                <b>{name}</b> is not list
              </OptionsPill>
            </>
          );
        })}
    </>
  );
};

export default FieldsPanel;
