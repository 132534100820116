import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useSavedFilterQuery } from "../../graphql/generated";
import Patches from "../../patch/components/patches";
import SavedFilterHistory from "../components/history";
import SavedFilterJson from "../components/json";
import SavedFilterSummary from "../components/summary";
import SavedFilterTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const SavedFilterShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useSavedFilterQuery({
    variables: { id: params.savedFilterId ?? "" },
    pause: !params.savedFilterId,
  });
  useTitle(
    data?.savedFilter?.name,
    data?.savedFilter.group?.nameLong,
    "Saved Filters"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <SavedFilterTitle filter={data?.savedFilter} />
          <SavedFilterSummary filter={data?.savedFilter} />
          <SavedFilterHistory filter={data?.savedFilter} />
          <SavedFilterJson filter={data?.savedFilter} />
          <Patches savedFilterId={data?.savedFilter.id} />
        </div>
      </Transition>
    </>
  );
};

export default SavedFilterShow;
