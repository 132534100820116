import { UserIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Status } from "../../common/components/status";
import { classNames } from "../../common/utils/classnames";
import { AgentFragment } from "../../graphql/generated";

const AgentPic: React.FC<{ agent?: AgentFragment; large?: boolean }> = ({
  agent,
  large,
}) => {
  const status = React.useMemo(() => {
    return Status.for(agent);
  }, [agent]);

  return (
    <UserIcon
      className={classNames(
        status.color.picClasses(),
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2"
      )}
    />
  );
};

export default AgentPic;
