import React from "react";
import { FeatureFlagFragment } from "../../graphql/generated";
import FeatureFlagItemActions from "./itemactions";
import FeatureFlagPic from "./pic";

const FeatureFlagTitle: React.FC<{ flag?: FeatureFlagFragment }> = ({
  flag,
}) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <FeatureFlagPic flag={flag} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {flag?.name.trim() === "" ? "Empty Name" : flag?.name}
            </h2>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <FeatureFlagItemActions flag={flag} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureFlagTitle;
