import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { BellAlertIcon } from "@heroicons/react/24/outline";

const EditProblemThresholdDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (problemThreshold: number) => void;
  isFetching: boolean;
}> = ({ isOpen, onClose, onSubmit, isFetching }) => {
  const [threshold, setThreshold] = React.useState(100);
  const inputRef = React.useRef(null);

  const handleSubmit = React.useCallback(() => {
    onSubmit(threshold);
  }, [onSubmit, threshold]);

  return (
    <ZenDialog
      show={isOpen}
      title="Update problem threshold"
      icon={BellAlertIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <input
          ref={inputRef}
          type="number"
          name="name"
          value={threshold}
          min={0}
          max={500}
          onChange={(e) => setThreshold(e.target.valueAsNumber)}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md font-mono"
        />
      </div>
    </ZenDialog>
  );
};

export default EditProblemThresholdDialog;
