import { Transition } from "@headlessui/react";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useApiKeyQuery } from "../../graphql/generated";
import ApiSubscriptions from "../components/subscriptions";
import ApiKeyTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const ApiKeyShow: React.FC = () => {
  const { apiKeyId } = useParams();
  const [{ data, error }, refetch] = useApiKeyQuery({
    variables: { id: apiKeyId ?? "" },
    pause: !apiKeyId,
  });
  useTitle(apiKeyId, "API Keys");

  const refetchApi = useCallback(() => {
    refetch({ requestPolicy: "network-only" });
  }, [refetch]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ApiKeyTitle apiKey={data?.apiKey} />
          <ApiSubscriptions apiKey={data?.apiKey} refresh={refetchApi} />
        </div>
      </Transition>
    </>
  );
};

export default ApiKeyShow;
