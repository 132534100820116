import React from "react";

interface IClipboardContext {
  sendToClip: (text?: string) => void;
  clear: () => void;
  value?: string;
}

export const ClipboardContext = React.createContext<IClipboardContext>({
  sendToClip: () => null,
  clear: () => null,
});

const ClipboardProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [value, setValue] = React.useState<string>();

  const clear = React.useCallback(() => setValue(undefined), []);

  const sendToClip = React.useCallback((text?: string) => {
    setValue(text);
    !!text && navigator.clipboard.writeText(text);
  }, []);

  return (
    <ClipboardContext.Provider
      value={{
        sendToClip,
        clear,
        value,
      }}
    >
      {children}
    </ClipboardContext.Provider>
  );
};

export default ClipboardProvider;
