import React from "react";
import Panel from "../../common/components/panel";
import { ApiKeyFragment } from "../../graphql/generated";
import SubscriptionActions from "./subscriptionactions";
import ApiSubscriptionCard from "./subscriptioncard";

const ApiSubscriptions: React.FC<{
  apiKey?: ApiKeyFragment;
  refresh: () => void;
}> = ({ apiKey, refresh }) => {
  return (
    <Panel>
      <Panel.Title
        actions={<SubscriptionActions apiKey={apiKey} refresh={refresh} />}
      >
        Subscriptions{" "}
        <span className="text-xs text-gray-500">
          {apiKey?.subscriptions.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <ul className="divide-y divide-gray-200">
              {apiKey?.subscriptions.map((subscription) => (
                <ApiSubscriptionCard
                  subscription={subscription}
                  key={subscription.id}
                />
              ))}
            </ul>
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default ApiSubscriptions;
