import React from "react";
import { useClient } from "urql";
import Panel from "../../common/components/panel";
import { classNames } from "../../common/utils/classnames";
import {
  MlsFragment,
  OfficesDocument,
  useOfficesQuery,
} from "../../graphql/generated";
import OfficeCard from "../../office/components/card";

const MlsOfficeEnablement: React.FC<{
  mls?: MlsFragment;
}> = ({ mls }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data: officeData }] = useOfficesQuery({
    variables: {
      first: 2,
      mlsId: mls?.id,
      manuallyEnabled: true,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (officeData?.offices.pageInfo.hasNextPage) {
      setLoading(true);
    }
    const promises = [];
    if (officeData?.offices.pageInfo.hasNextPage) {
      promises.push(
        graphqlClient
          .query(OfficesDocument, {
            first: 20,
            after: officeData.offices.pageInfo.endCursor,
            mlsId: mls?.id,
            manuallyEnabled: true,
          })
          .toPromise()
      );
    }
    if (promises.length) {
      await Promise.all(promises);
      setLoading(false);
    }
  }, [officeData, graphqlClient, mls]);

  return (
    <Panel>
      <Panel.Title>Manually Enabled Offices</Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {officeData?.offices.edges?.map((office) => (
            <OfficeCard office={office?.node} key={office?.node.id} />
          ))}
        </div>
      </Panel.Body>

      {officeData &&
        officeData?.offices.total >
          (officeData?.offices.edges?.length ?? 0) && (
          <Panel.Footer>
            <button
              className={classNames(
                loading
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetchMore()}
              disabled={loading}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
    </Panel>
  );
};

export default MlsOfficeEnablement;
