import React from "react";
import { useClient } from "urql";
import Panel from "../../common/components/panel";
import CommonSwitch from "../../common/components/switch";
import { classNames } from "../../common/utils/classnames";
import {
  AccountFragment,
  GroupsDocument,
  GroupState,
  useGroupsQuery,
} from "../../graphql/generated";
import GroupCard from "../../group/components/card";

const AccountGroups: React.FC<{
  account?: AccountFragment;
  states: GroupState[];
}> = ({ account }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();
  const [search, setSearch] = React.useState("");
  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSearch(event.target.value),
    [setSearch]
  );
  const [groupStates, groupStatesDispatch] = React.useReducer(
    (state: GroupState[], action: { state: GroupState }) => {
      if (state.indexOf(action.state) > -1) {
        return state.filter((at) => at !== action.state);
      }
      return [...state, action.state];
    },
    [
      GroupState.Personal,
      GroupState.Active,
      GroupState.Pending,
      GroupState.Archived,
      GroupState.Deleted,
    ]
  );
  const [{ data }] = useGroupsQuery({
    variables: {
      first: 20,
      states: groupStates,
      accountId: account?.id || "",
      search: search === "" ? undefined : search,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (search === "" && data?.groups.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(GroupsDocument, {
          first: 20,
          after: data.groups.pageInfo.endCursor,
          accountId: account?.id || "",
          states: groupStates,
          search: search === "" ? undefined : search,
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, account, groupStates, search]);

  // Queries used for stats in the header
  const [{ data: activeData }] = useGroupsQuery({
    variables: {
      first: 0,
      states: [GroupState.Active],
      accountId: account?.id || "",
    },
  });
  const [{ data: archivedData }] = useGroupsQuery({
    variables: {
      first: 0,
      states: [GroupState.Archived],
      accountId: account?.id || "",
    },
  });
  const [{ data: pendingData }] = useGroupsQuery({
    variables: {
      first: 0,
      states: [GroupState.Pending],
      accountId: account?.id || "",
    },
  });
  const [{ data: deletedData }] = useGroupsQuery({
    variables: {
      first: 0,
      states: [GroupState.Deleted],
      accountId: account?.id || "",
    },
  });

  return (
    <Panel>
      <Panel.Title>
        Groups{" "}
        <span className="text-xs text-gray-500">
          {activeData?.groups.total} active &bull; {pendingData?.groups.total}{" "}
          pending &bull; {archivedData?.groups.total} archived &bull;{" "}
          {deletedData?.groups.total} deleted
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-0">
          <div className="flex items-center text-sm text-gray-500">
            <CommonSwitch
              enabled={groupStates.indexOf(GroupState.Personal) > -1}
              toggle={() => groupStatesDispatch({ state: GroupState.Personal })}
              label="Personal"
            />
            <CommonSwitch
              enabled={groupStates.indexOf(GroupState.Active) > -1}
              toggle={() => groupStatesDispatch({ state: GroupState.Active })}
              label="Active"
            />
            <CommonSwitch
              enabled={groupStates.indexOf(GroupState.Pending) > -1}
              toggle={() => groupStatesDispatch({ state: GroupState.Pending })}
              label="Pending"
            />
            <CommonSwitch
              enabled={groupStates.indexOf(GroupState.Archived) > -1}
              toggle={() => groupStatesDispatch({ state: GroupState.Archived })}
              label="Archived"
            />
            <CommonSwitch
              enabled={groupStates.indexOf(GroupState.Deleted) > -1}
              toggle={() => groupStatesDispatch({ state: GroupState.Deleted })}
              label="Deleted"
            />
          </div>
        </div>
        <div className="my-4">
          <input
            id="search-field"
            className="block w-full h-full p-2 border-gray-300 text-gray-900 placeholder-gray-300 focus:placeholder-gray-400 sm:text-sm"
            placeholder="Search for groups"
            type="search"
            name="search"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.groups.edges?.map((group) => (
            <GroupCard group={group?.node} key={group?.node.id} />
          ))}
        </div>
      </Panel.Body>
      {data && data?.groups.total > (data?.groups.edges?.length ?? 0) && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default AccountGroups;
