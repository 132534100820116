import React from "react";
import {
  BillingTableEntryFragment,
  StripeEnvironment,
} from "../../graphql/generated";

const BillingTableEntryCard: React.FC<{
  entry?: BillingTableEntryFragment;
}> = ({ entry }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400">
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate">
          Agent Price Id:{" "}
          {entry?.agentPrice.environment === StripeEnvironment.Production && (
            <a
              href={`https://dashboard.stripe.com/prices/${entry?.agentPrice.id}`}
              className="text-blue-900 hover:underline"
              rel="noreferrer"
            >
              {entry?.agentPrice.id}
            </a>
          )}
          {entry?.agentPrice.environment === StripeEnvironment.Test && (
            <a
              href={`https://dashboard.stripe.com/test/prices/${entry?.agentPrice.id}`}
              className="text-blue-900 hover:underline"
              rel="noreferrer"
            >
              {entry?.agentPrice.id}
            </a>
          )}{" "}
          &rarr; Billed Price Id:{" "}
          {entry?.billedPrice.environment === StripeEnvironment.Production && (
            <a
              href={`https://dashboard.stripe.com/prices/${entry?.billedPrice.id}`}
              className="text-blue-900 hover:underline"
              rel="noreferrer"
            >
              {entry?.billedPrice.id}
            </a>
          )}
          {entry?.billedPrice.environment === StripeEnvironment.Test && (
            <a
              href={`https://dashboard.stripe.com/test/prices/${entry?.billedPrice.id}`}
              className="text-blue-900 hover:underline"
              rel="noreferrer"
            >
              {entry?.billedPrice.id}
            </a>
          )}
        </p>
        <p className="text-sm text-gray-500 truncate">
          Agent Price:{" "}
          {entry?.agentPrice.nickname ?? entry?.agentPrice.productName} &rarr;
          Billed Price:{" "}
          {entry?.billedPrice.nickname ?? entry?.billedPrice.productName}
        </p>
      </div>
    </div>
  );
};

export default BillingTableEntryCard;
