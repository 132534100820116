import React from "react";
import { Link } from "react-router-dom";
import { problemUrl } from "./problem-url";

export class NavigationItems {
  items: NavigationItem[];
  constructor() {
    this.items = [];
  }

  static withHome(): NavigationItems {
    const navigationItems = new NavigationItems();
    navigationItems.add("Resource Mapping", `/resource_mapping`);
    return navigationItems;
  }

  add(name?: string, href?: string) {
    this.items.push({ name, href });
  }

  addMls(name?: string, id?: string) {
    if (id !== undefined) {
      this.add(name, `/resource_mapping/mlses/${id}`);
    } else {
      this.add(name, undefined);
    }
  }

  addRoot(name?: string, id?: string) {
    if (id !== undefined) {
      this.add(name, `/resource_mapping/roots/${id}`);
    } else {
      this.add(name, undefined);
    }
  }

  addField(name?: string, id?: string) {
    if (id !== undefined) {
      this.add(name, `/resource_mapping/fields/${id}`);
    } else {
      this.add(name, undefined);
    }
  }

  addMapping(name?: string, id?: string) {
    if (id !== undefined) {
      this.add(name, `/resource_mapping/mappings/${id}`);
    } else {
      this.add(name, undefined);
    }
  }

  addNormalization(name?: string, id?: string) {
    if (id !== undefined) {
      this.add(name, `/resource_mapping/normalizations/${id}`);
    } else {
      this.add(name, undefined);
    }
  }

  addProblem(name?: string, id?: string) {
    if (id !== undefined) {
      this.add(name, problemUrl(id));
    } else {
      this.add(name, undefined);
    }
  }
}

type NavigationItem = {
  href?: string;
  name?: string;
};

export const Navigation: React.FC<{
  items: NavigationItems;
}> = ({ items }) => {
  return (
    <div className="px-4 sm:px-6">
      <NavigationInline items={items} />
    </div>
  );
};

export const NavigationInline: React.FC<{
  items: NavigationItems;
}> = ({ items }) => {
  return (
    <>
      {items.items.map((item, idx) => {
        let link;
        if (item.href !== undefined) {
          link = (
            <Link to={item.href} key={item.href}>
              {item.name}
            </Link>
          );
        } else {
          link = <span key={item.name}>{item.name ?? "—"}</span>;
        }
        if (idx > 0) {
          return (
            <>
              <span className="px-2 text-gray-400">/</span>
              {link}
            </>
          );
        } else {
          return link;
        }
      })}
    </>
  );
};
