import { Transition } from "@headlessui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useReprocessDataTaskQuery } from "../../graphql/generated";
import { ReprocessDataTaskTitle, buildTaskTitle } from "../components/title";
import { useTitle } from "../../common/utils/title";
import ReprocessDataTaskSummary from "../components/summary";
import SchemaVersionCountsTable from "../../resource-mapping/components/schema-version-counts-table";
import Panel from "../../common/components/panel";

const ReprocessDataTaskShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }, executeQuery] = useReprocessDataTaskQuery({
    variables: { id: params.reprocessDataTaskId ?? "" },
    pause: !params.reprocessDataTaskId,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      executeQuery({ requestPolicy: "network-only" });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [executeQuery]);

  useTitle(buildTaskTitle(data?.reprocessDataTask), "Reprocess Data Tasks");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ReprocessDataTaskTitle task={data?.reprocessDataTask} />
          <ReprocessDataTaskSummary task={data?.reprocessDataTask} />
          <Panel>
            <Panel.Title>Schema Versions</Panel.Title>
            <Panel.Body>
              <SchemaVersionCountsTable
                counts={data?.reprocessDataTask.schemaVersionCounts}
                targetSchemaVersion={
                  data?.reprocessDataTask.targetSchemaVersion
                }
              />
            </Panel.Body>
          </Panel>
        </div>
      </Transition>
    </>
  );
};

export default ReprocessDataTaskShow;
