import React from "react";
import { useClient } from "urql";
import Panel from "../../../common/components/panel";
import { classNames } from "../../../common/utils/classnames";
import {
  SubethaEventsDocument,
  SubethaMailFragment,
  useSubethaEventsQuery,
} from "../../../graphql/generated";
import EventCard from "../../event/components/card";

const MailEvents: React.FC<{ mail?: SubethaMailFragment }> = ({ mail }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();
  const [{ data }] = useSubethaEventsQuery({
    variables: {
      first: 20,
      mailId: mail?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.events.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(SubethaEventsDocument, {
          first: 20,
          after: data.events.pageInfo.endCursor,
          mailId: mail?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, mail]);

  return (
    <Panel>
      <Panel.Title>
        Events{" "}
        <span className="text-xs text-gray-500">
          {data?.events.edges?.length}
        </span>
      </Panel.Title>

      <Panel.Body>
        <div className="grid grid-cols-1 gap-2">
          <div className="flow-root">
            <ul className="-mb-8">
              {data?.events.edges?.map((event, index) => (
                <EventCard
                  event={event?.node}
                  key={event?.node.id}
                  last={index === (data.events.edges?.length ?? 0) - 1}
                />
              ))}
            </ul>
          </div>
        </div>
      </Panel.Body>

      {/* {data && data?.events.total > data?.events.edges.length && ( */}
      <Panel.Footer>
        <button
          className={classNames(
            loading
              ? "bg-gray-500 text-white"
              : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
            "font-bold py-2 px-4 rounded"
          )}
          onClick={() => fetchMore()}
          disabled={loading}
        >
          Fetch More
        </button>
      </Panel.Footer>
      {/* )} */}
    </Panel>
  );
};

export default MailEvents;
