import { BuildingLibraryIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import { MlsFragment } from "../../graphql/generated";
import { Status } from "../../common/components/status";

const MlsPic: React.FC<{ mls?: MlsFragment; large?: boolean }> = ({
  mls,
  large,
}) => {
  const status = React.useMemo(() => {
    return Status.for(mls);
  }, [mls]);

  return (
    <BuildingLibraryIcon
      className={classNames(
        status.color.picClasses(),
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2 text-gray-500"
      )}
    />
  );
};

export default MlsPic;
