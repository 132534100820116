import { Menu, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import {
  EnterpriseAgreementFragment,
  useEnterpriseSynchronizeStripeBillingMutation,
} from "../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";

const EnterpriseAgreementItemActions: React.FC<{
  agreement?: EnterpriseAgreementFragment;
}> = ({ agreement }) => {
  const [{ error }, mutation] = useEnterpriseSynchronizeStripeBillingMutation();
  const { updateNotification } = React.useContext(NotificationContext);

  React.useEffect(() => {
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [error, updateNotification]);

  const refreshStripeBilling = React.useCallback(() => {
    mutation({ enterpriseAgreementId: agreement?.id || "" });

    updateNotification({
      notification: "Attempting to update stripe.",
      notificationType: NotificationType.Success,
    });
  }, [agreement, mutation]);

  return (
    <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
      <div>
        <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/enterprise_agreements/${agreement?.id}/edit`}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <PencilIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Edit
                </Link>
              )}
            </Menu.Item>
            {agreement?.enterpriseBilling.__typename ===
              "EnterpriseBillingStripeSubscription" &&
              agreement?.enterpriseBilling
                .automaticallyAdjustStripeSubscription === true && (
                <Menu.Item>
                  <div
                    onClick={refreshStripeBilling}
                    className={classNames(
                      "bg-gray-100 text-gray-900",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <ArrowPathIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Sync Stripe Billing
                  </div>
                </Menu.Item>
              )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default EnterpriseAgreementItemActions;
