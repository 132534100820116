import React from "react";
import {
  RmProblemFragment,
  useRmProblemAcknowledgeMutation,
  useRmProblemUnacknowledgeMutation,
} from "../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import LoadingIcon from "../../common/components/loadingicon";

const ProblemStatus: React.FC<{
  problem?: RmProblemFragment;
}> = ({ problem }) => {
  if (!problem) {
    return <></>;
  }

  const { updateNotification } = React.useContext(NotificationContext);
  const [{ fetching: fetchingAcknowledge }, mutationAcknowledge] =
    useRmProblemAcknowledgeMutation();
  const [{ fetching: fetchingUnacknowledge }, mutationUnacknowledge] =
    useRmProblemUnacknowledgeMutation();

  const fetching = fetchingAcknowledge || fetchingUnacknowledge;

  const acknowledge = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutationAcknowledge({
      problemId: problem.id,
    });
    if (!error) {
      updateNotification({
        notification: `Problem acknowledged`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [fetching, mutationAcknowledge, problem, updateNotification]);

  const unacknowledge = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutationUnacknowledge({
      problemId: problem.id,
    });
    if (!error) {
      updateNotification({
        notification: `Problem unacknowledged`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [fetching, mutationUnacknowledge, problem, updateNotification]);

  if (fetching) {
    return <LoadingIcon />;
  }

  if (problem.acknowledgedAt) {
    return (
      <CheckCircleIcon
        className="h-5 w-5 text-green-500 cursor-pointer"
        onClick={unacknowledge}
      />
    );
  }

  return (
    <ExclamationCircleIcon
      className="h-5 w-5 text-gray-300 cursor-pointer"
      onClick={acknowledge}
    />
  );
};

export default ProblemStatus;
