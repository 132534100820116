/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { diffJson, Change } from "diff";

function compactDiffJson(
  originalOldJson: Record<string, any>,
  originalNewJson: Record<string, any>
): Change[] {
  const oldJson: Record<string, any> = {};
  const newJson: Record<string, any> = {};
  const allKeys = Object.keys(originalOldJson);
  allKeys.push(...Object.keys(originalNewJson));
  allKeys.sort();
  for (const key of allKeys) {
    if (
      JSON.stringify(originalOldJson[key]) !==
      JSON.stringify(originalNewJson[key])
    ) {
      oldJson[key] = originalOldJson[key];
      newJson[key] = originalNewJson[key];
    }
  }

  return diffJson(oldJson, newJson);
}

const Diff: React.FC<{
  oldJson: object;
  newJson: object;
  compact?: boolean;
}> = ({ oldJson, newJson, compact }) => {
  let diffs;
  if (compact) {
    diffs = compactDiffJson(oldJson, newJson);
  } else {
    diffs = diffJson(oldJson, newJson);
  }

  const addedStyle = {
    color: "#34d399",
  };
  const removedStyle = {
    color: "#ef4444",
    textDecoration: "line-through",
  };
  const defaultStyle = {};

  return (
    <pre
      className={"col-span-2"}
      style={{
        display: "block",
        overflowX: "auto",
        background: "rgb(43, 43, 43) none repeat scroll 0% 0%",
        color: "rgb(248, 248, 242)",
        padding: "0.5em",
        width: "100%",
      }}
    >
      <code style={{ whiteSpace: "pre" }}>
        {diffs.map((part, index) => {
          const style = part.added
            ? addedStyle
            : part.removed
            ? removedStyle
            : defaultStyle;
          return (
            <span style={style} key={index}>
              {part.value}
            </span>
          );
        })}
      </code>
    </pre>
  );
};

export default Diff;
