import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import Timestamp from "../../common/components/timestamp";
import { ProductFragment } from "../../graphql/generated";

const ProductSummary: React.FC<{
  product?: ProductFragment;
}> = ({ product }) => {
  return (
    <Panel>
      <Panel.Title>Product</Panel.Title>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
          <DescriptionListItem title="Id" value={product?.id} />
          <DescriptionListItem title="Name" value={product?.productName} />

          <DescriptionListContainer title="Created">
            <Timestamp timestamp={product?.createdAt} format="long" popover />
          </DescriptionListContainer>

          <DescriptionListContainer title="Last Modified">
            <Timestamp timestamp={product?.modifiedAt} format="long" popover />
          </DescriptionListContainer>

          <DescriptionListItem
            title="Active"
            value={product?.active ? "Active" : "Archived"}
            info="If a product is active or archived"
          />

          <DescriptionListItem
            title="Purchase Store"
            info="The store that created and owns this product record."
            value={product?.purchaseStore}
          />
          {product?.purchaseStore === "STRIPE" && (
            <DescriptionListItem
              title="Purchase Store Id"
              info="The unique identifier at the purchase store for this product"
              value={product?.purchaseStoreId}
              link={`https://dashboard.stripe.com/products/${product.purchaseStoreId}`}
            />
          )}
          {!(product?.purchaseStore === "STRIPE") && (
            <DescriptionListItem
              title="Purchase Store Id"
              info="The unique identifier at the purchase store for this product"
              value={product?.purchaseStoreId}
            />
          )}
          <DescriptionListContainer
            title="Last Data Sync"
            info="The last time product data was synced from the purchase store."
          >
            <Timestamp
              timestamp={product?.lastPurchaseStoreSyncAt}
              format="long"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Entitlement Group"
            info="The entitlement group this product provides when a subscription is created using it."
            value={product?.entitlementGroup?.id}
          />
          <DescriptionListItem
            title="RC Product"
            info="The corresponding Id for this product in RevenueCat"
            value={product?.revenueCatProductId}
            link={`https://app.revenuecat.com/projects/3368be2e/products/${product?.revenueCatProductId}`}
          />
          <DescriptionListItem
            title="Subscription Group"
            info="The Subscription Group identifier"
            value={product?.appStoreSubscriptionGroup}
          />
          <DescriptionListContainer
            title="Stripe Environment"
            info="The customer type that can use this product. This applies only to Stripe as Test customers cannot receive Production prices and vice versa."
          >
            {(product?.testAccountOnly ?? true) && <>Test</>}
            {!(product?.testAccountOnly ?? false) && <>Production</>}
          </DescriptionListContainer>
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default ProductSummary;
