import React from "react";

const Gauge: React.FC<{ count?: number; total?: number; gray?: boolean }> = ({
  count,
  total,
  gray,
}) => {
  const actualGray = gray ?? false;
  const percentage =
    count === undefined || total === undefined || total === 0
      ? 0
      : count / total;

  const percentageDisplay = percentage.toLocaleString(undefined, {
    style: "percent",
    maximumFractionDigits: 2,
  });

  const barFill =
    percentage < 0.1
      ? 0.1
      : percentage > 0.9 && percentage < 1
      ? 0.9
      : percentage;

  const color = actualGray
    ? "#666"
    : percentage < 0.33
    ? "#c66"
    : percentage < 0.67
    ? "#e94"
    : "#4d8";

  return (
    <>
      <span
        style={{
          border: "1px solid black",
          position: "relative",
          height: "0.5em",
          width: "4em",
          borderRadius: "1px",
          display: "inline-block",
          background: "rgb(230, 230, 230)",
          backgroundClip: "padding-box",
        }}
      >
        <span
          style={{
            position: "absolute",
            width: `${barFill * 100}%`,
            top: 0,
            left: 0,
            bottom: 0,
            background: color,
          }}
        ></span>
      </span>{" "}
      <span className="text-sm" style={{ color: color }}>
        {percentageDisplay}
      </span>{" "}
    </>
  );
};

export default Gauge;
