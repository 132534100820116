import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Panel from "../../../common/components/panel";
import { SubethaMailFragment } from "../../../graphql/generated";

const MailTemplate: React.FC<{ mail?: SubethaMailFragment }> = ({ mail }) => {
  return (
    <Panel>
      <Panel.Title>Template Data</Panel.Title>
      <Panel.Body fullWidth constrainHeight>
        <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers>
          {JSON.stringify(mail?.template, null, 2)}
        </SyntaxHighlighter>
      </Panel.Body>
    </Panel>
  );
};

export default MailTemplate;
