import React from "react";
import {
  RmFieldDetailsFragment,
  useRmFieldAddRuleMappingMutation,
  useRmFieldAddTextBasicMappingMutation,
  useRmFieldAddTextFlattenMappingMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../common/utils/classnames";
import {
  ZenDialog,
  ZenDialogState,
} from "../../../common/components/zen-dialog";

const TextMappingsActions: React.FC<{
  field?: RmFieldDetailsFragment;
  isReordering?: boolean;
  onStartReordering?: () => void;
}> = ({ field, isReordering, onStartReordering }) => {
  if (!field) {
    return <></>;
  }

  const { updateNotification } = React.useContext(NotificationContext);
  const [addTextBasicDialogIsVisible, setAddTextBasicDialogIsVisible] =
    React.useState(false);
  const [addTextFlattenDialogIsVisible, setAddTextFlattenDialogIsVisible] =
    React.useState(false);
  const [addRuleDialogIsVisible, setAddRuleDialogIsVisible] =
    React.useState(false);

  const [{ fetching: addTextBasicFetching }, basicMutation] =
    useRmFieldAddTextBasicMappingMutation();

  const addTextBasicCallback = React.useCallback(
    async (source: string) => {
      const { error } = await basicMutation({
        fieldId: field.id,
        source,
      });

      if (!error) {
        updateNotification({
          notification: `Mapping created`,
          notificationType: NotificationType.Success,
        });
      } else {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      }
      setAddTextBasicDialogIsVisible(false);
    },
    [basicMutation, field, updateNotification, setAddTextBasicDialogIsVisible]
  );

  const [{ fetching: addTextFlattenFetching }, flattenMutation] =
    useRmFieldAddTextFlattenMappingMutation();

  const addTextFlattenCallback = React.useCallback(
    async (source: string) => {
      const { error } = await flattenMutation({
        fieldId: field.id,
        source,
      });

      if (!error) {
        updateNotification({
          notification: `Mapping created`,
          notificationType: NotificationType.Success,
        });
      } else {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      }
      setAddTextFlattenDialogIsVisible(false);
    },
    [
      flattenMutation,
      field,
      updateNotification,
      setAddTextFlattenDialogIsVisible,
    ]
  );

  const [{ fetching: addRuleFetching }, addRuleMutation] =
    useRmFieldAddRuleMappingMutation();

  const addRuleCallback = React.useCallback(async () => {
    const { error } = await addRuleMutation({
      fieldId: field.id,
      isLocal: false,
    });

    if (!error) {
      updateNotification({
        notification: `Mapping created`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
    setAddRuleDialogIsVisible(false);
  }, [addRuleMutation, field, updateNotification, setAddRuleDialogIsVisible]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddTextBasicDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add basic text mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddTextFlattenDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add flatten text mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddRuleDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add rule mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={isReordering}>
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => onStartReordering && onStartReordering()}
                  >
                    <ChevronUpDownIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Reorder
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddTextBasicMappingDialog
        show={addTextBasicDialogIsVisible}
        onSubmit={addTextBasicCallback}
        onCancel={() => setAddTextBasicDialogIsVisible(false)}
        fetching={addTextBasicFetching}
      />
      <AddTextFlattenMappingDialog
        show={addTextFlattenDialogIsVisible}
        onSubmit={addTextFlattenCallback}
        onCancel={() => setAddTextFlattenDialogIsVisible(false)}
        fetching={addTextFlattenFetching}
      />
      <AddRuleMappingDialog
        show={addRuleDialogIsVisible}
        onSubmit={addRuleCallback}
        onCancel={() => setAddRuleDialogIsVisible(false)}
        fetching={addRuleFetching}
      />
    </>
  );
};

const AddTextBasicMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: (sourceField: string) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [value, setValue] = React.useState("");
  const valueId = React.useId();
  const isValid = !!value.length;
  return (
    <ZenDialog
      show={show}
      title="Add basic text mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit(value)}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <p>
          A basic text mapping maps text from the source field to text in the
          destination field.
        </p>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={valueId}>Source field</label>
        </div>
        <div>
          <input
            id={valueId}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

const AddTextFlattenMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: (sourceField: string) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [value, setValue] = React.useState("");
  const valueId = React.useId();
  const isValid = !!value.length;
  return (
    <ZenDialog
      show={show}
      title="Add flatten text mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit(value)}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <p>
          A flatten text mapping maps values from an array or a comma-separated
          list of values onto a single text field.
        </p>
      </div>
      <div className="mt-1">
        <p>
          This is typically used for non-searchable display fields to map all of
          the local lookup values for an MLS to something we can display in
          property details.
        </p>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={valueId}>Source field</label>
        </div>
        <div>
          <input
            id={valueId}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

const AddRuleMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  return (
    <ZenDialog
      show={show}
      title="Add rule mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit()}
      onCancel={onCancel}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <p>
          A rule mapping evaluates a RESO-standard RETS expression and maps the
          result of the expression onto the destination field.
        </p>
      </div>
    </ZenDialog>
  );
};

export default TextMappingsActions;
