import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useTourQuery } from "../../graphql/generated";
import Patches from "../../patch/components/patches";
import TourStops from "../components/stops";
import TourSummary from "../components/summary";
import TourTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const TourShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useTourQuery({
    variables: { id: params.tourId ?? "" },
    pause: !params.tourId,
  });
  useTitle(params.tourId, "Tours");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <TourTitle tour={data?.tour} />
          <TourSummary tour={data?.tour} />
          <TourStops tour={data?.tour} />
          <Patches tourId={data?.tour.id} />
        </div>
      </Transition>
    </>
  );
};

export default TourShow;
