import React from "react";
import { Link } from "react-router-dom";
import AccountProfilePic from "../../account/components/profilepic";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import { shortDateFormat } from "../../common/utils/dateformat";
import {
  ReferralFragment,
  ReferralState,
  ReferralType,
  useMarkReferralRewardedMutation,
  useUnmarkReferralRewardedMutation,
} from "../../graphql/generated";

const ReferralTableRow: React.FC<{ referral?: ReferralFragment }> = ({
  referral,
}) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [
    { data: markData, fetching: markFetching, error: markError },
    markMutation,
  ] = useMarkReferralRewardedMutation();
  const [
    { data: unmarkData, fetching: unmarkFetching, error: unmarkError },
    unmarkMutation,
  ] = useUnmarkReferralRewardedMutation();

  React.useEffect(() => {
    if (!markFetching && markData) {
      updateNotification({
        notification: "Item marked as rewarded.",
        notificationType: NotificationType.Success,
      });
    } else if (!markFetching && markError) {
      updateNotification({
        notification: markError.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [markData, markFetching, markError, updateNotification]);
  React.useEffect(() => {
    if (!unmarkFetching && unmarkData) {
      updateNotification({
        notification: "Item unmarked as rewarded.",
        notificationType: NotificationType.Success,
      });
    } else if (!unmarkFetching && unmarkError) {
      updateNotification({
        notification: unmarkError.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [unmarkData, unmarkFetching, unmarkError, updateNotification]);

  return (
    <tr>
      <td className="px-2 py-2 whitespace-nowrap">
        <AccountProfilePic account={referral?.referrer} />
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <span
          className={classNames(
            referral?.referralState === ReferralState.Active
              ? "text-blue-800 bg-blue-100"
              : "",
            referral?.referralState === ReferralState.Created
              ? "text-yellow-800 bg-yellow-100"
              : "",
            referral?.referralState === ReferralState.Rewarded
              ? "text-green-800 bg-green-100"
              : "",
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
          )}
        >
          {referral?.referralState}
        </span>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">
        <span
          className={classNames(
            referral?.referralType === ReferralType.Standard
              ? "text-cyan-800 bg-cyan-100"
              : "",
            referral?.referralType === ReferralType.EnterpriseAgreement
              ? "text-pink-800 bg-pink-100"
              : "",
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
          )}
        >
          {referral?.referralType === ReferralType.EnterpriseAgreement
            ? "EA"
            : referral?.referralType === ReferralType.Standard
            ? "Standard"
            : "???"}
        </span>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900">
        <Link
          to={`/accounts/${referral?.referrer.id}`}
          className="hover:underline"
        >
          {referral?.referrer.name}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900">
        <Link
          to={`/accounts/${referral?.referent.id}`}
          className="hover:underline"
        >
          {referral?.referent.name}
        </Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
        {shortDateFormat(referral?.createdAt)}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
        {shortDateFormat(referral?.activeAt)}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-500">
        {shortDateFormat(referral?.rewardedAt)}
      </td>
      <td className="px-2 py-2 whitespace-nowrap text-right text-sm font-medium">
        {referral?.referralState !== ReferralState.Rewarded ? (
          <div
            className="text-blue-900 hover:underline cursor-pointer"
            onClick={() => markMutation({ id: referral?.id ?? "" })}
          >
            Mark As Rewarded
          </div>
        ) : (
          <div
            className="text-gray-900 hover:underline cursor-pointer"
            onClick={() => unmarkMutation({ id: referral?.id ?? "" })}
          >
            Undo
          </div>
        )}
      </td>
    </tr>
  );
};

export default ReferralTableRow;
