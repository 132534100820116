import React from "react";
import {
  RmFieldDetailsFragment,
  useRmFieldAddLookupPriorityMappingMutation,
  useRmFieldAddLookupBasicMappingMutation,
  useRmFieldAddLookupTwoSourceMappingMutation,
  useRmFieldAddRuleMappingMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../common/utils/classnames";
import {
  ZenDialog,
  ZenDialogState,
} from "../../../common/components/zen-dialog";

const LookupMappingsActions: React.FC<{
  field?: RmFieldDetailsFragment;
  local?: boolean;
  isReordering?: boolean;
  onStartReordering?: () => void;
}> = ({ field, local, isReordering, onStartReordering }) => {
  if (!field) {
    return <></>;
  }

  const isLocal = !!local;

  const { updateNotification } = React.useContext(NotificationContext);
  const [addLookupBasicDialogIsVisible, setAddLookupBasicDialogIsVisible] =
    React.useState(false);
  const [
    addLookupPriorityDialogIsVisible,
    setAddLookupPriorityDialogIsVisible,
  ] = React.useState(false);
  const [
    addLookupTwoSourceDialogIsVisible,
    setAddLookupTwoSourceDialogIsVisible,
  ] = React.useState(false);
  const [addRuleDialogIsVisible, setAddRuleDialogIsVisible] =
    React.useState(false);

  const [{ fetching: addLookupBasicFetching }, priorityMutation] =
    useRmFieldAddLookupPriorityMappingMutation();
  const [{ fetching: addLookupPriorityFetching }, basicMutation] =
    useRmFieldAddLookupBasicMappingMutation();
  const [{ fetching: addLookupTwoSourceFetching }, twoSourceMutation] =
    useRmFieldAddLookupTwoSourceMappingMutation();

  const addLookupPriorityCallback = React.useCallback(
    async (source: string) => {
      const { error } = await priorityMutation({
        fieldId: field.id,
        source,
        isLocal,
      });

      if (!error) {
        updateNotification({
          notification: `Mapping created`,
          notificationType: NotificationType.Success,
        });
      } else {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      }
      setAddLookupPriorityDialogIsVisible(false);
    },
    [
      priorityMutation,
      field,
      updateNotification,
      setAddLookupPriorityDialogIsVisible,
      isLocal,
    ]
  );

  const addLookupBasicCallback = React.useCallback(
    async (source: string) => {
      const { error } = await basicMutation({
        fieldId: field.id,
        source,
        isLocal,
      });

      if (!error) {
        updateNotification({
          notification: `Mapping created`,
          notificationType: NotificationType.Success,
        });
      } else {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      }
      setAddLookupBasicDialogIsVisible(false);
    },
    [
      basicMutation,
      field,
      updateNotification,
      setAddLookupBasicDialogIsVisible,
      isLocal,
    ]
  );

  const addLookupTwoSourceCallback = React.useCallback(
    async (primarySource: string, secondarySource: string) => {
      const { error } = await twoSourceMutation({
        fieldId: field.id,
        primarySource,
        secondarySource,
        isLocal,
      });

      if (!error) {
        updateNotification({
          notification: `Mapping created`,
          notificationType: NotificationType.Success,
        });
      } else {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      }
      setAddLookupTwoSourceDialogIsVisible(false);
    },
    [
      twoSourceMutation,
      field,
      updateNotification,
      setAddLookupTwoSourceDialogIsVisible,
      isLocal,
    ]
  );

  const [{ fetching: addRuleFetching }, addRuleMutation] =
    useRmFieldAddRuleMappingMutation();

  const addRuleCallback = React.useCallback(async () => {
    const { error } = await addRuleMutation({
      fieldId: field.id,
      isLocal,
    });

    if (!error) {
      updateNotification({
        notification: `Mapping created`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
    setAddRuleDialogIsVisible(false);
  }, [
    addRuleMutation,
    field,
    updateNotification,
    setAddRuleDialogIsVisible,
    isLocal,
  ]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddLookupBasicDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add basic lookup mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddLookupTwoSourceDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add two-source mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddLookupPriorityDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add lookup priority mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddRuleDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add rule mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={isReordering}>
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => onStartReordering && onStartReordering()}
                  >
                    <ChevronUpDownIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Reorder
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddLookupBasicMappingDialog
        show={addLookupBasicDialogIsVisible}
        onSubmit={addLookupBasicCallback}
        onCancel={() => setAddLookupBasicDialogIsVisible(false)}
        fetching={addLookupBasicFetching}
      />
      <AddLookupPriorityMappingDialog
        show={addLookupPriorityDialogIsVisible}
        onSubmit={addLookupPriorityCallback}
        onCancel={() => setAddLookupPriorityDialogIsVisible(false)}
        fetching={addLookupPriorityFetching}
      />
      <AddLookupTwoSourceMappingDialog
        show={addLookupTwoSourceDialogIsVisible}
        onSubmit={addLookupTwoSourceCallback}
        onCancel={() => setAddLookupTwoSourceDialogIsVisible(false)}
        fetching={addLookupTwoSourceFetching}
      />
      <AddRuleMappingDialog
        show={addRuleDialogIsVisible}
        onSubmit={addRuleCallback}
        onCancel={() => setAddRuleDialogIsVisible(false)}
        fetching={addRuleFetching}
      />
    </>
  );
};

const AddLookupBasicMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: (sourceField: string) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [value, setValue] = React.useState("");
  const valueId = React.useId();
  const isValid = !!value.length;
  return (
    <ZenDialog
      show={show}
      title="Add basic lookup mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit(value)}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <p>
          A basic lookup mapping maps values from the source field to values in
          the destination field directly. If the source field has multiple
          values, the destination may also have multiple values.
        </p>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={valueId}>Source field</label>
        </div>
        <div>
          <input
            id={valueId}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

const AddLookupPriorityMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: (sourceField: string) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [value, setValue] = React.useState("");
  const valueId = React.useId();
  const isValid = !!value.length;
  return (
    <ZenDialog
      show={show}
      title="Add priority lookup mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit(value)}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <p>
          A priority lookup mapping maps values from the source field to a
          single value in the destination field. If multiple values from the
          source field match, the one with the highest priority is set on the
          destination field.
        </p>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={valueId}>Source field</label>
        </div>
        <div>
          <input
            id={valueId}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

const AddLookupTwoSourceMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: (primarySourceField: string, secondarySourceField: string) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [primarySourceField, setPrimarySourceField] = React.useState("");
  const [secondarySourceField, setSecondarySourceField] = React.useState("");
  const primarySourceFieldId = React.useId();
  const secondarySourceFieldId = React.useId();
  const isValid = !!primarySourceField.length && !!secondarySourceField.length;
  return (
    <ZenDialog
      show={show}
      title="Add two-source lookup mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit(primarySourceField, secondarySourceField)}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <p>
          A two-source lookup mapping maps values from two different source
          fields into one destination field. This is particularly useful for
          PropertyType + PropertySubType → NormalizedPropertyType mappings.
        </p>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={primarySourceFieldId}>Primary source field</label>
        </div>
        <div>
          <input
            id={primarySourceFieldId}
            type="text"
            value={primarySourceField}
            onChange={(e) => setPrimarySourceField(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={secondarySourceFieldId}>Secondary source field</label>
        </div>
        <div>
          <input
            id={secondarySourceFieldId}
            type="text"
            value={secondarySourceField}
            onChange={(e) => setSecondarySourceField(e.target.value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

const AddRuleMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  return (
    <ZenDialog
      show={show}
      title="Add rule mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit()}
      onCancel={onCancel}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <p>
          A rule mapping evaluates a RESO-standard RETS expression and maps the
          result of the expression onto the destination field.
        </p>
      </div>
    </ZenDialog>
  );
};

export default LookupMappingsActions;
