import React from "react";
import { useClient } from "urql";
import { classNames } from "../../common/utils/classnames";
import {
  AgentsDocument,
  MlsFragment,
  useAgentsQuery,
} from "../../graphql/generated";
import AgentCard from "../../agent/components/card";
import Panel from "../../common/components/panel";

const MlsAgentEnablement: React.FC<{
  mls?: MlsFragment;
}> = ({ mls }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data: agentData }] = useAgentsQuery({
    variables: {
      first: 2,
      mlsId: mls?.id,
      manuallyEnabled: true,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (agentData?.agents.pageInfo.hasNextPage) {
      setLoading(true);
    }
    const promises = [];
    if (agentData?.agents.pageInfo.hasNextPage) {
      promises.push(
        graphqlClient
          .query(AgentsDocument, {
            first: 20,
            after: agentData.agents.pageInfo.endCursor,
            mlsId: mls?.id,
            manuallyEnabled: true,
          })
          .toPromise()
      );
    }
    if (promises.length) {
      await Promise.all(promises);
      setLoading(false);
    }
  }, [agentData, graphqlClient, mls]);

  return (
    <Panel>
      <Panel.Title>Manually Enabled Agents</Panel.Title>

      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {agentData?.agents.edges?.map((agent) => (
            <AgentCard agent={agent?.node} key={agent?.node.id} />
          ))}
        </div>
      </Panel.Body>

      {agentData &&
        agentData?.agents.total > (agentData?.agents.edges?.length ?? 0) && (
          <Panel.Footer>
            <button
              className={classNames(
                loading
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetchMore()}
              disabled={loading}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
    </Panel>
  );
};

export default MlsAgentEnablement;
