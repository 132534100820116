import {
  IdentificationIcon,
  EnvelopeIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { ClipboardContext } from "../../common/context/clipboard";
import { LenderQuery } from "../../graphql/generated";
import LenderPic from "./pic";

const LenderTitle: React.FC<{ lender?: LenderQuery["lender"] }> = ({ lender }) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <LenderPic lender={lender} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {lender?.name === " " ? "Empty Name" : lender?.name}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <IdentificationIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {lender?.nmlsId}
              </div>
            </div>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                <div
                  className="text-blue-900 cursor-pointer"
                  onClick={() => sendToClip(lender?.email!)}
                >
                  {lender?.email}
                </div>
                <Link
                  to={{
                    pathname: "/subetha/recipients",
                    search: `?email=${lender?.email}`,
                  }}
                >
                  <MagnifyingGlassIcon
                    className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                </Link>
              </div>
              {lender?.phoneNumber && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <PhoneIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                  <a href={`tel:${lender?.phoneNumber}`}>{lender?.phoneNumber}</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LenderTitle;
