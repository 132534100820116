import React from "react";
import AgentCard from "../../agent/components/card";
import Panel from "../../common/components/panel";
import { AccountQuery } from "../../graphql/generated";
import AccountStatAgentActions from "./statsagentactions";

const AccountStatAgents: React.FC<{
  account?: AccountQuery["account"];
  refreshAccount?: () => void;
}> = ({ account, refreshAccount }) => {
  return (
    <Panel>
      <Panel.Title
        actions={
          <AccountStatAgentActions
            account={account}
            refreshAccount={refreshAccount}
          />
        }
      >
        {"Statistics Only Agents"}{" "}
        <span className="text-xs text-gray-500">
          {account?.statsOnlyAgents?.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {account?.statsOnlyAgents &&
            account?.statsOnlyAgents.map((agent) => (
              <AgentCard agent={agent} key={agent.id} />
            ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountStatAgents;
