import React from "react";
import { useClient } from "urql";
import AccountCard from "../../account/components/card";
import Panel from "../../common/components/panel";
import { classNames } from "../../common/utils/classnames";
import {
  AccountsDocument,
  EnterpriseAgreementFragment,
  useAccountsQuery,
} from "../../graphql/generated";

const EnterpriseAgreementAccounts: React.FC<{
  agreement?: EnterpriseAgreementFragment;
}> = ({ agreement }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data }] = useAccountsQuery({
    variables: {
      first: 2,
      enterpriseAgreementId: agreement?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.accounts.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(AccountsDocument, {
          first: 20,
          after: data.accounts.pageInfo.endCursor,
          enterpriseAgreementId: agreement?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, agreement]);

  return (
    <Panel>
      <Panel.Title>
        Active accounts{" "}
        <span className="text-xs text-gray-500">{data?.accounts.total}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.accounts.edges?.map((account) =>
            account ? (
              <AccountCard account={account.node} key={account.node.id} />
            ) : null
          )}
        </div>
      </Panel.Body>

      {data && data?.accounts.total > (data?.accounts.edges?.length ?? 0) && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default EnterpriseAgreementAccounts;
