import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useOfficeQuery } from "../../graphql/generated";
import Patches from "../../patch/components/patches";
import { SourceDataHistoryPanel } from "../../source/components/sourcedata";
import OfficeAgents from "../components/agents";
import OfficeOffices from "../components/offices";
import OfficeSummary from "../components/summary";
import OfficeTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const OfficeShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useOfficeQuery({
    variables: { id: params.officeId ?? "" },
    pause: !params.officeId,
  });
  useTitle(data?.office.name ?? params.officeId, "Offices");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <OfficeTitle office={data?.office} />
          <OfficeSummary office={data?.office} />
          <OfficeAgents office={data?.office} />
          <OfficeOffices office={data?.office} />
          <SourceDataHistoryPanel entityId={data?.office.sourceDataEntityId} />
          <Patches officeId={data?.office.id} />
        </div>
      </Transition>
    </>
  );
};

export default OfficeShow;
