import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import Timestamp from "../../common/components/timestamp";
import { TourQuery } from "../../graphql/generated";

const TourSummary: React.FC<{
  tour: TourQuery["tour"] | undefined;
}> = ({ tour }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
          <DescriptionListItem
            title="Created by"
            value={tour?.createdBy?.name}
            link={`/accounts/${tour?.createdBy?.id}`}
          />
          <DescriptionListItem
            title="Group"
            value={tour?.group?.nameLong!}
            link={`/groups/${tour?.group?.id}`}
          />
          <DescriptionListContainer title="Modified">
            <Timestamp timestamp={tour?.modifiedAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Deleted">
            <Timestamp timestamp={tour?.deletedAt} format="short" popover />
          </DescriptionListContainer>
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default TourSummary;
