import React from "react";
import { NavLink } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";

// type HeroIcon = React.ForwardRefExoticComponent<
//   React.SVGProps<SVGSVGElement> & {
//     title?: string | undefined;
//     titleId?: string | undefined;
//   }
// >;

type HeroIcon = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>;

const NavigationItem: React.FC<{
  Icon: HeroIcon;
  label: string;
  path: string;
}> = ({ Icon, label, path }) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        classNames(
          isActive
            ? "bg-zenlist-500 text-white"
            : "text-zenlist-100 hover:bg-zenlist-300",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )
      }
    >
      <Icon
        className="mr-3 flex-shrink-0 h-6 w-6 text-zenlist-300"
        aria-hidden="true"
      />
      {label}
    </NavLink>
  );
};

export default NavigationItem;
