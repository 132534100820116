import React from "react";
import {
  CanbyAnalysisFieldsQuery,
  CanbyFieldDetailsFragment,
  CanbyFieldSimpleFragment,
  CanbyFilterKeywordMatchInput,
  ResourceMetadataFieldFragment,
  useCanbyAnalysisFieldDetailsQuery,
  useResourceMetadataForFieldQuery,
} from "../../graphql/generated";
import Gauge from "../../common/components/gauge";
import LoadingIcon from "../../common/components/loadingicon";
import {
  CheckIcon,
  FunnelIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Filter } from "./filter";
import { OptionsPill } from "./pill";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { format } from "date-fns";
import { ResourceMetadataLookupValueComponent } from "../../resource-mapping/components/resource-metadata/resource-metadata-lookup-value-component";

const Field: React.FC<{
  analysis?: CanbyAnalysisFieldsQuery["canbyAnalysis"];
  field?: CanbyFieldSimpleFragment;
  filter: Filter;
}> = ({ analysis, field, filter }) => {
  // Don't load field details until this has been opened at least once.
  const [hasEverBeenOpened, setHasEverBeenOpened] = React.useState(false);
  const documentCount = analysis?.documentCount;

  const [{ data, error, fetching: detailsFetching }] =
    useCanbyAnalysisFieldDetailsQuery({
      variables: {
        id: analysis?.id ?? "",
        fieldName: field?.name ?? "",
        filter: filter.filter,
      },
      pause: !analysis || !field || !hasEverBeenOpened,
    });
  const [{ data: metadataData, fetching: metadataFetching }] =
    useResourceMetadataForFieldQuery({
      variables: {
        id: analysis?.rmRootResource?.id ?? "",
        fieldName: field?.name ?? "",
      },
      requestPolicy: "network-only",
      pause:
        !analysis || !analysis.rmRootResource || !field || !hasEverBeenOpened,
    });

  const fetching = detailsFetching || metadataFetching;
  const fieldDetails = data?.canbyAnalysis.field;

  const onToggle = React.useCallback(
    (event: { currentTarget: { open: boolean } }) => {
      if (event.currentTarget.open) {
        setHasEverBeenOpened(true);
      }
    },
    [setHasEverBeenOpened]
  );

  return (
    <details onToggle={onToggle}>
      <summary>
        {field?.name} <Gauge count={field?.count} total={documentCount} />
      </summary>
      <div>
        {fetching && <LoadingIcon />}
        {error && <div className="text-red-500">Failed to load details</div>}
        {!fetching && fieldDetails && (
          <>
            {metadataData && metadataData.resourceMetadata.field && (
              <Metadata metadata={metadataData.resourceMetadata.field} />
            )}
            <Boolean
              fieldExists={fieldDetails.statistics.fieldExists}
              details={fieldDetails}
              filter={filter}
            />
            <DateInterpretation
              fieldExists={fieldDetails.statistics.fieldExists}
              details={fieldDetails}
              filter={filter}
            />
            <Timestamp
              fieldExists={fieldDetails.statistics.fieldExists}
              details={fieldDetails}
              filter={filter}
            />
            <Number
              fieldExists={fieldDetails.statistics.fieldExists}
              details={fieldDetails}
              filter={filter}
            />
            <Keyword
              fieldExists={fieldDetails.statistics.fieldExists}
              details={fieldDetails}
              filter={filter}
              metadata={metadataData?.resourceMetadata.field ?? undefined}
            />
            <Object
              fieldExists={fieldDetails?.statistics.fieldExists}
              details={fieldDetails}
              filter={filter}
            />
            <List
              fieldExists={fieldDetails?.statistics.fieldExists}
              details={fieldDetails}
              filter={filter}
            />
          </>
        )}
      </div>
    </details>
  );
};

const Interpretation: React.FC<{
  title: string;
  filterPill?: React.ReactNode;
  children?: React.ReactNode;
}> = ({ title, filterPill, children }) => {
  return (
    <section className="m-2 border-l-2 border-gray-400 bg-gray-100">
      <header className="border-b border-gray-200 grid grid-cols-2 p-2">
        <h3 className="bold">{title}</h3>
        <div className="justify-self-end">{filterPill}</div>
      </header>
      <main className="p-2 border-b border-gray-200">{children}</main>
    </section>
  );
};

const Metadata: React.FC<{
  metadata: ResourceMetadataFieldFragment;
}> = ({ metadata }) => {
  return (
    <section className="m-2 border-l-2 border-teal-400 bg-teal-100">
      <header className="border-b border-teal-200 grid grid-cols-2 p-2">
        <h3 className="bold">Metadata</h3>
      </header>
      <main className="p-2 border-b border-teal-200">
        <table>
          <tbody>
            <tr>
              <th className="px-1">Name</th>
              <td className="px-1">{metadata.name}</td>
            </tr>
            {metadata.extras.map((item) => (
              <tr key={item.key}>
                <th className="px-1">{item.key}</th>
                <td className="px-1">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </section>
  );
};

const Boolean: React.FC<{
  fieldExists?: number;
  details: CanbyFieldDetailsFragment;
  filter: Filter;
}> = ({ fieldExists, details, filter }) => {
  const options: ["clear" | "exists" | "not_exists", string][] = [];
  let active = false;
  if (
    filter.hasBooleanExists(details.name) ||
    filter.hasBooleanNotExists(details.name)
  ) {
    options.push(["clear", "Clear"]);
    active = true;
  } else {
    options.push(["exists", "Exists"]);
    options.push(["not_exists", "Missing"]);
  }

  const onOptionSelected = (key: "clear" | "exists" | "not_exists") => {
    switch (key) {
      case "clear":
        filter.clearBooleanExists(details.name);
        filter.clearBooleanNotExists(details.name);
        break;
      case "exists":
        filter.setBooleanExists(details.name);
        break;
      case "not_exists":
        filter.setBooleanNotExists(details.name);
        break;
    }
  };

  const filterPill = (
    <OptionsPill
      icon={FunnelIcon}
      options={options}
      active={active}
      onOptionSelected={onOptionSelected}
    >
      Filter
    </OptionsPill>
  );
  const data = details.statistics.boolean;
  if (data) {
    return (
      <Interpretation title="Boolean" filterPill={filterPill}>
        <>
          <Gauge count={data.documents} total={fieldExists} />
          <table>
            <tbody>
              <tr>
                <th className="px-1">True</th>
                <td className="px-1 text-right">
                  {data.trueDocuments.toLocaleString()}{" "}
                </td>
                <td>
                  <Gauge count={data.trueDocuments} total={data.documents} />
                </td>
              </tr>
              <tr>
                <th className="px-1">False</th>
                <td className="px-1 text-right">
                  {data.falseDocuments.toLocaleString()}{" "}
                </td>
                <td>
                  <Gauge count={data.falseDocuments} total={data.documents} />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </Interpretation>
    );
  } else {
    return (
      <Interpretation title="Boolean" filterPill={filterPill}>
        <Gauge />
      </Interpretation>
    );
  }
};

// Can't call it "Date" because that conflicts with the built-in "Date".
const DateInterpretation: React.FC<{
  fieldExists?: number;
  details: CanbyFieldDetailsFragment;
  filter: Filter;
}> = ({ fieldExists, details, filter }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const options: ["clear" | "range" | "exists" | "not_exists", string][] = [];
  let active = false;
  if (
    filter.hasDateExists(details.name) ||
    filter.hasDateNotExists(details.name) ||
    filter.getDateRange(details.name)
  ) {
    options.push(["clear", "Clear"]);
    active = true;
  } else {
    options.push(["range", "Range..."]);
    options.push(["exists", "Exists"]);
    options.push(["not_exists", "Missing"]);
  }

  const onOptionSelected = (
    key: "clear" | "range" | "exists" | "not_exists"
  ) => {
    switch (key) {
      case "clear":
        filter.clearDateExists(details.name);
        filter.clearDateNotExists(details.name);
        filter.clearDateRange(details.name);
        break;
      case "range":
        setIsDialogOpen(true);
        break;
      case "exists":
        filter.setDateExists(details.name);
        break;
      case "not_exists":
        filter.setDateNotExists(details.name);
        break;
    }
  };

  const filterPill = (
    <OptionsPill
      icon={FunnelIcon}
      options={options}
      active={active}
      onOptionSelected={onOptionSelected}
    >
      Filter
    </OptionsPill>
  );

  const data = details.statistics.date;
  if (data) {
    return (
      <>
        <Interpretation title="Date" filterPill={filterPill}>
          <>
            <div>
              <Gauge count={data.documents} total={fieldExists} />
            </div>
            <table>
              <tbody>
                <tr>
                  <th className="px-1">min</th>
                  <td className="px-1">{data.min}</td>
                </tr>
                <tr>
                  <th className="px-1">¼ q</th>
                  <td className="px-1">{data.lowerQuartile}</td>
                </tr>
                <tr>
                  <th className="px-1">med</th>
                  <td className="px-1">{data.median}</td>
                </tr>
                <tr>
                  <th className="px-1">¾ q</th>
                  <td className="px-1">{data.upperQuartile}</td>
                </tr>
                <tr>
                  <th className="px-1">max</th>
                  <td className="px-1">{data.max}</td>
                </tr>
                <tr>
                  <th className="px-1">avg</th>
                  <td className="px-1">{data.average}</td>
                </tr>
              </tbody>
            </table>
          </>
        </Interpretation>
        <DateRangeDialog
          isOpen={isDialogOpen}
          name={details.name}
          onCancel={() => setIsDialogOpen(false)}
          onSubmit={(item) => {
            setIsDialogOpen(false);
            filter.setDateRange({
              field: details.name,
              lte: item.lte,
              gte: item.gte,
            });
          }}
        />
      </>
    );
  } else {
    return (
      <Interpretation title="Date" filterPill={filterPill}>
        <Gauge />
      </Interpretation>
    );
  }
};

const Timestamp: React.FC<{
  fieldExists?: number;
  details: CanbyFieldDetailsFragment;
  filter: Filter;
}> = ({ fieldExists, details, filter }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const options: ["clear" | "range" | "exists" | "not_exists", string][] = [];
  let active = false;
  if (
    filter.hasTimestampExists(details.name) ||
    filter.hasTimestampNotExists(details.name) ||
    filter.getTimestampRange(details.name)
  ) {
    options.push(["clear", "Clear"]);
    active = true;
  } else {
    options.push(["range", "Range..."]);
    options.push(["exists", "Exists"]);
    options.push(["not_exists", "Missing"]);
  }

  const onOptionSelected = (
    key: "clear" | "range" | "exists" | "not_exists"
  ) => {
    switch (key) {
      case "clear":
        filter.clearTimestampExists(details.name);
        filter.clearTimestampNotExists(details.name);
        filter.clearTimestampRange(details.name);
        break;
      case "range":
        setIsDialogOpen(true);
        break;
      case "exists":
        filter.setTimestampExists(details.name);
        break;
      case "not_exists":
        filter.setTimestampNotExists(details.name);
        break;
    }
  };

  const filterPill = (
    <OptionsPill
      icon={FunnelIcon}
      options={options}
      active={active}
      onOptionSelected={onOptionSelected}
    >
      Filter
    </OptionsPill>
  );

  const data = details.statistics.timestamp;
  if (data) {
    return (
      <>
        <Interpretation title="Timestamp" filterPill={filterPill}>
          <>
            <div>
              <Gauge count={data.documents} total={fieldExists} />
            </div>
            <table>
              <tbody>
                <tr>
                  <th className="px-1">min</th>
                  <td className="px-1">{data.min}</td>
                </tr>
                <tr>
                  <th className="px-1">¼ q</th>
                  <td className="px-1">{data.lowerQuartile}</td>
                </tr>
                <tr>
                  <th className="px-1">med</th>
                  <td className="px-1">{data.median}</td>
                </tr>
                <tr>
                  <th className="px-1">¾ q</th>
                  <td className="px-1">{data.upperQuartile}</td>
                </tr>
                <tr>
                  <th className="px-1">max</th>
                  <td className="px-1">{data.max}</td>
                </tr>
                <tr>
                  <th className="px-1">avg</th>
                  <td className="px-1">{data.average}</td>
                </tr>
              </tbody>
            </table>
          </>
        </Interpretation>
        <TimestampRangeDialog
          isOpen={isDialogOpen}
          name={details.name}
          onCancel={() => setIsDialogOpen(false)}
          onSubmit={(item) => {
            setIsDialogOpen(false);
            filter.setTimestampRange({
              field: details.name,
              lte: item.lte,
              gte: item.gte,
            });
          }}
        />
      </>
    );
  } else {
    return (
      <Interpretation title="Timestamp" filterPill={filterPill}>
        <Gauge />
      </Interpretation>
    );
  }
};

const Number: React.FC<{
  fieldExists?: number;
  details: CanbyFieldDetailsFragment;
  filter: Filter;
}> = ({ fieldExists, details, filter }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const options: ["clear" | "range" | "exists" | "not_exists", string][] = [];
  let active = false;
  if (
    filter.hasNumberExists(details.name) ||
    filter.hasNumberNotExists(details.name) ||
    filter.getNumberRange(details.name)
  ) {
    options.push(["clear", "Clear"]);
    active = true;
  } else {
    options.push(["range", "Range..."]);
    options.push(["exists", "Exists"]);
    options.push(["not_exists", "Missing"]);
  }

  const onOptionSelected = (
    key: "clear" | "range" | "exists" | "not_exists"
  ) => {
    switch (key) {
      case "clear":
        filter.clearNumberExists(details.name);
        filter.clearNumberNotExists(details.name);
        filter.clearNumberRange(details.name);
        break;
      case "range":
        setIsDialogOpen(true);
        break;
      case "exists":
        filter.setNumberExists(details.name);
        break;
      case "not_exists":
        filter.setNumberNotExists(details.name);
        break;
    }
  };

  const filterPill = (
    <OptionsPill
      icon={FunnelIcon}
      options={options}
      active={active}
      onOptionSelected={onOptionSelected}
    >
      Filter
    </OptionsPill>
  );

  const data = details.statistics.number;
  if (data) {
    return (
      <>
        <Interpretation title="Number" filterPill={filterPill}>
          <>
            <div>
              <Gauge count={data.documents} total={fieldExists} />
            </div>
            <table>
              <tbody>
                <tr>
                  <th className="px-1">min</th>
                  <td className="px-1 text-right">
                    {data.min.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <th className="px-1">¼ q</th>
                  <td className="px-1 text-right">
                    {data.lowerQuartile.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <th className="px-1">med</th>
                  <td className="px-1 text-right">
                    {data.median.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <th className="px-1">¾ q</th>
                  <td className="px-1 text-right">
                    {data.upperQuartile.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <th className="px-1">max</th>
                  <td className="px-1 text-right">
                    {data.max.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <th className="px-1">avg</th>
                  <td className="px-1 text-right">
                    {data.average.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        </Interpretation>
        <NumberRangeDialog
          isOpen={isDialogOpen}
          name={details.name}
          onCancel={() => setIsDialogOpen(false)}
          onSubmit={(item) => {
            setIsDialogOpen(false);
            filter.setNumberRange({
              field: details.name,
              lte: item.lte,
              gte: item.gte,
            });
          }}
        />
      </>
    );
  } else {
    return (
      <Interpretation title="Number" filterPill={filterPill}>
        <Gauge />
      </Interpretation>
    );
  }
};

const Keyword: React.FC<{
  fieldExists: number;
  details: CanbyFieldDetailsFragment;
  filter: Filter;
  metadata?: ResourceMetadataFieldFragment;
}> = ({ fieldExists, details, filter, metadata }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const options: ["clear" | "select" | "exists" | "not_exists", string][] = [];
  let active = false;
  if (
    filter.hasKeywordExists(details.name) ||
    filter.hasKeywordNotExists(details.name) ||
    !!filter.getKeywordMatch(details.name)
  ) {
    options.push(["clear", "Clear"]);
    active = true;
  } else {
    options.push(["select", "Select..."]);
    options.push(["exists", "Exists"]);
    options.push(["not_exists", "Missing"]);
  }

  const onOptionSelected = (
    key: "clear" | "select" | "exists" | "not_exists"
  ) => {
    switch (key) {
      case "clear":
        filter.clearKeywordExists(details.name);
        filter.clearKeywordNotExists(details.name);
        filter.clearKeywordMatch(details.name);
        break;
      case "select":
        setIsDialogOpen(true);
        break;
      case "exists":
        filter.setKeywordExists(details.name);
        break;
      case "not_exists":
        filter.setKeywordNotExists(details.name);
        break;
    }
  };

  const filterPill = (
    <OptionsPill
      icon={FunnelIcon}
      options={options}
      active={active}
      onOptionSelected={onOptionSelected}
    >
      Filter
    </OptionsPill>
  );
  const data = details.statistics.keyword;
  if (data) {
    return (
      <>
        <Interpretation title="Keyword" filterPill={filterPill}>
          <>
            <div>
              <Gauge count={data.documents} total={fieldExists} />
            </div>
            <table>
              <tbody>
                <tr>
                  <th className="px-1">Cardinality</th>
                  <td className="px-1 text-right">
                    {data.cardinality.toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <th className="px-1">Average length</th>
                  <td className="px-1 text-right">
                    {data.averageLength.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <th className="px-1">Max length</th>
                  <td className="px-1 text-right">
                    {data.maxLength.toLocaleString()}
                  </td>
                </tr>
                {details.keywords &&
                  details.keywords.map((keyword) => (
                    <tr key={keyword.keyword}>
                      <td className="px-1">
                        <ResourceMetadataLookupValueComponent
                          value={keyword.keyword}
                          field={metadata}
                        />
                      </td>
                      <td className="px-1 text-right">
                        {keyword.count.toLocaleString()}
                      </td>
                      <td className="px-1">
                        <Gauge
                          count={keyword.count}
                          total={data.documents}
                          gray
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        </Interpretation>
        <KeywordSelectDialog
          isOpen={isDialogOpen}
          onCancel={() => setIsDialogOpen(false)}
          onSubmit={(x) => {
            setIsDialogOpen(false);
            if (!x.matches.size && !x.excludes.size) {
              filter.clearKeywordMatch(details.name);
            } else {
              const match: CanbyFilterKeywordMatchInput = {
                field: details.name,
              };
              if (x.matches.size) {
                match.matches = Array.from(x.matches);
              }
              if (x.excludes.size) {
                match.excludes = Array.from(x.excludes);
              }
              filter.setKeywordMatch(match);
            }
          }}
          keywords={details.keywords}
          metadata={metadata}
        />
      </>
    );
  } else {
    return (
      <Interpretation title="Keyword" filterPill={filterPill}>
        <Gauge />
      </Interpretation>
    );
  }
};

const List: React.FC<{
  fieldExists?: number;
  details: CanbyFieldDetailsFragment;
  filter: Filter;
}> = ({ fieldExists, details, filter }) => {
  const options: ["clear" | "exists" | "not_exists", string][] = [];
  let active = false;
  if (
    filter.hasListExists(details.name) ||
    filter.hasListNotExists(details.name)
  ) {
    options.push(["clear", "Clear"]);
    active = true;
  } else {
    options.push(["exists", "Exists"]);
    options.push(["not_exists", "Missing"]);
  }

  const onOptionSelected = (key: "clear" | "exists" | "not_exists") => {
    switch (key) {
      case "clear":
        filter.clearListExists(details.name);
        filter.clearListNotExists(details.name);
        break;
      case "exists":
        filter.setListExists(details.name);
        break;
      case "not_exists":
        filter.setListNotExists(details.name);
        break;
    }
  };

  const filterPill = (
    <OptionsPill
      icon={FunnelIcon}
      options={options}
      active={active}
      onOptionSelected={onOptionSelected}
    >
      Filter
    </OptionsPill>
  );

  const data = details.statistics.list;
  if (data) {
    return (
      <Interpretation title="List" filterPill={filterPill}>
        <>
          <div>
            <Gauge count={data.documents} total={fieldExists} />
          </div>
          <table>
            <tbody>
              <tr>
                <th className="px-1">Average length</th>
                <td className="px-1 text-right">
                  {data.averageLength.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr>
                <th className="px-1">Max length</th>
                <td className="px-1 text-right">
                  {data.maxLength.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </Interpretation>
    );
  } else {
    return (
      <Interpretation title="List" filterPill={filterPill}>
        <Gauge />
      </Interpretation>
    );
  }
};

const Object: React.FC<{
  fieldExists?: number;
  details: CanbyFieldDetailsFragment;
  filter: Filter;
}> = ({ fieldExists, details, filter }) => {
  const options: ["clear" | "exists" | "not_exists", string][] = [];
  let active = false;
  if (
    filter.hasObjectExists(details.name) ||
    filter.hasObjectNotExists(details.name)
  ) {
    options.push(["clear", "Clear"]);
    active = true;
  } else {
    options.push(["exists", "Exists"]);
    options.push(["not_exists", "Missing"]);
  }

  const onOptionSelected = (key: "clear" | "exists" | "not_exists") => {
    switch (key) {
      case "clear":
        filter.clearObjectExists(details.name);
        filter.clearObjectNotExists(details.name);
        break;
      case "exists":
        filter.setObjectExists(details.name);
        break;
      case "not_exists":
        filter.setObjectNotExists(details.name);
        break;
    }
  };

  const filterPill = (
    <OptionsPill
      icon={FunnelIcon}
      options={options}
      active={active}
      onOptionSelected={onOptionSelected}
    >
      Filter
    </OptionsPill>
  );

  const data = details.statistics.object;
  if (data) {
    return (
      <Interpretation title="Object" filterPill={filterPill}>
        <>
          <div>
            <Gauge count={data.documents} total={fieldExists} />
          </div>
        </>
      </Interpretation>
    );
  } else {
    return (
      <Interpretation title="Object" filterPill={filterPill}>
        <Gauge />
      </Interpretation>
    );
  }
};

enum TripleToggleMode {
  Empty,
  Include,
  Exclude,
}

const TripleToggle: React.FC<{
  mode: TripleToggleMode;
  onChange: (mode: TripleToggleMode) => void;
}> = ({ mode, onChange }) => {
  switch (mode) {
    case TripleToggleMode.Empty:
      return (
        <button
          className="inline-block w-4 h-4 border border-gray-300 bg-gray-200 rounded-full p-px"
          onClick={() => onChange(TripleToggleMode.Include)}
        >
          <PlusIcon className="text-gray-300" />
        </button>
      );
    case TripleToggleMode.Include:
      return (
        <button
          className="inline-block w-4 h-4 border border-green-400 bg-green-300 rounded-full p-px"
          onClick={() => onChange(TripleToggleMode.Exclude)}
        >
          <CheckIcon className="text-green-700" />
        </button>
      );
      break;
    case TripleToggleMode.Exclude:
      return (
        <button
          className="inline-block w-4 h-4 border border-red-300 bg-red-200 rounded-full p-px"
          onClick={() => onChange(TripleToggleMode.Empty)}
        >
          <XMarkIcon className="text-red-600" />
        </button>
      );
      break;
  }
};

const NumberRangeDialog: React.FC<{
  isOpen: boolean;
  name: string;
  onCancel: () => void;
  onSubmit: (range: { lte?: number; gte?: number }) => void;
}> = ({ isOpen, name, onCancel, onSubmit }) => {
  type Mode = "lte" | "gte" | "between";

  const [mode, setMode] = React.useState<Mode>("gte");
  const [lte, setLte] = React.useState<number | undefined>();
  const [gte, setGte] = React.useState<number | undefined>();

  const isValid =
    (mode == "lte" && lte !== undefined) ||
    (mode == "gte" && gte !== undefined) ||
    (mode == "between" && lte !== undefined && gte !== undefined);

  return (
    <ZenDialog
      icon={FunnelIcon}
      show={isOpen}
      onCancel={onCancel}
      onSubmit={() =>
        onSubmit(
          mode === "lte" ? { lte } : mode === "gte" ? { gte } : { lte, gte }
        )
      }
      submit="Filter"
      title="Filter number"
      state={isValid ? ZenDialogState.Displaying : ZenDialogState.Invalid}
    >
      Show documents where <b>{name}</b> is{" "}
      <select value={mode} onChange={(e) => setMode(e.target.value as Mode)}>
        <option value={"gte"}>greater than</option>
        <option value={"lte"}>less than</option>
        <option value={"between"}>between</option>
      </select>
      {(mode === "gte" || mode === "between") && (
        <input
          type="number"
          value={gte}
          onChange={(e) => {
            if (!e.target.value) {
              setGte(undefined);
            } else {
              setGte(e.target.valueAsNumber);
            }
          }}
        />
      )}
      {mode === "between" && <> and </>}
      {(mode === "lte" || mode === "between") && (
        <input
          type="number"
          value={lte}
          onChange={(e) => {
            if (!e.target.value) {
              setLte(undefined);
            } else {
              setLte(e.target.valueAsNumber);
            }
          }}
        />
      )}
    </ZenDialog>
  );
};

const DateRangeDialog: React.FC<{
  isOpen: boolean;
  name: string;
  onCancel: () => void;
  onSubmit: (range: { lte?: string; gte?: string }) => void;
}> = ({ isOpen, name, onCancel, onSubmit }) => {
  type Mode = "lte" | "gte" | "between";

  const [mode, setMode] = React.useState<Mode>("gte");
  const [lte, setLte] = React.useState<string | undefined>();
  const [gte, setGte] = React.useState<string | undefined>();

  const lteIsValidDate = lte && !isNaN(new Date(lte).valueOf());
  const gteIsValidDate = gte && !isNaN(new Date(gte).valueOf());

  const isValid =
    (mode == "lte" && lteIsValidDate) ||
    (mode == "gte" && gteIsValidDate) ||
    (mode == "between" && lteIsValidDate && gteIsValidDate);

  return (
    <ZenDialog
      icon={FunnelIcon}
      show={isOpen}
      onCancel={onCancel}
      onSubmit={() => {
        const lteFormatted = lte
          ? format(new Date(lte), "yyyy-MM-dd")
          : undefined;
        const gteFormatted = gte
          ? format(new Date(gte), "yyyy-MM-dd")
          : undefined;
        onSubmit(
          mode === "lte"
            ? { lte: lteFormatted }
            : mode === "gte"
            ? { gte: gteFormatted }
            : { lte: lteFormatted, gte: gteFormatted }
        );
      }}
      submit="Filter"
      title="Filter number"
      state={isValid ? ZenDialogState.Displaying : ZenDialogState.Invalid}
    >
      Show documents where <b>{name}</b> is{" "}
      <select value={mode} onChange={(e) => setMode(e.target.value as Mode)}>
        <option value={"gte"}>newer than</option>
        <option value={"lte"}>older than</option>
        <option value={"between"}>between</option>
      </select>
      {(mode === "gte" || mode === "between") && (
        <input
          type="date"
          value={gte}
          onChange={(e) => {
            if (!e.target.value) {
              setGte(undefined);
            } else {
              setGte(e.target.value);
            }
          }}
        />
      )}
      {mode === "between" && <> and </>}
      {(mode === "lte" || mode === "between") && (
        <input
          type="date"
          value={lte}
          onChange={(e) => {
            if (!e.target.value) {
              setLte(undefined);
            } else {
              setLte(e.target.value);
            }
          }}
        />
      )}
    </ZenDialog>
  );
};

const TimestampRangeDialog: React.FC<{
  isOpen: boolean;
  name: string;
  onCancel: () => void;
  onSubmit: (range: { lte?: string; gte?: string }) => void;
}> = ({ isOpen, name, onCancel, onSubmit }) => {
  type Mode = "lte" | "gte" | "between";

  const [mode, setMode] = React.useState<Mode>("gte");
  const [lte, setLte] = React.useState<string | undefined>();
  const [gte, setGte] = React.useState<string | undefined>();

  const lteIsValidDate = lte && !isNaN(new Date(lte).valueOf());
  const gteIsValidDate = gte && !isNaN(new Date(gte).valueOf());

  const isValid =
    (mode == "lte" && lteIsValidDate) ||
    (mode == "gte" && gteIsValidDate) ||
    (mode == "between" && lteIsValidDate && gteIsValidDate);

  return (
    <ZenDialog
      icon={FunnelIcon}
      show={isOpen}
      onCancel={onCancel}
      onSubmit={() => {
        const lteFormatted = lte
          ? `${format(new Date(lte), "yyyy-MM-dd")}T23:59:59.999999Z`
          : undefined;
        const gteFormatted = gte
          ? `${format(new Date(gte), "yyyy-MM-dd")}T00:00:00Z`
          : undefined;
        onSubmit(
          mode === "lte"
            ? { lte: lteFormatted }
            : mode === "gte"
            ? { gte: gteFormatted }
            : { lte: lteFormatted, gte: gteFormatted }
        );
      }}
      submit="Filter"
      title="Filter number"
      state={isValid ? ZenDialogState.Displaying : ZenDialogState.Invalid}
    >
      Show documents where <b>{name}</b> is{" "}
      <select value={mode} onChange={(e) => setMode(e.target.value as Mode)}>
        <option value={"gte"}>newer than</option>
        <option value={"lte"}>older than</option>
        <option value={"between"}>between</option>
      </select>
      {(mode === "gte" || mode === "between") && (
        <input
          type="date"
          value={gte}
          onChange={(e) => {
            if (!e.target.value) {
              setGte(undefined);
            } else {
              setGte(e.target.value);
            }
          }}
        />
      )}
      {mode === "between" && <> and </>}
      {(mode === "lte" || mode === "between") && (
        <input
          type="date"
          value={lte}
          onChange={(e) => {
            if (!e.target.value) {
              setLte(undefined);
            } else {
              setLte(e.target.value);
            }
          }}
        />
      )}
    </ZenDialog>
  );
};

const KeywordSelectDialog: React.FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (match: { matches: Set<string>; excludes: Set<string> }) => void;
  keywords?: CanbyFieldDetailsFragment["keywords"];
  metadata?: ResourceMetadataFieldFragment;
}> = ({ isOpen, onCancel, onSubmit, keywords, metadata }) => {
  const [localMatchData, setLocalMatchData] = React.useState({
    matches: new Set<string>(),
    excludes: new Set<string>(),
  });
  const toggleMode = (keyword: string): TripleToggleMode => {
    if (localMatchData.matches.has(keyword)) {
      return TripleToggleMode.Include;
    } else if (localMatchData.excludes.has(keyword)) {
      return TripleToggleMode.Exclude;
    } else {
      return TripleToggleMode.Empty;
    }
  };
  const onToggle = (keyword: string, mode: TripleToggleMode) => {
    let matches = localMatchData.matches;
    let excludes = localMatchData.excludes;
    switch (mode) {
      case TripleToggleMode.Empty:
        if (matches.has(keyword)) {
          matches = new Set(matches);
          matches.delete(keyword);
        }
        if (excludes.has(keyword)) {
          excludes = new Set(excludes);
          excludes.delete(keyword);
        }
        break;
      case TripleToggleMode.Include:
        if (!matches.has(keyword)) {
          matches = new Set(matches);
          matches.add(keyword);
        }
        if (excludes.has(keyword)) {
          excludes = new Set(excludes);
          excludes.delete(keyword);
        }
        break;
      case TripleToggleMode.Exclude:
        if (matches.has(keyword)) {
          matches = new Set(matches);
          matches.delete(keyword);
        }
        if (!excludes.has(keyword)) {
          excludes = new Set(excludes);
          excludes.add(keyword);
        }
        break;
    }
    setLocalMatchData({
      matches,
      excludes,
    });
  };

  return (
    <ZenDialog
      icon={FunnelIcon}
      show={isOpen}
      onCancel={onCancel}
      onSubmit={() => onSubmit(localMatchData)}
      submit="Filter"
      title="Filter keywords"
    >
      <ul>
        {keywords &&
          keywords.map((item) => {
            return (
              <li key={item.keyword}>
                <TripleToggle
                  mode={toggleMode(item.keyword)}
                  onChange={(mode) => onToggle(item.keyword, mode)}
                />{" "}
                <ResourceMetadataLookupValueComponent
                  value={item.keyword}
                  field={metadata}
                />
              </li>
            );
          })}
      </ul>
    </ZenDialog>
  );
};

export default Field;
