import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../../common/components/error";
import Loading from "../../../common/components/loading";
import { useConversationQuery } from "../../../graphql/generated";
import ConversationMessages from "../components/messages";
import ConversationParticipants from "../components/participants";
import ConversationTitle from "../components/title";
import { useTitle } from "../../../common/utils/title";

const BabelConversationShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useConversationQuery({
    variables: { id: params.conversationId ?? "" },
    pause: !params.conversationId,
  });
  useTitle(
    data?.conversation.displayName ?? params.conversationId,
    "Conversations"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ConversationTitle conversation={data?.conversation} />
          <ConversationParticipants conversation={data?.conversation} />
          <ConversationMessages conversation={data?.conversation} />
        </div>
      </Transition>
    </>
  );
};

export default BabelConversationShow;
