import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useGroupQuery } from "../../graphql/generated";
import Patches from "../../patch/components/patches";
import GroupAccounts from "../components/accounts";
import GroupAgents from "../components/agents";
import GroupClientInvitations from "../components/clientinvitations";
import GroupConversations from "../components/conversations";
import GroupNotes from "../components/notes";
import GroupSavedFilters from "../components/savedfilters";
import GroupSummary from "../components/summary";
import GroupTitle from "../components/title";
import GroupTours from "../components/tours";
import { useTitle } from "../../common/utils/title";

const GroupShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useGroupQuery({
    variables: { id: params.groupId ?? "" },
    pause: !params.groupId,
  });
  useTitle(data?.group.nameLong ?? params.groupId, "Groups");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <GroupTitle group={data?.group} />
          <GroupSummary group={data?.group} />
          <GroupAgents accounts={data?.group.collaboratingAgents} />
          <GroupAccounts accounts={data?.group.members} />
          <GroupClientInvitations group={data?.group} />
          <GroupSavedFilters group={data?.group} />
          <GroupConversations group={data?.group} />
          <GroupNotes group={data?.group} />
          <GroupTours group={data?.group} />
          <Patches groupId={data?.group.id} />
        </div>
      </Transition>
    </>
  );
};

export default GroupShow;
