import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
// import { useGoogleLogout } from "react-google-login";
// import { LoginContext } from "../../auth/context/login";
import { googleLogout } from "@react-oauth/google";
import NavigationItems from "./items";

const NavigationSmallScreen: React.FC<{
  sidebarOpen: boolean;
  setSidebarOpen: (v: boolean) => void;
}> = ({ sidebarOpen, setSidebarOpen }) => {
  const logOut = React.useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    googleLogout();
  }, []);

  const googleName = React.useMemo(() => {
    return localStorage.getItem("auth_name");
  }, []);

  const googlePic = React.useMemo(() => {
    return localStorage.getItem("auth_picture");
  }, []);

  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex md:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-zenlist-500">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <img className="h-8 w-auto" src="/logo.svg" alt="Zenlist" />
              <h1 className="text-1xl font-semibold text-white pl-2">
                Zenlist Admin
              </h1>
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <NavigationItems />
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-zenlist-300 p-4">
              <div className="flex-shrink-0 group block">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src={googlePic ?? ""}
                      alt={googleName ?? ""}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-base font-medium text-white">
                      {googleName ?? "Unknown"}
                    </p>
                    <p
                      onClick={logOut}
                      className="text-sm font-medium text-indigo-200 group-hover:text-white"
                    >
                      Log Out
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NavigationSmallScreen;
