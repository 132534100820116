import React from "react";
import { Link } from "react-router-dom";
import AccountProfilePic from "../../account/components/profilepic";
import { longDateFormat } from "../../common/utils/dateformat";
import { GroupNoteFragment } from "../../graphql/generated";

const GroupNoteCard: React.FC<{ note?: GroupNoteFragment }> = ({ note }) => {
  return (
    <li key={note?.id} className="py-4">
      <div className="flex space-x-3">
        <div className="w-32">
          {note?.groupItem?.listing && (
            <p className="text-sm text-gray-500">
              <a
                href={`https://zenlist.com/app/listing/${note.groupItem?.listing.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`https://hue.zenlist.com/listing/${note.groupItem?.listing.id}.jpg?w=800`}
                  className="w-32 rounded-lg"
                  alt="listing"
                />
              </a>
            </p>
          )}
        </div>
        <div className="flex-1 space-y-1 pl-4">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <AccountProfilePic account={note?.owner} small />
            </div>
            <h3 className="flex-1 text-sm font-medium ml-4">
              {note?.owner?.name}
            </h3>
            <p className="text-sm text-gray-500">
              {longDateFormat(note?.createdAt)}
            </p>
          </div>

          <p className="text-sm text-gray-500">{note?.note}</p>

          <p className="text-sm text-gray-500">
            <Link
              to={`/groups/${note?.group?.id}`}
              className="text-blue-900 hover:underline"
            >
              Group: {note?.group?.nameLong}
            </Link>
          </p>
        </div>
      </div>
    </li>
  );
};

export default GroupNoteCard;
