import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowsPointingInIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import LoadingIcon from "../../common/components/loadingicon";
import { classNames } from "../../common/utils/classnames";
import { longDateFormat } from "../../common/utils/dateformat";
import {
  GroupFragment,
  GroupState,
  useGroupQuery,
} from "../../graphql/generated";
import GroupPic from "./pic";

const MergeDialog: React.FC<{
  isOpen: boolean;
  sourceGroup?: GroupFragment;
  onClose: () => void;
  onSubmit: (group: GroupFragment) => void;
}> = ({ isOpen, sourceGroup, onClose, onSubmit }) => {
  const [destinationGroupId, setDestinationGroupId] = React.useState("");
  let normalizedDestinationGroupId = destinationGroupId;
  const matches = destinationGroupId.match(/https?:\/\/.*\/groups\/([^/]*)/);
  if (matches) {
    normalizedDestinationGroupId = matches[1];
  }

  const [{ data: groupQueryData, error: groupQueryError, fetching }] =
    useGroupQuery({
      variables: { id: normalizedDestinationGroupId },
      pause:
        !normalizedDestinationGroupId || normalizedDestinationGroupId === "",
    });

  const mergeDisabled = fetching || !groupQueryData?.group;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ArrowsPointingInIcon
                    className="h-6 w-6 text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Merge groups
                  </Dialog.Title>
                  <div className="my-4 text-sm text-gray-500">
                    <div className="mb-4">
                      <div className="mb-2">
                        <p className="font-bold">What gets transfered</p>
                        <ul className="list-disc list-inside">
                          <li>Group members</li>
                          <li>Favorited listings</li>
                          <li>Listings where a tour has been requested</li>
                        </ul>
                      </div>
                      <div className="mb-2">
                        <p className="font-bold">
                          What does not get transfered
                        </p>
                        <ul className="list-disc list-inside">
                          <li>Saved searches</li>
                          <li>
                            Feed listings that only exist because of a search
                            (no manual action)
                          </li>
                          <li>Co-agents</li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <h2 className="font-bold text-lg">Source group</h2>
                      <div className="text-orange-500 text-xs">
                        <ExclamationTriangleIcon
                          className="h-4 w-4 inline"
                          aria-hidden="true"
                        />{" "}
                        This is the group that will be deleted
                      </div>
                      <GroupSummary group={sourceGroup} />
                    </div>

                    <div className="mt-4">
                      <h2 className="font-bold text-lg">Destination group</h2>
                      <div className="text-blue-500 text-xs">
                        <InformationCircleIcon className="h-4 w-4 inline" />{" "}
                        Paste the group ID or URL of the destination group
                      </div>
                      <div className="flex">
                        <input
                          type="search"
                          name="search"
                          id="search"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md flex-1 my-1"
                          placeholder="grp_123456789"
                          value={destinationGroupId}
                          onChange={(e) =>
                            setDestinationGroupId(e.target.value)
                          }
                        />
                        <div className="w-5 pl-2 flex-none">
                          {fetching ? <LoadingIcon /> : <></>}
                        </div>
                      </div>
                      {groupQueryError ? (
                        <div>{groupQueryError.message}</div>
                      ) : (
                        <GroupSummary group={groupQueryData?.group} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={classNames(
                    mergeDisabled
                      ? "bg-gray-400 text-gray-100"
                      : "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500",
                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
                  )}
                  disabled={mergeDisabled}
                  onClick={() => onSubmit(groupQueryData!.group)}
                >
                  Merge
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const GroupSummary: React.FC<{
  group?: GroupFragment;
}> = ({ group }) => {
  if (!group) {
    return (
      <div>
        <div>No group found</div>
      </div>
    );
  }

  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <GroupPic group={group} />
      </div>
      <div className="flex-1 min-w-0">
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-sm font-medium text-gray-900">
          {group?.nameLong ?? group?.groupState}
        </p>
        <div className="grid grid-cols-3">
          <p className="text-xs text-gray-500 truncate">Agent:</p>
          <p className="text-xs text-gray-500 truncate col-span-2">
            {group?.owner.name}
          </p>
          {group?.groupState === GroupState.Active && (
            <>
              <p className="text-xs text-gray-500 truncate">Created:</p>
              <p className="text-xs text-gray-500 truncate col-span-2">
                {longDateFormat(group.createdAt)}
              </p>
            </>
          )}
          {group?.groupState === GroupState.Archived && (
            <>
              <p className="text-xs text-gray-500 truncate">Archived:</p>
              <p className="text-xs text-gray-500 truncate col-span-2">
                {longDateFormat(group.archivedAt)}
              </p>
            </>
          )}
          {group?.groupState === GroupState.Deleted && (
            <>
              <p className="text-xs text-gray-500 truncate">Deleted:</p>
              <p className="text-xs text-gray-500 truncate col-span-2">
                {longDateFormat(group.deletedAt)}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MergeDialog;
