import React from "react";
import { MlsFragment, useMlsesQuery } from "../../graphql/generated";

interface IMlsContext {
  mls?: MlsFragment;
  mlses: MlsFragment[];
  setMls: (mls?: MlsFragment) => void;
}

export const MlsContext = React.createContext<IMlsContext>({
  mlses: [],
  setMls: () => null,
});

const MlsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [mls, setMls] = React.useState<MlsFragment>();

  const [{ data }] = useMlsesQuery();

  return (
    <MlsContext.Provider value={{ mls, setMls, mlses: data?.mlses ?? [] }}>
      {children}
    </MlsContext.Provider>
  );
};

export default MlsProvider;
