import React from "react";
import { distanceDateFormat } from "../../common/utils/dateformat";
import {
  useMessageSummaryQuery,
  HistogramInterval,
  HistogramRange,
  useGroupNoteSummaryQuery,
} from "../../graphql/generated";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { ChartData } from "chart.js";
import { Transition } from "@headlessui/react";
import Loading from "../../common/components/loading";

interface CombinedData {
  [key: string]: CombinedValues;
}

interface CombinedValues {
  bucketDate: string;
  messages: number;
  notes: number;
}

const DashboardCommunication: React.FC<{
  interval: HistogramInterval;
  range: HistogramRange;
}> = ({ interval, range }) => {
  const [lastUpdated, setLastUpdated] = React.useState(new Date());

  const [{ data, fetching }, executeQuery] = useMessageSummaryQuery({
    variables: { interval, range },
  });

  const [{ data: gnData, fetching: gnFetching }, gnExecuteQuery] =
    useGroupNoteSummaryQuery({ variables: { interval, range } });

  const combinedData = React.useMemo(() => {
    if (data && gnData) {
      let combined = data.messageSummary.intervals.reduce(
        (obj: CombinedData, item) => {
          obj[item.bucketDate] = obj[item.bucketDate] ?? {
            bucketDate: item.bucketDate,
            messages: 0,
            notes: 0,
          };
          obj[item.bucketDate].messages = item.totalCount;
          return obj;
        },
        {}
      );
      combined = gnData.groupNoteSummary.intervals.reduce((obj, item) => {
        obj[item.bucketDate] = obj[item.bucketDate] ?? {
          bucketDate: item.bucketDate,
          messages: 0,
          notes: 0,
        };
        obj[item.bucketDate].notes = item.totalCount;
        return obj;
      }, combined);

      return Object.values(combined).sort((a, b) =>
        a.bucketDate < b.bucketDate ? -1 : 1
      );
    }
    return [];
  }, [data, gnData]);

  const chartData = React.useMemo<ChartData<"line", number[], Date>>(() => {
    return {
      labels: combinedData.map((d) => new Date(d.bucketDate)),
      datasets: [
        {
          label: "Chat",
          data: combinedData.map((d) => d.messages),
          borderColor: "rgb(239, 68, 68)",
          backgroundColor: "rgba(239, 68, 68, 0.5)",
        },
        {
          label: "Notes",
          data: combinedData.map((d) => d.notes),
          borderColor: "rgb(52, 211, 153)",
          backgroundColor: "rgba(52, 211, 153, 0.5)",
        },
        {
          label: "Combined",
          data: combinedData.map((d) => d.messages + d.notes),
          borderColor: "rgb(14, 165, 233)",
          backgroundColor: "rgba(14, 165, 233, 0.5)",
          hidden: true,
        },
      ],
    };
  }, [combinedData]);

  React.useEffect(() => {
    if (!fetching && !gnFetching) {
      const id = setTimeout(() => {
        setLastUpdated(new Date());
        executeQuery({ requestPolicy: "network-only" });
        gnExecuteQuery({ requestPolicy: "network-only" });
      }, 30000);
      return () => clearTimeout(id);
    }
  }, [executeQuery, fetching, gnExecuteQuery, gnFetching]);

  return (
    <>
      <Loading show={!data && !gnData} />
      <Transition
        show={!!data && !!gnData}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="px-2 my-2 sm:px-6 flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 grow">
              Messages
              <span className="text-xs text-gray-500">
                {" · "}
                Updated {distanceDateFormat(lastUpdated.toISOString())}
              </span>
            </h3>
          </div>
          <div className="p-2 border-t border-gray-200">
            {data && (
              <div className="col-span-3 h-56">
                <Line
                  options={{
                    maintainAspectRatio: false,
                    showLine: true,
                    interaction: { mode: "index" },
                    scales: {
                      x: {
                        type: "time",
                      },
                    },
                  }}
                  data={chartData}
                />
              </div>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default DashboardCommunication;
