import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmNumberBasicMappingTypeFragment,
  useRmMappingUpdateNumberBasicMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import LoadingIcon from "../../../common/components/loadingicon";

const RMNumberBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmNumberBasicMappingTypeFragment;
}> = ({ mapping, mappingType }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  const [{ fetching }, mutation] = useRmMappingUpdateNumberBasicMutation();
  const [validAboveIsNull, setValidAboveIsNull] = React.useState(
    mappingType.validAbove === null
  );
  const [validAboveValue, setValidAboveValue] = React.useState(
    mappingType.validAbove ?? 0.0
  );
  const [invalidBelowIsNull, setInvalidBelowIsNull] = React.useState(
    mappingType.invalidBelow === null
  );
  const [invalidBelowValue, setInvalidBelowValue] = React.useState(
    mappingType.invalidBelow ?? 0.0
  );

  const update = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      validAbove: validAboveIsNull ? null : validAboveValue,
      invalidBelow: invalidBelowIsNull ? null : invalidBelowValue,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mapping,
    validAboveIsNull,
    validAboveValue,
    invalidBelowIsNull,
    invalidBelowValue,
    updateNotification,
  ]);

  const cancel = React.useCallback(() => {
    setValidAboveIsNull(mappingType.validAbove === null);
    setValidAboveValue(mappingType.validAbove ?? 0.0);
    setInvalidBelowIsNull(mappingType.invalidBelow === null);
    setInvalidBelowValue(mappingType.invalidBelow ?? 0.0);
  }, [
    setValidAboveIsNull,
    setValidAboveValue,
    setInvalidBelowIsNull,
    setInvalidBelowValue,
    mappingType,
  ]);

  return (
    <Panel>
      <Panel.Title>Number Mapping</Panel.Title>
      <Panel.Body>
        <div className="py-2">
          <p>
            Invalid below – values below this value are considered invalid and
            will be ignored.
          </p>
          <div>
            <label>
              <input
                type="radio"
                name="invalid-below"
                checked={invalidBelowIsNull}
                onClick={() => setInvalidBelowIsNull(true)}
              />{" "}
              No value
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="invalid-below"
                checked={!invalidBelowIsNull}
                onClick={() => setInvalidBelowIsNull(false)}
              />
              Value:{" "}
              <input
                type="number"
                value={invalidBelowValue}
                onInput={(evt) => {
                  setInvalidBelowValue(evt.currentTarget.valueAsNumber);
                  setInvalidBelowIsNull(false);
                }}
              />
            </label>
          </div>
        </div>
        <div className="py-2">
          <p>
            Valid above – values above this value are considered valid and will
            be set on the resulting field
          </p>
          <div>
            <label>
              <input
                type="radio"
                name="valid-above"
                checked={validAboveIsNull}
                onClick={() => setValidAboveIsNull(true)}
              />{" "}
              No value
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="valid-above"
                checked={!validAboveIsNull}
                onClick={() => setValidAboveIsNull(false)}
              />
              Value:{" "}
              <input
                type="number"
                value={validAboveValue}
                onInput={(evt) => {
                  setValidAboveValue(evt.currentTarget.valueAsNumber);
                  setValidAboveIsNull(false);
                }}
              />
            </label>
          </div>
        </div>
        <div className="py-2">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            disabled={fetching}
            onClick={update}
          >
            {fetching && <LoadingIcon />}
            Update
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            disabled={fetching}
            onClick={cancel}
          >
            Cancel
          </button>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default RMNumberBasicMappingTypeControl;
