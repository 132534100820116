import { Transition } from "@headlessui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { IngestTaskStatus, useIngestTaskQuery } from "../../graphql/generated";
import IngestTaskSummary from "../components/summary";
import { IngestTaskTitle, buildTaskTitle } from "../components/title";
import { useTitle } from "../../common/utils/title";

const IngestTaskShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }, executeQuery] = useIngestTaskQuery({
    variables: { id: params.ingestTaskId ?? "" },
    pause: !params.ingestTaskId,
  });

  const refetch = React.useCallback(() => {
    executeQuery({ requestPolicy: "network-only" });
  }, [executeQuery]);

  useEffect(() => {
    if (
      data?.ingestTask.status === IngestTaskStatus.Active ||
      data?.ingestTask.status === IngestTaskStatus.Paused
    ) {
      const interval = setInterval(() => {
        executeQuery({ requestPolicy: "network-only" });
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    } else {
      // No autorefresh required
      return () => null;
    }
  }, [data?.ingestTask.status]);

  useTitle(buildTaskTitle(data?.ingestTask), "Ingest Tasks");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <IngestTaskTitle task={data?.ingestTask} refetch={refetch} />
          <IngestTaskSummary task={data?.ingestTask} />
        </div>
      </Transition>
    </>
  );
};

export default IngestTaskShow;
