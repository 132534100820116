import React from "react";
import Panel from "../../common/components/panel";
import { EnterpriseAgreementFragment } from "../../graphql/generated";
import BillingTableEntryCard from "./billingtableentries";
import BillingTableEntryActions from "./billingtableentryactions";

const EnterpriseAgreementBillingTableEntries: React.FC<{
  agreement?: EnterpriseAgreementFragment;
}> = ({ agreement }) => {
  return (
    <Panel>
      <Panel.Title actions={<BillingTableEntryActions agreement={agreement} />}>
        Billing Table Cost Mapping*
        <p className="text-sm">*Synchronize after making any changes</p>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-1">
          {agreement?.enterpriseBilling.__typename ===
            "EnterpriseBillingStripeSubscription" &&
            agreement.enterpriseBilling.billingTable.map((billingTableEntry) =>
              billingTableEntry ? (
                <BillingTableEntryCard
                  entry={billingTableEntry}
                  key={agreement?.id}
                />
              ) : null
            )}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default EnterpriseAgreementBillingTableEntries;
