import React from "react";
import Panel from "../../common/components/panel";
import { AccountQuery, AccountType } from "../../graphql/generated";
import LenderCard from "../../lender/components/card";
import AccountCard from "./card";

const LenderAgentCollaborations: React.FC<{
  account?: AccountQuery["account"];
}> = ({ account }) => {
  return (
    <Panel>
      <Panel.Title>
        Lender/Agent Collaborations: 
        <span className="text-xs text-gray-500"> {account?.lenderAgentCollaborations.length}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
        {
          account?.lenderAgentCollaborations.map((lenderAgentCollaboration) => {
            if (account?.accountType == AccountType.Agent) {
              return (
                lenderAgentCollaboration.lenderAccount ? <AccountCard account={lenderAgentCollaboration.lenderAccount} key={lenderAgentCollaboration.lenderAccount.id} lenderAgentCollaborationState={lenderAgentCollaboration.state} /> :
                  <LenderCard lender={lenderAgentCollaboration.lender} key={lenderAgentCollaboration.lender.id} lenderAgentCollaborationState={lenderAgentCollaboration.state} />
              )
            } else if (account?.accountType == AccountType.Lender) {
              return <AccountCard account={lenderAgentCollaboration.agentAccount} key={lenderAgentCollaboration.agentAccount.id}  lenderAgentCollaborationState={lenderAgentCollaboration.state} />
            }
          })
        }
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default LenderAgentCollaborations;
