import React from "react";
import { ProductFragment } from "../../graphql/generated";
import DescriptionListItem from "../../common/components/descriptionlistitem";

const AppStoreProductCard: React.FC<{
  product?: ProductFragment;
}> = ({ product }) => {
  return (
    <div className="relative bg-white px-6 py-3 flex items-center space-x-3">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
        <DescriptionListItem
          title="Product"
          info="Internal ID for the Product"
          value={product?.id}
          link={`/products/${product?.id}`}
        />
        <DescriptionListItem
          title="Entitlement"
          info="Entitlement granted by this Product"
          value={product?.entitlementGroup?.label}
        />
        <DescriptionListItem
          title="RC Product"
          info="Entitlement granted by this Product"
          value={product?.revenueCatProductId}
          link={`https://app.revenuecat.com/projects/3368be2e/products/${product?.revenueCatProductId}`}
        />
        <DescriptionListItem
          title="Subscription Group"
          info="Subscription Group at the app store"
          value={product?.appStoreSubscriptionGroup}
        />
      </dl>
    </div>
  );
};

export default AppStoreProductCard;
