import React, { Fragment } from "react";
import { ResourceMetadataFieldFragment } from "../../../graphql/generated";
import { Popover, Transition } from "@headlessui/react";

export const ResourceMetadataFieldComponent: React.FC<{
  field?: ResourceMetadataFieldFragment;
  name: string;
}> = ({ field, name }) => {
  if (!field) {
    return <>{name}</>;
  }

  return (
    <Popover className="relative inline">
      {() => (
        <>
          <Popover.Button className="inline">
            <span className={`border-b border-dashed border-purple-600`}>
              {field.name}
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-l w-64">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="bg-white p-4">
                  <div
                    className="grid auto-cols-min gap-2 items-baseline"
                    style={{ gridTemplateColumns: "auto 1fr" }}
                  >
                    <span className="text-gray-500">Name</span>{" "}
                    <span>{field.name}</span>{" "}
                    {field.extras.map((extra) => {
                      return (
                        <>
                          <span
                            key={`key-${extra.key}`}
                            className="text-gray-500"
                          >
                            {extra.key}
                          </span>{" "}
                          <span key={`value-${extra.key}`}>{extra.value}</span>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
