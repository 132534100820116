import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../common/utils/dateformat";
import { LeadFragment } from "../../graphql/generated";
import LeadPic from "./pic";

const LeadCard: React.FC<{ lead?: LeadFragment }> = ({ lead }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <LeadPic lead={lead} />
      </div>
      <div className="flex-1 win-w-0">
        <Link to={`/leads/${lead?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {lead?.account?.name ??
              lead?.unauthenticatedDetails?.name ??
              lead?.id}
          </p>
          <div className="grid grid-cols-3">
            <p className="text-xs text-gray-500 truncate">Created:</p>
            <p className="text-xs text-gray-500 truncate col-span-2">
              {longDateFormat(lead?.createdAt)}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default LeadCard;
