import {
  CalendarIcon,
  EnvelopeIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import Timestamp from "../../common/components/timestamp";
import { ClipboardContext } from "../../common/context/clipboard";
import { AccountFragment, AccountType } from "../../graphql/generated";
import AccountItemActions from "./itemactions";
import AccountProfilePic from "./profilepic";

const accountType = (accountType?: AccountType) => {
  switch(accountType) {
    case AccountType.Agent:
      return "Agent"
    case AccountType.Draft:
      return "Draft"
    case AccountType.Member:
      return "Member"
    case AccountType.Lender:
      return "Lender"
    default:
      return "Unknown"
  }
}

const AccountTitle: React.FC<{ account?: AccountFragment }> = ({ account }) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <AccountProfilePic account={account} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {account?.name.trim() === "" ? "Empty Name" : account?.name}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <UserIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {accountType(account?.accountType)}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                <Timestamp
                  timestamp={account?.createdAt}
                  format="short"
                  popover
                />
                /
                <Timestamp
                  timestamp={account?.activeAt}
                  format="short"
                  popover
                />
              </div>
            </div>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                <div
                  className="text-blue-900 cursor-pointer"
                  onClick={() => sendToClip(account?.email)}
                >
                  {account?.email}
                </div>
                <Link
                  to={{
                    pathname: "/subetha/recipients",
                    search: `?email=${account?.email}`,
                  }}
                >
                  <MagnifyingGlassIcon
                    className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                </Link>
              </div>
              {account?.phone && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <PhoneIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                  <a href={`tel:${account?.phone}`}>{account?.phone}</a>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <AccountItemActions account={account} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountTitle;
