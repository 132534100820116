import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Panel from "../../common/components/panel";
import { SavedFilterQuery } from "../../graphql/generated";

const SavedFilterJson: React.FC<{
  filter?: SavedFilterQuery["savedFilter"];
}> = ({ filter }) => {
  return (
    <>
      <Panel>
        <Panel.Title>Filter</Panel.Title>
        <Panel.Body fullWidth>
          <SyntaxHighlighter language="json" style={a11yDark}>
            {JSON.stringify(filter?.filter, null, 2)}
          </SyntaxHighlighter>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Query</Panel.Title>
        <Panel.Body fullWidth>
          <SyntaxHighlighter language="json" style={a11yDark}>
            {JSON.stringify(filter?.query, null, 2)}
          </SyntaxHighlighter>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default SavedFilterJson;
