import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useMlsQuery } from "../../graphql/generated";
import MlsAgentEnablement from "../components/agents";
import MlsDisclosure from "../components/disclosure";
import MlsOfficeEnablement from "../components/offices";
import MlsShowingtime from "../components/showingtime";
import MlsTitle from "../components/title";
import { useTitle } from "../../common/utils/title";
import MlsSummary from "../components/summary";

const MlsShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useMlsQuery({
    variables: { id: params.mlsId ?? "" },
    pause: !params.mlsId,
  });
  useTitle(data?.mls.shortName ?? params.mlsId, "MLSes");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        {data && (
          <div className="max-w-7xl mx-auto py-4">
            <MlsTitle mls={data?.mls} />
            <MlsSummary mls={data?.mls} />
            {data?.mls.showingTimeConfig && (
              <MlsShowingtime
                showingTimeConfig={data?.mls?.showingTimeConfig}
              />
            )}
            <MlsDisclosure mls={data?.mls} />
            <MlsOfficeEnablement mls={data?.mls} />
            <MlsAgentEnablement mls={data?.mls} />
          </div>
        )}
      </Transition>
    </>
  );
};

export default MlsShow;
