import { Menu, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  DocumentPlusIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  CanbyAnalysisShowFragment,
  useCanbyAnalysisBumpExpirationMutation,
  useStartCanbyMostRecentIngestMutation,
  useStartCanbyRandomSampleIngestMutation,
} from "../../graphql/generated";
import StartRandomSampleIngestDialog from "./start-random-sample-ingest-dialog";
import StartMostRecentIngestDialog from "./start-most-recent-ingest-dialog";

const AnalysisActions: React.FC<{
  analysis?: CanbyAnalysisShowFragment;
}> = ({ analysis }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [
    startRandomSampleIngestDialogIsOpen,
    setStartRandomSampleIngestDialogIsOpen,
  ] = React.useState(false);
  const [
    startRandomSampleIngestDialogIsSubmitting,
    setStartRandomSampleIngestDialogIsSubmitting,
  ] = React.useState(false);
  const [
    startMostRecentIngestDialogIsOpen,
    setStartMostRecentIngestDialogIsOpen,
  ] = React.useState(false);
  const [
    startMostRecentIngestDialogIsSubmitting,
    setStartMostRecentIngestDialogIsSubmitting,
  ] = React.useState(false);
  const [, bumpExpirationMutation] = useCanbyAnalysisBumpExpirationMutation();
  const [, startRandomSampleIngestMutation] =
    useStartCanbyRandomSampleIngestMutation();
  const [, startMostRecentIngestMutation] =
    useStartCanbyMostRecentIngestMutation();

  const bumpExpiration = React.useCallback(async () => {
    const { error } = await bumpExpirationMutation({
      id: analysis?.id ?? "",
    });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Expiration bumped",
        notificationType: NotificationType.Success,
      });
    }
  }, [analysis, bumpExpirationMutation, updateNotification]);

  const startRandomSampleIngest = React.useCallback(
    async (samples: number) => {
      setStartRandomSampleIngestDialogIsSubmitting(true);
      const { error } = await startRandomSampleIngestMutation({
        id: analysis?.id ?? "",
        samples,
      });
      setStartRandomSampleIngestDialogIsOpen(false);
      setStartRandomSampleIngestDialogIsSubmitting(false);
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Ingest started",
          notificationType: NotificationType.Success,
        });
      }
    },
    [
      analysis,
      startRandomSampleIngestMutation,
      updateNotification,
      setStartRandomSampleIngestDialogIsOpen,
      setStartRandomSampleIngestDialogIsSubmitting,
    ]
  );

  const startMostRecentIngest = React.useCallback(
    async (count: number) => {
      setStartMostRecentIngestDialogIsSubmitting(true);
      const { error } = await startMostRecentIngestMutation({
        id: analysis?.id ?? "",
        count,
      });
      setStartMostRecentIngestDialogIsOpen(false);
      setStartMostRecentIngestDialogIsSubmitting(false);
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Ingest started",
          notificationType: NotificationType.Success,
        });
      }
    },
    [
      analysis,
      startMostRecentIngestMutation,
      updateNotification,
      setStartMostRecentIngestDialogIsOpen,
      setStartMostRecentIngestDialogIsSubmitting,
    ]
  );

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => bumpExpiration()}
                  >
                    <CalendarIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Bump expiration
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setStartRandomSampleIngestDialogIsOpen(true)}
                  >
                    <DocumentPlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Ingest random sample...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setStartMostRecentIngestDialogIsOpen(true)}
                  >
                    <DocumentPlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Ingest recently updated...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <StartRandomSampleIngestDialog
        isOpen={startRandomSampleIngestDialogIsOpen}
        isSubmitting={startRandomSampleIngestDialogIsSubmitting}
        onClose={() => setStartRandomSampleIngestDialogIsOpen(false)}
        onSubmit={startRandomSampleIngest}
      />
      <StartMostRecentIngestDialog
        isOpen={startMostRecentIngestDialogIsOpen}
        isSubmitting={startMostRecentIngestDialogIsSubmitting}
        onClose={() => setStartMostRecentIngestDialogIsOpen(false)}
        onSubmit={startMostRecentIngest}
      />
    </>
  );
};

export default AnalysisActions;
