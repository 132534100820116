import React from "react";
import Diff from "./diff";
import Patch from "./patch";
import { apply_patch } from "jsonpatch";
import { PatchFragment } from "../../graphql/generated";
import Timestamp from "../../common/components/timestamp";

const PatchList: React.FC<{
  patchBase: object | null;
  patches: (PatchFragment | undefined)[];
}> = ({ patchBase, patches }) => {
  let nextPatchBase = patchBase;
  const patchesData = [];
  for (const patch of patches) {
    const newJson = nextPatchBase;
    let oldJson = null;
    if (newJson) {
      try {
        const backwardPatch = JSON.parse(patch!.backwardPatch);
        oldJson = apply_patch(newJson, backwardPatch);
      } catch {
        oldJson = null;
      }
    }
    nextPatchBase = oldJson;

    const element =
      oldJson && newJson ? (
        <Diff oldJson={oldJson} newJson={newJson} />
      ) : (
        <Patch
          forwardPatch={patch!.forwardPatch}
          backwardPatch={patch!.backwardPatch}
          key={patch?.id}
        />
      );

    const createdAt = patch!.createdAt;
    patchesData.push({
      element,
      createdAt,
    });
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {patchesData.map(({ element, createdAt }) => (
        <div key={createdAt} className="col-span-2">
          <div className="text-sm text-gray-500 py-2">
            <Timestamp timestamp={createdAt} format="long" popover />
          </div>
          {element}
        </div>
      ))}
    </div>
  );
};

export default PatchList;
