import { AtSymbolIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import { OfficeFragment } from "../../graphql/generated";

const OfficePic: React.FC<{ office?: OfficeFragment; large?: boolean }> = ({
  office,
  large,
}) => {
  return (
    <>
      {office?.media?.url ? (
        <img
          className={classNames(
            office?.enterpriseAgreement
              ? "bg-green-100 border-green-300"
              : "bg-red-100 border-red-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "object-contain rounded-full border-2"
          )}
          src={office?.media?.url}
          alt={office?.name ?? ""}
        />
      ) : (
        <AtSymbolIcon
          className={classNames(
            office?.enterpriseAgreement
              ? "bg-green-100 border-green-300"
              : "bg-red-100 border-red-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
    </>
  );
};

export default OfficePic;
