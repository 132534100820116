import React from "react";
import Panel from "../../common/components/panel";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
  DropResult,
} from "react-beautiful-dnd";

type Section = {
  t: "section";
  key: string;
  name: string;
};
type Subsection = {
  t: "subsection";
  key: string;
  name: string;
};
type Control = {
  t: "control";
  key: string;
  name: string;
};

export type ReorderableItem = Section | Subsection | Control;

export const TestRoute: React.FC = () => {
  const [elements, setElements] = React.useState<ReorderableItem[]>([
    { t: "section", key: "1", name: "Listing Basics" },
    { t: "subsection", key: "17", name: "A subsection" },
    { t: "subsection", key: "18", name: "Another subsection" },
    { t: "section", key: "2", name: "Miscellaneous" },
    { t: "control", key: "3", name: "List price" },
    { t: "control", key: "4", name: "Property Type" },
    { t: "control", key: "5", name: "Status" },
    { t: "control", key: "6", name: "Public Remarks" },
  ]);

  const [hidden, setHidden] = React.useState<ReorderableItem[]>([]);

  // const reorder = (
  //   list: ReorderableItem[],
  //   startIndex: number,
  //   endIndex: number
  // ) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result;
  // };

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      let item: ReorderableItem;
      if (result.source.droppableId === "order") {
        const [removedItem] = elements.splice(result.source.index, 1);
        item = removedItem;
      } else if (result.source.droppableId === "hidden") {
        const [removedItem] = hidden.splice(result.source.index, 1);
        item = removedItem;
      } else {
        return;
      }

      if (result.destination.droppableId === "order") {
        elements.splice(result.destination.index, 0, item);
      }
      if (result.destination.droppableId === "hidden") {
        if (item.t !== "control") {
          return;
        }
        hidden.splice(result.destination.index, 0, item);
      }

      setElements(elements);
      setHidden(hidden);
    },
    [elements, setElements, hidden, setHidden]
  );

  console.log(elements);

  return (
    <Panel>
      <Panel.Title>SFAR Listing Input – Control order</Panel.Title>
      <Panel.Body>
        <DragDropContext onDragEnd={onDragEnd}>
          <h2>Order</h2>
          <div className="border border-dashed">
            <Droppable droppableId="order">
              {(provided: DroppableProvided) => (
                <div
                  className="droppable"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {elements.map((element, index) => (
                    <Draggable
                      key={element.key}
                      draggableId={element.key}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <ReorderableElement
                          element={element}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <h2>🙈 Hidden</h2>
          <div className="border border-dashed">
            <Droppable droppableId="hidden">
              {(provided: DroppableProvided) => (
                <div
                  className="droppable"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {hidden.map((element, index) => (
                    <Draggable
                      key={element.key}
                      draggableId={element.key}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <ReorderableElement
                          element={element}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Panel.Body>
    </Panel>
  );
};

const ReorderableElement: React.FC<{
  element: ReorderableItem;
  provided: DraggableProvided;
}> = ({ element, provided }) => {
  switch (element.t) {
    case "section":
      return <ReorderableSection element={element} provided={provided} />;
    case "subsection":
      return <ReorderableSubsection element={element} provided={provided} />;
    case "control":
      return <ReorderableControl element={element} provided={provided} />;
  }
};

const ReorderableSection: React.FC<{
  element: Section;
  provided: DraggableProvided;
}> = ({ element, provided }) => {
  return (
    <h2
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="text-xl font-light"
    >
      {element.name}
    </h2>
  );
};

const ReorderableSubsection: React.FC<{
  element: Subsection;
  provided: DraggableProvided;
}> = ({ element, provided }) => {
  return (
    <h3
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="text-lg font-light"
    >
      — {element.name}
    </h3>
  );
};

const ReorderableControl: React.FC<{
  element: Control;
  provided: DraggableProvided;
}> = ({ element, provided }) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="p-1"
    >
      <div className="rounded-full bg-emerald-100 border border-emerald-200 p-2 text-sm">
        {element.name}
      </div>
    </div>
  );
};
