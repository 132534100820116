import { FlagIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import { FeatureFlagFragment, FeatureFlagState } from "../../graphql/generated";

const FeatureFlagPic: React.FC<{
  flag?: FeatureFlagFragment;
  large?: boolean;
}> = ({ flag, large }) => {
  return (
    <FlagIcon
      className={classNames(
        flag?.state === FeatureFlagState.Active
          ? "bg-green-100 border-green-300"
          : "",
        flag?.state === FeatureFlagState.Archived
          ? "bg-red-100 border-red-300"
          : "",
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2"
      )}
    />
  );
};

export default FeatureFlagPic;
