import { CalendarIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../../common/utils/dateformat";
import { ConversationFragment } from "../../../graphql/generated";
import GroupPic from "../../../group/components/pic";

const ConversationTitle: React.FC<{
  conversation?: ConversationFragment;
}> = ({ conversation }) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            {conversation?.group && (
              <GroupPic group={conversation.group} large />
            )}
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Conversation{" "}
              <span className="text-xs text-gray-500">
                {conversation?.displayName ??
                  "Group: " + conversation?.group?.nameLong}
              </span>
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                Latest Message: {longDateFormat(conversation?.messagedAt)}
              </div>
              {conversation?.group && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <UserGroupIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                  <Link
                    to={`/groups/${conversation.group.id}`}
                    className="text-blue-900 hover:underline"
                  >
                    {conversation.group.nameLong}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConversationTitle;
