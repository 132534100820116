import React from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useGroupItemLookupQuery } from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";

const GroupItemLookup: React.FC = () => {
  useTitle("Group Items");
  const { groupId } = useParams();
  const [listingForm, setListingForm] = React.useState("");
  const [listing, setListing] = React.useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(
    () => setListingForm(searchParams.get("listing") ?? ""),
    [searchParams]
  );

  const [{ data, fetching }] = useGroupItemLookupQuery({
    variables: {
      groupId: groupId!,
      listingId: listing!,
    },
    pause: !groupId || !listing,
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> =
    React.useCallback(
      (event) => {
        event.preventDefault();
        setListing(listingForm);
      },
      [listingForm]
    );

  const onListingChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (event) => {
        searchParams.set("listing", event?.target.value);
        setSearchParams(searchParams);
      },
      [searchParams, setSearchParams]
    );

  return data?.groupItemLookup ? (
    <Navigate to={`/groups/${groupId}/items/${data.groupItemLookup.id}`} />
  ) : (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <form className="space-y-8" onSubmit={handleSubmit}>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Lookup Group Item
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Search for a group item by a listing ID.
                  </p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Listing ID
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="listing"
                        id="listing"
                        autoComplete="listing"
                        value={listingForm}
                        onChange={onListingChange}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                      {fetching && (
                        <span className="text-sm text-gray-500">
                          Searching...
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupItemLookup;
