import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { shortDateFormat } from "../../common/utils/dateformat";
import { ClientInvitationQuery } from "../../graphql/generated";

const ClientInvitationSummary: React.FC<{
  invitation?: ClientInvitationQuery["clientInvitation"];
}> = ({ invitation }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem title="Code" value={invitation?.code ?? ""} />
          <DescriptionListItem title="Email" value={invitation?.email} />
          <DescriptionListItem
            title="Phone"
            value={invitation?.phone ?? ""}
            a={`tel:${invitation?.phone}`}
          />
          <DescriptionListItem
            title="Inviter"
            value={invitation?.inviter?.name}
            link={`/accounts/${invitation?.inviter?.id}`}
          />
          <DescriptionListItem
            title="Group"
            value={invitation?.group?.nameLong ?? ""}
            link={`/groups/${invitation?.group?.id}`}
          />
          <DescriptionListItem
            title="Modified"
            value={shortDateFormat(invitation?.modifiedAt)}
          />
          <DescriptionListItem
            title="Accepted"
            value={shortDateFormat(invitation?.acceptedAt)}
          />
          <DescriptionListItem
            title="Declined"
            value={shortDateFormat(invitation?.declinedAt)}
          />
          <DescriptionListItem
            title="Cancelled"
            value={shortDateFormat(invitation?.cancelledAt)}
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default ClientInvitationSummary;
