import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Panel from "../../common/components/panel";
import { FeatureFlagFragment } from "../../graphql/generated";

const FeatureFlagDescription: React.FC<{ flag?: FeatureFlagFragment }> = ({
  flag,
}) => {
  return (
    <Panel>
      <Panel.Title>Description</Panel.Title>
      <Panel.Body summary>
        <article className="prose">
          {flag && (
            <ReactMarkdown
              children={flag?.descriptionMarkdown}
              remarkPlugins={[remarkGfm]}
            />
          )}
        </article>
      </Panel.Body>
    </Panel>
  );
};

export default FeatureFlagDescription;
