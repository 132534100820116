import React from "react";
import { useClient } from "urql";
import Panel from "../../../common/components/panel";
import { classNames } from "../../../common/utils/classnames";
import {
  SubethaMailsQueryDocument,
  SubethaRecipientFragment,
  useSubethaMailsQueryQuery,
} from "../../../graphql/generated";
import MailCard from "../../mail/components/card";

const RecipientMails: React.FC<{ recipient?: SubethaRecipientFragment }> = ({
  recipient,
}) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();
  const [{ data }] = useSubethaMailsQueryQuery({
    variables: {
      first: 20,
      recipientId: recipient?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.mails.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(SubethaMailsQueryDocument, {
          first: 20,
          after: data.mails.pageInfo.endCursor,
          recipientId: recipient?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, recipient]);

  return (
    <Panel>
      <Panel.Title>
        Mails{" "}
        <span className="text-xs text-gray-500">
          {data?.mails.edges?.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="flow-root">
          <ul className="-mb-8">
            {data?.mails.edges?.map((mail, index) => (
              <MailCard
                mail={mail?.node}
                key={mail?.node.id}
                last={index === (data?.mails.edges?.length ?? 0) - 1}
              />
            ))}
          </ul>
        </div>
      </Panel.Body>
      <Panel.Footer>
        <button
          className={classNames(
            loading
              ? "bg-gray-500 text-white"
              : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
            "font-bold py-2 px-4 rounded"
          )}
          onClick={() => fetchMore()}
          disabled={loading}
        >
          Fetch More
        </button>
      </Panel.Footer>
      {/* )} */}
    </Panel>
  );
};

export default RecipientMails;
