import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import {
  EventHistogramInterval,
  EventHistogramRange,
  HistogramInterval,
  HistogramRange,
} from "../../graphql/generated";
import DashboardCommunication from "../components/communication";
import DashboardListingViews from "../components/listing-views";
import DashboardTours from "../components/tours";
import DashboardUserSessions from "../components/user-sessions";
import { useTitle } from "../../common/utils/title";

const Dashboard: React.FC = () => {
  useTitle("Dashboard");
  const [interval, setInterval] = React.useState(EventHistogramInterval.Day);
  const [range, setRange] = React.useState(EventHistogramRange.ThreeMonth);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0 grow">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Welcome
            </h2>
          </div>

          <div className="shrink w-40 mx-1">
            <Listbox value={range} onChange={setRange}>
              {({ open }) => (
                <div className="flex items-center w-full">
                  <div className="mt-1 relative w-full">
                    <Listbox.Button className="bg-white relative w-full pl-2 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md border border-gray-300 shadow-sm">
                      <span className="block truncate">
                        {rangeLabel(range)}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={React.Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {[
                          EventHistogramInterval.Hour,
                          EventHistogramInterval.Minute,
                        ].includes(interval) &&
                          newOption(
                            rangeLabel(EventHistogramRange.Day),
                            EventHistogramRange.Day
                          )}
                        {[
                          EventHistogramInterval.Hour,
                          EventHistogramInterval.Day,
                        ].includes(interval) &&
                          newOption(
                            rangeLabel(EventHistogramRange.Week),
                            EventHistogramRange.Week
                          )}
                        {[
                          EventHistogramInterval.Day,
                          EventHistogramInterval.Week,
                        ].includes(interval) &&
                          newOption(
                            rangeLabel(EventHistogramRange.OneMonth),
                            EventHistogramRange.OneMonth
                          )}
                        {[
                          EventHistogramInterval.Day,
                          EventHistogramInterval.Week,
                          EventHistogramInterval.Month,
                        ].includes(interval) &&
                          newOption(
                            rangeLabel(EventHistogramRange.ThreeMonth),
                            EventHistogramRange.ThreeMonth
                          )}
                        {[
                          EventHistogramInterval.Day,
                          EventHistogramInterval.Week,
                          EventHistogramInterval.Month,
                        ].includes(interval) &&
                          newOption(
                            rangeLabel(EventHistogramRange.SixMonth),
                            EventHistogramRange.SixMonth
                          )}
                        {[
                          EventHistogramInterval.Week,
                          EventHistogramInterval.Month,
                        ].includes(interval) &&
                          newOption(
                            rangeLabel(EventHistogramRange.Year),
                            EventHistogramRange.Year
                          )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </div>
              )}
            </Listbox>
          </div>
          <div className="shrink w-40">
            <Listbox value={interval} onChange={setInterval}>
              {({ open }) => (
                <div className="flex items-center w-full">
                  <div className="mt-1 relative w-full">
                    <Listbox.Button className="bg-white relative w-full pl-2 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md border border-gray-300 shadow-sm">
                      <span className="block truncate">
                        {intervalLabel(interval)}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={React.Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {[
                          EventHistogramRange.Week,
                          EventHistogramRange.OneMonth,
                          EventHistogramRange.ThreeMonth,
                          EventHistogramRange.SixMonth,
                        ].includes(range) &&
                          newOption(
                            intervalLabel(EventHistogramInterval.Day),
                            EventHistogramInterval.Day
                          )}
                        {[
                          EventHistogramRange.Day,
                          EventHistogramRange.Week,
                        ].includes(range) &&
                          newOption(
                            intervalLabel(EventHistogramInterval.Hour),
                            EventHistogramInterval.Hour
                          )}
                        {[EventHistogramRange.Day].includes(range) &&
                          newOption(
                            intervalLabel(EventHistogramInterval.Minute),
                            EventHistogramInterval.Minute
                          )}
                        {[
                          EventHistogramRange.SixMonth,
                          EventHistogramRange.Year,
                        ].includes(range) &&
                          newOption(
                            intervalLabel(EventHistogramInterval.Month),
                            EventHistogramInterval.Month
                          )}
                        {[EventHistogramRange.Year].includes(range) &&
                          newOption(
                            intervalLabel(EventHistogramInterval.Quarter),
                            EventHistogramInterval.Quarter
                          )}
                        {[
                          EventHistogramRange.OneMonth,
                          EventHistogramRange.ThreeMonth,
                          EventHistogramRange.SixMonth,
                          EventHistogramRange.Year,
                        ].includes(range) &&
                          newOption(
                            intervalLabel(EventHistogramInterval.Week),
                            EventHistogramInterval.Week
                          )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </div>
              )}
            </Listbox>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4 grid grid-cols-1 lg:grid-cols-2 gap-4 content-start">
        <DashboardUserSessions interval={interval} range={range} />
        <DashboardCommunication
          interval={eventToStandardInterval(interval)}
          range={eventToStandardRange(range)}
        />
        <DashboardListingViews interval={interval} range={range} />
        <DashboardTours interval={interval} range={range} />
      </div>
    </>
  );
};

export default Dashboard;

const rangeLabel = (range: EventHistogramRange) => {
  switch (range) {
    case EventHistogramRange.Day:
      return "One Day";
    case EventHistogramRange.Week:
      return "One Week";
    case EventHistogramRange.OneMonth:
      return "One Month";
    case EventHistogramRange.ThreeMonth:
      return "Three Months";
    case EventHistogramRange.SixMonth:
      return "Six Months";
    case EventHistogramRange.Year:
      return "One Year";
  }
};

const intervalLabel = (interval: EventHistogramInterval) => {
  switch (interval) {
    case EventHistogramInterval.Day:
      return "Day";
    case EventHistogramInterval.Hour:
      return "Hour";
    case EventHistogramInterval.Minute:
      return "Minute";
    case EventHistogramInterval.Month:
      return "Month";
    case EventHistogramInterval.Quarter:
      return "Quarter";
    case EventHistogramInterval.Week:
      return "Week";
    case EventHistogramInterval.Year:
      return "Year";
  }
};

const newOption = (
  label: string,
  value: EventHistogramRange | EventHistogramInterval
) => {
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(
          active ? "text-white bg-indigo-600" : "text-gray-900",
          "cursor-default select-none relative py-2 pl-3 pr-9"
        )
      }
      value={value}
    >
      {({ selected, active }) => (
        <>
          <span
            className={classNames(
              selected ? "font-semibold" : "font-normal",
              "block truncate"
            )}
          >
            {label}
          </span>

          {selected ? (
            <span
              className={classNames(
                active ? "text-white" : "text-indigo-600",
                "absolute inset-y-0 right-0 flex items-center pr-4"
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};

const eventToStandardInterval = (interval: EventHistogramInterval) => {
  switch (interval) {
    case EventHistogramInterval.Day:
      return HistogramInterval.Day;
    case EventHistogramInterval.Hour:
      return HistogramInterval.Hour;
    case EventHistogramInterval.Minute:
      return HistogramInterval.Minute;
    case EventHistogramInterval.Month:
      return HistogramInterval.Month;
    case EventHistogramInterval.Quarter:
      return HistogramInterval.Quarter;
    case EventHistogramInterval.Week:
      return HistogramInterval.Week;
    case EventHistogramInterval.Year:
      return HistogramInterval.Year;
  }
};

const eventToStandardRange = (range: EventHistogramRange) => {
  switch (range) {
    case EventHistogramRange.Day:
      return HistogramRange.Day;
    case EventHistogramRange.OneMonth:
      return HistogramRange.OneMonth;
    case EventHistogramRange.SixMonth:
      return HistogramRange.SixMonth;
    case EventHistogramRange.ThreeMonth:
      return HistogramRange.ThreeMonth;
    case EventHistogramRange.Week:
      return HistogramRange.Week;
    case EventHistogramRange.Year:
      return HistogramRange.Year;
  }
};
