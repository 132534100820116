import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import ProductSummary from "../components/summary";
import { useProductQuery } from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";

const ProductShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useProductQuery({
    variables: { id: params.productId ?? "" },
  });
  const name = React.useMemo(() => {
    if (!data) {
      return undefined;
    }
    const name = data.product.entitlementGroup?.name;
    if (!name) {
      return undefined;
    }

    return `${data.product.purchaseStore} ${name}`;
  }, [data]);
  useTitle(name, "Products");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ProductSummary product={data?.product} />
        </div>
      </Transition>
    </>
  );
};

export default ProductShow;
