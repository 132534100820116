import { CalendarIcon, SignalIcon } from "@heroicons/react/24/outline";
import React from "react";
import Timestamp from "../../common/components/timestamp";
import { ApiKeyFragment } from "../../graphql/generated";

const ApiKeyTitle: React.FC<{ apiKey?: ApiKeyFragment }> = ({ apiKey }) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Api Key: {apiKey?.id}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                <Timestamp
                  timestamp={apiKey?.createdAt}
                  format="short"
                  popover
                />
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <SignalIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {apiKey?.activeState}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiKeyTitle;
