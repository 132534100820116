import React from "react";
import Panel from "../../common/components/panel";
import {
  AccountFragment,
  useLeadsForAccountQuery,
} from "../../graphql/generated";
import LeadCard from "../../lead/components/card";

const AccountLeads: React.FC<{
  account?: AccountFragment;
}> = ({ account }) => {
  const [{ data }] = useLeadsForAccountQuery({
    variables: { accountId: account?.id || "" },
  });

  return (
    <Panel>
      <Panel.Title>
        Leads:{" "}
        <span className="text-xs text-gray-500">
          {data?.leadsForAccount.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.leadsForAccount.map((lead) => (
            <LeadCard lead={lead} key={lead.id} />
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountLeads;
