import React from "react";
import { useClient } from "urql";
import Panel from "../../common/components/panel";
import { classNames } from "../../common/utils/classnames";
import {
  GroupFragment,
  GroupNotesDocument,
  useGroupNotesQuery,
} from "../../graphql/generated";
import GroupNoteCard from "../../group-note/components/card";

const GroupNotes: React.FC<{ group?: GroupFragment }> = ({ group }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data }] = useGroupNotesQuery({
    variables: {
      first: 20,
      groupId: group?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.groupNotes.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(GroupNotesDocument, {
          first: 20,
          after: data.groupNotes.pageInfo.endCursor,
          groupId: group?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, group]);

  return (
    <Panel>
      <Panel.Title>
        Notes{" "}
        <span className="text-xs text-gray-500">{data?.groupNotes.total}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <ul className="divide-y divide-gray-200">
              {data?.groupNotes.edges?.map((edge) => (
                <GroupNoteCard note={edge?.node} key={edge?.node.id} />
              ))}
            </ul>
          </div>
        </div>
      </Panel.Body>

      {data &&
        data?.groupNotes.total > (data?.groupNotes.edges?.length ?? 0) && (
          <Panel.Footer>
            <button
              className={classNames(
                loading
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetchMore()}
              disabled={loading}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
    </Panel>
  );
};

export default GroupNotes;
