import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Loading from "../../common/components/loading";
import { Transition } from "@headlessui/react";
import { SourceData } from "../components/sourcedata";
import Error from "../../common/components/error";
import { useTitle } from "../../common/utils/title";

const SourceDataVersionShow: React.FC = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const previousVersionId = searchParams.get("previous");
  const data = {};
  const error = undefined;
  useTitle(params.entityId, "Source Data");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          {/* <ListingTitle listing={data?.listing} /> */}
          <SourceData
            entityId={params.entityId}
            versionId={params.versionId}
            previousVersionId={
              previousVersionId ? previousVersionId : undefined
            }
          />
        </div>
      </Transition>
    </>
  );
};

export default SourceDataVersionShow;
