import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  CheckIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
  UserGroupIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  IdentityFragment,
  IdentityState,
  useCreateDraftAccountMutation,
  useDisableIdentityMutation,
} from "../../graphql/generated";

const IdentityActions: React.FC<{ identity?: IdentityFragment }> = ({
  identity,
}) => {
  const [createDraftAccountOpen, setCreateDraftAccountOpen] =
    React.useState(false);
  const [disableIdentityOpen, setDisableIdentityOpen] = React.useState(false);
  const disableIdentityCancelButtonRef = React.useRef(null);
  const [{ fetching: createDraftAccountFetching }, createDraftAccountMutation] =
    useCreateDraftAccountMutation();
  const [{ fetching: disableIdentityFetching }, disableIdentityMutation] =
    useDisableIdentityMutation();
  const navigate = useNavigate();
  const { updateNotification } = React.useContext(NotificationContext);

  const [firstNameState, setFirstNameState] = React.useState("");
  const [lastNameState, setLastNameState] = React.useState("");

  const createDraftAccount = React.useCallback(async () => {
    if (createDraftAccountFetching) {
      return;
    }
    const { data, error } = await createDraftAccountMutation({
      identityId: identity?.id ?? "",
      firstName: firstNameState,
      lastName: lastNameState,
    });
    if (!error) {
      updateNotification({
        notification: `Created draft account for ${identity?.name}`,
        notificationType: NotificationType.Success,
      });
      navigate(`/accounts/${data?.createDraftAccount.id}`);
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    createDraftAccountFetching,
    createDraftAccountMutation,
    identity,
    firstNameState,
    lastNameState,
    updateNotification,
    navigate,
  ]);

  const disableIdentity = React.useCallback(async () => {
    if (disableIdentityFetching) {
      return;
    }
    const { error } = await disableIdentityMutation({
      id: identity?.id ?? "",
    });
    if (!error) {
      updateNotification({
        notification: `Identity has been disabled`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
    setDisableIdentityOpen(false);
  }, [
    disableIdentityFetching,
    disableIdentityMutation,
    identity,
    updateNotification,
  ]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              {!identity?.account && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => setCreateDraftAccountOpen(true)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <UserGroupIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Create Draft Account
                    </div>
                  )}
                </Menu.Item>
              )}
              {identity?.identityState !== IdentityState.Disabled &&
                !identity?.account && (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => setDisableIdentityOpen(true)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <XCircleIcon
                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Disable Identity
                      </div>
                    )}
                  </Menu.Item>
                )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Transition.Root show={createDraftAccountOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setCreateDraftAccountOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Create Draft Account
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="first-name"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="First Name"
                          value={firstNameState}
                          onChange={(e) => setFirstNameState(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Last Name"
                          value={lastNameState}
                          onChange={(e) => setLastNameState(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={createDraftAccount}
                  >
                    Create Draft Account
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={disableIdentityOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setDisableIdentityOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Disable Identity
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to disable this identity?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={disableIdentity}
                  >
                    Disable
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setDisableIdentityOpen(false)}
                    ref={disableIdentityCancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default IdentityActions;
