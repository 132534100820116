import React from "react";
import Panel from "../../common/components/panel";
import { EnterpriseAgreementFragment } from "../../graphql/generated";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";

const EnterpriseAgreementSummary: React.FC<{
  agreement?: EnterpriseAgreementFragment;
}> = ({ agreement }) => {
  return (
    <>
      <Panel>
        <Panel.Title>Configuration</Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListContainer title="Agent Stripe Price">
              {agreement?.isAgentStripePriceTest && (
                <a
                  href={`https://dashboard.stripe.com/test/prices/${agreement?.agentStripePriceId}`}
                  target={"_blank"}
                  className="text-blue-900 hover:underline"
                  rel="noreferrer"
                >
                  {agreement?.agentStripePriceId}
                </a>
              )}
              {!agreement?.isAgentStripePriceTest && (
                <a
                  href={`https://dashboard.stripe.com/prices/${agreement?.agentStripePriceId}`}
                  target={"_blank"}
                  className="text-blue-900 hover:underline"
                  rel="noreferrer"
                >
                  {agreement?.agentStripePriceId}
                </a>
              )}
            </DescriptionListContainer>
            {agreement?.enterpriseBilling.__typename ===
              "EnterpriseBillingStripeSubscription" && (
              <>
                <DescriptionListContainer title="Enterprise Billing">
                  <abbr title="The Enterprise is billed through a Stripe subscription. The agent will typically have a $0 subscription.">
                    Through Stripe
                  </abbr>
                </DescriptionListContainer>
                <DescriptionListContainer title="Enterprise Billing Customer">
                  <a
                    href={`https://dashboard.stripe.com/customers/${agreement.enterpriseBilling.billingStripeCustomerId}`}
                    target={"_blank"}
                    className="text-blue-900 hover:underline"
                    rel="noreferrer"
                  >
                    {agreement.enterpriseBilling.billingStripeCustomerId}
                  </a>
                </DescriptionListContainer>
                <DescriptionListContainer title="Enterprise Billing Subscription">
                  <a
                    href={`https://dashboard.stripe.com/subscriptions/${agreement.enterpriseBilling.billingStripeSubscriptionId}`}
                    target={"_blank"}
                    className="text-blue-900 hover:underline"
                    rel="noreferrer"
                  >
                    {agreement.enterpriseBilling.billingStripeSubscriptionId}
                  </a>
                </DescriptionListContainer>
                <DescriptionListContainer title="Automatically Adjust Stripe">
                  {agreement?.enterpriseBilling
                    .automaticallyAdjustStripeSubscription && (
                    <abbr title="Automatically adjust the Stripe subscription when an account accepts or cancels a Stripe subscription.">
                      Yes
                    </abbr>
                  )}
                  {!agreement?.enterpriseBilling
                    .automaticallyAdjustStripeSubscription && (
                    <abbr title="Account subscription changes will not be used to automatically adjust the Enterprise Stripe subscription.">
                      No
                    </abbr>
                  )}
                </DescriptionListContainer>
              </>
            )}
            {agreement?.enterpriseBilling.__typename ===
              "BilledExternalToStripe" && (
              <DescriptionListContainer title="Billing Type">
                <abbr title="The Enterprise is billed through a mechanism other than Stripe.">
                  External to Stripe
                </abbr>
              </DescriptionListContainer>
            )}
            {agreement?.enterpriseBilling.__typename ===
              "IndividualBilling" && (
              <DescriptionListContainer title="Billing Type">
                <abbr title="The Enterprise is not billed. Billing happens at the individual agent level.">
                  Individual Agent Billing
                </abbr>
              </DescriptionListContainer>
            )}
            <DescriptionListItem
              title="Product Offering"
              info="The active product offering."
              value={agreement?.baseAgentOfferingId}
              link={`/product_offerings/${agreement?.baseAgentOfferingId}`}
            />
          </dl>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Message</Panel.Title>
        <Panel.Body summary fullWidth>
          <article className="prose p-4 border-t border-gray-200 px-4 my-2 sm:px-6">
            {agreement?.message ?? ""}
          </article>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default EnterpriseAgreementSummary;
