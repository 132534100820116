import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../utils/classnames";

const CommonNotification: React.FC = () => {
  const [timer, setTimer] = React.useState<NodeJS.Timeout>();
  const [show, setShow] = React.useState(false);

  const { clearNotification, currentNotification } =
    React.useContext(NotificationContext);

  const closeNotification = React.useCallback(() => {
    setShow(false);
    clearNotification();
  }, [clearNotification]);

  React.useEffect(() => {
    if (!!currentNotification && !show) {
      setShow(true);
      setTimer(setTimeout(() => closeNotification(), 3000));
    }
  }, [currentNotification, timer, closeNotification, show]);

  React.useEffect(() => () => timer && clearTimeout(timer), [timer]);

  const icon = React.useMemo(() => {
    switch (currentNotification?.notificationType) {
      case NotificationType.Error:
        return <XCircleIcon className="h-6 w-6 h-6 w-6 text-white" />;
      case NotificationType.Success:
        return <CheckCircleIcon className="h-6 w-6 h-6 w-6 text-white" />;
    }
  }, [currentNotification?.notificationType]);

  return (
    <Transition
      show={show}
      as={React.Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div
            className={classNames(
              currentNotification?.notificationType === NotificationType.Error
                ? "bg-red-600"
                : "",
              currentNotification?.notificationType === NotificationType.Success
                ? "bg-green-600"
                : "",
              "p-2 rounded-lg shadow-lg sm:p-3"
            )}
          >
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span
                  className={classNames(
                    currentNotification?.notificationType ===
                      NotificationType.Error
                      ? "bg-red-800"
                      : "",
                    currentNotification?.notificationType ===
                      NotificationType.Success
                      ? "bg-green-800"
                      : "",
                    "flex p-2 rounded-lg"
                  )}
                >
                  {icon}
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="hidden md:inline">
                    {currentNotification?.notification}
                  </span>
                </p>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  onClick={closeNotification}
                  type="button"
                  className={classNames(
                    currentNotification?.notificationType ===
                      NotificationType.Error
                      ? "hover:bg-red-500"
                      : "",
                    currentNotification?.notificationType ===
                      NotificationType.Success
                      ? "hover:bg-green-500"
                      : "",
                    "-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
                  )}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default CommonNotification;
