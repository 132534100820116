import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import Timestamp from "../../common/components/timestamp";
import { InternalListingBucketFragment } from "../../graphql/generated";
import { StatusComponent } from "../../common/components/status";

const InternalListingBucketSummary: React.FC<{
  bucket?: InternalListingBucketFragment;
}> = ({ bucket }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem title="ID" value={bucket?.id} />
          <DescriptionListItem
            title="Name"
            value={bucket?.name}
            info="The name that shows up in search"
          />
          <DescriptionListContainer title="Status">
            <StatusComponent entity={bucket} />
          </DescriptionListContainer>
          <DescriptionListContainer title="Created at">
            <Timestamp format="long" timestamp={bucket?.createdAt} popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Modified at">
            <Timestamp format="long" timestamp={bucket?.modifiedAt} popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Deleted at">
            <Timestamp format="long" timestamp={bucket?.deletedAt} popover />
          </DescriptionListContainer>
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default InternalListingBucketSummary;
