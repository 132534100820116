import React from "react";
import Panel from "../../common/components/panel";
import {
  FeatureFlagFragment,
  ProductOfferingFragment,
} from "../../graphql/generated";
import ProductOfferingCard from "./productofferingcard";
import ProductOfferingActions from "./productofferingactions";

const FeatureFlagProductOfferings: React.FC<{
  productOfferings?: ProductOfferingFragment[];
  flag?: FeatureFlagFragment;
  label: string;
  enabled?: boolean;
}> = ({ productOfferings, label, flag, enabled }) => {
  return (
    <Panel>
      <Panel.Title
        actions={
          <ProductOfferingActions featureFlag={flag} enabled={enabled} />
        }
      >
        {label}{" "}
        <span className="text-xs text-gray-500">
          {productOfferings?.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {productOfferings &&
            flag &&
            productOfferings.map((productOffering) => (
              <ProductOfferingCard
                productOffering={productOffering}
                flag={flag}
                key={productOffering.id}
                enabled={enabled}
              />
            ))}
        </ul>
      </Panel.Body>
    </Panel>
  );
};

export default FeatureFlagProductOfferings;
