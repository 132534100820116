import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useClient } from "urql";
import {
  SourceDataHistoryDocument,
  SourceDataHistoryEntryFragment,
  SourceDataHistoryQuery,
  useSourceDataQuery,
} from "../../graphql/generated";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Timestamp from "../../common/components/timestamp";
import { Link } from "react-router-dom";
import LoadingIcon from "../../common/components/loadingicon";
import Diff from "../../patch/components/diff";
import Panel from "../../common/components/panel";

/**
 * Component used on the Listing Source Data page to show an S3 record
 */
export const SourceData: React.FC<{
  entityId?: string;
  versionId?: string;
  previousVersionId?: string;
}> = ({ entityId, versionId, previousVersionId }) => {
  const [{ data }] = useSourceDataQuery({
    variables: {
      entityId: entityId ?? "",
      versionId: versionId ?? "",
    },
    pause: !entityId || !versionId,
  });

  const [{ data: previousData }] = useSourceDataQuery({
    variables: {
      entityId: entityId ?? "",
      versionId: previousVersionId ?? "",
    },
    pause: !entityId || !previousVersionId,
  });

  return (
    <>
      <Panel>
        <Panel.Title>Source Data</Panel.Title>

        <Panel.Body fullWidth>
          <div className="px-4 py-5">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
              <DescriptionListContainer title="Seen at">
                <Timestamp
                  timestamp={data?.sourceData.createdAt}
                  format="short"
                  popover
                />
              </DescriptionListContainer>
              <DescriptionListItem
                title="Class"
                value={data?.sourceData.class}
              />
            </dl>
          </div>
          {data?.sourceData.json && (
            <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers>
              {JSON.stringify(data.sourceData.json, null, 2)}
            </SyntaxHighlighter>
          )}
        </Panel.Body>
      </Panel>

      {data?.sourceData.json && previousData?.sourceData.json && (
        <Panel>
          <Panel.Title>Diff</Panel.Title>

          <Panel.Body fullWidth>
            <Diff
              oldJson={previousData.sourceData.json}
              newJson={data.sourceData.json}
              compact
            />
          </Panel.Body>
        </Panel>
      )}
    </>
  );
};

/**
 * Component used on the Listing summary page to show the history of the listing
 */
export const SourceDataHistoryPanel: React.FC<{
  entityId?: string | null;
  createdAt?: string | null;
  removedAt?: string | null;
  manuallyRemovedAt?: string | null;
}> = ({ entityId, createdAt, removedAt, manuallyRemovedAt }) => {
  const graphqlClient = useClient();
  const [allLoaded, setAllLoaded] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [entries, setEntries] = React.useState<
    SourceDataHistoryEntryFragment[]
  >([]);
  const [after, setAfter] = React.useState<string | undefined | null>(
    undefined
  );

  React.useEffect(() => {
    const fetchMore = async () => {
      if (!fetching && !allLoaded) {
        setFetching(true);
        const response = await graphqlClient
          .query<SourceDataHistoryQuery>(SourceDataHistoryDocument, {
            first: 5,
            after: after,
            entityId: entityId ?? "",
          })
          .toPromise();

        setAfter(response.data?.sourceDataHistory.pageInfo.endCursor);
        if (response.data?.sourceDataHistory.edges) {
          for (const edge of response.data?.sourceDataHistory.edges) {
            if (edge?.node) {
              entries.push(edge.node);
            }
          }
          setEntries(entries);
        }
        if (!response.data?.sourceDataHistory.pageInfo.hasNextPage) {
          setAllLoaded(true);
        }

        setFetching(false);
      }
    };

    fetchMore().catch(console.error);
  }, [
    entries,
    setEntries,
    after,
    setAfter,
    graphqlClient,
    entityId,
    allLoaded,
    setAllLoaded,
    fetching,
    setFetching,
  ]);

  return (
    <Panel>
      <Panel.Title>Source system history</Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <ul className="divide-y divide-gray-200">
              <SourceDataHistoryTimeline
                entityId={entityId}
                createdAt={createdAt}
                removedAt={removedAt}
                manuallyRemovedAt={manuallyRemovedAt}
                entries={entries}
                loading={!allLoaded}
              />
            </ul>
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

type SourceDataHistoryTimelineProps = {
  entityId?: string | null;
  createdAt?: string | null;
  removedAt?: string | null;
  manuallyRemovedAt?: string | null;
  loading?: boolean;
  entries: SourceDataHistoryEntry[];
};

type SourceDataHistoryEntry = {
  id: string;
  versionId: string;
  receivedAt: string;
  class?: string;
};

enum SourceDataHistoryTimelineContainerStyle {
  Last,
  Indeterminate,
  Regular,
}

export const SourceDataHistoryTimeline: React.FC<
  SourceDataHistoryTimelineProps
> = ({
  entityId,
  createdAt,
  removedAt,
  manuallyRemovedAt,
  loading,
  entries,
}) => {
  let leading;
  if (manuallyRemovedAt) {
    leading = (
      <SourceDataHistoryTimelineContainer
        dot
        timelineStyle={SourceDataHistoryTimelineContainerStyle.Regular}
        key="leading"
      >
        <div>
          Listing <strong>manually removed</strong> at{" "}
          <Timestamp timestamp={manuallyRemovedAt} format="long" popover />
        </div>
        <div className="text-sm text-gray-500">
          The entity was removed by a Zenlist employee, likely as a response to
          an agent request.
        </div>
      </SourceDataHistoryTimelineContainer>
    );
  } else if (removedAt) {
    leading = (
      <SourceDataHistoryTimelineContainer
        dot
        timelineStyle={SourceDataHistoryTimelineContainerStyle.Regular}
        key="leading"
      >
        <div>
          Entity <strong>expired</strong> at{" "}
          <Timestamp timestamp={removedAt} format="long" popover />
        </div>
        <div className="text-sm text-gray-500">
          <div>The entity was removed because</div>
          <ul className="list-disc pl-4">
            <li>
              it was not seen in the source data recently and is from an MLS
              where we expire listings, or
            </li>
            <li>we were told by the MLS that it was removed.</li>
          </ul>
        </div>
      </SourceDataHistoryTimelineContainer>
    );
  }

  let trailing;
  if (!loading && entries && entries.length > 0 && createdAt) {
    const oldestEntry = entries[entries.length - 1];
    const createdAtDate = new Date(createdAt);
    const oldestReceivedAt = new Date(oldestEntry.receivedAt);
    if (createdAtDate < oldestReceivedAt) {
      trailing = (
        <SourceDataHistoryTimelineContainer
          dot
          timelineStyle={SourceDataHistoryTimelineContainerStyle.Last}
          key="trailing"
        >
          <div>
            Listing <strong>created</strong> at{" "}
            <Timestamp timestamp={createdAt} format="long" popover />
          </div>
          <div className="text-sm text-gray-500">
            The source data history for this entity does not extend all the way
            back to when this entity was created.
          </div>
        </SourceDataHistoryTimelineContainer>
      );
    }
  }

  const hasTrailing = !!loading || !!trailing;

  const combinedEntries = entries?.map((entry, idx) => {
    const nextIdx = idx + 1;
    let olderEntry;
    let timelineStyle = SourceDataHistoryTimelineContainerStyle.Regular;

    if (nextIdx < entries.length) {
      olderEntry = entries[nextIdx];
    }

    if (olderEntry == null && hasTrailing) {
      timelineStyle = SourceDataHistoryTimelineContainerStyle.Indeterminate;
    }
    if (olderEntry == null && !hasTrailing) {
      timelineStyle = SourceDataHistoryTimelineContainerStyle.Last;
    }

    return {
      entry,
      olderEntry,
      timelineStyle,
    };
  });

  return (
    <div>
      {leading}
      {entityId &&
        combinedEntries?.map(({ entry, olderEntry, timelineStyle }) => (
          <SourceDataHistoryTimelineContainer
            dot
            timelineStyle={timelineStyle}
            key={entry.id}
          >
            <SourceDataHistoryTimelineEntry
              entityId={entityId}
              entry={entry}
              olderEntry={olderEntry}
            />
          </SourceDataHistoryTimelineContainer>
        ))}
      {trailing}
      {!!loading && (
        <SourceDataHistoryTimelineContainer
          timelineStyle={SourceDataHistoryTimelineContainerStyle.Last}
          key="loading"
        >
          <div className="py-2">
            <LoadingIcon /> Loading...
          </div>
        </SourceDataHistoryTimelineContainer>
      )}
    </div>
  );
};

export const SourceDataHistoryTimelineContainer: React.FC<{
  timelineStyle: SourceDataHistoryTimelineContainerStyle;
  dot?: boolean;
  children?: React.ReactNode;
}> = ({ timelineStyle, dot, children }) => {
  let borderStyle = "";
  switch (timelineStyle) {
    case SourceDataHistoryTimelineContainerStyle.Last:
      borderStyle = "border-green-500 border-l-2 border-hidden";
      break;
    case SourceDataHistoryTimelineContainerStyle.Indeterminate:
      borderStyle = "border-green-500 border-l-2 border-dashed";
      break;
    case SourceDataHistoryTimelineContainerStyle.Regular:
      borderStyle = "border-green-500 border-l-2 border-solid";
      break;
  }

  return (
    <div className="relative pl-4">
      <div
        style={{ left: "0px", top: "12px", bottom: "-12px" }}
        className={`absolute ${borderStyle}`}
      ></div>
      {!!dot && (
        <div
          style={{ width: "10px", height: "10px", left: "-4px", top: "12px" }}
          className="absolute border-2 border-solid border-green-500 rounded-full bg-white"
        ></div>
      )}

      <div className="py-1">{children}</div>
    </div>
  );
};

const SourceDataHistoryTimelineEntry: React.FC<{
  entityId: string;
  entry: SourceDataHistoryEntry;
  olderEntry?: SourceDataHistoryEntry;
}> = ({ entityId, entry, olderEntry }) => {
  let link = `/source/${entityId}/${entry.versionId}`;
  if (olderEntry) {
    link += `?previous=${olderEntry.versionId}`;
  }

  return (
    <>
      <div>
        Received an update at{" "}
        <Timestamp timestamp={entry.receivedAt} format="long" popover />
      </div>
      <div>
        <Link to={link} className="text-blue-900 hover:underline">
          View raw data
        </Link>
      </div>
    </>
  );
};
