import React from "react";
import { OfficeMediaFragment } from "../../graphql/generated";

const OfficeLogo: React.FC<{
  media: OfficeMediaFragment;
  altText: string;
}> = ({ media, altText }) => {
  return (
    <img
      className="border border-gray-300"
      src={media.url}
      width={media.width?.toString()}
      height={media.height?.toString()}
      alt={altText}
    ></img>
  );
};

export default OfficeLogo;
