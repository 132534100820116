import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import AuthRequire from "./auth/components/require";
import Main from "./common/components/layout";
import Login from "./auth/routes/login";
import Dashboard from "./dashboard/routes";
import NotFound from "./common/routes/notfound";
import AccountList from "./account/routes/list";
import AccountShow from "./account/routes/show";
import AgentList from "./agent/routes/list";
import AgentShow from "./agent/routes/show";
import BabelConversationList from "./babel/conversation/routes/list";
import BabelConversationShow from "./babel/conversation/routes/show";
import BrokerageList from "./brokerage/routes/list";
import BrokerageShow from "./brokerage/routes/show";
import ClientInvitationShow from "./client-invitation/routes/show";
import EnterpriseAgreementList from "./enterprise-agreement/routes/list";
import EnterpriseAgreementNew from "./enterprise-agreement/routes/new";
import EnterpriseAgreementShow from "./enterprise-agreement/routes/show";
import EnterpriseAgreementEdit from "./enterprise-agreement/routes/edit";
import FeatureFlagList from "./feature-flag/routes/list";
import FeatureFlagNew from "./feature-flag/routes/new";
import FeatureFlagShow from "./feature-flag/routes/show";
import FeatureFlagEdit from "./feature-flag/routes/edit";
import GroupNoteList from "./group-note/routes/list";
import GroupShow from "./group/routes/show";
import GroupList from "./group/routes/list";
import IdentityList from "./identity/routes/list";
import IdentityShow from "./identity/routes/show";
import IngestTaskShow from "./ingest-task/routes/show";
import LenderList from "./lender/routes/list";
import LenderNew from "./lender/routes/new";
import LenderNewTest from "./lender/routes/new-test";
import LenderShow from "./lender/routes/show";
import ListingLookup from "./listing/routes/lookup";
import ListingShow from "./listing/routes/show";
import OfficeList from "./office/routes/list";
import OfficeShow from "./office/routes/show";
import SavedFilterShow from "./saved-filter/routes/show";
import MailShow from "./subetha/mail/routes/show";
import RecipientList from "./subetha/recipient/routes/list";
import RecipientShow from "./subetha/recipient/routes/show";
import ListingGeocode from "./listing/routes/geocode";
import ReferralList from "./referral/routes/list";
import ReferralNew from "./referral/routes/new";
import AgentNew from "./agent/routes/new";
import MlsList from "./mls/routes/list";
import MlsShow from "./mls/routes/show";
import ListingInputShow from "./mls/routes/listing-input";
import GroupItemLookup from "./group-item/routes/lookup";
import GroupItemShow from "./group-item/routes/show";
import EventShow from "./subetha/event/routes/show";
import TourShow from "./tour/routes/show";
import LeadList from "./lead/routes/list";
import LeadShow from "./lead/routes/show";
import ApiKeyShow from "./api-key/routes/show";
import SourceDataEntityShow from "./source/routes/entity";
import SourceDataVersionShow from "./source/routes/version";
import ProductOfferingShow from "./product-offering/routes/show";

import { TestRoute } from "./test-route/routes/test-route";
import InternalListingBucketShow from "./internal-listing-bucket/routes/show";
import InternalListingBucketList from "./internal-listing-bucket/routes/list";
import InternalListingBucketEdit from "./internal-listing-bucket/routes/edit";
import InternalListingBucketNew from "./internal-listing-bucket/routes/new";
import ProductShow from "./product/routes/show";
import AccountSubscriptionList from "./account_subscription/routes/list";
import AccountSubscriptionShow from "./account_subscription/routes/show";
import ProductOfferingList from "./product-offering/routes/list";
import ProductList from "./product/routes/list";
import RMField from "./resource-mapping/routes/field";
import RMMapping from "./resource-mapping/routes/mapping";
import RMNormalization from "./resource-mapping/routes/normalization";
import RMRootResource from "./resource-mapping/routes/root";
import RMMls from "./resource-mapping/routes/mls";
import RMProblem from "./resource-mapping/routes/problem";
import RMIndex from "./resource-mapping/routes";
import ReprocessDataTaskShow from "./reprocess-data-task/routes/show";
import ReprocessDataTaskList from "./reprocess-data-task/routes/list";
import CanbyAnalysis from "./canby/routes/analysis";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRequire>
              <Main />
            </AuthRequire>
          }
        >
          <Route element={<Dashboard />} index />
          <Route path="test" element={<TestRoute />} />
          <Route path="accounts" element={<Outlet />}>
            <Route element={<AccountList />} index />
            <Route path=":accountId" element={<AccountShow />} />
          </Route>
          <Route path="account_subscriptions" element={<Outlet />}>
            <Route element={<AccountSubscriptionList />} index />
            <Route
              path=":accountSubscriptionId"
              element={<AccountSubscriptionShow />}
            />
          </Route>
          <Route path="api-keys" element={<Outlet />}>
            <Route path=":apiKeyId" element={<ApiKeyShow />} />
          </Route>
          <Route path="agents" element={<Outlet />}>
            <Route element={<AgentList />} index />
            <Route path="new" element={<AgentNew />} />
            <Route path=":agentId" element={<AgentShow />} />
          </Route>
          <Route path="/babel/conversations" element={<Outlet />}>
            <Route element={<BabelConversationList />} index />
            <Route path=":conversationId" element={<BabelConversationShow />} />
          </Route>
          <Route path="brokerages" element={<Outlet />}>
            <Route element={<BrokerageList />} index />
            <Route path=":brokerageId" element={<BrokerageShow />} />
          </Route>
          <Route path="canby" element={<Outlet />}>
            <Route element={<Navigate to="/" />} index />
            <Route path=":canbyAnalysisId" element={<CanbyAnalysis />} />
          </Route>
          <Route path="client_invitations" element={<Outlet />}>
            <Route element={<Navigate to="/" />} index />
            <Route
              path=":clientInvitationId"
              element={<ClientInvitationShow />}
            />
          </Route>
          <Route path="enterprise_agreements" element={<Outlet />}>
            <Route element={<EnterpriseAgreementList />} index />
            <Route path="new" element={<EnterpriseAgreementNew />} />
            <Route path=":enterpriseAgreementId" element={<Outlet />}>
              <Route element={<EnterpriseAgreementShow />} index />
              <Route path="edit" element={<EnterpriseAgreementEdit />} />
            </Route>
          </Route>
          <Route path="feature_flags" element={<Outlet />}>
            <Route element={<FeatureFlagList />} index />
            <Route path="new" element={<FeatureFlagNew />} />
            <Route path=":featureFlagId" element={<Outlet />}>
              <Route element={<FeatureFlagShow />} index />
              <Route path="edit" element={<FeatureFlagEdit />} />
            </Route>
          </Route>
          <Route path="groups" element={<Outlet />}>
            <Route element={<GroupList />} index />
            <Route path=":groupId" element={<Outlet />}>
              <Route element={<GroupShow />} index />
              <Route path="items" element={<Outlet />}>
                <Route element={<GroupItemLookup />} index />
                <Route path=":groupItemId" element={<GroupItemShow />} />
              </Route>
            </Route>
          </Route>
          <Route path="group_notes" element={<Outlet />}>
            <Route element={<GroupNoteList />} index />
          </Route>
          <Route path="identities" element={<Outlet />}>
            <Route element={<IdentityList />} index />
            <Route path=":identityId" element={<IdentityShow />} />
          </Route>
          <Route path="ingest_tasks" element={<Outlet />}>
            <Route path=":ingestTaskId" element={<IngestTaskShow />} />
          </Route>
          <Route path="internal_listing_buckets" element={<Outlet />}>
            <Route element={<InternalListingBucketList />} index />
            <Route path="new" element={<InternalListingBucketNew />} />
            <Route path=":internalListingBucketId" element={<Outlet />}>
              <Route element={<InternalListingBucketShow />} index />
              <Route path="edit" element={<InternalListingBucketEdit />} />
            </Route>
          </Route>
          <Route path="leads" element={<Outlet />}>
            <Route element={<LeadList />} index />
            <Route path=":leadId" element={<LeadShow />} />
          </Route>
          <Route path="lenders" element={<Outlet />}>
            <Route element={<LenderList />} index />
            <Route path="new" element={<LenderNew />} />
            <Route path="new-test" element={<LenderNewTest />} />
            <Route path=":lenderId" element={<LenderShow />} />
          </Route>
          <Route path="listings" element={<Outlet />}>
            <Route element={<ListingLookup />} index />
            <Route path=":listingId" element={<Outlet />}>
              <Route element={<ListingShow />} index />
              <Route path="geocode" element={<ListingGeocode />} />
            </Route>
          </Route>
          <Route path="mlses" element={<Outlet />}>
            <Route element={<MlsList />} index />
            <Route path=":mlsId" element={<Outlet />}>
              <Route element={<MlsShow />} index />
              <Route path="listing_input" element={<ListingInputShow />} />
            </Route>
          </Route>
          <Route path="offices" element={<Outlet />}>
            <Route element={<OfficeList />} index />
            <Route path=":officeId" element={<OfficeShow />} />
          </Route>
          <Route path="product_offerings" element={<Outlet />}>
            <Route element={<ProductOfferingList />} index />
            <Route
              path=":productOfferingId"
              element={<ProductOfferingShow />}
            />
          </Route>
          <Route path="products" element={<Outlet />}>
            <Route element={<ProductList />} index />
            <Route path=":productId" element={<ProductShow />} />
          </Route>
          <Route path="referrals" element={<Outlet />}>
            <Route element={<ReferralList />} index />
            <Route path="new" element={<ReferralNew />} />
          </Route>
          <Route path="reprocess_data_tasks" element={<Outlet />}>
            <Route element={<ReprocessDataTaskList />} index />
            <Route
              path=":reprocessDataTaskId"
              element={<ReprocessDataTaskShow />}
            />
          </Route>
          <Route path="resource_mapping" element={<Outlet />}>
            <Route element={<RMIndex />} index />
            <Route path="mlses" element={<Outlet />}>
              <Route path=":mlsId" element={<RMMls />} />
            </Route>
            <Route path="roots" element={<Outlet />}>
              <Route
                path=":mlsResourceMappingId"
                element={<RMRootResource />}
              />
            </Route>
            <Route path="fields" element={<Outlet />}>
              <Route path=":fieldId" element={<RMField />} />
            </Route>
            <Route path="mappings" element={<Outlet />}>
              <Route path=":mappingId" element={<RMMapping />} />
            </Route>
            <Route path="normalizations" element={<Outlet />}>
              <Route path=":normalizationId" element={<RMNormalization />} />
            </Route>
            <Route path="problems" element={<Outlet />}>
              <Route element={<Navigate to="/resource_mapping" />} index />
              <Route path=":problemId" element={<RMProblem />} />
            </Route>
          </Route>
          <Route path="saved_filters" element={<Outlet />}>
            <Route element={<Navigate to="/" />} index />
            <Route path=":savedFilterId" element={<SavedFilterShow />} />
          </Route>
          <Route path="source" element={<Outlet />}>
            <Route element={<Navigate to="/" />} index />
            <Route path=":entityId" element={<Outlet />}>
              <Route element={<SourceDataEntityShow />} index />
              <Route path=":versionId" element={<SourceDataVersionShow />} />
            </Route>
          </Route>
          <Route path="subetha/mails" element={<Outlet />}>
            <Route element={<Navigate to="/" />} index />
            <Route path=":mailId" element={<MailShow />} />
          </Route>
          <Route path="subetha/recipients" element={<Outlet />}>
            <Route element={<RecipientList />} index />
            <Route path=":recipientId" element={<RecipientShow />} />
          </Route>
          <Route path="subetha/events" element={<Outlet />}>
            <Route element={<Navigate to="/" />} index />
            <Route path=":eventId" element={<EventShow />} />
          </Route>
          <Route path="tours" element={<Outlet />}>
            <Route element={<Navigate to="/" />} index />
            <Route path=":tourId" element={<TourShow />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
