import {
  IdentificationIcon,
  BuildingLibraryIcon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { OfficeQuery } from "../../graphql/generated";
import OfficeActions from "./actions";
import OfficePic from "./pic";

const OfficeTitle: React.FC<{ office?: OfficeQuery["office"] }> = ({
  office,
}) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <OfficePic office={office} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {office?.name?.trim() === "" ? "Empty Name" : office?.name} -{" "}
              {office?.address?.city}, {office?.address?.state}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <IdentificationIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {office?.mlsId}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <BuildingLibraryIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {office?.mls?.name}
              </div>
            </div>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                  aria-hidden="true"
                />
                {office?.address?.address1}, {office?.address?.city},{" "}
                {office?.address?.zipCode} {office?.address?.state}
              </div>
              {office?.phone && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <PhoneIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                  <a href={`tel:${office.phone}`}>{office.phone}</a>
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <OfficeActions office={office} />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeTitle;
