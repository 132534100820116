import React from "react";
import { RmFieldLookupValueFragment } from "../../../graphql/generated";

type LookupValueSelectorSuggestion = {
  value: string;
  definition: string;
  coefficient: number;
};

const LookupValueSelector: React.FC<{
  value: string | null;
  options: RmFieldLookupValueFragment[];
  suggestions?: LookupValueSelectorSuggestion[];
  disabled?: boolean;
  setValue: (newValue: string | null) => void;
}> = ({ value, options, suggestions, disabled, setValue }) => {
  const innerValue = value === null || value === "" ? "special:null" : value;

  const onChange = React.useCallback(
    (newValue: string) => {
      if (newValue === "special:null") {
        setValue(null);
      } else {
        setValue(newValue);
      }
    },
    [setValue]
  );

  return (
    <select
      value={innerValue}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    >
      {suggestions &&
        suggestions.map((suggestion) => {
          return (
            <option
              value={suggestion.value}
              key={`special:suggestion-{suggestion.value}`}
              title={suggestion.definition}
            >
              {suggestion.value} (
              {suggestion.coefficient.toLocaleString(undefined, {
                style: "percent",
                maximumFractionDigits: 1,
              })}
              )
            </option>
          );
        })}
      {suggestions && (
        <option value="" disabled key="suggestions-separator">
          —
        </option>
      )}
      <option value="special:null" key="special:null">
        ignore
      </option>
      <option value="" disabled key="separator">
        —
      </option>
      {options.map((option) => {
        return (
          <option
            value={option.value}
            key={option.value}
            title={option.definition}
          >
            {option.value}
          </option>
        );
      })}
    </select>
  );
};

export default LookupValueSelector;
