import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Panel from "../../../common/components/panel";
import { SubethaEventQuery } from "../../../graphql/generated";

const EventData: React.FC<{ event?: SubethaEventQuery["event"] }> = ({
  event,
}) => {
  return (
    <Panel>
      <Panel.Title>Template Data</Panel.Title>
      <Panel.Body fullWidth constrainHeight>
        <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers>
          {JSON.stringify(event?.sesPayload, null, 2)}
        </SyntaxHighlighter>
      </Panel.Body>
    </Panel>
  );
};

export default EventData;
