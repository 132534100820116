import React from "react";
import { Link } from "react-router-dom";
import { Maybe } from "../../graphql/generated";
import DescriptionListContainer from "./descriptionlistcontainer";

const DescriptionListItem: React.FC<{
  title: string;
  value?: Maybe<string> | number;
  span?: number;
  link?: string;
  a?: string;
  onClick?: () => void;
  info?: string;
}> = ({ title, value, span, link, onClick, a, info }) => {
  return (
    <DescriptionListContainer title={title} span={span} info={info}>
      {link && (
        <Link to={link} className="text-blue-900 hover:underline">
          {value}
        </Link>
      )}
      {!!a && (
        <a
          href={a}
          className="hover:underline text-blue-900"
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      )}
      {onClick && (
        <div
          onClick={onClick}
          className="hover:underline text-blue-900 cursor-pointer"
        >
          {value}
        </div>
      )}
      {!link && !onClick && !a && value}
    </DescriptionListContainer>
  );
};

export default DescriptionListItem;
