import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import Timestamp from "../../common/components/timestamp";
import { ListingFragment } from "../../graphql/generated";

const ListingGeolocation: React.FC<{ listing?: ListingFragment }> = ({
  listing,
}) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListContainer title="Geolocated at">
            <Timestamp
              timestamp={listing?.property?.geolocatedAt}
              format="short"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Source"
            value={listing?.property?.geolocationSource}
          />
          <DescriptionListItem
            title="Strategy"
            value={listing?.property?.geolocationStrategy}
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default ListingGeolocation;
