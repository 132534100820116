import React from "react";

export enum NotificationType {
  Success,
  Error,
}

interface INotification {
  notification: string;
  notificationType: NotificationType;
}

interface INotificationContext {
  updateNotification: (notification: INotification) => void;
  clearNotification: () => void;
  currentNotification?: INotification;
}

export const NotificationContext = React.createContext<INotificationContext>({
  updateNotification: () => null,
  clearNotification: () => null,
});

const NotificationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [notification, setNotification] = React.useState<INotification>();

  const clearNotification = React.useCallback(
    () => setNotification(undefined),
    []
  );

  const updateNotification = React.useCallback(
    (notification: INotification) => setNotification(notification),
    []
  );

  return (
    <NotificationContext.Provider
      value={{
        currentNotification: notification,
        clearNotification,
        updateNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
