import { Transition } from "@headlessui/react";
import React from "react";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useEnterpriseAgreementsQuery } from "../../graphql/generated";
import EnterpriseAgreementActions from "../components/actions";
import EnterpriseAgreementCard from "../components/card";
import AgreementStats from "../components/stats";
import { useTitle } from "../../common/utils/title";

const EnterpriseAgreementList: React.FC = () => {
  useTitle("Enterprise Agreements");
  const [{ data, error, fetching }] = useEnterpriseAgreementsQuery({});

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Enterprise Agreements
                <span className="text-sm text-gray-500 px-2">
                  {fetching
                    ? "Loading..."
                    : `${data?.enterpriseAgreements.length}`}
                </span>
              </h2>
            </div>
            <div className="mt-5 flex sm:mt-0 sm:mr-4">
              <EnterpriseAgreementActions />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          {data?.enterpriseAgreements && (
            <AgreementStats agreements={data?.enterpriseAgreements} />
          )}
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {data?.enterpriseAgreements.map((agreement) => (
                  <EnterpriseAgreementCard
                    agreement={agreement}
                    key={agreement.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default EnterpriseAgreementList;
