import React from "react";
import {
  CanbyFilterDateRangeInput,
  CanbyFilterInput,
  CanbyFilterKeywordMatchInput,
  CanbyFilterNumberRangeInput,
  CanbyFilterTimestampRangeInput,
} from "../../graphql/generated";

type ExistsKey = Exclude<
  keyof CanbyFilterInput,
  "keywordMatch" | "numberRange" | "dateRange" | "timestampRange"
>;

export class Filter {
  filter: CanbyFilterInput;
  setFilter: (filter: CanbyFilterInput) => void;

  constructor(
    filter: CanbyFilterInput,
    setFilter: (filter: CanbyFilterInput) => void
  ) {
    this.filter = filter;
    this.setFilter = setFilter;
  }

  static useState(): Filter {
    const [f, setF] = React.useState({});
    return new Filter(f, setF);
  }

  setExists(field: string) {
    this.setExistsHelper("exists", "notExists", field);
  }

  clearExists(field: string) {
    this.clearExistsHelper("exists", field);
  }

  hasExists(field: string): boolean {
    return this.hasExistsHelper("notExists", field);
  }

  setNotExists(field: string) {
    this.setExistsHelper("notExists", "exists", field);
  }

  clearNotExists(field: string) {
    this.clearExistsHelper("notExists", field);
  }

  hasNotExists(field: string): boolean {
    return this.hasExistsHelper("notExists", field);
  }

  setBooleanExists(field: string) {
    this.setExistsHelper("booleanExists", "booleanNotExists", field);
  }

  clearBooleanExists(field: string) {
    this.clearExistsHelper("booleanExists", field);
  }

  hasBooleanExists(field: string): boolean {
    return this.hasExistsHelper("booleanExists", field);
  }

  setBooleanNotExists(field: string) {
    this.setExistsHelper("booleanNotExists", "booleanExists", field);
  }

  clearBooleanNotExists(field: string) {
    this.clearExistsHelper("booleanNotExists", field);
  }

  hasBooleanNotExists(field: string): boolean {
    return this.hasExistsHelper("booleanNotExists", field);
  }

  setDateExists(field: string) {
    this.setExistsHelper("dateExists", "dateNotExists", field);
  }

  clearDateExists(field: string) {
    this.clearExistsHelper("dateExists", field);
  }

  hasDateExists(field: string): boolean {
    return this.hasExistsHelper("dateExists", field);
  }

  setDateNotExists(field: string) {
    this.setExistsHelper("dateNotExists", "dateExists", field);
  }

  clearDateNotExists(field: string) {
    this.clearExistsHelper("dateNotExists", field);
  }

  hasDateNotExists(field: string): boolean {
    return this.hasExistsHelper("dateNotExists", field);
  }

  setTimestampExists(field: string) {
    this.setExistsHelper("timestampExists", "timestampNotExists", field);
  }

  clearTimestampExists(field: string) {
    this.clearExistsHelper("timestampExists", field);
  }

  hasTimestampExists(field: string): boolean {
    return this.hasExistsHelper("timestampExists", field);
  }

  setTimestampNotExists(field: string) {
    this.setExistsHelper("timestampNotExists", "timestampExists", field);
  }

  clearTimestampNotExists(field: string) {
    this.clearExistsHelper("timestampNotExists", field);
  }

  hasTimestampNotExists(field: string): boolean {
    return this.hasExistsHelper("timestampNotExists", field);
  }

  setNumberExists(field: string) {
    this.setExistsHelper("numberExists", "numberNotExists", field);
  }

  clearNumberExists(field: string) {
    this.clearExistsHelper("numberExists", field);
  }

  hasNumberExists(field: string): boolean {
    return this.hasExistsHelper("numberExists", field);
  }

  setNumberNotExists(field: string) {
    this.setExistsHelper("numberNotExists", "numberExists", field);
  }

  clearNumberNotExists(field: string) {
    this.clearExistsHelper("numberNotExists", field);
  }

  hasNumberNotExists(field: string): boolean {
    return this.hasExistsHelper("numberNotExists", field);
  }

  setKeywordExists(field: string) {
    this.setExistsHelper("keywordExists", "keywordNotExists", field);
  }

  clearKeywordExists(field: string) {
    this.clearExistsHelper("keywordExists", field);
  }

  hasKeywordExists(field: string): boolean {
    return this.hasExistsHelper("keywordExists", field);
  }

  setKeywordNotExists(field: string) {
    this.setExistsHelper("keywordNotExists", "keywordExists", field);
  }

  clearKeywordNotExists(field: string) {
    this.clearExistsHelper("keywordNotExists", field);
  }

  hasKeywordNotExists(field: string): boolean {
    return this.hasExistsHelper("keywordNotExists", field);
  }

  setObjectExists(field: string) {
    this.setExistsHelper("objectExists", "objectNotExists", field);
  }

  clearObjectExists(field: string) {
    this.clearExistsHelper("objectExists", field);
  }

  hasObjectExists(field: string): boolean {
    return this.hasExistsHelper("objectExists", field);
  }

  setObjectNotExists(field: string) {
    this.setExistsHelper("objectNotExists", "objectExists", field);
  }

  clearObjectNotExists(field: string) {
    this.clearExistsHelper("objectNotExists", field);
  }

  hasObjectNotExists(field: string): boolean {
    return this.hasExistsHelper("objectNotExists", field);
  }

  setListExists(field: string) {
    this.setExistsHelper("listExists", "listNotExists", field);
  }

  clearListExists(field: string) {
    this.clearExistsHelper("listExists", field);
  }

  hasListExists(field: string): boolean {
    return this.hasExistsHelper("listExists", field);
  }

  setListNotExists(field: string) {
    this.setExistsHelper("listNotExists", "listExists", field);
  }

  clearListNotExists(field: string) {
    this.clearExistsHelper("listNotExists", field);
  }

  hasListNotExists(field: string): boolean {
    return this.hasExistsHelper("listNotExists", field);
  }

  setKeywordMatch(match: CanbyFilterKeywordMatchInput) {
    const keywordMatch =
      this.filter.keywordMatch?.filter((item) => item.field !== match.field) ??
      [];
    keywordMatch.push(match);
    this.setFilter({
      ...this.filter,
      keywordMatch,
    });
  }

  clearKeywordMatch(field: string) {
    if (this.filter.keywordMatch?.some((item) => item.field === field)) {
      this.setFilter({
        ...this.filter,
        keywordMatch: this.filter.keywordMatch?.filter(
          (item) => item.field !== field
        ),
      });
    }
  }

  getKeywordMatch(field: string): CanbyFilterKeywordMatchInput | undefined {
    return this.filter.keywordMatch?.find((item) => item.field === field);
  }

  setNumberRange(match: CanbyFilterNumberRangeInput) {
    const numberRange =
      this.filter.numberRange?.filter((item) => item.field !== match.field) ??
      [];
    numberRange.push(match);
    this.setFilter({
      ...this.filter,
      numberRange,
    });
  }

  clearNumberRange(field: string) {
    if (this.filter.numberRange?.some((item) => item.field === field)) {
      this.setFilter({
        ...this.filter,
        numberRange: this.filter.numberRange?.filter(
          (item) => item.field !== field
        ),
      });
    }
  }

  getNumberRange(field: string): CanbyFilterNumberRangeInput | undefined {
    return this.filter.numberRange?.find((item) => item.field === field);
  }

  setDateRange(match: CanbyFilterDateRangeInput) {
    const dateRange =
      this.filter.dateRange?.filter((item) => item.field !== match.field) ?? [];
    dateRange.push(match);
    this.setFilter({
      ...this.filter,
      dateRange,
    });
  }

  clearDateRange(field: string) {
    if (this.filter.dateRange?.some((item) => item.field === field)) {
      this.setFilter({
        ...this.filter,
        dateRange: this.filter.dateRange?.filter(
          (item) => item.field !== field
        ),
      });
    }
  }

  getDateRange(field: string): CanbyFilterDateRangeInput | undefined {
    return this.filter.dateRange?.find((item) => item.field === field);
  }

  setTimestampRange(match: CanbyFilterTimestampRangeInput) {
    const timestampRange =
      this.filter.timestampRange?.filter(
        (item) => item.field !== match.field
      ) ?? [];
    timestampRange.push(match);
    this.setFilter({
      ...this.filter,
      timestampRange,
    });
  }

  clearTimestampRange(field: string) {
    if (this.filter.timestampRange?.some((item) => item.field === field)) {
      this.setFilter({
        ...this.filter,
        timestampRange: this.filter.timestampRange?.filter(
          (item) => item.field !== field
        ),
      });
    }
  }

  getTimestampRange(field: string): CanbyFilterTimestampRangeInput | undefined {
    return this.filter.timestampRange?.find((item) => item.field === field);
  }

  setExistsHelper(key: ExistsKey, oppositeKey: ExistsKey, field: string) {
    if (
      !this.filter[key]?.includes(field) ||
      this.filter[oppositeKey]?.includes(field)
    ) {
      this.setFilter({
        ...this.filter,
        [key]: [...(this.filter[key] ?? []), field],
        [oppositeKey]: this.filter[oppositeKey]?.filter(
          (item) => item != field
        ),
      });
    }
  }

  clearExistsHelper(key: ExistsKey, field: string) {
    if (this.filter[key]?.includes(field)) {
      this.setFilter({
        ...this.filter,
        [key]: this.filter[key]?.filter((item) => item != field),
      });
    }
  }

  hasExistsHelper(key: ExistsKey, field: string): boolean {
    return this.filter[key]?.includes(field) ?? false;
  }
}
