import { Transition } from "@headlessui/react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecipientLookupQuery } from "../../../graphql/generated";
import { useTitle } from "../../../common/utils/title";

const RecipientList: React.FC = () => {
  useTitle("Recipients");
  const [emailForm, setEmailForm] = React.useState("");
  const [email, setEmail] = React.useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(
    () => setEmailForm(searchParams.get("email") ?? ""),
    [searchParams]
  );

  const [{ data, fetching }] = useRecipientLookupQuery({
    variables: {
      email: email!,
    },
    pause: !email,
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> =
    React.useCallback(
      (event) => {
        event.preventDefault();
        setEmail(emailForm);
      },
      [emailForm]
    );

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (event) => {
        searchParams.set("email", event?.target.value);
        setSearchParams(searchParams);
      },
      [searchParams, setSearchParams]
    );

  React.useEffect(() => {
    if (data?.recipientLookup) {
      navigate(`/subetha/recipients/${data.recipientLookup.id}`);
    }
  }, [data, navigate]);

  return (
    <Transition
      show={true}
      appear={true}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className=" px-4 sm:px-6 md:px-8 py-4">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="flex-1 min-w-0">
                <form className="space-y-8" onSubmit={handleSubmit}>
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Lookup Email Address
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Search for an email address active or inactive.
                    </p>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Email Address
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          autoComplete="email"
                          value={emailForm}
                          onChange={onEmailChange}
                          className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        />
                        {fetching && (
                          <span className="text-sm text-gray-500">
                            Searching...
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default RecipientList;
