import React from "react";
import { ZenDialog } from "../../common/components/zen-dialog";
import { CodeBracketIcon } from "@heroicons/react/24/outline";

// According to GraphQL, a task's filter is of type Json. That gets converted to
// `any` by codegen. So we really do want the `any` type here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterJson = any;

const EditFilterDialog: React.FC<{
  isOpen: boolean;
  filter?: FilterJson;
  onClose: () => void;
  onSubmit: (filter?: FilterJson) => void;
}> = ({ isOpen, filter, onClose, onSubmit }) => {
  const [editableFilter, setEditableFilter] = React.useState(
    filter ? JSON.stringify(filter, undefined, 2) : ""
  );
  let isValidJson;
  try {
    JSON.parse(editableFilter);
    isValidJson = true;
  } catch (err) {
    isValidJson = editableFilter.trim() === "";
  }
  const inputRef = React.useRef(null);

  const handleChange = (e: { target: { value: string } }) => {
    setEditableFilter(e.target.value);
  };

  const handleSubmit = React.useCallback(() => {
    let filter;
    try {
      filter = JSON.parse(editableFilter);
    } catch (err) {
      filter = undefined;
    }
    onSubmit(filter);
  }, [onSubmit, editableFilter]);

  return (
    <ZenDialog
      show={isOpen}
      title="Update filter"
      icon={CodeBracketIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="mt-1">
        <textarea
          ref={inputRef}
          rows={editableFilter.split("\n").length + 2}
          name="name"
          id="name"
          value={editableFilter}
          onChange={handleChange}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md font-mono"
        />
      </div>
      {!isValidJson && <div className="mt-1 text-sm">JSON is not valid</div>}
    </ZenDialog>
  );
};

export default EditFilterDialog;
