import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import {
  ExternalCommunicationState,
  MlsFragment,
} from "../../graphql/generated";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Panel from "../../common/components/panel";
import { Status, StatusIndicator } from "../../common/components/status";

const MlsSummary: React.FC<{ mls?: MlsFragment }> = ({ mls }) => {
  const status = Status.for(mls);
  function bool(bool: boolean | undefined, t: string, f: string): string {
    if (bool === undefined) {
      return "—";
    } else if (bool) {
      return t;
    } else {
      return f;
    }
  }
  let externalCommunication = "—";
  if (mls) {
    switch (mls.externalCommunication) {
      case ExternalCommunicationState.Allowed:
        externalCommunication = "Allowed";
        break;
      case ExternalCommunicationState.Restricted:
        externalCommunication = "Restricted";
        break;
    }
  }

  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
          <DescriptionListContainer
            title="Allow onboarding"
            info="Whether agents are allowed to find this MLS in the onboarding screens and onboard themselves."
          >
            <StatusIndicator statusColor={status.color} />{" "}
            {bool(mls?.allowOnboarding, "Yes", "No")}
          </DescriptionListContainer>
          <DescriptionListItem title="Name" value={mls?.name} />
          <DescriptionListItem title="Short name" value={mls?.shortName} />
          <DescriptionListItem
            title="Market location"
            value={mls?.marketLocation}
          />
          <DescriptionListItem title="Order" value={mls?.order} />
          <DescriptionListItem
            title="Signup / Enabled"
            info="Whether all agents and offices in this mls are enabled for signup"
            value={bool(mls?.enabled, "Enabled", "Disabled")}
          />
          <DescriptionListItem
            title="External communication"
            info="Whether we send 'somebody viewed/liked/requested a tour on your listing' emails to agents to encourage them to join Zenlist. This field can be set on the MLS to inherit down to all offices and agents, and can be overridden at the office or agent level."
            value={externalCommunication}
          />

          <DescriptionListItem
            title="My Listings"
            info="Whether the 'My Listings' feature is enabled for this MLS."
            value={bool(mls?.isMyListingsEnabled, "Enabled", "Disabled")}
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default MlsSummary;
