import React from "react";
import { Link } from "react-router-dom";
import { shortDateFormat } from "../../common/utils/dateformat";
import { SavedFilterFragment } from "../../graphql/generated";
import SavedFilterPic from "./savedfilterpic";

const SavedFilterCard: React.FC<{
  filter?: SavedFilterFragment;
}> = ({ filter }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <SavedFilterPic filter={filter} />
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/saved_filters/${filter?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {filter?.name}
          </p>
          <p className="text-sm text-gray-500 truncate">
            {shortDateFormat(filter?.createdAt)}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default SavedFilterCard;
