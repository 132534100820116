import React from "react";
import { ProductOfferingTierFragment } from "../../graphql/generated";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import StripeProductPriceCard from "./stripeproductprice";
import AppStoreProductCard from "./appstoreproduct";

const TierCard: React.FC<{
  tier?: ProductOfferingTierFragment;
}> = ({ tier }) => {
  return (
    <>
      <div className="rounded-lg border border-gray-200 bg-white px-6 py-3 shadow-sm">
        <div className="relative bg-white px-6 py-3 flex items-center space-x-3">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-5">
            <DescriptionListItem
              title="Relative Order"
              info="The relative order, from least permissive to most permissive, of tiers, as represented by a number"
              value={tier?.relativeOrder}
            />
            <DescriptionListItem
              title="Bubble Text"
              info="Optional text that appears in a bubble above the tier name."
              value={tier?.shortDocumentCustomization.bubbleText}
            />
            <DescriptionListItem
              title="Subtitle Text"
              info="Optional text that appears below the tier name."
              value={tier?.shortDocumentCustomization.subtitleText}
            />
            <DescriptionListItem
              title="Custom Short List"
              info="Has the short list of features been customized?"
              value={
                tier?.shortDocumentCustomization.hasCustomRows == true
                  ? "Yes"
                  : "No"
              }
            />
            <DescriptionListItem
              title="Custom Long List"
              info="Has the long list of features been customized?"
              value={tier?.hasCustomizedLongDocumentRows == true ? "Yes" : "No"}
            />
          </dl>
        </div>
        <div>
          {tier?.stripeProduct && (
            <div className="relative rounded-lg border border-gray-100 bg-white px-6 shadow-sm flex items-center space-x-3">
              <p className="text-sm">Stripe Product</p>
              <StripeProductPriceCard stripeproductprice={tier.stripeProduct} />
            </div>
          )}
          {tier?.appStoreProduct && (
            <div className="relative rounded-lg border border-gray-100 bg-white px-6 shadow-sm flex items-center space-x-3">
              <p className="text-sm">AppStore Product</p>
              <AppStoreProductCard product={tier.appStoreProduct} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TierCard;
