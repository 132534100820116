import React from "react";

const CacheSafeAtPill: React.FC<{
  cacheSafeAt: string | null | undefined;
}> = ({ cacheSafeAt }) => {
  if (!cacheSafeAt) {
    return <></>;
  }

  let timestamp: Date;
  try {
    timestamp = new Date(Date.parse(cacheSafeAt));
  } catch {
    return <></>;
  }

  const [remaining, setRemaining] = React.useState<number | undefined>(
    calculateRemaining(timestamp)
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      const remaining = calculateRemaining(timestamp);
      setRemaining(remaining);
      if (remaining === undefined) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timestamp, setRemaining]);

  if (!remaining) {
    return <></>;
  }

  const remainingMinutes = Math.floor(remaining / 60);
  const remainingSeconds = remaining % 60;
  const remainingText = `${remainingMinutes.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${remainingSeconds.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}`;

  return (
    <span className="ml-1 bg-amber-600 text-white text-xs px-1 py-0.5 rounded-md">
      Cached for {remainingText}
    </span>
  );
};

function calculateRemaining(timestamp: Date): number | undefined {
  const now = new Date();
  const remainingMs = timestamp.valueOf() - now.valueOf();
  const remainingS = Math.floor(remainingMs / 1000);
  if (remainingS >= 0) {
    return remainingS;
  } else {
    return undefined;
  }
}

export default CacheSafeAtPill;
