import React from "react";
import AccountProfilePic from "../../../account/components/profilepic";
import { longDateFormat } from "../../../common/utils/dateformat";
import { MessageFragment } from "../../../graphql/generated";

const MessageCard: React.FC<{ message?: MessageFragment }> = ({ message }) => {
  return (
    <li key={message?.id} className="py-4">
      <div className="flex space-x-3">
        <AccountProfilePic account={message?.participant?.account} small />
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">
              {message?.participant?.account?.name}
            </h3>
            <p className="text-sm text-gray-500">
              {longDateFormat(message?.updatedAt)}
            </p>
          </div>
          {message?.messageBody && (
            <p className="text-sm text-gray-500">{message.messageBody}</p>
          )}
          {message?.listing && (
            <p className="text-sm text-gray-500">
              <a
                href={`https://zenlist.com/app/listing/${message.listing.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`https://hue.zenlist.com/listing/${message.listing.id}.jpg?w=800`}
                  className="h-40 rounded-sm"
                  alt="listing"
                />
              </a>
            </p>
          )}
        </div>
      </div>
    </li>
  );
};

export default MessageCard;
