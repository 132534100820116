import React from "react";
import { useClient } from "urql";
import { MlsContext } from "../../mls/context/mls";
import { SearchContext } from "../../common/context/search";
import { classNames } from "../../common/utils/classnames";
import { OfficesDocument, useOfficesQuery } from "../../graphql/generated";
import OfficeCard from "../components/card";
import Loading from "../../common/components/loading";
import { Transition } from "@headlessui/react";
import Error from "../../common/components/error";
import CommonSwitch from "../../common/components/switch";
import { useTitle } from "../../common/utils/title";

const OfficeList: React.FC = () => {
  useTitle("Offices");
  const graphqlClient = useClient();
  const [loading, setLoading] = React.useState(false);
  const { searchBounced } = React.useContext(SearchContext);
  const { mls } = React.useContext(MlsContext);
  const [includeChildOffices, setIncludeChildOffices] = React.useState(true);
  const [includeHidden, setIncludeHidden] = React.useState(false);

  const [{ data, error, fetching }] = useOfficesQuery({
    variables: {
      first: 20,
      search: searchBounced,
      mlsId: mls?.id,
      includeChildOffices,
      includeHidden,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.offices.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(OfficesDocument, {
          first: 20,
          after: data.offices.pageInfo.endCursor,
          search: searchBounced,
          mlsId: mls?.id,
          includeChildOffices,
          includeHidden,
        })
        .toPromise();
      setLoading(false);
    }
  }, [
    data,
    graphqlClient,
    mls,
    searchBounced,
    includeChildOffices,
    includeHidden,
  ]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Offices
                <span className="text-sm text-gray-500 px-2">
                  {fetching
                    ? "Loading..."
                    : `${data?.offices.total.toLocaleString()} in ${
                        mls?.shortName ?? "All"
                      }`}
                </span>
              </h2>
              <div className="mt-1 grid grid-cols-1 gap-0">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CommonSwitch
                    enabled={includeChildOffices}
                    toggle={() => setIncludeChildOffices(!includeChildOffices)}
                    label="Include child offices"
                  />
                  <CommonSwitch
                    enabled={includeHidden}
                    toggle={() => setIncludeHidden(!includeHidden)}
                    label="Include hidden offices"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {data?.offices.edges?.map((office) => (
                  <OfficeCard office={office?.node} key={office?.node.id} />
                ))}
              </div>
            </div>
          </div>
          {data && data?.offices.total > (data?.offices.edges?.length ?? 0) && (
            <div className="min-w-full divide-y divide-gray-200">
              <div className="p-4">
                <button
                  className={classNames(
                    loading
                      ? "bg-gray-500 text-white"
                      : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                    "font-bold py-2 px-4 rounded"
                  )}
                  onClick={() => fetchMore()}
                  disabled={loading}
                >
                  Fetch More
                </button>
              </div>
            </div>
          )}
        </div>
      </Transition>
    </>
  );
};

export default OfficeList;
