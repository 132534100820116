import { Transition } from "@headlessui/react";
import React from "react";
import { useClient } from "urql";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import CommonSwitch from "../../common/components/switch";
import { SearchContext } from "../../common/context/search";
import { classNames } from "../../common/utils/classnames";
import {
  GroupsDocument,
  GroupState,
  useGroupsQuery,
} from "../../graphql/generated";
import GroupCard from "../components/card";
import { useTitle } from "../../common/utils/title";

const GroupList: React.FC = () => {
  useTitle("Groups");
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const { searchBounced, groupStates, groupStatesDispatch } =
    React.useContext(SearchContext);

  const [{ data, error, fetching }] = useGroupsQuery({
    variables: {
      first: 20,
      states: groupStates,
      search: searchBounced,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.groups.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(GroupsDocument, {
          first: 20,
          after: data.groups.pageInfo.endCursor,
          states: groupStates,
          search: searchBounced,
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, groupStates, searchBounced]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Groups
                <span className="text-sm text-gray-500 px-2">
                  {fetching ? "Loading..." : `Total: ${data?.groups.total}`}
                </span>
              </h2>
              <div className="mt-1 grid grid-cols-1 gap-0">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="w-16">State:</span>
                  <CommonSwitch
                    enabled={groupStates.indexOf(GroupState.Personal) > -1}
                    toggle={() =>
                      groupStatesDispatch({ state: GroupState.Personal })
                    }
                    label="Personal"
                  />
                  <CommonSwitch
                    enabled={groupStates.indexOf(GroupState.Active) > -1}
                    toggle={() =>
                      groupStatesDispatch({ state: GroupState.Active })
                    }
                    label="Active"
                  />
                  <CommonSwitch
                    enabled={groupStates.indexOf(GroupState.Pending) > -1}
                    toggle={() =>
                      groupStatesDispatch({ state: GroupState.Pending })
                    }
                    label="Pending"
                  />
                  <CommonSwitch
                    enabled={groupStates.indexOf(GroupState.Archived) > -1}
                    toggle={() =>
                      groupStatesDispatch({ state: GroupState.Archived })
                    }
                    label="Archived"
                  />
                  <CommonSwitch
                    enabled={groupStates.indexOf(GroupState.Deleted) > -1}
                    toggle={() =>
                      groupStatesDispatch({ state: GroupState.Deleted })
                    }
                    label="Deleted"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {data?.groups.edges?.map((group) => (
                  <GroupCard group={group?.node} key={group?.node.id} />
                ))}
              </div>
            </div>
          </div>
          {data && data?.groups.total > (data?.groups.edges?.length ?? 0) && (
            <div className="min-w-full divide-y divide-gray-200">
              <div className="p-4">
                <button
                  className={classNames(
                    loading
                      ? "bg-gray-500 text-white"
                      : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                    "font-bold py-2 px-4 rounded"
                  )}
                  onClick={() => fetchMore()}
                  disabled={loading}
                >
                  Fetch More
                </button>
              </div>
            </div>
          )}
        </div>
      </Transition>
    </>
  );
};

export default GroupList;
