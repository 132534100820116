import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Panel from "../../common/components/panel";
import { ListingFragment } from "../../graphql/generated";

const ListingJson: React.FC<{ listing?: ListingFragment }> = ({ listing }) => {
  return (
    <Panel>
      <Panel.Title>Stored Data</Panel.Title>
      <Panel.Body fullWidth constrainHeight>
        <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers>
          {JSON.stringify(listing?.raw, null, 2)}
        </SyntaxHighlighter>
      </Panel.Body>
    </Panel>
  );
};

export default ListingJson;
