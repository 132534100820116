import React from "react";
import { classNames } from "../../common/utils/classnames";
import InfoPopover from "./infopopover";

const DescriptionListContainer: React.FC<{
  title: string;
  span?: number;
  children?: React.ReactNode;
  info?: string;
}> = ({ title, span, children, info }) => {
  return (
    <div className={classNames(`sm:col-span-${span ?? 1}`)}>
      <dt className="text-sm font-medium text-gray-500">
        {title} {!!info && <InfoPopover content={info} />}
      </dt>
      <dd className="mt-1 text-sm text-gray-900">{children}</dd>
    </div>
  );
};

export default DescriptionListContainer;
