import { Transition } from "@headlessui/react";
import React from "react";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  ReprocessDataTaskStatus,
  RmProblemsForResourceTypeDocument,
  useRmMlsesQuery,
  useRmProblemsForResourceTypeQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import ResourceMappingTitle from "../components/resource-mapping-title";
import Problems from "../components/problems";
import { useClient } from "urql";
import Panel from "../../common/components/panel";
import { Link } from "react-router-dom";
import ReprocessDataTasksPanel from "../components/reprocess-data-tasks-panel";

const RMIndex: React.FC = () => {
  const graphqlClient = useClient();
  const [{ data: mlsesData, error: mlsesError }] = useRmMlsesQuery({
    variables: {},
  });
  const [{ data: problemData, error: problemError }] =
    useRmProblemsForResourceTypeQuery({
      variables: { resourceTypeId: "Property", first: 100 },
    });

  const [loading, setLoading] = React.useState(false);
  const canFetchMore =
    problemData?.rmProblemsForResourceType.pageInfo.hasNextPage ?? false;
  const fetchMore = React.useCallback(async () => {
    if (problemData?.rmProblemsForResourceType.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(RmProblemsForResourceTypeDocument, {
          first: 100,
          after: problemData.rmProblemsForResourceType.pageInfo.endCursor,
          resourceTypeId: "Property",
        })
        .toPromise();
      setLoading(false);
    }
  }, [problemData, graphqlClient]);

  useTitle("Resource Mapping");

  return (
    <>
      <Loading
        show={!problemData && !problemError && !mlsesData && !mlsesError}
      />
      <Error error={problemError ?? mlsesError} />
      <Transition
        show={!!problemData || !!mlsesData}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ResourceMappingTitle name="Resource Mapping">
            Resource Mapping
          </ResourceMappingTitle>

          <Problems
            data={problemData?.rmProblemsForResourceType}
            error={problemError}
            displayMls
            displayResource
            fetch={{
              fetch: fetchMore,
              canFetch: canFetchMore,
              isFetching: loading,
            }}
          />

          <ReprocessDataTasksPanel
            statuses={[
              ReprocessDataTaskStatus.Paused,
              ReprocessDataTaskStatus.Pausing,
              ReprocessDataTaskStatus.Ready,
              ReprocessDataTaskStatus.Running,
              ReprocessDataTaskStatus.WaitingOnCache,
            ]}
          />

          <Panel>
            <Panel.Title>MLSes</Panel.Title>
            <Panel.Body>
              <table>
                <tbody>
                  {mlsesData?.mlses &&
                    mlsesData.mlses.map((mls) => {
                      return (
                        <tr key={mls.id}>
                          <td className="p-1">
                            <Link to={`/resource_mapping/mlses/${mls.id}`}>
                              {mls.shortName}
                            </Link>
                          </td>
                          {mls.propertyMapping && (
                            <td className="p-1">
                              <Link
                                to={`/resource_mapping/roots/${mls.propertyMapping.id}`}
                              >
                                {mls.propertyMapping.name}
                              </Link>
                            </td>
                          )}
                          {!mls.propertyMapping && (
                            <td className="p-1 text-gray-500">&mdash;</td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Panel.Body>
          </Panel>
        </div>
      </Transition>
    </>
  );
};

export default RMIndex;
