import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useInternalListingBucketQuery } from "../../graphql/generated";
import InternalListingBucketTitle from "../components/title";
import InternalListingBucketSummary from "../components/summary";
import InternalListingBucketManualEnrollment from "../components/manualenrollment";
import { useTitle } from "../../common/utils/title";

const InternalListingBucketShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useInternalListingBucketQuery({
    variables: { id: params.internalListingBucketId ?? "" },
    pause: !params.internalListingBucketId,
  });
  useTitle(
    data?.internalListingBucket.name ?? params.internalListingBucketId,
    "Internal Listing Buckets"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <InternalListingBucketTitle bucket={data?.internalListingBucket} />
          <InternalListingBucketSummary bucket={data?.internalListingBucket} />
          <InternalListingBucketManualEnrollment
            bucket={data?.internalListingBucket}
          />
        </div>
      </Transition>
    </>
  );
};

export default InternalListingBucketShow;
