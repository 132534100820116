import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../common/components/loading";
import EnterpriseAgreementAccounts from "../components/accounts";
import { useEnterpriseAgreementQuery } from "../../graphql/generated";
import EnterpriseAgreementAgents from "../components/agents";
import EnterpriseAgreementOffices from "../components/offices";
import EnterpriseAgreementSummary from "../components/summary";
import EnterpriseAgreementTitle from "../components/title";
import EnterpriseAgreementManualEnrollment from "../components/manualenrollment";
import { Transition } from "@headlessui/react";
import Error from "../../common/components/error";
import { useTitle } from "../../common/utils/title";
import EnterpriseAgreementBillingTableEntries from "../components/billingentries";

const EnterpriseAgreementShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useEnterpriseAgreementQuery({
    variables: { id: params.enterpriseAgreementId ?? "" },
    pause: !params.enterpriseAgreementId,
  });
  useTitle(
    data?.enterpriseAgreement.name ?? params.enterpriseAgreementId,
    "Enterprise Agreements"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        {data && (
          <div className="max-w-7xl mx-auto py-4">
            <EnterpriseAgreementTitle agreement={data?.enterpriseAgreement} />
            <EnterpriseAgreementSummary agreement={data?.enterpriseAgreement} />
            {data?.enterpriseAgreement.enterpriseBilling.__typename ===
              "EnterpriseBillingStripeSubscription" && (
              <EnterpriseAgreementBillingTableEntries
                agreement={data?.enterpriseAgreement}
              />
            )}
            <EnterpriseAgreementAccounts
              agreement={data?.enterpriseAgreement}
            />
            <EnterpriseAgreementManualEnrollment
              agreement={data?.enterpriseAgreement}
            />
            <EnterpriseAgreementAgents agreement={data?.enterpriseAgreement} />
            <EnterpriseAgreementOffices agreement={data?.enterpriseAgreement} />
          </div>
        )}
      </Transition>
    </>
  );
};

export default EnterpriseAgreementShow;
