import React from "react";
import {
  RmFieldDetailsFragment,
  useRmFieldAddLocalLookupValueMutation,
} from "../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../common/utils/classnames";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";

const LookupDetailsActions: React.FC<{
  field?: RmFieldDetailsFragment;
  local?: boolean;
}> = ({ field }) => {
  if (!field) {
    return <></>;
  }

  const { updateNotification } = React.useContext(NotificationContext);
  const [addValueDialogIsVisible, setAddValueDialogIsVisible] =
    React.useState(false);

  const [{ fetching: addLookupValueFetching }, addLookupValueMutation] =
    useRmFieldAddLocalLookupValueMutation();

  const addLookupValueCallback = React.useCallback(
    async (value: string) => {
      const { error } = await addLookupValueMutation({
        fieldId: field.id,
        lookupValue: value,
      });

      if (!error) {
        updateNotification({
          notification: `Lookup value added`,
          notificationType: NotificationType.Success,
        });
      } else {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      }
      setAddValueDialogIsVisible(false);
    },
    [field, addLookupValueMutation, setAddValueDialogIsVisible]
  );

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddValueDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add value...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddValueDialog
        show={addValueDialogIsVisible}
        onSubmit={addLookupValueCallback}
        onCancel={() => setAddValueDialogIsVisible(false)}
        fetching={addLookupValueFetching}
      />
    </>
  );
};

const AddValueDialog: React.FC<{
  show?: boolean;
  onSubmit: (value: string) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [value, setValue] = React.useState("");
  const valueId = React.useId();
  const isValid = !!value.length;
  return (
    <ZenDialog
      show={show}
      title="Add lookup value"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit(value)}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <div>
          <label htmlFor={valueId}>Value</label>
        </div>
        <div>
          <input
            id={valueId}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

export default LookupDetailsActions;
