import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import {
  InternalListingBucketFragment,
  useDeleteInternalListingBucketMutation,
} from "../../graphql/generated";
import { DeleteInternalListingBucketDialog } from "./delete-dialog";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";

const InternalListingBucketItemActions: React.FC<{
  bucket?: InternalListingBucketFragment;
}> = ({ bucket }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [deleteIsOpen, setDeleteIsOpen] = React.useState(false);

  const [, deleteBucketMutation] = useDeleteInternalListingBucketMutation();

  const deleteBucket = React.useCallback(async () => {
    const { error } = await deleteBucketMutation({ id: bucket?.id || "" });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Account has been deactivated",
        notificationType: NotificationType.Success,
      });
    }
    setDeleteIsOpen(false);
  }, [bucket?.id, deleteBucketMutation, updateNotification, setDeleteIsOpen]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/internal_listing_buckets/${bucket?.id}/edit`}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Edit...
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setDeleteIsOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <XCircleIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <DeleteInternalListingBucketDialog
        bucket={bucket}
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onSubmit={() => deleteBucket()}
      />
    </>
  );
};

export default InternalListingBucketItemActions;
