import React from "react";
import { useClient } from "urql";
import Panel from "../../common/components/panel";
import { classNames } from "../../common/utils/classnames";
import {
  AccountFragment,
  IdentitiesDocument,
  useIdentitiesQuery,
} from "../../graphql/generated";
import IdentityCard from "../../identity/components/card";

const AccountIdentities: React.FC<{ account?: AccountFragment }> = ({
  account,
}) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();
  const [{ data }] = useIdentitiesQuery({
    variables: { first: 20, accountId: account?.id || "" },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.identities.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(IdentitiesDocument, {
          first: 20,
          after: data.identities.pageInfo.endCursor,
          accountId: account?.id || "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, account]);

  return (
    <Panel>
      <Panel.Title>
        Identities:{" "}
        <span className="text-xs text-gray-500">{data?.identities.total}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.identities.edges?.map((identity) => (
            <IdentityCard identity={identity?.node} key={identity?.node.id} />
          ))}
        </div>
      </Panel.Body>
      {data &&
        data?.identities.total > (data?.identities.edges?.length ?? 0) && (
          <Panel.Footer>
            <button
              className={classNames(
                loading
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetchMore()}
              disabled={loading}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
    </Panel>
  );
};

export default AccountIdentities;
