import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useAgentQuery } from "../../graphql/generated";
import Patches from "../../patch/components/patches";
import { SourceDataHistoryPanel } from "../../source/components/sourcedata";
import AgentSummary from "../components/summary";
import AgentTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const AgentShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useAgentQuery({
    variables: { id: params.agentId ?? "" },
    pause: !params.agentId,
  });
  useTitle(data?.agent.name ?? params.agentId, "Agents");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <AgentTitle agent={data?.agent} />
          <AgentSummary agent={data?.agent} />
          <SourceDataHistoryPanel entityId={data?.agent.sourceDataEntityId} />
          <Patches agentId={data?.agent.id} />
        </div>
      </Transition>
    </>
  );
};

export default AgentShow;
