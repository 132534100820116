import React from "react";
import ClientInvitationCard from "../../client-invitation/components/card";
import Panel from "../../common/components/panel";
import {
  ClientInvitationState,
  GroupFragment,
  useClientInvitationsQuery,
} from "../../graphql/generated";

const GroupClientInvitations: React.FC<{ group?: GroupFragment }> = ({
  group,
}) => {
  const [{ data }] = useClientInvitationsQuery({
    variables: {
      groupId: group?.id ?? "",
      states: [
        ClientInvitationState.Accepted,
        ClientInvitationState.Cancelled,
        ClientInvitationState.Declined,
        ClientInvitationState.Pending,
      ],
    },
  });

  return (
    <Panel>
      <Panel.Title>
        Client Invitations{" "}
        <span className="text-xs text-gray-500">
          {data?.clientInvitations.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.clientInvitations.map((invitation) => (
            <ClientInvitationCard invitation={invitation} key={invitation.id} />
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default GroupClientInvitations;
