import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { TrashIcon } from "@heroicons/react/24/outline";

const AbandonTaskDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isFetching: boolean;
}> = ({ isOpen, onClose, onSubmit, isFetching }) => {
  return (
    <ZenDialog
      show={isOpen}
      title="Abandon task?"
      icon={TrashIcon}
      submit="Abandon"
      onSubmit={() => onSubmit()}
      onCancel={onClose}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <p>
        This will cause the task to be deleted earlier, but it cannot be
        restored.
      </p>
    </ZenDialog>
  );
};

export default AbandonTaskDialog;
