import { ArrowLongRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import AccountProfilePic from "../../account/components/profilepic";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { shortDateFormat } from "../../common/utils/dateformat";
import {
  AccountFragment,
  FeatureFlagFragment,
  useDisableAccountForFeatureFlagMutation,
  useEnableAccountForFeatureFlagMutation,
  useForgetAccountForFeatureFlagMutation,
} from "../../graphql/generated";

const AccountCard: React.FC<{
  account?: AccountFragment;
  flag?: FeatureFlagFragment;
  enabled?: boolean;
}> = ({ account, flag, enabled }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [{ error: enableError, fetching: enableFetching }, enableMutation] =
    useEnableAccountForFeatureFlagMutation();
  const [{ error: disableError, fetching: disableFetching }, disableMutation] =
    useDisableAccountForFeatureFlagMutation();
  const [{ error: forgetError, fetching: forgetFetching }, forgetMutation] =
    useForgetAccountForFeatureFlagMutation();

  const fetching = React.useMemo(
    () => enableFetching || disableFetching || forgetFetching,
    [disableFetching, enableFetching, forgetFetching]
  );

  React.useEffect(() => {
    if (enableError) {
      updateNotification({
        notification: enableError.message,
        notificationType: NotificationType.Error,
      });
    } else if (disableError) {
      updateNotification({
        notification: disableError.message,
        notificationType: NotificationType.Error,
      });
    } else if (forgetError) {
      updateNotification({
        notification: forgetError.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [enableError, disableError, forgetError, updateNotification]);

  const handleMove = React.useCallback(async () => {
    if (!fetching) {
      if (enabled) {
        await disableMutation({
          accountId: account?.id ?? "",
          featureFlagId: flag?.id ?? "",
        });
      } else {
        enableMutation({
          accountId: account?.id ?? "",
          featureFlagId: flag?.id ?? "",
        });
      }
    }
  }, [account, fetching, disableMutation, enableMutation, enabled, flag]);

  const handleForget = React.useCallback(async () => {
    if (!fetching) {
      await forgetMutation({
        accountId: account?.id ?? "",
        featureFlagId: flag?.id ?? "",
      });
    }
  }, [account, fetching, flag, forgetMutation]);

  return (
    <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {account?.name.trim() === "" ? "Empty Name" : account?.name}
            </h3>
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
              {account?.accountType === "AGENT" ? "Agent" : "Member"}
            </span>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">
            {shortDateFormat(account?.createdAt)}
          </p>
        </div>
        <AccountProfilePic account={account} />
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <div
              onClick={handleMove}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer"
            >
              <ArrowLongRightIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="ml-3">
                Send to {enabled ? "Disabled" : "Enabled"}
              </span>
            </div>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <div
              onClick={handleForget}
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 cursor-pointer"
            >
              <XMarkIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Remove</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default AccountCard;
