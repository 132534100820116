import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import Panel from "../../../common/components/panel";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../../common/utils/classnames";
import { State } from ".";
import { ZenDialog } from "../../../common/components/zen-dialog";
import {
  ListingInputDefinitionStatusOverlayInput,
  ListingInputDefinitionStatusColor,
} from "../../../graphql/generated";

export const StatusesPanel: React.FC<{
  state: State;
  setState: (state: State) => void;
  setIsModified: (isModified: boolean) => void;
}> = ({ state, setState, setIsModified }) => {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);

  const onStatusUpdateSubmit = React.useCallback(
    (update: ListingInputDefinitionStatusOverlayInput) => {
      state.statusUpdates.set(update.name, update);
      setState(state);
      setIsModified(true);
    },
    [state, setState, setIsModified]
  );

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const [item] = state.statusOrder.splice(result.source.index, 1);
      state.statusOrder.splice(result.destination.index, 0, item);

      setState(state);
      setIsModified(true);
    },
    [state, setState, setIsModified]
  );

  return (
    <>
      <Panel>
        <Panel.Title>
          <span
            onClick={() => setIsPanelOpen(!isPanelOpen)}
            className="cursor-pointer"
          >
            {isPanelOpen && <ChevronDownIcon className="inline h-5 w-5" />}
            {!isPanelOpen && <ChevronRightIcon className="inline h-5 w-5" />}
            Statuses
          </span>
        </Panel.Title>
        <Panel.Body summary className={classNames(isPanelOpen ? "" : "hidden")}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="grid grid-cols-3 gap-4">
              <div
                className="grid self-stretch"
                style={{ gridTemplateRows: "auto 1fr" }}
              >
                <Droppable droppableId="order">
                  {(provided: DroppableProvided) => (
                    <div
                      className="border border-dashed border-gray-300 self-stretch"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {state.statusOrder.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => (
                            <StatusElement
                              statusName={item}
                              state={state}
                              provided={provided}
                              snapshot={snapshot}
                              onStatusUpdateSubmit={onStatusUpdateSubmit}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </Panel.Body>
      </Panel>
    </>
  );
};

const StatusElement: React.FC<{
  statusName: string;
  state: State;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onStatusUpdateSubmit: (
    status: ListingInputDefinitionStatusOverlayInput
  ) => void;
}> = ({ statusName, state, provided, snapshot, onStatusUpdateSubmit }) => {
  const existingUpdate = state.statusUpdates.get(statusName);
  const status = state.statuses.get(statusName);
  const name =
    existingUpdate?.displayName ??
    status?.overlayDisplayName ??
    status?.sourceDisplayName ??
    statusName;
  const isModified = !!existingUpdate;

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const onSubmit = React.useCallback(
    (status: ListingInputDefinitionStatusOverlayInput) => {
      onStatusUpdateSubmit(status);
      setEditDialogOpen(false);
    },
    [setEditDialogOpen, onStatusUpdateSubmit]
  );

  const color =
    existingUpdate?.color ??
    status?.overlayColor ??
    ListingInputDefinitionStatusColor.Red;
  let cssColor = "#d60015";
  switch (color) {
    case ListingInputDefinitionStatusColor.Red:
      cssColor = "#d60015";
      break;
    case ListingInputDefinitionStatusColor.Yellow:
      cssColor = "#ffc100";
      break;
    case ListingInputDefinitionStatusColor.Green:
      cssColor = "#00c291";
      break;
  }

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="p-1"
      >
        <div
          className={classNames(
            "rounded-2 bg-gray-100 border border-gray-200 p-2 text-sm",
            snapshot.isDragging ? "shadow-md" : "shadow-sm"
          )}
        >
          <div className="flex gap-1">
            <div className="grow">
              <div className="flex mt-1 gap-1">
                <span>
                  <span
                    style={{
                      borderRadius: "100%",
                      background: cssColor,
                      width: "10px",
                      height: "10px",
                      display: "inline-block",
                    }}
                  ></span>{" "}
                  {name}
                </span>
                {isModified && (
                  <span className="text-xs bg-red-200 border border-red-300 px-1 py-0.5">
                    Modified
                  </span>
                )}
              </div>
            </div>
            <div
              className="flex-none cursor-pointer"
              onClick={() => setEditDialogOpen(true)}
            >
              <PencilIcon className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>

      <EditStatusDialog
        isOpen={editDialogOpen}
        statusName={statusName}
        state={state}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

const EditStatusDialog: React.FC<{
  isOpen: boolean;
  statusName: string;
  state: State;
  onClose: () => void;
  onSubmit: (control: ListingInputDefinitionStatusOverlayInput) => void;
}> = ({ isOpen, statusName, state, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);
  const existingUpdate = state.statusUpdates.get(statusName);
  const status = state.statuses.get(statusName);
  const [overlayDisplayName, setOverlayDisplayName] = React.useState(
    existingUpdate?.displayName ?? status?.overlayDisplayName ?? ""
  );
  const [overlayDefinition, setOverlayDefinition] = React.useState(
    existingUpdate?.displayName ?? status?.overlayDefinition ?? ""
  );
  const [overlayColor, setOverlayColor] = React.useState(
    existingUpdate?.color ??
      status?.overlayColor ??
      ListingInputDefinitionStatusColor.Red
  );
  const [useOverlayDisplayName, setUseOverlayDisplayName] = React.useState(
    existingUpdate
      ? !!existingUpdate?.displayName
      : !!status?.overlayDisplayName
  );
  const [useOverlayDefinition, setUseOverlayDefinition] = React.useState(
    existingUpdate ? !!existingUpdate?.definition : !!status?.overlayDefinition
  );

  const onDisplayNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOverlayDisplayName(e.target.value);
      setUseOverlayDisplayName(true);
    },
    [setOverlayDisplayName, setUseOverlayDisplayName]
  );

  const onDefinitionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOverlayDefinition(e.target.value);
      setUseOverlayDefinition(true);
    },
    [setOverlayDefinition, setUseOverlayDefinition]
  );

  const onColorChange = React.useCallback(
    (e: ListingInputDefinitionStatusColor) => {
      setOverlayColor(e);
    },
    [setOverlayColor]
  );

  const handleSubmit = React.useCallback(() => {
    const displayName =
      useOverlayDisplayName && !!overlayDisplayName ? overlayDisplayName : null;
    const definition =
      useOverlayDefinition && !!overlayDefinition ? overlayDefinition : null;
    const color = overlayColor;
    const update: ListingInputDefinitionStatusOverlayInput = {
      name: statusName,
      displayName,
      definition,
      color,
    };
    onSubmit(update);
  }, [
    onSubmit,
    statusName,
    overlayDisplayName,
    useOverlayDisplayName,
    overlayDefinition,
    useOverlayDefinition,
    overlayColor,
  ]);

  if (!status) {
    return (
      <ZenDialog
        show={isOpen}
        title="Update status"
        icon={PencilSquareIcon}
        submit="Update"
        onSubmit={() => null}
        onCancel={onClose}
      >
        ???
      </ZenDialog>
    );
  }

  return (
    <ZenDialog
      show={isOpen}
      title="Update status"
      icon={PencilSquareIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">MLS name</div>
        <div className="font-bold">{statusName}</div>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Display name</div>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={!useOverlayDisplayName}
            onChange={() => setUseOverlayDisplayName(false)}
          />{" "}
          Source:{" "}
          <span className="font-bold">
            {status.sourceDisplayName ?? status.name}
          </span>
        </label>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={useOverlayDisplayName}
            onChange={() => setUseOverlayDisplayName(true)}
          />{" "}
          Override:{" "}
          <input
            type="text"
            value={overlayDisplayName}
            onChange={onDisplayNameChange}
          />
        </label>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Definition</div>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={!useOverlayDefinition}
            onChange={() => setUseOverlayDefinition(false)}
          />{" "}
          Source:{" "}
          <span className="font-bold">
            {status.sourceDefinition ?? status.sourceDisplayName ?? status.name}
          </span>
        </label>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={useOverlayDefinition}
            onChange={() => setUseOverlayDefinition(true)}
          />{" "}
          Override:{" "}
          <input
            type="text"
            value={overlayDefinition}
            onChange={onDefinitionChange}
          />
        </label>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Color</div>
        <label className="block py-1">
          <ColorSelect selected={overlayColor} onChange={onColorChange} />
        </label>
      </div>
    </ZenDialog>
  );
};

const ColorSelect: React.FC<{
  selected: ListingInputDefinitionStatusColor;
  onChange: (value: ListingInputDefinitionStatusColor) => void;
}> = ({ selected, onChange }) => {
  return (
    <select
      onChange={(e) =>
        onChange(e.target.value as ListingInputDefinitionStatusColor)
      }
      value={selected}
    >
      <option value={ListingInputDefinitionStatusColor.Green}>Green</option>
      <option value={ListingInputDefinitionStatusColor.Yellow}>Yellow</option>
      <option value={ListingInputDefinitionStatusColor.Red}>Red</option>
    </select>
  );
};
