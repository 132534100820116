import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Timestamp from "../../common/components/timestamp";
import {
  AgentActiveType,
  AgentQuery,
  ExternalCommunicationState,
  InheritMode,
  ListingQueryPermissionValue,
} from "../../graphql/generated";
import { LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import { Link } from "react-router-dom";

const AgentSummary: React.FC<{ agent?: AgentQuery["agent"] }> = ({ agent }) => {
  function externalCommunicationStateName(
    state?: ExternalCommunicationState
  ): string {
    if (state === ExternalCommunicationState.Allowed) {
      return "Allowed";
    } else if (state === ExternalCommunicationState.Restricted) {
      return "Restricted";
    } else {
      return state ?? "None";
    }
  }

  // function listingInputPermissionValueName(
  //   value?: ListingInputPermissionValue
  // ): string {
  //   if (value === ListingInputPermissionValue.Allowed) {
  //     return "Allowed";
  //   } else if (value === ListingInputPermissionValue.Restricted) {
  //     return "Restricted";
  //   } else {
  //     return value ?? "None";
  //   }
  // }

  function listingQueryPermissionValueName(
    value?: ListingQueryPermissionValue
  ): string {
    if (value === ListingQueryPermissionValue.AllListings) {
      return "All Listings";
    } else if (value === ListingQueryPermissionValue.PublicListingsOnly) {
      return "Public Listings";
    } else {
      return value ?? "None";
    }
  }

  return (
    <>
      <Panel>
        <Panel.Title>MLS Data</Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListContainer
              title="Status"
              info="The status of this agent, according to the MLS"
            >
              <StatusComponent entity={agent} />
            </DescriptionListContainer>
            <DescriptionListItem
              title="Office"
              value={`${agent?.office?.name} - ${agent?.office?.address?.city}, ${agent?.office?.address?.state}`}
              link={`/offices/${agent?.office?.id}`}
              info="The office that this agent belongs to, according to the MLS."
            />
            <DescriptionListItem
              title="License"
              value={
                agent?.agentLicense
                  ? `${agent?.agentLicense?.issuingState} ${agent?.agentLicense?.licenseNumber}`
                  : undefined
              }
            />
            <DescriptionListItem title="Phone" value={agent?.phone} />
            <DescriptionListItem title="Cell phone" value={agent?.cellPhone} />
            <DescriptionListItem title="Home phone" value={agent?.homePhone} />
            <DescriptionListItem
              title="Direct phone"
              value={agent?.directPhone}
            />
            <DescriptionListContainer
              title="MLS Modification Date"
              info="When the MLS last modified this agent."
            >
              <Timestamp
                timestamp={agent?.sourceModifiedAt}
                format="short"
                popover
              />
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Searchable"
              info="Whether this agent is included in searches."
            >
              {(agent?.hidden ?? false) && (
                <>No ({agent?.hiddenReason ?? "Unknown reason"})</>
              )}
              {!(agent?.hidden ?? false) && <>Yes</>}
            </DescriptionListContainer>
          </dl>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Zenlist Data</Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListItem
              title="Brokerage"
              info="The brokerage that this agent belongs to. This field is historical and almost never actually used."
              value={agent?.brokerage?.name}
              link={`/brokerages/${agent?.brokerage?.id}`}
            />
            <DescriptionListItem
              title="Enterprise Agreement"
              info="The enterprise agreement that this agent could use to sign up for Zenlist for free."
              value={`${agent?.enterpriseAgreement?.name ?? "None"} (${
                agent?.enterpriseAgreementMode
              })`}
              link={
                agent?.enterpriseAgreement
                  ? `/enterprise_agreements/${agent?.enterpriseAgreement?.id}`
                  : undefined
              }
            />
            <DescriptionListItem
              title="Account"
              value={agent?.account?.name}
              link={`/accounts/${agent?.account?.id}`}
              info="The account on Zenlist that has claimed this agent."
            />
            <DescriptionListContainer
              title="Active type"
              info="Whether this agent is allowed to sign up for Zenlist."
            >
              {!agent?.activeType && (
                <abbr title="Not set; cannot be claimed">&mdash;</abbr>
              )}
              {agent?.activeType === AgentActiveType.Auto && (
                <abbr title="Allowed because of backend logic">Auto</abbr>
              )}
              {agent?.activeType === AgentActiveType.Manual && (
                <abbr title="Allowed because a Zenlist employee marked as allowed">
                  Manual
                </abbr>
              )}
              {agent?.activeType === AgentActiveType.External && (
                <abbr title="Allowed because of backend logic">External</abbr>
              )}
              {agent?.activeType === AgentActiveType.None && (
                <abbr title="Not allowed">None</abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListItem
              title="Signup"
              info="Upon attempting to sign up for a Zenlist account, will the MLS ID of this agent, or agents in this office, be able to be looked up to complete the account creation process? Offices or agents that are not enabled likely require an MLS Authorization prior to being allowed to sign up."
              value={
                agent?.enabled
                  ? `Enabled (${agent?.enablementSource})`
                  : `Disabled (${agent?.enablementSource})`
              }
            />
            <DescriptionListItem
              title="Authorization"
              value={
                agent?.authorized
                  ? `Authorized (${agent?.authorizationSource})`
                  : `Unauthorized (${agent?.authorizationSource})`
              }
              info="Has the required MLS data authorization been completed and approved for this office or agent, allowing them to access MLS data in Zenlist?"
            />
            <DescriptionListContainer
              title="Authorization Document"
              info="Link to the required MLS data authorization that approves this office or agent for use of MLS data in Zenlist"
            >
              {agent?.authorizationDocument ? (
                <a
                  href={`${agent.authorizationDocument}`}
                  className="text-blue-900 hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Document
                </a>
              ) : (
                "None"
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="External Communication"
              info="Whether we send 'somebody viewed/liked/requested a tour on your listing' emails to agents to encourage them to join Zenlist."
            >
              {externalCommunicationStateName(agent?.externalCommunication)}{" "}
              {agent?.externalCommunicationMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {agent?.externalCommunicationMode === InheritMode.Manual && (
                <abbr title="Set specifically for this agent">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {agent?.externalCommunicationMode === InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Internal Listing Buckets"
              info="When this agent enters or searches for internal listings, what are they allowed to see?"
            >
              {agent?.internalListingBuckets.length === 0 && "—"}
              {agent?.internalListingBuckets.map((bucket, idx) => {
                return (
                  <span key={bucket.id}>
                    {idx > 0 && ", "}
                    <Link
                      to={`/internal_listing_buckets/${bucket.id}`}
                      className="text-blue-900 hover:underline"
                    >
                      {bucket.name}
                    </Link>
                  </span>
                );
              })}
              {agent?.internalListingBucketsMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {agent?.internalListingBucketsMode === InheritMode.Manual && (
                <abbr title="Set specifically for this agent">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {agent?.internalListingBucketsMode === InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Listing Query Permission"
              info="Is this agent allowed to query for all listings or only public listings?"
            >
              {listingQueryPermissionValueName(agent?.listingQueryPermission)}{" "}
              {agent?.listingQueryPermissionMode === InheritMode.Auto && (
                <abbr title="Inherited from parent office">
                  <LinkIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {agent?.listingQueryPermissionMode === InheritMode.Manual && (
                <abbr title="Set specifically for this agent">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
              {agent?.listingQueryPermissionMode === InheritMode.ManualNone && (
                <abbr title="Specifically set to none">
                  <PencilIcon className="h-4 w-4 inline text-gray-400" />
                </abbr>
              )}
            </DescriptionListContainer>

            <DescriptionListContainer
              title="Last Modified"
              info="The last time this agent was modified in the Zenlist agent. See 'MLS Modification Date' for when this agent was modified according to the MLS."
            >
              <Timestamp timestamp={agent?.modifiedAt} format="short" popover />
            </DescriptionListContainer>
          </dl>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default AgentSummary;
