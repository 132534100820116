import { UserGroupIcon, UserIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import {
  SavedFilterFragment,
  SavedFilterState,
  SavedFilterType,
} from "../../graphql/generated";

const SavedFilterPic: React.FC<{
  filter?: SavedFilterFragment;
  large?: boolean;
}> = ({ filter, large }) => {
  return (
    <>
      {filter?.savedFilterType === SavedFilterType.Shared && (
        <UserGroupIcon
          className={classNames(
            filter?.savedFilterState === SavedFilterState.Active
              ? "bg-green-100 border-green-300"
              : "bg-red-100 border-red-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
      {filter?.savedFilterType === SavedFilterType.Saved && (
        <UserIcon
          className={classNames(
            filter?.savedFilterState === SavedFilterState.Active
              ? "bg-green-100 border-green-300"
              : "bg-red-100 border-red-300",
            large ? "h-20 w-20" : "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      )}
    </>
  );
};

export default SavedFilterPic;
