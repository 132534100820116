import {
  PencilIcon,
  PlusCircleIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import {
  AccountQuery,
  useAccountLabelsQuery,
  useAddLabelToAccountMutation,
  useRemoveLabelFromAccountMutation,
} from "../../graphql/generated";

enum Action {
  Create,
  Add,
  Remove,
  None,
}

const Labels: React.FC<{ account?: AccountQuery["account"] }> = ({
  account,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const { updateNotification } = React.useContext(NotificationContext);

  const [{ data: potentialLabels }] = useAccountLabelsQuery({
    variables: {
      prefix: search,
    },
  });

  const inputOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  const [, addlabelToAccount] = useAddLabelToAccountMutation();
  const [, removelabelFromAccount] = useRemoveLabelFromAccountMutation();
  const addLabel = React.useCallback(
    async (label: string) => {
      const { error } = await addlabelToAccount({
        id: account?.id || "",
        label: label,
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: `Label "${label}" added to account`,
          notificationType: NotificationType.Success,
        });
      }
      setEditing(false);
    },
    [account, addlabelToAccount, setEditing, updateNotification]
  );
  const removeLabel = React.useCallback(
    async (label: string) => {
      const { error } = await removelabelFromAccount({
        id: account?.id || "",
        label: label,
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: `Label "${label}" removed from account`,
          notificationType: NotificationType.Success,
        });
      }
      setEditing(false);
    },
    [account, removelabelFromAccount, setEditing, updateNotification]
  );

  if (editing) {
    return (
      <>
        <div>
          {account &&
            account.labels.map((label) => (
              <Pill
                label={label}
                action={Action.Remove}
                onClick={() => removeLabel(label)}
                key={`existing-${label}`}
              />
            ))}
        </div>
        <div>
          <input
            type="search"
            onChange={inputOnChange}
            value={search}
            placeholder="Search..."
          ></input>
        </div>
        <div>
          {search && (
            <Pill
              label={search}
              action={Action.Create}
              onClick={() => addLabel(search)}
              key={`create-${search}`}
            />
          )}
          {potentialLabels?.accountLabels &&
            potentialLabels.accountLabels.map((label) => (
              <Pill
                label={label}
                action={Action.Add}
                onClick={() => addLabel(label)}
                key={`potential-${label}`}
              />
            ))}
        </div>
      </>
    );
  } else {
    return (
      <div>
        {account && account.labels.length === 0 && (
          <button
            onClick={() => setEditing(true)}
            className="italic text-gray-300"
          >
            none <PencilIcon className="inline flex-shrink-0 ml-px h-4 w-4" />
          </button>
        )}
        {account &&
          account.labels.length > 0 &&
          account.labels.map((label) => (
            <Pill
              label={label}
              action={Action.None}
              onClick={() => setEditing(true)}
              key={`existing-${label}`}
            />
          ))}
      </div>
    );
  }
};

const Pill: React.FC<{
  label: string;
  action: Action;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ label, action, onClick }) => {
  return (
    <div
      className="cursor-pointer rounded-full bg-green-600 text-green-100 py-0.5 px-2 inline-block m-px select-none"
      onClick={onClick}
    >
      {label}
      {action === Action.Add && (
        <PlusIcon className="inline flex-shrink-0 ml-1.5 h-5 w-5 text-green-100 hover:text-white hover:bg-green-400 rounded-full" />
      )}
      {action === Action.Remove && (
        <TrashIcon className="inline flex-shrink-0 ml-1.5 h-5 w-5 text-green-100 hover:text-white hover:bg-green-400 rounded-full" />
      )}
      {action === Action.Create && (
        <PlusCircleIcon className="inline flex-shrink-0 ml-1.5 h-5 w-5 text-green-100 hover:text-white hover:bg-green-400 rounded-full" />
      )}
    </div>
  );
};

export default Labels;
