import React from "react";

const DashboardStatistic: React.FC<{ name: string; stat?: string }> = ({
  name,
  stat,
}) => {
  return (
    <div className="px-2 py-3 bg-zenlist-500 shadow rounded-lg overflow-hidden sm:p-3">
      <dt className="text-xs font-medium text-zenlist-100 truncate">{name}</dt>
      <dd className="mt-1 text-xl font-semibold text-white">
        {stat ? stat : "Undefined"}
      </dd>
    </div>
  );
};

export default DashboardStatistic;
