import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../common/components/loading";
import { Transition } from "@headlessui/react";
import { SourceDataHistoryPanel } from "../components/sourcedata";
import Error from "../../common/components/error";
import { useTitle } from "../../common/utils/title";

const SourceDataEntityShow: React.FC = () => {
  const params = useParams();
  useTitle(params.entityId, "Source Data");
  const data = {};
  const error = undefined;

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <SourceDataHistoryPanel entityId={params.entityId} />
      </Transition>
    </>
  );
};

export default SourceDataEntityShow;
