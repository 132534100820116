import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useBrokerageQuery } from "../../graphql/generated";
import BrokerageOffices from "../components/offices";
import BrokerageTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const BrokerageShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useBrokerageQuery({
    variables: { id: params.brokerageId ?? "" },
    pause: !params.brokerageId,
  });
  useTitle(data?.brokerage.name ?? params.brokerageId, "Brokerages");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <BrokerageTitle brokerage={data?.brokerage} />
          <BrokerageOffices brokerage={data?.brokerage} />
        </div>
      </Transition>
    </>
  );
};

export default BrokerageShow;
