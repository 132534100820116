import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import { classNames } from "../../common/utils/classnames";

type IconType = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>;

interface PillProps<T> {
  active?: boolean;
  icon: IconType;
  options: [T, ReactNode][];
  onOptionSelected?: (option: T) => void;
  right?: boolean;
  children?: ReactNode;
}

export const OptionsPill = <T,>({
  active,
  options,
  icon,
  onOptionSelected,
  right,
  children,
}: PropsWithChildren<PillProps<T>>): ReactElement | null => {
  const [isOptionsVisible, setIsOptionsVisible] = React.useState(false);

  let bgColor: string;
  let hoverBgColor: string;
  let textColor: string;
  if (active) {
    bgColor = "bg-green-600";
    hoverBgColor = "hover:bg-green-700";
    textColor = "text-white";
  } else {
    bgColor = "bg-gray-200";
    hoverBgColor = "hover:bg-gray-300";
    textColor = "text-gray-800";
  }

  const Icon = icon;

  return (
    <span
      className={classNames(
        "text-xs rounded-full p-px px-2 relative inline-block cursor-pointer",
        bgColor,
        hoverBgColor,
        textColor
      )}
      onClick={() => setIsOptionsVisible(!isOptionsVisible)}
    >
      <Icon
        className={classNames("p-px rounded-lg inline-block")}
        style={{
          width: "1.1rem",
          height: "1.1rem",
        }}
      />{" "}
      {children}
      <div
        className={classNames(
          "absolute rounded py-1 z-40",
          bgColor,
          textColor,
          isOptionsVisible ? "" : "hidden",
          right ? "right-0" : "left-0"
        )}
        style={{
          top: "calc(100% + 2px)",
          width: "max-content",
        }}
      >
        {options.map(([key, value], idx) => (
          <div
            key={idx}
            className={classNames("p-1 px-3 cursor-pointer", hoverBgColor)}
            onClick={() => onOptionSelected && onOptionSelected(key)}
          >
            {value}
          </div>
        ))}
      </div>
    </span>
  );
};

export const InfoPill: React.FC<{
  icon: IconType;
  text?: string;
  children?: ReactNode;
}> = ({ icon, text, children }) => {
  const Icon = icon;

  return (
    <span
      className={classNames(
        "text-xs rounded-full p-px px-2 relative inline-block bg-gray-100 text-gray-800"
      )}
    >
      <Icon
        className={classNames("p-px rounded-lg inline-block")}
        style={{
          width: "1.1rem",
          height: "1.1rem",
        }}
      />{" "}
      {text ?? children}
    </span>
  );
};
