import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { FeatureFlagFragment } from "../../graphql/generated";

const FeatureFlagSummary: React.FC<{ flag?: FeatureFlagFragment }> = ({
  flag,
}) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem title="ID" value={flag?.id} />
          <DescriptionListItem
            title="Enabled"
            value={`${flag?.percentEnabled}%`}
          />
          <DescriptionListItem title="State" value={flag?.state} />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default FeatureFlagSummary;
