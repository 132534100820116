import { EnvelopeIcon, EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../../common/utils/classnames";
import {
  SubethaEventType,
  SubethaMailFragment,
} from "../../../graphql/generated";

const MailPic: React.FC<{
  mail?: SubethaMailFragment;
  large?: boolean;
  timeline?: boolean;
}> = ({ mail, large, timeline }) => {
  const mailColor = React.useMemo(() => {
    switch (mail?.latestEvent) {
      case SubethaEventType.Send:
        return "bg-yellow-100 border-yellow-300";
      case SubethaEventType.Delivery:
      case SubethaEventType.Click:
      case SubethaEventType.Open:
        return "bg-green-300 border-green-300";
      case SubethaEventType.Bounce:
      case SubethaEventType.Complaint:
      case SubethaEventType.Delay:
        return "bg-red-100 border-red-300";
      default:
        return "bg-gray-100 border-gray-300";
    }
  }, [mail]);

  return timeline ? (
    <span
      className={classNames(
        mailColor,
        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
      )}
    >
      {mail?.latestEvent === SubethaEventType.Click ||
      mail?.latestEvent === SubethaEventType.Open ? (
        <EnvelopeOpenIcon className="h-5 w-5 text-white" aria-hidden="true" />
      ) : (
        <EnvelopeIcon className="h-5 w-5 text-white" aria-hidden="true" />
      )}
    </span>
  ) : (
    <EnvelopeOpenIcon
      className={classNames(
        mailColor,
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2"
      )}
    />
  );
};

export default MailPic;
