import React from "react";
import { useClient } from "urql";
import Panel from "../../../common/components/panel";
import { classNames } from "../../../common/utils/classnames";
import {
  ConversationFragment,
  MessagesDocument,
  useMessagesQuery,
} from "../../../graphql/generated";
import MessageCard from "../../message/components/card";

const ConversationMessages: React.FC<{
  conversation?: ConversationFragment;
}> = ({ conversation }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();
  const [{ data }] = useMessagesQuery({
    variables: {
      first: 20,
      conversationId: conversation?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.messages.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(MessagesDocument, {
          first: 20,
          after: data.messages.pageInfo.endCursor,
          conversationId: conversation?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, conversation]);

  return (
    <Panel>
      <Panel.Title>
        Messages{" "}
        <span className="text-xs text-gray-500">{data?.messages.total}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <ul className="divide-y divide-gray-200">
              {data?.messages.edges?.map((message) => (
                <MessageCard message={message?.node} key={message?.node.id} />
              ))}
            </ul>
          </div>
        </div>
      </Panel.Body>

      {data && data?.messages.total > (data?.messages.edges?.length ?? 0) && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default ConversationMessages;
