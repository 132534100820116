import React from "react";
import { useClient } from "urql";
import AgentCard from "../../agent/components/card";
import Panel from "../../common/components/panel";
import { classNames } from "../../common/utils/classnames";
import {
  AgentsDocument,
  OfficeFragment,
  useAgentsQuery,
} from "../../graphql/generated";

const OfficeAgents: React.FC<{ office?: OfficeFragment }> = ({ office }) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data }] = useAgentsQuery({
    variables: { first: 10, officeId: office?.id ?? "" },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.agents.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(AgentsDocument, {
          first: 20,
          after: data.agents.pageInfo.endCursor,
          officeId: office?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, office]);

  return (
    <Panel>
      <Panel.Title>
        Agents{" "}
        <span className="text-xs text-gray-500">{data?.agents.total}</span>
      </Panel.Title>

      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.agents.edges?.map((agent) => (
            <AgentCard agent={agent?.node} key={agent?.node.id} />
          ))}
        </div>
      </Panel.Body>

      {data && data?.agents.total > (data?.agents.edges?.length ?? 0) && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default OfficeAgents;
