import React from "react";
import AgentCard from "../../agent/components/card";
import Panel from "../../common/components/panel";
import { AccountQuery } from "../../graphql/generated";
import AccountAgentActions from "./agentactions";

const AccountAgents: React.FC<{
  account?: AccountQuery["account"];
  linked?: boolean;
  refreshAccount?: () => void;
}> = ({ linked, account, refreshAccount }) => {
  return (
    <Panel>
      <Panel.Title
        actions={
          <>
            {linked && (
              <AccountAgentActions
                account={account}
                refreshAccount={refreshAccount}
              />
            )}
          </>
        }
      >
        {linked ? "Linked Agents" : "Agent"}{" "}
        <span className="text-xs text-gray-500">{account?.agents?.length}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {account?.agents &&
            account?.agents.map((agent) => (
              <AgentCard agent={agent} key={agent.id} />
            ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountAgents;
