import React from "react";
import Timestamp from "../../common/components/timestamp";
import { ApiSubscriptionFragment } from "../../graphql/generated";

const ApiSubscriptionCard: React.FC<{
  subscription?: ApiSubscriptionFragment;
}> = ({ subscription }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-1 win-w-0">
        <span className="absolute inset-0" aria-hidden="true" />
        <p className="text-sm font-medium text-gray-900 truncate">
          {subscription?.id}
        </p>
        <div className="grid grid-cols-3">
          <p className="text-xs text-gray-500 truncate">Created At:</p>
          <p className="text-xs text-gray-500 truncate col-span-2">
            <Timestamp timestamp={subscription?.createdAt} format={"short"} />
          </p>
          <p className="text-xs text-gray-500 truncate">Subscription Type:</p>
          <p className="text-xs text-gray-500 truncate col-span-2">
            {subscription?.subscriptionType}
          </p>
          <p className="text-xs text-gray-500 truncate">Resource Type:</p>
          <p className="text-xs text-gray-500 truncate col-span-2">
            {subscription?.resourceType}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApiSubscriptionCard;
