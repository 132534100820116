import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../../common/components/error";
import Loading from "../../../common/components/loading";
import { useSubethaMailQuery } from "../../../graphql/generated";
import MailEvents from "../components/events";
import MailTemplate from "../components/template";
import MailTitle from "../components/title";
import { useTitle } from "../../../common/utils/title";

const MailShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useSubethaMailQuery({
    variables: { id: params.mailId ?? "" },
    pause: !params.mailId,
  });
  useTitle(params.mailId, "Mail");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        {data && (
          <div className="max-w-7xl mx-auto py-4">
            <MailTitle mail={data?.mail} />
            <MailEvents mail={data?.mail} />
            <MailTemplate mail={data?.mail} />
          </div>
        )}
      </Transition>
    </>
  );
};

export default MailShow;
