import { RmNormalizationDetailsFragment } from "../../../graphql/generated";
import RMRuleNormalizationTypeControl from "./RMRuleNormalizationTypeControl";

const RMNormalizationTypeControl: React.FC<{
  normalization: RmNormalizationDetailsFragment;
}> = ({ normalization }) => {
  switch (normalization.type.__typename) {
    case "RMRuleNormalizationType":
      return (
        <RMRuleNormalizationTypeControl
          normalization={normalization}
          normalizationType={normalization.type}
        />
      );
  }
};

export default RMNormalizationTypeControl;
