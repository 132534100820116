import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

const StartMostRecentIngestDialog: React.FC<{
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (count: number) => void;
}> = ({ isOpen, isSubmitting, onClose, onSubmit }) => {
  const [samples, setSamples] = React.useState(20000);

  const inputRef = React.useRef(null);

  const handleSubmit = React.useCallback(() => {
    onSubmit(samples);
  }, [onSubmit, samples]);

  return (
    <ZenDialog
      show={isOpen}
      state={
        isSubmitting ? ZenDialogState.Submitting : ZenDialogState.Displaying
      }
      title="Start most recent ingest"
      icon={DocumentPlusIcon}
      submit="Start"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="mt-1">
        <input
          type="number"
          value={samples}
          onChange={(e) => setSamples(e.target.valueAsNumber)}
          min={5000}
          max={50000}
          step={5000}
        />
      </div>
    </ZenDialog>
  );
};

export default StartMostRecentIngestDialog;
