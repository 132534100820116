import { Transition } from "@headlessui/react";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  useCreateReprocessDataTaskForProblemMutation,
  useRmProblemQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import {
  Navigation,
  NavigationInline,
  NavigationItems,
} from "../components/nav";
import ResourceMappingTitle from "../components/resource-mapping-title";
import Panel from "../../common/components/panel";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Timestamp from "../../common/components/timestamp";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ProblemStatus from "../components/problem-status";
import { classNames } from "../../common/utils/classnames";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";

const RMProblem: React.FC = () => {
  const params = useParams();
  const problemId = params.problemId
    ? decodeURIComponent(params.problemId)
    : undefined;
  const [{ data, error }] = useRmProblemQuery({
    variables: { id: problemId ?? "" },
  });
  const name = React.useMemo(() => {
    if (data?.rmProblem.mapping?.field.name) {
      return `${data.rmProblem.mapping.field.name} mapping problem`;
    } else if (data?.rmProblem.normalization?.field.name) {
      return `${data.rmProblem.normalization.field.name} normalization problem`;
    } else {
      return undefined;
    }
  }, [data]);

  useTitle("Problem", "Resource Mapping");

  const field =
    data?.rmProblem.mapping?.field ?? data?.rmProblem.normalization?.field;

  const nav = NavigationItems.withHome();
  nav.addMls(field?.rootResource.mls.shortName, field?.rootResource.mls.id);
  nav.addRoot(field?.rootResource.name, field?.rootResource.id);
  if (data?.rmProblem.mapping?.parentFields) {
    for (const parent of data.rmProblem.mapping.parentFields) {
      nav.addField(parent.name, parent.id);
    }
  }
  if (data?.rmProblem.normalization?.parentFields) {
    for (const parent of data.rmProblem.normalization.parentFields) {
      nav.addField(parent.name, parent.id);
    }
  }
  if (data?.rmProblem.mapping) {
    nav.addMapping("Mapping", data.rmProblem.mapping.id);
  } else if (data?.rmProblem.normalization) {
    nav.addNormalization("Normalization", data.rmProblem.normalization.id);
  }
  nav.addProblem("Problem", data?.rmProblem.id);

  const { updateNotification } = React.useContext(NotificationContext);
  const navigate = useNavigate();
  const [, createReprocessDataTaskMutation] =
    useCreateReprocessDataTaskForProblemMutation();

  const createReprocessDataTask = React.useCallback(async () => {
    const { error, data: createMutationData } =
      await createReprocessDataTaskMutation({
        problemId: data?.rmProblem.id || "",
      });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
    if (createMutationData) {
      navigate(
        `/reprocess_data_tasks/${createMutationData.createReprocessDataTaskForProblem.id}`
      );
    }
  }, [data, updateNotification]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle name={name}>Problem</ResourceMappingTitle>
          <Panel>
            <Panel.Body summary>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                <DescriptionListItem
                  title="Count"
                  value={data?.rmProblem.count}
                />
                <DescriptionListItem
                  title="Message"
                  value={data?.rmProblem.message}
                  span={2}
                />
                <DescriptionListItem
                  title="Affected resources"
                  value={`${data?.rmProblem.affectedIdsCount}${
                    data?.rmProblem.affectedIdsCapped ? "+" : ""
                  }`}
                />
                <DescriptionListContainer title="Reprocess">
                  {data?.rmProblem.affectedIdsCapped === false && (
                    <button
                      className={classNames(
                        "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                        "font-bold py-2 px-4 rounded"
                      )}
                      onClick={() => createReprocessDataTask()}
                    >
                      Reprocess
                    </button>
                  )}
                  {data?.rmProblem.affectedIdsCapped !== false && (
                    <>{"Too many affected resources"}</>
                  )}
                </DescriptionListContainer>
                <DescriptionListContainer title="Path" span={3}>
                  <NavigationInline items={nav} />
                </DescriptionListContainer>
                <DescriptionListContainer title="Created at">
                  <Timestamp
                    format="long"
                    timestamp={data?.rmProblem.createdAt}
                    popover
                  />{" "}
                  (
                  <Timestamp
                    format="distance"
                    timestamp={data?.rmProblem.createdAt}
                  />
                  )
                </DescriptionListContainer>
                <DescriptionListContainer title="Last problem at">
                  <Timestamp
                    format="long"
                    timestamp={data?.rmProblem.lastProblemAt}
                    popover
                  />{" "}
                  (
                  <Timestamp
                    format="distance"
                    timestamp={data?.rmProblem.lastProblemAt}
                  />
                  )
                </DescriptionListContainer>
                <DescriptionListContainer title="Acknowledged at">
                  <Timestamp
                    format="long"
                    timestamp={data?.rmProblem.acknowledgedAt}
                    popover
                  />
                  {data?.rmProblem.acknowledgedAt && (
                    <>
                      {" "}
                      (
                      <Timestamp
                        format="distance"
                        timestamp={data?.rmProblem.acknowledgedAt}
                      />
                      )
                    </>
                  )}
                  <div>
                    <ProblemStatus problem={data?.rmProblem} />
                  </div>
                </DescriptionListContainer>
              </dl>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Title>Data</Panel.Title>
            <Panel.Body>
              <SyntaxHighlighter
                language="json"
                style={a11yDark}
                showLineNumbers
              >
                {JSON.stringify(data?.rmProblem.data, null, 2)}
              </SyntaxHighlighter>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Title>Source data</Panel.Title>
            <Panel.Body>
              <ul>
                {data?.rmProblem.affectedIds.map((id) => (
                  <li key={id}>
                    <Link
                      to={`/source/${data?.rmProblem.rootResource.mls.id}:${data?.rmProblem.rootResource.name}:${id}`}
                      className="text-blue-900 hover:underline"
                    >
                      /source/{data?.rmProblem.rootResource.mls.id}:
                      {data?.rmProblem.rootResource.name}:{id}
                    </Link>
                  </li>
                ))}
                {((data?.rmProblem.affectedIds.length ?? 0) <
                  (data?.rmProblem.affectedIdsCount ?? 0) ||
                  data?.rmProblem.affectedIdsCapped) && (
                  <li>
                    <i>and many more</i>
                  </li>
                )}
              </ul>
            </Panel.Body>
          </Panel>
        </div>
      </Transition>
    </>
  );
};

export default RMProblem;
