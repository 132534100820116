import React from "react";
import { useClient } from "urql";
import LenderActions from "../components/actions";
import { SearchContext } from "../../common/context/search";
import { classNames } from "../../common/utils/classnames";
import LenderCard from "../components/card";
import { LendersDocument, useLendersQuery } from "../../graphql/generated";
import { Transition } from "@headlessui/react";
import Loading from "../../common/components/loading";
import CommonSwitch from "../../common/components/switch";
import Error from "../../common/components/error";
import { useTitle } from "../../common/utils/title";

const LenderList: React.FC = () => {
  useTitle("Lenders");
  const graphqlClient = useClient();
  const [loading, setLoading] = React.useState(false);
  const { searchBounced } = React.useContext(SearchContext);
  const [includeTestLenders, setIncludeTestLenders] = React.useState(false);

  const [{ data, error, fetching }, executeQuery] = useLendersQuery({
    variables: {
      first: 20,
      search: searchBounced,
      includeTestLenders,
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.lenders.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(LendersDocument, {
          first: 20,
          after: data.lenders.pageInfo.endCursor,
          search: searchBounced,
          includeTestLenders,
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, searchBounced, includeTestLenders]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Lenders
                <span className="text-sm text-gray-500 px-2">
                  {fetching
                    ? "Loading..."
                    : `${data?.lenders.total.toLocaleString()} in "All"`
                  }
                </span>
              </h2>
              <div className="mt-1 grid grid-cols-1 gap-0">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CommonSwitch
                    enabled={includeTestLenders}
                    toggle={() => setIncludeTestLenders(!includeTestLenders)}
                    label="Include test lenders"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 flex sm:mt-0 sm:mr-4">
              <LenderActions executeQuery={() => executeQuery()} />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {data?.lenders.edges?.map((lender) => (
                  <LenderCard lender={lender?.node} key={lender?.node.id} />
                ))}
              </div>
            </div>
          </div>
          {data && data?.lenders.total > (data?.lenders.edges?.length ?? 0) && (
            <div className="min-w-full divide-y divide-gray-200">
              <div className="p-4">
                <button
                  className={classNames(
                    loading
                      ? "bg-gray-500 text-white"
                      : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                    "font-bold py-2 px-4 rounded"
                  )}
                  onClick={() => fetchMore()}
                  disabled={loading}
                >
                  Fetch More
                </button>
              </div>
            </div>
          )}
        </div>
      </Transition>
    </>
  );
};

export default LenderList;
