import { InboxArrowDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Status } from "../../common/components/status";
import { classNames } from "../../common/utils/classnames";
import { LeadFragment } from "../../graphql/generated";

const LeadPic: React.FC<{ lead?: LeadFragment; large?: boolean }> = ({
  lead,
  large,
}) => {
  const status = React.useMemo(() => {
    return Status.for(lead);
  }, [lead]);

  return (
    <InboxArrowDownIcon
      className={classNames(
        status.color.picClasses(),
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2"
      )}
    />
  );
};

export default LeadPic;
