import { Transition } from "@headlessui/react";
import { GoogleLogin, CredentialResponse } from "@react-oauth/google";
import React from "react";
import { AuthContext } from "../context/auth";

import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { AuthenticateGoogleDocument } from "../../graphql/generated";
import jwtDecode from "jwt-decode";
import { useTitle } from "../../common/utils/title";

const Login: React.FC = () => {
  useTitle("Login");
  const { updateAuthorized, authorized } = React.useContext(AuthContext);

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";
  const onSuccess = React.useCallback(async (response: CredentialResponse) => {
    const { credential } = response;

    if (credential) {
      const { name, picture } = jwtDecode<{ name: string; picture: string }>(
        credential
      );
      localStorage.setItem("auth_name", name);
      localStorage.setItem("auth_picture", picture);
    }

    if (credential) {
      await autheticateGoogle({
        token: credential,
        origin: window.location.origin,
      });
    }
  }, []);

  const [authenticateGoogleResult, autheticateGoogle] = useMutation(
    AuthenticateGoogleDocument
  );

  React.useEffect(() => {
    if (authenticateGoogleResult.data) {
      localStorage.setItem(
        "token",
        authenticateGoogleResult.data.authenticateGoogle.accessToken
      );
      localStorage.setItem(
        "refreshToken",
        authenticateGoogleResult.data.authenticateGoogle.refreshToken
      );
      updateAuthorized(true);
    }
  }, [authenticateGoogleResult]);

  React.useEffect(() => {
    if (authorized) {
      navigate(from, { replace: true });
    }
  }, [authorized, from, navigate]);

  return (
    <>
      <Transition
        show={true}
        appear
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="flex justify-center">
                <GoogleLogin
                  hosted_domain="zenlist.com"
                  onSuccess={onSuccess}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  useOneTap
                  auto_select
                />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default Login;
