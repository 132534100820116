import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import {
  AccountFragment,
  AccountProfileFragment,
} from "../../graphql/generated";
import AccountProfileActions from "./profileactions";

const AccountProfile: React.FC<{
  account?: AccountFragment;
  profile?: AccountProfileFragment;
}> = ({ profile, account }) => {
  return (
    <Panel>
      <Panel.Title
        actions={<AccountProfileActions profile={profile} account={account} />}
      >
        Profile
      </Panel.Title>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem
            title="URL"
            value={profile?.url}
            a={profile?.url}
            span={3}
          />
          <DescriptionListItem title="Slug" value={profile?.slug} />
          <DescriptionListItem
            title="Previous Slug"
            value={profile?.previousSlug ?? ""}
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default AccountProfile;
