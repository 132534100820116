import React from "react";
import {
  AccountPatchesDocument,
  ListingPatchesDocument,
  useAccountPatchesQuery,
  useListingPatchesQuery,
  useAgentPatchesQuery,
  useGroupSettingPatchesQuery,
  useGroupPatchesQuery,
  useOfficePatchesQuery,
  useSavedFilterPatchesQuery,
  useTourPatchesQuery,
  AgentPatchesDocument,
  SavedFilterPatchesDocument,
  OfficePatchesDocument,
  GroupPatchesDocument,
  GroupSettingPatchesDocument,
  TourPatchesDocument,
} from "../../graphql/generated";
import { classNames } from "../../common/utils/classnames";
import { useClient } from "urql";
import PatchList from "./patchlist";
import Panel from "../../common/components/panel";

const Patches: React.FC<{
  accountId?: string;
  agentId?: string;
  groupSettingId?: string;
  groupId?: string;
  listingId?: string;
  officeId?: string;
  savedFilterId?: string;
  tourId?: string;
}> = ({
  accountId,
  agentId,
  groupSettingId,
  groupId,
  listingId,
  officeId,
  savedFilterId,
  tourId,
}) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data: accountData }] = useAccountPatchesQuery({
    pause: !accountId,
    variables: { accountId: accountId!, first: 2 },
  }); //accountId
  const [{ data: agentData }] = useAgentPatchesQuery({
    pause: !agentId,
    variables: { agentId: agentId!, first: 2 },
  }); //agentId
  const [{ data: groupSettingData }] = useGroupSettingPatchesQuery({
    pause: !groupSettingId,
    variables: { groupSettingId: groupSettingId!, first: 2 },
  }); //groupSettingId
  const [{ data: groupData }] = useGroupPatchesQuery({
    pause: !groupId,
    variables: { groupId: groupId!, first: 2 },
  }); //groupId
  const [{ data: listingData }] = useListingPatchesQuery({
    pause: !listingId,
    variables: { listingId: listingId!, first: 2 },
  }); //listingId
  const [{ data: officeData }] = useOfficePatchesQuery({
    pause: !officeId,
    variables: { officeId: officeId!, first: 2 },
  }); //officeId
  const [{ data: savedFilterData }] = useSavedFilterPatchesQuery({
    pause: !savedFilterId,
    variables: { savedFilterId: savedFilterId!, first: 2 },
  }); //savedFilterId
  const [{ data: tourData }] = useTourPatchesQuery({
    pause: !tourId,
    variables: { tourId: tourId!, first: 2 },
  }); //savedFilterId

  const patches = React.useMemo(() => {
    if (accountId && accountData?.accountPatches.edges) {
      return accountData.accountPatches.edges.map((e) => e?.node);
    }
    if (agentId && agentData?.agentPatches.edges) {
      return agentData.agentPatches.edges.map((e) => e?.node);
    }
    if (groupSettingId && groupSettingData?.groupSettingPatches.edges) {
      return groupSettingData.groupSettingPatches.edges.map((e) => e?.node);
    }
    if (groupId && groupData?.groupPatches.edges) {
      return groupData.groupPatches.edges.map((e) => e?.node);
    }
    if (listingId && listingData?.listingPatches.edges) {
      return listingData.listingPatches.edges.map((e) => e?.node);
    }
    if (officeId && officeData?.officePatches.edges) {
      return officeData.officePatches.edges.map((e) => e?.node);
    }
    if (savedFilterId && savedFilterData?.savedFilterPatches.edges) {
      return savedFilterData.savedFilterPatches.edges.map((e) => e?.node);
    }
    if (tourId && tourData?.tourPatches.edges) {
      return tourData.tourPatches.edges.map((e) => e?.node);
    }

    return [];
  }, [
    accountId,
    accountData,
    agentId,
    agentData,
    groupSettingId,
    groupSettingData,
    groupId,
    groupData,
    listingId,
    listingData,
    officeId,
    officeData,
    savedFilterId,
    savedFilterData,
    tourId,
    tourData,
  ]);

  const canFetchMore = React.useMemo(() => {
    if (accountId && accountData?.accountPatches.pageInfo) {
      return accountData?.accountPatches.pageInfo.hasNextPage;
    }
    if (agentId && agentData?.agentPatches.pageInfo) {
      return agentData.agentPatches.pageInfo.hasNextPage;
    }
    if (groupSettingId && groupSettingData?.groupSettingPatches.pageInfo) {
      return groupSettingData.groupSettingPatches.pageInfo.hasNextPage;
    }
    if (groupId && groupData?.groupPatches.pageInfo) {
      return groupData.groupPatches.pageInfo.hasNextPage;
    }
    if (listingId && listingData?.listingPatches.pageInfo) {
      return listingData.listingPatches.pageInfo.hasNextPage;
    }
    if (officeId && officeData?.officePatches.pageInfo) {
      return officeData.officePatches.pageInfo.hasNextPage;
    }
    if (savedFilterId && savedFilterData?.savedFilterPatches.pageInfo) {
      return savedFilterData.savedFilterPatches.pageInfo.hasNextPage;
    }
    if (tourId && tourData?.tourPatches.pageInfo) {
      return tourData.tourPatches.pageInfo.hasNextPage;
    }

    return true;
  }, [
    accountId,
    accountData,
    agentId,
    agentData,
    groupSettingId,
    groupSettingData,
    groupId,
    groupData,
    listingId,
    listingData,
    officeId,
    officeData,
    savedFilterId,
    savedFilterData,
    tourId,
    tourData,
  ]);

  const patchBase = React.useMemo(() => {
    if (accountId && accountData?.accountPatches.patchBase) {
      return accountData?.accountPatches.patchBase;
    }
    if (agentId && agentData?.agentPatches.patchBase) {
      return agentData?.agentPatches.patchBase;
    }
    if (groupSettingId && groupSettingData?.groupSettingPatches.patchBase) {
      return groupSettingData?.groupSettingPatches.patchBase;
    }
    if (groupId && groupData?.groupPatches.patchBase) {
      return groupData?.groupPatches.patchBase;
    }
    if (listingId && listingData?.listingPatches.patchBase) {
      return listingData?.listingPatches.patchBase;
    }
    if (officeId && officeData?.officePatches.patchBase) {
      return officeData?.officePatches.patchBase;
    }
    if (savedFilterId && savedFilterData?.savedFilterPatches.patchBase) {
      return savedFilterData?.savedFilterPatches.patchBase;
    }
    if (tourId && tourData?.tourPatches.patchBase) {
      return tourData?.tourPatches.patchBase;
    }

    return null;
  }, [
    accountId,
    accountData,
    agentId,
    agentData,
    groupSettingId,
    groupSettingData,
    groupId,
    groupData,
    listingId,
    listingData,
    officeId,
    officeData,
    savedFilterId,
    savedFilterData,
    tourId,
    tourData,
  ]);

  const fetchMore = React.useCallback(async () => {
    if (accountData?.accountPatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(AccountPatchesDocument, {
          first: 10,
          after: accountData.accountPatches.pageInfo.endCursor,
          accountId: accountId!,
        })
        .toPromise();
      setLoading(false);
    } else if (agentData?.agentPatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(AgentPatchesDocument, {
          first: 10,
          after: agentData.agentPatches.pageInfo.endCursor,
          agentId: agentId!,
        })
        .toPromise();
      setLoading(false);
    } else if (groupSettingData?.groupSettingPatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(GroupSettingPatchesDocument, {
          first: 10,
          after: groupSettingData.groupSettingPatches.pageInfo.endCursor,
          groupSettingId: groupSettingId!,
        })
        .toPromise();
      setLoading(false);
    } else if (groupData?.groupPatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(GroupPatchesDocument, {
          first: 10,
          after: groupData.groupPatches.pageInfo.endCursor,
          groupId: groupId!,
        })
        .toPromise();
      setLoading(false);
    } else if (listingData?.listingPatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(ListingPatchesDocument, {
          first: 10,
          after: listingData.listingPatches.pageInfo.endCursor,
          listingId: listingId!,
        })
        .toPromise();
      setLoading(false);
    } else if (officeData?.officePatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(OfficePatchesDocument, {
          first: 10,
          after: officeData.officePatches.pageInfo.endCursor,
          officeId: officeId!,
        })
        .toPromise();
      setLoading(false);
    } else if (savedFilterData?.savedFilterPatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(SavedFilterPatchesDocument, {
          first: 10,
          after: savedFilterData.savedFilterPatches.pageInfo.endCursor,
          savedFilterId: savedFilterId!,
        })
        .toPromise();
      setLoading(false);
    } else if (tourData?.tourPatches.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(TourPatchesDocument, {
          first: 10,
          after: tourData.tourPatches.pageInfo.endCursor,
          tourId: tourId!,
        })
        .toPromise();
      setLoading(false);
    }
  }, [
    accountId,
    accountData,
    agentId,
    agentData,
    groupSettingId,
    groupSettingData,
    groupId,
    groupData,
    listingId,
    listingData,
    officeId,
    officeData,
    savedFilterId,
    savedFilterData,
    tourId,
    tourData,
    graphqlClient,
  ]);

  return (
    <Panel>
      <Panel.Title>Patches</Panel.Title>
      <Panel.Body>
        <PatchList patchBase={patchBase} patches={patches} />
      </Panel.Body>
      {canFetchMore && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default Patches;
