import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../../common/components/error";
import Loading from "../../../common/components/loading";
import { useSubethaEventQuery } from "../../../graphql/generated";
import EventData from "../components/data";
import EventTitle from "../components/title";
import { useTitle } from "../../../common/utils/title";

const EventShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useSubethaEventQuery({
    variables: { id: params.eventId ?? "" },
    pause: !params.eventId,
  });
  useTitle(data?.event.mail?.subject ?? params.eventId, "Events");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        {data && (
          <div className="max-w-7xl mx-auto py-4">
            <EventTitle event={data?.event} />
            <EventData event={data?.event} />
          </div>
        )}
      </Transition>
    </>
  );
};

export default EventShow;
