import React from "react";
import { Link } from "react-router-dom";
import { SearchedListingFragment } from "../../graphql/generated";
import { HomeIcon } from "@heroicons/react/24/outline";
import { Status } from "../../common/components/status";
import { classNames } from "../../common/utils/classnames";

const SearchedListingCard: React.FC<{ listing?: SearchedListingFragment }> = ({
  listing,
}) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <HomeIcon
          className={classNames(
            Status.for(listing).color.picClasses(),
            "h-10 w-10",
            "rounded-full border-2"
          )}
        />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/listings/${listing?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">
            {listing?.address.address1}, {listing?.address.city},{" "}
            {listing?.address.state} {listing?.address.zipCode}
          </p>
          <p className="text-sm text-gray-500 truncate">
            {listing?.mls.shortName} #{listing?.mlsId}
          </p>
          <p className="text-sm text-gray-500 truncate">
            {listing?.cardStatusLong} · {listing?.cardPropertyTypeLong}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default SearchedListingCard;
