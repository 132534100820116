import React from "react";
import Panel from "../../../common/components/panel";
import {
  ResourceMetadataFieldFragment,
  RmFieldLookupValueFragment,
  RmLookupBasicMappingTypeFragment,
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
  useResourceMetadataForFieldQuery,
  useRmMappingSetLookupBasicTransformMutation,
  useRmMappingUpdateLookupBasicMutation,
  useRmProblemAcknowledgeMutation,
} from "../../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../../common/context/notification";
import LookupValueSelector from "./LookupValueSelector";
import LoadingIcon from "../../../common/components/loadingicon";
import ProblemStatus from "../problem-status";
import { diceCoefficient } from "dice-coefficient";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import CommonSwitch from "../../../common/components/switch";
import {
  ResourceMetadataLookupValueComponent,
  resourceMetadataLookupValueForValue,
} from "../resource-metadata/resource-metadata-lookup-value-component";
import { ResourceMetadataFieldComponent } from "../resource-metadata/resource-metadata-field-component";

const RMLookupBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmLookupBasicMappingTypeFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping, mappingType, problems }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [source, setSource] = React.useState<string>("");
  const [destination, setDestination] = React.useState<string | null>(null);
  const options = mapping.destinationIsLocalLookup
    ? mapping.field.localLookupValues
    : mapping.field.standardLookupValues;

  const [{ fetching }, mutation] =
    useRmMappingSetLookupBasicTransformMutation();
  const [{ fetching: updateFetching }, updateLookupBasicMutation] =
    useRmMappingUpdateLookupBasicMutation();

  const [{ data: resourceMetadata }] = useResourceMetadataForFieldQuery({
    variables: {
      id: mapping.field.rootResource.resourceMetadataId,
      fieldName: mappingType.source,
    },
  });

  const resourceMetadataField =
    resourceMetadata?.resourceMetadata.field ?? undefined;

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      source,
      destination,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [fetching, mutation, mapping, source, destination, updateNotification]);

  const onSplitOnCommaToggleChanged = React.useCallback(async () => {
    if (updateFetching) {
      return;
    }
    const { error } = await updateLookupBasicMutation({
      mappingId: mapping.id,
      splitOnComma: !mappingType.splitOnComma,
    });
    if (!error) {
      updateNotification({
        notification: `Split on commas toggled`,
        notificationType: NotificationType.Success,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    mapping.id,
    mappingType.splitOnComma,
    updateFetching,
    updateLookupBasicMutation,
    updateNotification,
  ]);

  return (
    <>
      <Panel>
        <Panel.Title>Lookup Mapping</Panel.Title>
        <Panel.Body>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListContainer
              title="Split on commas"
              info="Whether or not to treat a comma-separated string as multiple values. Enable this for RETS MLSes and certain API MLSes that return multiple values in a single comma-separated string."
            >
              <CommonSwitch
                label="Split on commas"
                enabled={mappingType.splitOnComma ?? false}
                toggle={onSplitOnCommaToggleChanged}
              />
            </DescriptionListContainer>
            <DescriptionListContainer title="Transforms" span={3}>
              <table className="w-full text-sm text-left">
                <thead>
                  <tr>
                    <th className="p-1">
                      Source:{" "}
                      <ResourceMetadataFieldComponent
                        name={mappingType.source}
                        field={resourceMetadataField}
                      />
                    </th>
                    <th className="p-1">Destination: {mapping.field.name}</th>
                  </tr>
                </thead>
                <tbody>
                  {mappingType.transforms.map((transform, idx) => {
                    return (
                      <tr key={idx} className="border-t">
                        <td className="p-1">
                          <ResourceMetadataLookupValueComponent
                            field={resourceMetadataField}
                            value={transform.from}
                          />
                        </td>
                        <td className="p-1">
                          {transform.to && transform.to}
                          {!transform.to && (
                            <span className="text-gray-400">ignore</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr key="add" className="border-t">
                    <td className="p-1">
                      <input
                        type="text"
                        value={source}
                        onChange={(e) => setSource(e.target.value)}
                      />
                    </td>
                    <td className="p-1">
                      <LookupValueSelector
                        value={destination}
                        setValue={setDestination}
                        options={options}
                      />{" "}
                      {!fetching && (
                        <button onClick={() => setLookup()}>Set</button>
                      )}
                      {fetching && <LoadingIcon />}
                    </td>
                  </tr>
                </tbody>
              </table>
            </DescriptionListContainer>
          </dl>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Suggestions</Panel.Title>
        <Panel.Body>
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">Source: {mappingType.source}</th>
                <th className="p-1">Destination: {mapping.field.name}</th>
                <th className="p-1"></th>
              </tr>
            </thead>
            <tbody>
              {problems?.edges.map((edge) => (
                <Suggestion
                  key={edge.node.id}
                  edge={edge}
                  mapping={mapping}
                  field={resourceMetadataField}
                  options={options}
                />
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
};

const Suggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
  field?: ResourceMetadataFieldFragment;
  options: RmFieldLookupValueFragment[];
}> = ({ edge, mapping, field, options }) => {
  const { updateNotification } = React.useContext(NotificationContext);

  if (edge.node.data["problem_type"] !== "unidentified_lookup") {
    return <></>;
  }

  const problem = edge.node;
  const source = problem.data["value"];
  const isAcknowledged = problem.acknowledgedAt != null;
  const lookupValue = resourceMetadataLookupValueForValue(source, field);

  const suggestions = options
    .map((value) => {
      return {
        value: value.value,
        definition: value.definition,
        coefficient: Math.max(
          diceCoefficient(source, value.value),
          lookupValue ? diceCoefficient(lookupValue.name, value.value) : 0.0
        ),
      };
    })
    .filter((suggestion) => suggestion.coefficient > 0.5);

  suggestions.sort((a, b) => b.coefficient - a.coefficient);
  suggestions.splice(3);

  const [destination, setDestination] = React.useState<string | null>(
    suggestions.length ? suggestions[0]?.value : null
  );

  const [{ fetching }, mutation] =
    useRmMappingSetLookupBasicTransformMutation();
  const [, mutationAcknowledge] = useRmProblemAcknowledgeMutation();

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      source,
      destination,
    });
    if (!error) {
      updateNotification({
        notification: `Mapping updated`,
        notificationType: NotificationType.Success,
      });

      mutationAcknowledge({
        problemId: problem.id,
      });
    } else {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }
  }, [
    fetching,
    mutation,
    mutationAcknowledge,
    mapping,
    problem,
    source,
    destination,
    updateNotification,
  ]);

  return (
    <tr>
      <td>
        <ResourceMetadataLookupValueComponent field={field} value={source} />
      </td>
      <td>
        <LookupValueSelector
          value={destination}
          setValue={setDestination}
          suggestions={suggestions}
          options={options}
          disabled={isAcknowledged}
        />
        {!fetching && (
          <button onClick={() => setLookup()} disabled={isAcknowledged}>
            Set
          </button>
        )}
        {fetching && <LoadingIcon />}
      </td>

      <td>
        <ProblemStatus problem={problem} />
      </td>
    </tr>
  );
};

export default RMLookupBasicMappingTypeControl;
