import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useLeadQuery } from "../../graphql/generated";
import LeadSummary from "../components/summary";
import LeadTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const LeadShow: React.FC = () => {
  const params = useParams();

  const [{ data, error }] = useLeadQuery({
    variables: { id: params.leadId ?? "" },
    pause: !params.leadId,
  });

  useTitle(
    data?.lead?.account?.name ??
      data?.lead?.unauthenticatedDetails?.name ??
      params.leadId,
    "Leads"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <LeadTitle lead={data?.lead} />
          <LeadSummary lead={data?.lead} />
        </div>
      </Transition>
    </>
  );
};

export default LeadShow;
