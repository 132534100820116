import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../common/utils/dateformat";
import { ApiKeyFragment } from "../../graphql/generated";

const ApiKeyCard: React.FC<{ apiKey?: ApiKeyFragment }> = ({ apiKey }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-1 win-w-0">
        <Link to={`/api-keys/${apiKey?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {apiKey?.activeState}: {apiKey?.id}
          </p>
          <div className="grid grid-cols-3">
            <p className="text-xs text-gray-500 truncate">Created At:</p>
            <p className="text-xs text-gray-500 truncate col-span-2">
              {longDateFormat(apiKey?.createdAt)}
            </p>
            <p className="text-xs text-gray-500 truncate">Subscriptions:</p>
            <p className="text-xs text-gray-500 truncate col-span-2">
              {apiKey?.subscriptions.length}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ApiKeyCard;
