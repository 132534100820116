import { PaperClipIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import {
  AccountSubscriptionFragment,
  AccountSubscriptionStatus,
} from "../../graphql/generated";

const AccountSubscriptionCard: React.FC<{
  subscription?: AccountSubscriptionFragment;
}> = ({ subscription }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <PaperClipIcon
          className={classNames(
            subscription?.status === AccountSubscriptionStatus.Canceled
              ? "bg-red-100 border-red-300"
              : "bg-green-100 border-green-300",
            "h-10 w-10 rounded-full border-2"
          )}
        />
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/account_subscriptions/${subscription?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            Account: {subscription?.accountId}
          </p>
          <p className="text-sm font-medium text-gray-900 truncate">
            Id: {subscription?.id}
          </p>
          <p className="text-sm text-gray-500 truncate">
            Status: {subscription?.status}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default AccountSubscriptionCard;
