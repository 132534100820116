import React from "react";
import { Link } from "react-router-dom";
import {
  ReprocessDataTaskStatus,
  useReprocessDataTasksQuery,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";
import ReprocessDataTaskCard from "../../reprocess-data-task/components/card";
import LoadingIcon from "../../common/components/loadingicon";

const ReprocessDataTasksPanel: React.FC<{
  ids?: string[];
  statuses?: ReprocessDataTaskStatus[];
  limit?: number;
}> = ({ ids, statuses, limit }) => {
  const [{ data, error, fetching }] = useReprocessDataTasksQuery({
    variables: {
      mlsResourceIds: ids,
      statuses,
      limit,
    },
  });

  return (
    <Panel>
      <Panel.Title>
        Active reprocess tasks{" "}
        <Link
          to="/reprocess_data_tasks"
          className="text-xs text-gray text-underline"
        >
          View all »
        </Link>
      </Panel.Title>
      <Panel.Body>
        {fetching && <LoadingIcon />}
        {error && <div className="text-red-500">Failed to load details</div>}
        {data && (
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            {data?.reprocessDataTasks.map((task) => (
              <ReprocessDataTaskCard task={task} key={task.id} />
            ))}
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};

export default ReprocessDataTasksPanel;
