import React from "react";
import { Link } from "react-router-dom";
import { RmProblemConnectionFragment } from "../../graphql/generated";
import Panel from "../../common/components/panel";
import { CombinedError } from "urql";
import ProblemStatus from "./problem-status";
import { classNames } from "../../common/utils/classnames";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, FireIcon } from "@heroicons/react/24/outline";
import { problemUrl } from "./problem-url";

type Fetch = {
  fetch: () => void;
  canFetch: boolean;
  isFetching: boolean;
};

type DeclareBankruptcy = {
  declareBankruptcy: () => void;
  canDeclareBankruptcy: boolean;
  isDeclaringBankruptcy: boolean;
};

const Problems: React.FC<{
  data?: RmProblemConnectionFragment;
  error?: CombinedError;
  displayMls?: boolean;
  displayResource?: boolean;
  fetch?: Fetch;
  declareBankruptcy?: DeclareBankruptcy;
}> = ({ data, displayMls, displayResource, fetch, declareBankruptcy }) => {
  if (!data) {
    return <></>;
  }

  const showMls = !!displayMls;
  const showResource = !!displayResource;

  return (
    <Panel>
      <Panel.Title actions={<Actions declareBankruptcy={declareBankruptcy} />}>
        Problems
      </Panel.Title>
      <Panel.Body>
        <table className="w-full text-sm text-left">
          <thead>
            <tr>
              <th className="p-1">Field</th>
              <th className="p-1">Message</th>
              <th className="p-1">Count</th>
              <th className="p-1"></th>
            </tr>
          </thead>
          <tbody>
            {data.edges.map((edge) => {
              return (
                <tr key={edge.node.id} className="border-t">
                  <td className="p-1">
                    {showMls && (
                      <>
                        <Link
                          to={`/resource_mapping/mlses/${edge.node.rootResource.mls.id}`}
                        >
                          {edge.node.rootResource.mls.shortName}
                        </Link>{" "}
                        /{" "}
                      </>
                    )}
                    {showResource && (
                      <>
                        <Link
                          to={`/resource_mapping/roots/${edge.node.rootResource.id}`}
                        >
                          {edge.node.rootResource.name}
                        </Link>{" "}
                        /{" "}
                      </>
                    )}
                    <Link to={problemUrl(edge.node.id)}>
                      {edge.node.mapping?.field.name ??
                        edge.node.normalization?.field.name ??
                        "—"}
                    </Link>
                  </td>
                  <td className="p-1">{edge.node.message}</td>
                  <td className="p-1">{edge.node.count}</td>
                  <td className="p-1">
                    <ProblemStatus problem={edge.node} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {fetch && (
          <Panel.Footer>
            <button
              className={classNames(
                fetch.isFetching || !fetch.canFetch
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetch.fetch()}
              disabled={!fetch.canFetch}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
      </Panel.Body>
    </Panel>
  );
};

const Actions: React.FC<{
  declareBankruptcy?: DeclareBankruptcy;
}> = ({ declareBankruptcy }) => {
  if (!declareBankruptcy) {
    return <></>;
  }

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item
                disabled={
                  !declareBankruptcy.canDeclareBankruptcy ||
                  declareBankruptcy.isDeclaringBankruptcy
                }
              >
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => declareBankruptcy.declareBankruptcy()}
                  >
                    <FireIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Declare bankruptcy
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default Problems;
