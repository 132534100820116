import React from "react";
import {
  ReprocessDataTaskDetailsFragment,
  ReprocessDataTaskFragment,
} from "../../graphql/generated";
import ReprocessDataTaskPic from "./pic";
import ReprocessDataTaskItemActions from "./itemactions";

export function buildTaskTitle(task?: ReprocessDataTaskFragment): string {
  return `Reprocess ${task?.rootResource.mls.shortName} ${task?.rootResource.name}`;
}

export const ReprocessDataTaskTitle: React.FC<{
  task?: ReprocessDataTaskDetailsFragment;
}> = ({ task }) => {
  const title = buildTaskTitle(task);

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <ReprocessDataTaskPic task={task} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {title}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                {task?.id}
              </div>
            </div>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <ReprocessDataTaskItemActions task={task} />
          </div>
        </div>
      </div>
    </>
  );
};
