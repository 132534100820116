import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import { IdentityFragment } from "../../graphql/generated";

const IdentitySummary: React.FC<{
  identity?: IdentityFragment;
  password?: string;
}> = ({ identity, password }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListContainer title="Status">
            <StatusComponent entity={identity} />
          </DescriptionListContainer>
          <DescriptionListItem title="Type" value={identity?.identityType} />
          <DescriptionListItem title="Provider" value={identity?.provider} />
          <DescriptionListItem
            title="First Name"
            value={identity?.firstName ?? ""}
          />
          <DescriptionListItem
            title="Last Name"
            value={identity?.lastName ?? ""}
          />
          <DescriptionListItem title="Email" value={identity?.email} />
          <DescriptionListItem
            title="Account"
            value={identity?.account?.name}
            link={`/accounts/${identity?.account?.id}`}
          />
          {password && (
            <DescriptionListItem title="Temporary Password" value={password} />
          )}
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default IdentitySummary;
