import React, { useMemo } from "react";
import AuthProvider, { AuthContext } from "./auth/context/auth";
import SearchProvider from "./common/context/search";
import { Provider } from "urql";
import applicationClient from "./common/utils/urqlclient";
import Router from "./router";
import "./index.css";
import MlsProvider from "./mls/context/mls";
import NotificationProvider from "./common/context/notification";
import ClipboardProvider from "./common/context/clipboard";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App: React.FC = () => {
  const { authorized } = React.useContext(AuthContext);
  const client = useMemo(() => {
    return applicationClient();
  }, [authorized]);
  return (
    <NotificationProvider>
      <AuthProvider>
        <Provider value={client}>
          <GoogleOAuthProvider clientId="1003879098481-19vm7eeoqfuc0anlk3oosag4843ubuud.apps.googleusercontent.com">
            <SearchProvider>
              <MlsProvider>
                <ClipboardProvider>
                  <Router />
                </ClipboardProvider>
              </MlsProvider>
            </SearchProvider>
            {/* </LoginProvider> */}
          </GoogleOAuthProvider>
        </Provider>
      </AuthProvider>
    </NotificationProvider>
  );
};

export default App;
