import React from "react";
import {
  ListingHistoryItemFragment,
  useListingHideHistoryItemMutation,
  useListingUnhideHistoryItemMutation
} from "../../graphql/generated";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";


const ListingHistoryHiddenStatus: React.FC<{
  historyItem?: ListingHistoryItemFragment;
}> = ({ historyItem }) => {
  if (!historyItem) {
    return <></>;
  }

  const { updateNotification } = React.useContext(NotificationContext);
  const [, mutationHideHistoryItem] =
    useListingHideHistoryItemMutation();
  const [, mutationUnhideHistoryItem] =
    useListingUnhideHistoryItemMutation();

  const hideHistoryItem = React.useCallback(async () => {
    const { error } = await mutationHideHistoryItem({
      id: historyItem.id,
    });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }

  }, [mutationHideHistoryItem, historyItem]);

  const unhideHistoryItem = React.useCallback(async () => {

    const { error } = await mutationUnhideHistoryItem({
      id: historyItem.id,
    });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    }

  }, [mutationUnhideHistoryItem, historyItem]);


  if (historyItem.hidden) {
    return (
      <EyeSlashIcon
        className="h-5 w-5 text-black bg-red-300 border border-black cursor-pointer"
        onClick={unhideHistoryItem}
      />
    );
  }

  return (
    <EyeIcon
      className="h-5 w-5 text-black bg-green-300 border border-black cursor-pointer"
      onClick={hideHistoryItem}
    />
  );

};

export default ListingHistoryHiddenStatus;
