import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { longDateFormat } from "../../common/utils/dateformat";
import { GroupItemFragment } from "../../graphql/generated";

const GroupItemSummary: React.FC<{ item?: GroupItemFragment }> = ({ item }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem
            title="Group"
            value={item?.group?.nameLong}
            link={`/groups/${item?.group?.id}`}
          />
          <DescriptionListItem
            title="Listing"
            value={item?.listing.id}
            link={`/listings/${item?.listing.id}`}
          />
          <DescriptionListItem
            title="Owner"
            value={item?.owner?.name ?? "None"}
            link={item?.owner ? `/accounts/${item?.owner?.id}` : undefined}
          />
          <DescriptionListItem
            title="Created At"
            value={longDateFormat(item?.createdAt)}
          />
          <DescriptionListItem
            title="Modifed At"
            value={longDateFormat(item?.modifiedAt)}
          />
          <DescriptionListItem
            title="Modified Type"
            value={item?.modifiedType}
          />
          <DescriptionListItem
            title="Purged At"
            value={longDateFormat(item?.purgedAt)}
          />
          <DescriptionListItem
            title="Deleted At"
            value={longDateFormat(item?.deletedAt)}
          />
          <DescriptionListItem
            title="Deleted By"
            value={item?.deletedBy?.name ?? "None"}
            link={
              item?.deletedBy ? `/accounts/${item?.deletedBy?.id}` : undefined
            }
          />

          <DescriptionListItem
            title="Tour"
            value={item?.tour ? item?.tourId : ""}
          />
          <DescriptionListItem
            title="Tour At"
            value={longDateFormat(item?.tourAt)}
          />
          <DescriptionListItem
            title="Tour Owner"
            value={item?.tourOwner?.name ?? "None"}
            link={
              item?.tourOwner ? `/accounts/${item?.tourOwner?.id}` : undefined
            }
          />

          <DescriptionListItem
            title="Tour Requested At"
            value={longDateFormat(item?.tourRequestedAt)}
          />
          <DescriptionListItem
            title="Tour Requested By"
            value={item?.tourRequestedBy?.name ?? "None"}
            link={
              item?.tourRequestedBy
                ? `/accounts/${item?.tourRequestedBy?.id}`
                : undefined
            }
            span={2}
          />

          <DescriptionListItem
            title="Saved"
            value={item?.pinned ? "Saved" : ""}
          />
          <DescriptionListItem
            title="Saved At"
            value={longDateFormat(item?.pinnedAt)}
          />
          <DescriptionListItem
            title="Saved Owner"
            value={item?.pinnedOwner?.name ?? "None"}
            link={
              item?.pinnedOwner
                ? `/accounts/${item?.pinnedOwner?.id}`
                : undefined
            }
          />

          <DescriptionListItem
            title="Latest Note"
            value={item?.latestNote?.id}
            link={
              item?.latestNote
                ? `/group_notes/${item?.latestNote.id}`
                : undefined
            }
          />
          <DescriptionListItem
            title="Latest Note At"
            value={longDateFormat(item?.latestNoteAt)}
          />
          <DescriptionListItem
            title="Latest Note Owner"
            value={item?.latestNoteOwner?.name ?? "None"}
            link={
              item?.latestNoteOwner
                ? `/accounts/${item?.latestNoteOwner?.id}`
                : undefined
            }
          />

          <DescriptionListItem
            title="Chatted At"
            value={longDateFormat(item?.chattedAt)}
          />
          <DescriptionListItem
            title="Chatted By"
            value={item?.chattedBy?.name ?? "None"}
            link={
              item?.chattedBy ? `/accounts/${item?.chattedBy?.id}` : undefined
            }
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default GroupItemSummary;
