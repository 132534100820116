import { CircleStackIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Status } from "../../common/components/status";
import { classNames } from "../../common/utils/classnames";
import { InternalListingBucketFragment } from "../../graphql/generated";

const InternalListingBucketPic: React.FC<{
  bucket?: InternalListingBucketFragment;
  large?: boolean;
}> = ({ bucket, large }) => {
  const status = React.useMemo(() => {
    return Status.for(bucket);
  }, [bucket]);

  return (
    <CircleStackIcon
      className={classNames(
        status.color.picClasses(),
        large ? "h-20 w-20" : "h-10 w-10",
        "rounded-full border-2"
      )}
    />
  );
};

export default InternalListingBucketPic;
