import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { State } from ".";
import Panel from "../../../common/components/panel";
import { classNames } from "../../../common/utils/classnames";
import React from "react";
import {
  ListingInputDefinitionRuleAction,
  ListingInputDefinitionRuleFragment,
  ListingInputDefinitionRuleOverlayInput,
} from "../../../graphql/generated";
import { ZenDialog } from "../../../common/components/zen-dialog";

export const RulesPanel: React.FC<{
  state: State;
  setState: (state: State) => void;
  setIsModified: (isModified: boolean) => void;
}> = ({ state, setState, setIsModified }) => {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);

  const onRuleUpdateSubmit = React.useCallback(
    (update: ListingInputDefinitionRuleOverlayInput) => {
      state.ruleUpdates.set(update.id, update);
      setState(state);
      setIsModified(true);
    },
    [state, setState, setIsModified]
  );

  return (
    <Panel>
      <Panel.Title>
        <span
          onClick={() => setIsPanelOpen(!isPanelOpen)}
          className="cursor-pointer"
        >
          {isPanelOpen && <ChevronDownIcon className="inline h-5 w-5" />}
          {!isPanelOpen && <ChevronRightIcon className="inline h-5 w-5" />}
          Rules
        </span>
      </Panel.Title>
      <Panel.Body summary className={classNames(isPanelOpen ? "" : "hidden")}>
        <div>
          {Array.from(state.rules).map(([, item]) => (
            <RuleElement
              rule={item}
              state={state}
              onRuleUpdateSubmit={onRuleUpdateSubmit}
              key={item.id}
            />
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

const RuleElement: React.FC<{
  rule: ListingInputDefinitionRuleFragment;
  state: State;
  onRuleUpdateSubmit: (update: ListingInputDefinitionRuleOverlayInput) => void;
}> = ({ rule, state, onRuleUpdateSubmit }) => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const existingUpdate = state.ruleUpdates.get(rule.id);
  const isModified =
    !!existingUpdate && (existingUpdate.message || !!existingUpdate.disabled);

  const field = state.fields.get(rule.field);
  const fieldDisplayName =
    field?.overlayDisplayName ??
    field?.sourceDisplayName ??
    field?.name ??
    rule.field;
  const fieldName =
    fieldDisplayName !== rule.field
      ? `${fieldDisplayName} (${rule.field})`
      : rule.field;

  const onEditSubmit = React.useCallback(
    (update: ListingInputDefinitionRuleOverlayInput) => {
      onRuleUpdateSubmit(update);
      setEditDialogOpen(false);
    },
    [setEditDialogOpen, onRuleUpdateSubmit]
  );

  return (
    <>
      <div className="p-1">
        <div
          className={classNames(
            "rounded-2 bg-gray-100 border border-gray-200 p-2 text-sm"
          )}
        >
          <div className="flex gap-1">
            <div className="grow">
              <div className="flex mt-1 gap-1">
                <span>{fieldName}</span>
                <span className="text-xs bg-gray-200 border border-gray-300 px-1 py-0.5">
                  {rule.action}
                </span>
                {isModified && (
                  <span className="text-xs bg-red-200 border border-red-300 px-1 py-0.5">
                    Modified
                  </span>
                )}
              </div>
              <div className="text-xs text-gray-500">
                {existingUpdate?.message ??
                  rule.overlayMessage ??
                  rule.sourceMessage}
              </div>
            </div>
            <div
              className="flex-none cursor-pointer"
              onClick={() => setEditDialogOpen(true)}
            >
              <PencilIcon className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>

      <EditRuleDialog
        isOpen={editDialogOpen}
        rule={rule}
        state={state}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={onEditSubmit}
      />
    </>
  );
};

const EditRuleDialog: React.FC<{
  isOpen: boolean;
  rule: ListingInputDefinitionRuleFragment;
  state: State;
  onClose: () => void;
  onSubmit: (update: ListingInputDefinitionRuleOverlayInput) => void;
}> = ({ isOpen, rule, state, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);
  const existingUpdate = state.ruleUpdates.get(rule.id);

  const [overlayMessage, setOverlayMessage] = React.useState(
    existingUpdate?.message ?? rule.overlayMessage ?? ""
  );
  const [useOverlayMessage, setUseOverlayMessage] = React.useState(
    existingUpdate ? !!existingUpdate?.message : !!rule?.overlayMessage
  );
  const [disabled, setDisabled] = React.useState(
    existingUpdate?.disabled ?? rule.overlayDisabled
  );

  const handleSubmit = React.useCallback(() => {
    const message =
      useOverlayMessage && !!overlayMessage ? overlayMessage : null;
    const update: ListingInputDefinitionRuleOverlayInput = {
      id: rule.id,
      disabled,
      message,
    };
    onSubmit(update);
  }, [rule.id, onSubmit, overlayMessage, useOverlayMessage, disabled]);

  const onMessageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOverlayMessage(e.target.value);
      setUseOverlayMessage(true);
    },
    [setOverlayMessage, setUseOverlayMessage]
  );

  return (
    <ZenDialog
      show={isOpen}
      title="Update rule"
      icon={PencilSquareIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">ID</div>
        <div className="font-bold">{rule.id}</div>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Field</div>
        <div className="font-bold">{rule.field}</div>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Action</div>
        <div className="font-bold">{rule.action}</div>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Expression</div>
        <div className="font-bold">{rule.expression}</div>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Message</div>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={!useOverlayMessage}
            onChange={() => setUseOverlayMessage(false)}
          />{" "}
          Source: <span className="font-bold">{rule.sourceMessage}</span>
        </label>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={useOverlayMessage}
            onChange={() => setUseOverlayMessage(true)}
          />{" "}
          Override:{" "}
          <input
            type="text"
            value={overlayMessage}
            onChange={onMessageChange}
          />
        </label>
      </div>

      {(rule.action === ListingInputDefinitionRuleAction.SetPicklist ||
        rule.action === ListingInputDefinitionRuleAction.RestrictPicklist) && (
        <div className="p-1 my-3">
          <div className="text-lg font-light mb-1">Value</div>
          <PicklistValues
            fieldName={rule.field}
            value={rule.value}
            state={state}
          />
        </div>
      )}

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Enabled</div>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={!disabled}
            onChange={() => setDisabled(false)}
          />{" "}
          Enabled
        </label>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={disabled}
            onChange={() => setDisabled(true)}
          />{" "}
          Disabled
        </label>
      </div>
    </ZenDialog>
  );
};

const PicklistValues: React.FC<{
  fieldName: string;
  value: string[] | null | undefined;
  state: State;
}> = ({ fieldName, value, state }) => {
  const field = state.fields.get(fieldName);

  return (
    <ul>
      {value &&
        value.map((value) => {
          const lookup = field?.lookups.find((lookup) => lookup.name === value);
          if (lookup) {
            return (
              <li key={value}>
                {value} ({lookup.sourceDisplayName ?? lookup.name})
              </li>
            );
          } else {
            return <li key={value}>{value}</li>;
          }
        })}
    </ul>
  );
};
