import React from "react";
import {
  IngestTaskFinishMode,
  IngestTaskFragment,
  IngestTaskTarget,
} from "../../graphql/generated";
import IngestTaskItemActions from "./itemactions";
import IngestTaskPic from "./pic";

export function buildTaskTitle(task?: IngestTaskFragment): string {
  return `${taskType(task?.finishMode)} task on ${
    task?.mls.shortName
  } ${taskTarget(task?.target)}`;
}

export const IngestTaskTitle: React.FC<{
  task?: IngestTaskFragment;
  refetch: () => void;
}> = ({ task, refetch }) => {
  const title = buildTaskTitle(task);

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <IngestTaskPic task={task} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {title}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                {task?.id}
              </div>
            </div>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <IngestTaskItemActions task={task} refetch={refetch} />
          </div>
        </div>
      </div>
    </>
  );
};

function taskType(mode?: IngestTaskFinishMode): string {
  if (!mode) {
    return "Unknown";
  }

  switch (mode) {
    case IngestTaskFinishMode.Finish:
      return "Backfill";
    case IngestTaskFinishMode.Follow:
      return "Production";
  }
}

function taskTarget(target?: IngestTaskTarget): string {
  if (!target) {
    return "entities";
  }

  switch (target) {
    case IngestTaskTarget.Agent:
      return "agents";
    case IngestTaskTarget.BrokerTour:
      return "broker tours";
    case IngestTaskTarget.Office:
      return "offices";
    case IngestTaskTarget.Openhouse:
      return "open houses";
    case IngestTaskTarget.Property:
      return "listings";
    default: 
      return "entitites"
  }
  
}
