import React from "react";
import { Link } from "react-router-dom";
import { naiveDateFormat } from "../../common/utils/dateformat";
import { TourFragment } from "../../graphql/generated";
import TourPic from "./tourpic";

const TourCard: React.FC<{
  tour: TourFragment | undefined;
}> = ({ tour }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <TourPic tour={tour} />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/tours/${tour?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {naiveDateFormat(tour?.date)}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default TourCard;
