import React from "react";
import { distanceDateFormat } from "../../common/utils/dateformat";
import {
  HistogramInterval,
  HistogramRange,
  useReferralCreatedSummaryQuery,
  useReferralActiveSummaryQuery,
} from "../../graphql/generated";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { ChartData } from "chart.js";
import { Transition } from "@headlessui/react";
import Loading from "../../common/components/loading";

interface CombinedData {
  [key: string]: CombinedValues;
}

interface CombinedValues {
  bucketDate: string;
  created: number;
  active: number;
  accepted: number;
}

const ReferralStats: React.FC<{
  interval: HistogramInterval;
  range: HistogramRange;
}> = ({ interval, range }) => {
  const [lastUpdated, setLastUpdated] = React.useState(new Date());

  const [{ data, fetching }, executeQuery] = useReferralCreatedSummaryQuery({
    variables: { interval, range },
  });

  const [{ data: activeData, fetching: activeFetching }, activeExecuteQuery] =
    useReferralActiveSummaryQuery({ variables: { interval, range } });

  const combinedData = React.useMemo(() => {
    if (data && activeData) {
      let combined = data.referralCreatedSummary.intervals.reduce(
        (obj: CombinedData, item) => {
          obj[item.bucketDate] = obj[item.bucketDate] ?? {
            bucketDate: item.bucketDate,
            created: 0,
            active: 0,
          };
          obj[item.bucketDate].created = item.totalCount;
          return obj;
        },
        {}
      );
      combined = activeData.referralActiveSummary.intervals.reduce(
        (obj, item) => {
          obj[item.bucketDate] = obj[item.bucketDate] ?? {
            bucketDate: item.bucketDate,
            created: 0,
            active: 0,
          };
          obj[item.bucketDate].active = item.totalCount;
          return obj;
        },
        combined
      );

      return Object.values(combined).sort((a, b) =>
        a.bucketDate < b.bucketDate ? -1 : 1
      );
    }
    return [];
  }, [data, activeData]);

  const chartData = React.useMemo<ChartData<"line", number[], Date>>(() => {
    return {
      labels: combinedData.map((d) => new Date(d.bucketDate)),
      datasets: [
        {
          label: `Created: ${combinedData.reduce((s, d) => s + d.created, 0)}`,
          data: combinedData.map((d) => d.created),
          borderColor: "rgb(239, 68, 68)",
          backgroundColor: "rgba(239, 68, 68, 0.5)",
        },
        {
          label: `Active: ${combinedData.reduce((s, d) => s + d.active, 0)}`,
          data: combinedData.map((d) => d.active),
          borderColor: "rgb(52, 211, 153)",
          backgroundColor: "rgba(52, 211, 153, 0.5)",
        },
      ],
    };
  }, [combinedData]);

  React.useEffect(() => {
    if (!fetching && !activeFetching) {
      const id = setTimeout(() => {
        setLastUpdated(new Date());
        executeQuery({ requestPolicy: "network-only" });
        activeExecuteQuery({ requestPolicy: "network-only" });
      }, 30000);
      return () => clearTimeout(id);
    }
  }, [executeQuery, fetching, activeExecuteQuery, activeFetching]);

  return (
    <>
      <Loading show={!data && !activeData} />
      <Transition
        show={!!data && !!activeData}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="bg-white shadow overflow-hidden sm:rounded-md mt-4">
          <div className="px-4 my-2 sm:px-6 flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 grow">
              Referrals
              <span className="text-xs text-gray-500">
                {" · "}
                Updated {distanceDateFormat(lastUpdated.toISOString())}
              </span>
            </h3>
          </div>
          <div className="p-4 border-t border-gray-200">
            {data && (
              <div className="col-span-3 h-56">
                <Line
                  options={{
                    maintainAspectRatio: false,
                    showLine: true,
                    interaction: { mode: "index" },
                    scales: {
                      x: {
                        type: "time",
                      },
                    },
                  }}
                  data={chartData}
                />
              </div>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default ReferralStats;
