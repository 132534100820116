import React from "react";
import Panel from "../../common/components/panel";
import { AccountQuery } from "../../graphql/generated";
import LenderCard from "../../lender/components/card";

const AccountLenders: React.FC<{
  account?: AccountQuery["account"];
}> = ({ account }) => {
  return (
    <Panel>
      <Panel.Title>
        Linked Lender: 
        <span className="text-xs text-gray-500"> {account?.lender ? 1 : 0}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {account?.lender && <LenderCard lender={account?.lender} key={account?.lender?.id} />}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountLenders;
