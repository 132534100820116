import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import ProductOfferingSummary from "../components/summary";
import { useProductOfferingQuery } from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";

const ProductOfferingShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useProductOfferingQuery({
    variables: { id: params.productOfferingId ?? "" },
  });
  useTitle(params.productOfferingId, "Product Offerings");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ProductOfferingSummary productOffering={data?.productOffering} />
        </div>
      </Transition>
    </>
  );
};

export default ProductOfferingShow;
