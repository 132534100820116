import React from "react";
import { useParams } from "react-router-dom";
import ListingMap from "../components/map";
import { useListingQuery } from "../../graphql/generated";
import Loading from "../../common/components/loading";
import ListingTitle from "../components/title";
import { useJsApiLoader } from "@react-google-maps/api";
import { Transition } from "@headlessui/react";
import { useTitle } from "../../common/utils/title";

const LIBRARIES = ["places"];
const GOOGLE_MAPS_API_KEY = "AIzaSyCJ6zBl9hXXeiYo8YXBUsmXB590E1Lp6Nw";

const ListingGeocode: React.FC = () => {
  const params = useParams();
  const [{ data }] = useListingQuery({
    variables: { id: params.listingId ?? "" },
    pause: !params.listingId,
  });
  useTitle("Geocode", params.listingId, "Listings");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    libraries: LIBRARIES as any,
  });

  // isLoaded && !fetching && data?.listing ? (
  return (
    <>
      <Loading show={!isLoaded || !data} />
      <Transition
        show={isLoaded && !!data}
        appear
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ListingTitle listing={data?.listing} />
          {isLoaded && data?.listing && <ListingMap listing={data?.listing} />}
        </div>
      </Transition>
    </>
  );
};

export default ListingGeocode;
