import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import Panel from "../../../common/components/panel";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../../common/utils/classnames";
import { FieldItem, HeaderItem, Item, nanoid, State, SubheaderItem } from ".";
import { ZenDialog } from "../../../common/components/zen-dialog";
import {
  ListingInputDefinitionControlOverlayInput,
  ListingInputDefinitionControlType,
  ListingInputDefinitionFieldType,
} from "../../../graphql/generated";

export const ControlsPanel: React.FC<{
  state: State;
  setState: (state: State) => void;
  setIsModified: (isModified: boolean) => void;
}> = ({ state, setState, setIsModified }) => {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [addHeaderOpen, setAddHeaderOpen] = React.useState(false);
  const [addSubheaderOpen, setAddSubheaderOpen] = React.useState(false);

  const onAddHeaderSubmit = React.useCallback(
    (newHeader: HeaderItem) => {
      state.orderedItems.push(newHeader);
      setAddHeaderOpen(false);
      setIsModified(true);
    },
    [state, setAddHeaderOpen, setIsModified]
  );

  const onAddSubheaderSubmit = React.useCallback(
    (newSubheader: SubheaderItem) => {
      state.orderedItems.push(newSubheader);
      setAddSubheaderOpen(false);
      setIsModified(true);
    },
    [state, setAddSubheaderOpen, setIsModified]
  );

  const onItemUpdateSubmit = React.useCallback(
    (item: Item) => {
      for (const i of state.orderedItems) {
        if (i.type === item.type && i.id === item.id) {
          switch (i.type) {
            case "header":
              i.name = item.name;
              i.expandedByDefault = (item as HeaderItem).expandedByDefault;
              break;
            case "subheader":
              i.name = item.name;
              break;
          }
        }
      }

      setState(state);
      setIsModified(true);
    },
    [state, setState, setIsModified]
  );

  const onControlUpdateSubmit = React.useCallback(
    (update: ListingInputDefinitionControlOverlayInput) => {
      state.controlUpdates.set(update.name, update);
      setState(state);
      setIsModified(true);
    },
    [state, setState, setIsModified]
  );

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      let item: Item;
      if (result.source.droppableId === "order") {
        const [removedItem] = state.orderedItems.splice(result.source.index, 1);
        item = removedItem;
      } else if (result.source.droppableId === "hidden") {
        const [removedItem] = state.hiddenItems.splice(result.source.index, 1);
        item = removedItem;
      } else if (result.source.droppableId === "new") {
        const [removedItem] = state.newItems.splice(result.source.index, 1);
        item = removedItem;
      } else {
        return;
      }

      if (result.destination.droppableId === "order") {
        state.orderedItems.splice(result.destination.index, 0, item);
      }
      if (result.destination.droppableId === "hidden") {
        state.hiddenItems.splice(result.destination.index, 0, item);
      }
      if (result.destination.droppableId === "new") {
        state.newItems.splice(result.destination.index, 0, item);
      }

      setState(state);
      setIsModified(true);
    },
    [state, setState, setIsModified]
  );

  return (
    <>
      <Panel>
        <Panel.Title>
          <span
            onClick={() => setIsPanelOpen(!isPanelOpen)}
            className="cursor-pointer"
          >
            {isPanelOpen && <ChevronDownIcon className="inline h-5 w-5" />}
            {!isPanelOpen && <ChevronRightIcon className="inline h-5 w-5" />}
            Controls
          </span>
        </Panel.Title>
        <Panel.Body summary className={classNames(isPanelOpen ? "" : "hidden")}>
          <div className="my-4">
            <button
              type="button"
              className="bg-zenlist-500 hover:bg-zenlist-700 text-white font-bold py-1 px-4 rounded mx-1"
              onClick={() => setAddHeaderOpen(true)}
            >
              Add header
            </button>
            <button
              type="button"
              className="bg-zenlist-500 hover:bg-zenlist-700 text-white font-bold py-1 px-4 rounded mx-1"
              onClick={() => setAddSubheaderOpen(true)}
            >
              Add subheader
            </button>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="grid grid-cols-3 gap-4">
              <div
                className="grid self-stretch"
                style={{ gridTemplateRows: "auto 1fr" }}
              >
                <h2 className="m-2">↕️ Order</h2>
                <Droppable droppableId="order">
                  {(provided: DroppableProvided) => (
                    <div
                      className="border border-dashed border-gray-300 self-stretch"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {state.orderedItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => (
                            <ItemElement
                              item={item}
                              state={state}
                              provided={provided}
                              snapshot={snapshot}
                              onItemUpdateSubmit={onItemUpdateSubmit}
                              onControlUpdateSubmit={onControlUpdateSubmit}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div
                className="grid self-stretch"
                style={{ gridTemplateRows: "auto 1fr" }}
              >
                <h2 className="m-2">🌟 New</h2>
                <Droppable droppableId="new">
                  {(provided: DroppableProvided) => (
                    <div
                      className="droppable border border-dashed border-gray-300 self-stretch"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {state.newItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => (
                            <ItemElement
                              item={item}
                              state={state}
                              provided={provided}
                              snapshot={snapshot}
                              onItemUpdateSubmit={onItemUpdateSubmit}
                              onControlUpdateSubmit={onControlUpdateSubmit}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div
                className="grid self-stretch"
                style={{ gridTemplateRows: "auto 1fr" }}
              >
                <h2 className="m-2">🙈 Hidden</h2>
                <Droppable droppableId="hidden">
                  {(provided: DroppableProvided) => (
                    <div
                      className="border border-dashed border-gray-300 self-stretch"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {state.hiddenItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => (
                            <ItemElement
                              item={item}
                              state={state}
                              provided={provided}
                              snapshot={snapshot}
                              onItemUpdateSubmit={onItemUpdateSubmit}
                              onControlUpdateSubmit={onControlUpdateSubmit}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </Panel.Body>
      </Panel>

      <NewHeaderDialog
        isOpen={addHeaderOpen}
        onClose={() => setAddHeaderOpen(false)}
        onSubmit={onAddHeaderSubmit}
      />
      <NewSubheaderDialog
        isOpen={addSubheaderOpen}
        onClose={() => setAddSubheaderOpen(false)}
        onSubmit={onAddSubheaderSubmit}
      />
    </>
  );
};

const ItemElement: React.FC<{
  item: Item;
  state: State;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onItemUpdateSubmit: (item: Item) => void;
  onControlUpdateSubmit: (
    control: ListingInputDefinitionControlOverlayInput
  ) => void;
}> = ({
  item,
  state,
  provided,
  snapshot,
  onItemUpdateSubmit,
  onControlUpdateSubmit,
}) => {
  switch (item.type) {
    case "header":
      return (
        <HeaderItemElement
          item={item}
          provided={provided}
          snapshot={snapshot}
          onItemUpdateSubmit={onItemUpdateSubmit}
        />
      );
    case "subheader":
      return (
        <SubheaderItemElement
          item={item}
          provided={provided}
          snapshot={snapshot}
          onItemUpdateSubmit={onItemUpdateSubmit}
        />
      );
    case "field":
      return (
        <FieldItemElement
          item={item}
          state={state}
          provided={provided}
          snapshot={snapshot}
          onControlUpdateSubmit={onControlUpdateSubmit}
        />
      );
  }
};

const HeaderItemElement: React.FC<{
  item: HeaderItem;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onItemUpdateSubmit: (item: Item) => void;
}> = ({ item, provided, snapshot, onItemUpdateSubmit }) => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const onHeaderUpdateSubmit = React.useCallback(
    (header: HeaderItem) => {
      onItemUpdateSubmit(header);
      setEditDialogOpen(false);
    },
    [setEditDialogOpen, onItemUpdateSubmit]
  );

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="p-1"
        onDoubleClick={() => setEditDialogOpen(true)}
      >
        <div
          className={classNames(
            "rounded-2 bg-gray-300 border border-gray-400 p-2 text-sm",
            snapshot.isDragging ? "shadow-md" : "shadow-sm"
          )}
        >
          <div className="flex mt-1 gap-1">
            <span>{item.name}</span>
            <span className="text-xs bg-gray-400 border border-gray-500 px-1 py-0.5">
              header
            </span>
            {item.expandedByDefault && (
              <span className="text-xs bg-gray-400 border border-gray-500 px-1 py-0.5">
                expanded
              </span>
            )}
          </div>
        </div>
      </div>
      <EditHeaderDialog
        isOpen={editDialogOpen}
        item={item}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={onHeaderUpdateSubmit}
      />
    </>
  );
};

const SubheaderItemElement: React.FC<{
  item: SubheaderItem;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onItemUpdateSubmit: (item: Item) => void;
}> = ({ item, provided, snapshot, onItemUpdateSubmit }) => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const onSubheaderUpdateSubmit = React.useCallback(
    (subheader: SubheaderItem) => {
      onItemUpdateSubmit(subheader);
      setEditDialogOpen(false);
    },
    [setEditDialogOpen, onItemUpdateSubmit]
  );

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="p-1 pl-3"
        onDoubleClick={() => setEditDialogOpen(true)}
      >
        <div
          className={classNames(
            "rounded-2 bg-gray-200 border border-gray-300 p-2 text-sm",
            snapshot.isDragging ? "shadow-md" : "shadow-sm"
          )}
        >
          <div className="flex mt-1 gap-1">
            <span>{item.name}</span>
            <span className="text-xs bg-gray-300 border border-gray-400 px-1 py-0.5">
              subheader
            </span>
          </div>
        </div>
      </div>
      <EditSubheaderDialog
        isOpen={editDialogOpen}
        item={item}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={onSubheaderUpdateSubmit}
      />
    </>
  );
};

const FieldItemElement: React.FC<{
  item: FieldItem;
  state: State;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onControlUpdateSubmit: (
    control: ListingInputDefinitionControlOverlayInput
  ) => void;
}> = ({ item, state, provided, snapshot, onControlUpdateSubmit }) => {
  const existingUpdate = state.controlUpdates.get(item.name);
  const field = state.fields.get(item.name);
  const name =
    existingUpdate?.displayName ??
    field?.overlayDisplayName ??
    field?.sourceDisplayName ??
    item.name;
  const display = name === item.name ? name : `${name} (${item.name})`;
  const controlTypeValue =
    existingUpdate?.controlType ??
    field?.overlayControlType ??
    field?.sourceControlType;
  const controlType = controlTypeValue
    ? controlTypeName(controlTypeValue)
    : "???";
  const isModified = !!existingUpdate;

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const onSubmit = React.useCallback(
    (control: ListingInputDefinitionControlOverlayInput) => {
      onControlUpdateSubmit(control);
      setEditDialogOpen(false);
    },
    [setEditDialogOpen, onControlUpdateSubmit]
  );

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="p-1 pl-5"
        onDoubleClick={() => setEditDialogOpen(true)}
      >
        <div
          className={classNames(
            "rounded-2 bg-gray-100 border border-gray-200 p-2 text-sm",
            snapshot.isDragging ? "shadow-md" : "shadow-sm"
          )}
        >
          <div className="flex mt-1 gap-1">
            <span>{display}</span>
            {isModified && (
              <span className="text-xs bg-red-200 border border-red-300 px-1 py-0.5">
                Modified
              </span>
            )}
            <span className="text-xs bg-gray-200 border border-gray-300 px-1 py-0.5">
              {controlType}
            </span>
          </div>
        </div>
      </div>
      <EditControlDialog
        isOpen={editDialogOpen}
        item={item}
        state={state}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

const NewHeaderDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (item: HeaderItem) => void;
}> = ({ isOpen, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);
  const [name, setName] = React.useState("New Header");
  const [expandedByDefault, setExpandedByDefault] = React.useState(false);

  const handleChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };
  const handleChecked = (e: { target: { checked: boolean } }) => {
    setExpandedByDefault(e.target.checked);
  };

  const handleSubmit = React.useCallback(() => {
    const item: HeaderItem = {
      type: "header",
      id: `header_${nanoid()}`,
      name: name,
      expandedByDefault: expandedByDefault,
    };
    onSubmit(item);
  }, [onSubmit, name, expandedByDefault]);

  return (
    <ZenDialog
      show={isOpen}
      title="Add header"
      icon={PlusCircleIcon}
      submit="Add"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="mt-1">
        <input
          ref={inputRef}
          type="text"
          name="name"
          id="name"
          value={name}
          onChange={handleChange}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="mt-1">
        <label>
          <input
            type="checkbox"
            name="slug"
            id="slug"
            checked={expandedByDefault}
            onChange={handleChecked}
          />{" "}
          Expanded by default?
        </label>
      </div>
    </ZenDialog>
  );
};

const NewSubheaderDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (item: SubheaderItem) => void;
}> = ({ isOpen, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);
  const [name, setName] = React.useState("New Subheader");

  const handleChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };

  const handleSubmit = React.useCallback(() => {
    const item: SubheaderItem = {
      type: "subheader",
      id: `subheader_${nanoid()}`,
      name: name,
    };
    onSubmit(item);
  }, [onSubmit, name]);

  return (
    <ZenDialog
      show={isOpen}
      title="Add sub-header"
      icon={PlusCircleIcon}
      submit="Add"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <input
        ref={inputRef}
        type="text"
        name="name"
        id="name"
        value={name}
        onChange={handleChange}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </ZenDialog>
  );
};

const EditHeaderDialog: React.FC<{
  isOpen: boolean;
  item: HeaderItem;
  onClose: () => void;
  onSubmit: (item: HeaderItem) => void;
}> = ({ isOpen, item, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);
  const [name, setName] = React.useState(item.name);
  const [expandedByDefault, setExpandedByDefault] = React.useState(
    item?.expandedByDefault
  );

  const handleChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };
  const handleChecked = (e: { target: { checked: boolean } }) => {
    setExpandedByDefault(e.target.checked);
  };

  const handleSubmit = React.useCallback(() => {
    const updatedItem: HeaderItem = {
      type: "header",
      id: item.id,
      name: name,
      expandedByDefault: expandedByDefault,
    };
    onSubmit(updatedItem);
  }, [onSubmit, item, name, expandedByDefault]);

  return (
    <ZenDialog
      show={isOpen}
      title="Update header"
      icon={PencilSquareIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="mt-1">
        <input
          ref={inputRef}
          type="text"
          name="name"
          id="name"
          value={name}
          onChange={handleChange}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <div className="mt-1">
        <label>
          <input
            type="checkbox"
            name="slug"
            id="slug"
            checked={expandedByDefault}
            onChange={handleChecked}
          />{" "}
          Expanded by default?
        </label>
      </div>
    </ZenDialog>
  );
};

const EditSubheaderDialog: React.FC<{
  isOpen: boolean;
  item: SubheaderItem;
  onClose: () => void;
  onSubmit: (item: SubheaderItem) => void;
}> = ({ isOpen, item, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);
  const [name, setName] = React.useState(item.name);

  const handleChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };

  const handleSubmit = React.useCallback(() => {
    const updatedItem: SubheaderItem = {
      type: "subheader",
      id: item.id,
      name: name,
    };
    onSubmit(updatedItem);
  }, [onSubmit, item, name]);

  return (
    <ZenDialog
      show={isOpen}
      title="Update sub-header"
      icon={PencilSquareIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <input
        ref={inputRef}
        type="text"
        name="name"
        id="name"
        value={name}
        onChange={handleChange}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </ZenDialog>
  );
};

const EditControlDialog: React.FC<{
  isOpen: boolean;
  item: FieldItem;
  state: State;
  onClose: () => void;
  onSubmit: (control: ListingInputDefinitionControlOverlayInput) => void;
}> = ({ isOpen, item, state, onClose, onSubmit }) => {
  const inputRef = React.useRef(null);
  const existingUpdate = state.controlUpdates.get(item.name);
  const field = state.fields.get(item.name);
  const [overlayDisplayName, setOverlayDisplayName] = React.useState(
    existingUpdate?.displayName ?? field?.overlayDisplayName ?? ""
  );
  const [overlayDefinition, setOverlayDefinition] = React.useState(
    existingUpdate?.displayName ?? field?.overlayDefinition ?? ""
  );
  const [overlayControlType, setOverlayControlType] = React.useState(
    existingUpdate?.controlType ??
      field?.overlayControlType ??
      ListingInputDefinitionControlType.Boolean
  );
  const [useOverlayDisplayName, setUseOverlayDisplayName] = React.useState(
    existingUpdate ? !!existingUpdate?.displayName : !!field?.overlayDisplayName
  );
  const [useOverlayDefinition, setUseOverlayDefinition] = React.useState(
    existingUpdate ? !!existingUpdate?.definition : !!field?.overlayDefinition
  );
  const [useOverlayControlType, setUseOverlayControlType] = React.useState(
    existingUpdate ? !!existingUpdate?.controlType : !!field?.overlayControlType
  );

  const onDisplayNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOverlayDisplayName(e.target.value);
      setUseOverlayDisplayName(true);
    },
    [setOverlayDisplayName, setUseOverlayDisplayName]
  );

  const onDefinitionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOverlayDefinition(e.target.value);
      setUseOverlayDefinition(true);
    },
    [setOverlayDefinition, setUseOverlayDefinition]
  );

  const onControlTypeChange = React.useCallback(
    (e: ListingInputDefinitionControlType) => {
      setOverlayControlType(e);
      setUseOverlayControlType(true);
    },
    [setOverlayControlType, setUseOverlayControlType]
  );

  const handleSubmit = React.useCallback(() => {
    const displayName =
      useOverlayDisplayName && !!overlayDisplayName ? overlayDisplayName : null;
    const definition =
      useOverlayDefinition && !!overlayDefinition ? overlayDefinition : null;
    const controlType =
      useOverlayControlType && !!overlayControlType ? overlayControlType : null;
    const isRequired = field?.overlayIsRequired;
    const update: ListingInputDefinitionControlOverlayInput = {
      name: item.name,
      displayName,
      definition,
      controlType,
      isRequired,
    };
    onSubmit(update);
  }, [
    onSubmit,
    item,
    field,
    overlayDisplayName,
    useOverlayDisplayName,
    overlayDefinition,
    useOverlayDefinition,
    overlayControlType,
    useOverlayControlType,
  ]);

  if (!field) {
    return (
      <ZenDialog
        show={isOpen}
        title="Update field"
        icon={PencilSquareIcon}
        submit="Update"
        onSubmit={() => null}
        onCancel={onClose}
      >
        ???
      </ZenDialog>
    );
  }

  return (
    <ZenDialog
      show={isOpen}
      title="Update field"
      icon={PencilSquareIcon}
      submit="Update"
      onSubmit={handleSubmit}
      onCancel={onClose}
      initialFocus={inputRef}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">MLS name</div>
        <div className="font-bold">{item.name}</div>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Field type</div>
        <div className="font-bold">{field.fieldType}</div>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Display name</div>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={!useOverlayDisplayName}
            onChange={() => setUseOverlayDisplayName(false)}
          />{" "}
          Source:{" "}
          <span className="font-bold">
            {field.sourceDisplayName ?? field.name}
          </span>
        </label>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={useOverlayDisplayName}
            onChange={() => setUseOverlayDisplayName(true)}
          />{" "}
          Override:{" "}
          <input
            type="text"
            value={overlayDisplayName}
            onChange={onDisplayNameChange}
          />
        </label>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Definition</div>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={!useOverlayDefinition}
            onChange={() => setUseOverlayDefinition(false)}
          />{" "}
          Source:{" "}
          <span className="font-bold">
            {field.sourceDefinition ?? field.sourceDisplayName ?? field.name}
          </span>
        </label>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={useOverlayDefinition}
            onChange={() => setUseOverlayDefinition(true)}
          />{" "}
          Override:{" "}
          <input
            type="text"
            value={overlayDefinition}
            onChange={onDefinitionChange}
          />
        </label>
      </div>

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Control</div>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={!useOverlayControlType}
            onChange={() => setUseOverlayControlType(false)}
          />{" "}
          Source:{" "}
          <span className="font-bold">
            {controlTypeName(field.sourceControlType)}
          </span>
        </label>
        <label className="block py-1">
          <input
            type="radio"
            className="mr-1"
            checked={useOverlayControlType}
            onChange={() => setUseOverlayControlType(true)}
          />{" "}
          Override:{" "}
          <ControlTypeSelect
            fieldType={field.fieldType}
            selected={overlayControlType}
            onChange={onControlTypeChange}
          />
        </label>
      </div>

      {(field.fieldType === ListingInputDefinitionFieldType.Lookup ||
        field.fieldType === ListingInputDefinitionFieldType.LookupMulti) && (
        <div className="p-1 my-3">
          <div className="text-lg font-light mb-1">Lookups</div>
          <ul>
            {field.lookups.map((lookup) => {
              return (
                <li key={lookup.name}>
                  {lookup.sourceDisplayName ?? lookup.name} ({lookup.name})
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Property types</div>
        <ul>
          {Array.from(state.propertyTypes.values()).map((propertyType) => {
            if (
              field.propertyTypes.some(
                (fieldPropertyType) => fieldPropertyType === propertyType.name
              )
            ) {
              return (
                <li key={propertyType.name}>
                  {propertyType.overlayDisplayName ??
                    propertyType.sourceDisplayName ??
                    propertyType.name}
                </li>
              );
            } else {
              return (
                <li key={propertyType.name}>
                  <s>
                    {propertyType.overlayDisplayName ??
                      propertyType.sourceDisplayName ??
                      propertyType.name}
                  </s>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </ZenDialog>
  );
};

const ControlTypeSelect: React.FC<{
  fieldType: ListingInputDefinitionFieldType;
  selected: ListingInputDefinitionControlType;
  onChange: (value: ListingInputDefinitionControlType) => void;
}> = ({ fieldType, selected, onChange }) => {
  const controlTypes = controlTypesForFieldType(fieldType);

  return (
    <select
      onChange={(e) =>
        onChange(e.target.value as ListingInputDefinitionControlType)
      }
      value={selected}
    >
      {controlTypes.map((controlType) => (
        <option value={controlType} key={controlType}>
          {controlTypeName(controlType)}
        </option>
      ))}
    </select>
  );
};

function controlTypeName(
  controlType: ListingInputDefinitionControlType
): string {
  switch (controlType) {
    case ListingInputDefinitionControlType.Boolean:
      return "Boolean";
    case ListingInputDefinitionControlType.Date:
      return "Date";
    case ListingInputDefinitionControlType.Lookup:
      return "Lookup";
    case ListingInputDefinitionControlType.LookupMulti:
      return "Multi-lookup";
    case ListingInputDefinitionControlType.Number:
      return "Number";
    case ListingInputDefinitionControlType.NumberButton:
      return "Number buttons";
    case ListingInputDefinitionControlType.NumberDollar:
      return "Dollars";
    case ListingInputDefinitionControlType.NumberYear:
      return "Year";
    case ListingInputDefinitionControlType.StringEmail:
      return "Email";
    case ListingInputDefinitionControlType.StringTel:
      return "Phone number";
    case ListingInputDefinitionControlType.StringUrl:
      return "Url";
    case ListingInputDefinitionControlType.TextArea:
      return "Text area";
    case ListingInputDefinitionControlType.TextBox:
      return "Text box";
  }
}

function controlTypesForFieldType(
  fieldType: ListingInputDefinitionFieldType
): ListingInputDefinitionControlType[] {
  switch (fieldType) {
    case ListingInputDefinitionFieldType.Boolean:
      return [ListingInputDefinitionControlType.Boolean];
    case ListingInputDefinitionFieldType.Date:
      return [ListingInputDefinitionControlType.Date];
    case ListingInputDefinitionFieldType.Lookup:
      return [ListingInputDefinitionControlType.Lookup];
    case ListingInputDefinitionFieldType.LookupMulti:
      return [ListingInputDefinitionControlType.LookupMulti];
    case ListingInputDefinitionFieldType.NumberFractional:
      return [
        ListingInputDefinitionControlType.Number,
        ListingInputDefinitionControlType.NumberButton,
        ListingInputDefinitionControlType.NumberDollar,
        ListingInputDefinitionControlType.NumberYear,
      ];
    case ListingInputDefinitionFieldType.NumberWhole:
      return [
        ListingInputDefinitionControlType.Number,
        ListingInputDefinitionControlType.NumberButton,
        ListingInputDefinitionControlType.NumberDollar,
        ListingInputDefinitionControlType.NumberYear,
      ];
    case ListingInputDefinitionFieldType.String:
      return [
        ListingInputDefinitionControlType.TextBox,
        ListingInputDefinitionControlType.TextArea,
        ListingInputDefinitionControlType.StringEmail,
        ListingInputDefinitionControlType.StringTel,
        ListingInputDefinitionControlType.StringUrl,
      ];
    case ListingInputDefinitionFieldType.StringMulti:
      return [ListingInputDefinitionControlType.LookupMulti];
    case ListingInputDefinitionFieldType.Timestamp:
      return [ListingInputDefinitionControlType.Date];
  }
}
