import React from "react";
import { Link } from "react-router-dom";
import { ReprocessDataTaskFragment } from "../../graphql/generated";
import ReprocessDataTaskPic from "./pic";
import Timestamp from "../../common/components/timestamp";

const ReprocessDataTaskCard: React.FC<{ task?: ReprocessDataTaskFragment }> = ({
  task,
}) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <ReprocessDataTaskPic task={task} />
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/reprocess_data_tasks/${task?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {task?.rootResource.mls.shortName} &bull; {task?.rootResource.name}
          </p>
          <p className="text-sm text-gray-500 truncate">
            <Timestamp timestamp={task?.createdAt} format="long" />
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ReprocessDataTaskCard;
