import { EnvelopeIcon, EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../../common/utils/classnames";
import { SubethaRecipientFragment } from "../../../graphql/generated";

const RecipientPic: React.FC<{
  recipient?: SubethaRecipientFragment;
  large?: boolean;
}> = ({ recipient, large }) => {
  return recipient?.pauseAt ? (
    <EnvelopeIcon
      className={classNames(
        large ? "h-20 w-20" : "h-10 w-10",
        "bg-red-100 border-red-300 rounded-full border-2"
      )}
    />
  ) : (
    <EnvelopeOpenIcon
      className={classNames(
        large ? "h-20 w-20" : "h-10 w-10",
        "bg-green-100 border-green-300 rounded-full border-2"
      )}
    />
  );
};

export default RecipientPic;
