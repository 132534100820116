import { Menu, Transition } from "@headlessui/react";
import {
  BellAlertIcon,
  BellSlashIcon,
  CodeBracketIcon,
  EllipsisVerticalIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import {
  NotificationContext,
  NotificationType,
} from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  ReprocessDataTaskDetailsFragment,
  ReprocessDataTaskStatus,
  useAbandonReprocessDataTaskMutation,
  usePauseReprocessDataTaskMutation,
  useUnpauseReprocessDataTaskMutation,
  useUpdateReprocessDataTaskFilterMutation,
  useUpdateReprocessDataTaskProblemThresholdMutation,
} from "../../graphql/generated";
import EditFilterDialog from "./edit-filter-dialog";
import ClearProblemThresholdDialog from "./clear-problem-threshold-dialog";
import EditProblemThresholdDialog from "./edit-problem-threshold-dialog";
import AbandonTaskDialog from "./abandon-task-dialog";

// According to GraphQL, a task's filter is of type Json. That gets converted to
// `any` by codegen. So we really do want the `any` type here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterJson = any;

const ReprocessDataTaskItemActions: React.FC<{
  task?: ReprocessDataTaskDetailsFragment;
}> = ({ task }) => {
  const { updateNotification } = React.useContext(NotificationContext);
  const [, pauseMutation] = usePauseReprocessDataTaskMutation();
  const [, unpauseMutation] = useUnpauseReprocessDataTaskMutation();
  const [{ fetching: abandonFetching }, abandonMutation] =
    useAbandonReprocessDataTaskMutation();
  const [, updateFilterMutation] = useUpdateReprocessDataTaskFilterMutation();
  const [
    { fetching: updateProblemThresholdFetching },
    updateProblemThresholdMutation,
  ] = useUpdateReprocessDataTaskProblemThresholdMutation();
  const [editFilterDialogIsOpen, setEditFilterDialogIsOpen] =
    React.useState(false);
  const [
    updateProblemThresholdDialogIsOpen,
    setUpdateProblemThresholdDialogIsOpen,
  ] = React.useState(false);
  const [
    clearProblemThresholdDialogIsOpen,
    setClearProblemThresholdDialogIsOpen,
  ] = React.useState(false);
  const [abandonDialogIsOpen, setAbandonDialogIsOpen] = React.useState(false);

  const { canPause, canUnpause, canEdit } = evaluateStatus(task?.status);

  const pause = React.useCallback(async () => {
    const { error } = await pauseMutation({ id: task?.id || "" });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Task has been paused",
        notificationType: NotificationType.Success,
      });
    }
  }, [task, updateNotification]);

  const unpause = React.useCallback(async () => {
    const { error } = await unpauseMutation({ id: task?.id || "" });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Task has been unpaused",
        notificationType: NotificationType.Success,
      });
    }
  }, [task, updateNotification]);

  const updateFilter = React.useCallback(
    async (filter?: FilterJson) => {
      const { error } = await updateFilterMutation({
        id: task?.id || "",
        filter: filter,
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Filter updated",
          notificationType: NotificationType.Success,
        });
      }
    },
    [task, updateNotification]
  );

  const updateProblemThreshold = React.useCallback(
    async (problemThreshold?: number) => {
      const { error } = await updateProblemThresholdMutation({
        id: task?.id || "",
        problemThreshold,
      });
      if (error) {
        updateNotification({
          notification: error.message,
          notificationType: NotificationType.Error,
        });
      } else {
        updateNotification({
          notification: "Threshold updated",
          notificationType: NotificationType.Success,
        });
      }
      setUpdateProblemThresholdDialogIsOpen(false);
      setClearProblemThresholdDialogIsOpen(false);
    },
    [
      task,
      updateNotification,
      updateProblemThresholdMutation,
      setUpdateProblemThresholdDialogIsOpen,
      setClearProblemThresholdDialogIsOpen,
    ]
  );

  const abandon = React.useCallback(async () => {
    const { error } = await abandonMutation({ id: task?.id || "" });
    if (error) {
      updateNotification({
        notification: error.message,
        notificationType: NotificationType.Error,
      });
    } else {
      updateNotification({
        notification: "Task has been abandoned",
        notificationType: NotificationType.Success,
      });
    }
    setAbandonDialogIsOpen(false);
  }, [task, updateNotification, setAbandonDialogIsOpen]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled={!canPause}>
                {({ active, disabled }) => (
                  <div
                    onClick={pause}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <PauseCircleIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Pause
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={!canUnpause}>
                {({ active, disabled }) => (
                  <div
                    onClick={unpause}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <PlayCircleIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Unpause
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={!canEdit}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setEditFilterDialogIsOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <CodeBracketIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Edit filter...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={!canEdit}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setUpdateProblemThresholdDialogIsOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <BellAlertIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Set problem threshold...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={!canEdit}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setClearProblemThresholdDialogIsOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <BellSlashIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Clear problem threshold...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={!canEdit}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setAbandonDialogIsOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <TrashIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Abandon task...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <EditFilterDialog
        isOpen={editFilterDialogIsOpen && canEdit}
        onClose={() => {
          setEditFilterDialogIsOpen(false);
        }}
        onSubmit={(filter) => {
          setEditFilterDialogIsOpen(false);
          updateFilter(filter);
        }}
        filter={task?.filter}
      />
      <EditProblemThresholdDialog
        isOpen={updateProblemThresholdDialogIsOpen && canEdit}
        onClose={() => setUpdateProblemThresholdDialogIsOpen(false)}
        onSubmit={(threshold) => updateProblemThreshold(threshold)}
        isFetching={updateProblemThresholdFetching}
      />
      <ClearProblemThresholdDialog
        isOpen={clearProblemThresholdDialogIsOpen && canEdit}
        onClose={() => setClearProblemThresholdDialogIsOpen(false)}
        onSubmit={() => updateProblemThreshold()}
        isFetching={updateProblemThresholdFetching}
      />
      <AbandonTaskDialog
        isOpen={abandonDialogIsOpen && canEdit}
        onClose={() => setAbandonDialogIsOpen(false)}
        onSubmit={() => abandon()}
        isFetching={abandonFetching}
      />
    </>
  );
};

function evaluateStatus(status?: ReprocessDataTaskStatus): {
  canPause: boolean;
  canUnpause: boolean;
  canEdit: boolean;
} {
  if (!status) {
    return { canPause: false, canUnpause: false, canEdit: false };
  }

  switch (status) {
    case ReprocessDataTaskStatus.Ready:
      return { canPause: true, canUnpause: false, canEdit: false };
    case ReprocessDataTaskStatus.Running:
      return { canPause: true, canUnpause: false, canEdit: false };
    case ReprocessDataTaskStatus.WaitingOnCache:
      return { canPause: true, canUnpause: false, canEdit: false };
    case ReprocessDataTaskStatus.Paused:
      return { canPause: false, canUnpause: true, canEdit: true };
    case ReprocessDataTaskStatus.Pausing:
      return { canPause: false, canUnpause: false, canEdit: false };
    case ReprocessDataTaskStatus.Finished:
      return { canPause: false, canUnpause: false, canEdit: false };
    case ReprocessDataTaskStatus.Abandoned:
      return { canPause: false, canUnpause: false, canEdit: false };
  }
}

export default ReprocessDataTaskItemActions;
