import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useFeatureFlagQuery } from "../../graphql/generated";
import FeatureFlagAccounts from "../components/accounts";
import FeatureFlagDescription from "../components/description";
import FeatureFlagSummary from "../components/summary";
import FeatureFlagTitle from "../components/title";
import { useTitle } from "../../common/utils/title";
import FeatureFlagProductOfferings from "../components/productofferings";

const FeatureFlagShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useFeatureFlagQuery({
    variables: { id: params.featureFlagId ?? "" },
    pause: !params.featureFlagId,
  });
  useTitle(data?.featureFlag.name ?? params.featureFlagId, "Feature Flags");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <FeatureFlagTitle flag={data?.featureFlag} />
          <FeatureFlagSummary flag={data?.featureFlag} />
          <FeatureFlagDescription flag={data?.featureFlag} />
          <FeatureFlagAccounts
            accounts={data?.featureFlag.enabledAccounts}
            label="Enabled Accounts"
            flag={data?.featureFlag}
            enabled
          />
          <FeatureFlagAccounts
            accounts={data?.featureFlag.disabledAccounts}
            label="Disabled Accounts"
            flag={data?.featureFlag}
          />
          <FeatureFlagProductOfferings
            productOfferings={data?.featureFlag.enabledProductOfferings}
            label="Enabled Product Offerings"
            flag={data?.featureFlag}
            enabled
          />
          <FeatureFlagProductOfferings
            productOfferings={data?.featureFlag.disabledProductOfferings}
            label="Disabled Product Offerings"
            flag={data?.featureFlag}
          />
        </div>
      </Transition>
    </>
  );
};

export default FeatureFlagShow;
