import React from "react";

type TitleInput = string | null | undefined;

export function useTitle(...titles: TitleInput[]): void {
  React.useEffect(() => {
    const renderedTitles = [];
    for (const title of titles) {
      if (typeof title === "string") {
        renderedTitles.push(title);
      }
    }
    renderedTitles.push("Zenlist Admin");
    const finalTitle = renderedTitles.join(" • ");

    document.title = finalTitle;
  }, titles);
}
