import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import Timestamp from "../../common/components/timestamp";
import { LeadFragment, LeadState } from "../../graphql/generated";

const LeadSummary: React.FC<{
  lead?: LeadFragment;
}> = ({ lead }) => {
  return (
    <>
      <Panel>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <DescriptionListContainer title="Status">
              <strong>
                <StatusComponent entity={lead} />
              </strong>
              {lead?.state === LeadState.Pending && (
                <>
                  {" "}
                  since{" "}
                  <Timestamp
                    timestamp={lead?.createdAt}
                    format="long"
                    popover
                  />
                </>
              )}
              {lead?.state === LeadState.Denied && (
                <>
                  {" "}
                  on{" "}
                  <Timestamp timestamp={lead?.deniedAt} format="long" popover />
                </>
              )}
              {lead?.state === LeadState.Accepted && (
                <>
                  {" "}
                  on{" "}
                  <Timestamp
                    timestamp={lead?.acceptedAt}
                    format="long"
                    popover
                  />
                </>
              )}
              {lead?.state === LeadState.Cancelled && (
                <>
                  {" "}
                  on{" "}
                  <Timestamp
                    timestamp={lead?.cancelledAt}
                    format="long"
                    popover
                  />
                </>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer title="Created">
              <Timestamp timestamp={lead?.createdAt} format="long" popover />
            </DescriptionListContainer>
            {!!lead?.account && (
              <DescriptionListItem
                title="Requesting account"
                value={lead?.account?.name}
                link={`/accounts/${lead?.account?.id}`}
              />
            )}
            {!lead?.account && (
              <DescriptionListContainer title="Requesting account">
                <em>Unauthenticated</em>
              </DescriptionListContainer>
            )}
            <DescriptionListItem title="Message" value={lead?.message ?? "—"} />
          </dl>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>
          Request type{" "}
          <span className="text-xs text-gray-500">
            How the client user requested access to Zenlist.
          </span>
        </Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            {lead?.requestType.__typename === "LeadRequestTypeAgent" && (
              <>
                <DescriptionListContainer title="Type">
                  <abbr title="The client found an agent and requested access directly">
                    Agent
                  </abbr>
                </DescriptionListContainer>
                <DescriptionListItem
                  title="Agent"
                  value={lead.requestType.agent.name}
                  link={`/agents/{lead.requestType.agent.id}`}
                />
              </>
            )}
            {lead?.requestType.__typename ===
              "LeadRequestTypeAgentNotFound" && (
              <>
                <DescriptionListContainer title="Type">
                  <abbr title="The client could not find the agent they were working with">
                    Agent not found
                  </abbr>
                </DescriptionListContainer>
                <DescriptionListItem
                  title="Brokerage"
                  value={lead.requestType.brokerage}
                />
                <DescriptionListItem
                  title="Location"
                  value={lead.requestType.location}
                />
              </>
            )}
            {lead?.requestType.__typename === "LeadRequestTypeConnect" && (
              <>
                <DescriptionListContainer title="Type">
                  <abbr title="The client requested to be connected with an agent">
                    Connect
                  </abbr>
                </DescriptionListContainer>
              </>
            )}
            {lead?.requestType.__typename ===
              "LeadRequestTypeUnlistedLocation" && (
              <>
                <DescriptionListContainer title="Type">
                  <abbr title="The client's location was not listed as an option">
                    Unlisted location
                  </abbr>
                </DescriptionListContainer>
                <DescriptionListItem
                  title="Location"
                  value={lead.requestType.location}
                />
              </>
            )}
          </dl>
        </Panel.Body>
      </Panel>

      {!!lead?.unauthenticatedDetails && (
        <Panel>
          <Panel.Title>
            Unauthenticated details{" "}
            <span className="text-xs text-gray-500">
              This lead was created without logging in. These are the details
              the requestor put in on the Zenlink page.
            </span>
          </Panel.Title>
          <Panel.Body summary>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
              <DescriptionListItem
                title="Name"
                value={lead.unauthenticatedDetails.name}
              />
              <DescriptionListItem
                title="Email"
                value={lead.unauthenticatedDetails.email}
              />
              <DescriptionListItem
                title="Phone"
                value={lead.unauthenticatedDetails.phone}
              />
            </dl>
          </Panel.Body>
        </Panel>
      )}
    </>
  );
};

export default LeadSummary;
