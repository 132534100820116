import { Transition } from "@headlessui/react";
import React from "react";
import { useClient } from "urql";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { classNames } from "../../common/utils/classnames";
import {
  HistogramInterval,
  HistogramRange,
  ReferralsDocument,
  ReferralState,
  useReferralsQuery,
} from "../../graphql/generated";
import ReferralActions from "../components/actions";
import ReferralStats from "../components/stats";
import ReferralTableRow from "../components/table-row";
import { useTitle } from "../../common/utils/title";

const referralStates = [
  { id: ReferralState.Active, title: "Active" },
  { id: ReferralState.Created, title: "Created" },
  { id: ReferralState.Rewarded, title: "Rewarded" },
];

const ReferralList: React.FC = () => {
  useTitle("Referrals");
  const graphqlClient = useClient();
  const [loading, setLoading] = React.useState(false);

  const [referralState, setReferralState] = React.useState(
    ReferralState.Active
  );

  const [{ data, error, fetching }, executeQuery] = useReferralsQuery({
    variables: { first: 20, referralState },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.referrals.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(ReferralsDocument, {
          first: 20,
          after: data.referrals.pageInfo.endCursor,
          referralState,
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, referralState]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Referrals
                <span className="text-sm text-gray-500 px-2">
                  {fetching ? "Loading..." : `${data?.referrals.edges?.length}`}
                </span>
              </h2>
            </div>
            <div className="mt-5 flex sm:mt-0 sm:mr-4">
              <ReferralActions executeQuery={() => executeQuery()} />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ReferralStats
              interval={HistogramInterval.Week}
              range={HistogramRange.Year}
            />
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="p-4">
              <label className="text-base font-medium text-gray-900">
                Referral State
              </label>
              <fieldset className="my-4">
                <legend className="sr-only">Notification method</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {referralStates.map((state) => (
                    <div key={state.id} className="flex items-center">
                      <input
                        id={state.id}
                        value={state.id}
                        name="notification-method"
                        type="radio"
                        defaultChecked={state.id === referralState}
                        onChange={() => setReferralState(state.id)}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label
                        htmlFor={state.id}
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        {state.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
            <div className="p-4 border-t border-gray-200">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Referrer
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Referrer Name
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Referrent Name
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Created At
                            </th>

                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Active At
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Rewarded At
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {data?.referrals.edges?.map((edge) => (
                            <ReferralTableRow referral={edge?.node} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="min-w-full divide-y divide-gray-200">
            <div className="p-4">
              <button
                className={classNames(
                  loading
                    ? "bg-gray-500 text-white"
                    : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                  "font-bold py-2 px-4 rounded"
                )}
                onClick={() => fetchMore()}
                disabled={loading}
              >
                Fetch More
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default ReferralList;
