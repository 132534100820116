import React from "react";
import Panel from "../../common/components/panel";
import {
  ListingFragment,
  MediaSetEagerness,
  useListingMediaSetQuery,
} from "../../graphql/generated";
import { CheckIcon, PauseIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LoadingIcon from "../../common/components/loadingicon";
import Timestamp from "../../common/components/timestamp";

const ListingMediaPanel: React.FC<{ listing?: ListingFragment }> = ({
  listing,
}) => {
  const [{ fetching, data }] = useListingMediaSetQuery({
    variables: {
      id: listing?.id ?? "",
    },
    pause: !listing,
  });

  return (
    <Panel>
      <Panel.Title>
        Media{" "}
        {data && (
          <span className="text-xs text-gray-500">
            {eagerName(data.listing.mediaSet.eagerness)}
          </span>
        )}
      </Panel.Title>
      <Panel.Body>
        {fetching && <LoadingIcon />}
        {!fetching && (
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">Key</th>
                <th className="p-1">Order</th>
                <th className="p-1">Type</th>
                <th className="p-1">Available</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.listing.mediaSet &&
                data.listing.mediaSet.items.map((item) => (
                  <tr className="border-t" key={item.id}>
                    <td className="p-1">{item.mediaKey}</td>
                    <td className="p-1">{item.order}</td>
                    <td className="p-1">{item.mediaType}</td>
                    <td className="p-1 text-nowrap">
                      {item.isAvailable === true && <Available available />}
                      {item.isAvailable === false &&
                        (item.unavailableReasonAt === null ||
                          item.unavailableReasonAt === undefined) && (
                          <Available queued />
                        )}
                      {item.isAvailable === false &&
                        !(
                          item.unavailableReasonAt === null ||
                          item.unavailableReasonAt === undefined
                        ) && (
                          <Available
                            unavailable
                            unavailableReason={
                              item.unavailableReason ?? undefined
                            }
                            unavailableReasonAt={
                              item.unavailableReasonAt ?? undefined
                            }
                          />
                        )}
                      {(item.isAvailable === null ||
                        item.isAvailable === undefined) && (
                          <Available missing />
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Panel.Body>
    </Panel>
  );
};

function eagerName(eagerness: MediaSetEagerness): string {
  switch (eagerness) {
    case MediaSetEagerness.Eager:
      return "Eager";
    case MediaSetEagerness.Lazy:
      return "Lazy";
  }
}

const Available: React.FC<{
  available?: boolean;
  queued?: boolean;
  unavailable?: boolean;
  unavailableReason?: string;
  unavailableReasonAt?: string;
  missing?: boolean;
}> = ({
  available,
  queued,
  unavailable,
  unavailableReason,
  unavailableReasonAt,
  missing,
}) => {
    if (available) {
      return (
        <CheckIcon className="w-4 h-4 p-[1px] text-green-800 bg-green-300 rounded-full border border-green-400" />
      );
    }
    if (queued) {
      return (
        <>
          <PauseIcon className="w-4 h-4 p-[1px] text-yellow-800 bg-yellow-300 rounded-full border border-yellow-400 inline" />{" "}
          queued
        </>
      );
    }
    if (unavailable) {
      return (
        <>
          <XMarkIcon className="w-4 h-4 p-[1px] text-red-800 bg-red-300 rounded-full border border-red-400 inline" />{" "}
          {unavailableReason} –{" "}
          <Timestamp timestamp={unavailableReasonAt} popover format={"short"} />
        </>
      );
    }
    if (missing) {
      return (
        <>
          <div className="w-4 h-4 p-[1px] bg-gray-200 rounded-full border border-gray-400 border-dashed" />
        </>
      );
    }
    return <>unknown</>;
  };

export default ListingMediaPanel;
