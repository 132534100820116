import { format, formatDistanceToNow } from "date-fns";
import { utcToZonedTime, format as formatZoned } from "date-fns-tz";

export const naiveDateFormat = (date?: string | null) => {
  if (!date) {
    return "";
  }

  const newDate = new Date(date);
  const zoned = utcToZonedTime(newDate, "Etc/UTC");
  return formatZoned(zoned, "MMM d, yyyy", { timeZone: "Etc/UTC" });
};

export const extraShortDateFormat = (date?: string | null) => {
  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  return format(newDate, "MM/dd");
};

export const shortDateFormat = (date?: string | null) => {
  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  return format(newDate, "MMM d, yyyy");
};

export const longDateFormat = (date?: string | null) => {
  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  return format(newDate, "MMM d, yyyy @ h:mm a");
};

export const distanceDateFormat = (date?: string | null) => {
  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  return formatDistanceToNow(newDate, {
    includeSeconds: true,
    addSuffix: true,
  });
};
