import { Transition } from "@headlessui/react";
import React from "react";
import MlsCard from "../components/card";
import { MlsContext } from "../context/mls";
import { useTitle } from "../../common/utils/title";

const MlsList: React.FC = () => {
  useTitle("MLSes");
  const { mlses } = React.useContext(MlsContext);

  return (
    <Transition
      show={true}
      appear={true}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              MLSes
              <span className="text-sm text-gray-500 px-2">{mlses.length}</span>
            </h2>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="p-4">
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
              {mlses.map((mls) => (
                <MlsCard mls={mls} key={mls.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default MlsList;
