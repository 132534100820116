import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import Timestamp from "../../common/components/timestamp";
import { AccountSubscriptionFragment } from "../../graphql/generated";
import AccountSubscriptionActions from "./actions";

const AccountSubscriptionSummary: React.FC<{
  subscription?: AccountSubscriptionFragment;
}> = ({ subscription }) => {
  return (
    <>
      {subscription?.statusManualOverride && (
        <Panel>
          <Panel.Title>Manual Overrides</Panel.Title>
          <Panel.Body summary>
            <p>
              This section is used when someone at Zenlist has manually
              intervened to override subscription management. Updates from the
              remote system will still flow through however manual overrides, if
              present, will always take precedence over remote system state.
            </p>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
              <DescriptionListItem
                title="Status"
                value={subscription?.statusManualOverride}
                info="The manually set status of this subscription."
              />
              <DescriptionListItem
                title="Reason"
                value={subscription?.manualOverrideReason}
                info="The reason for this manual override."
              />
              <DescriptionListItem
                title="Account"
                value={subscription?.manualOverrideRequestor}
                info="The email address that created the manual override."
                link={`/accounts/${subscription?.manualOverrideRequestor}`}
              />
              <DescriptionListContainer
                title="Created"
                info="The system time when this manual override was created"
              >
                <Timestamp
                  timestamp={subscription?.manualOverrideLastModifiedAt}
                  format="long"
                  popover
                />
              </DescriptionListContainer>
            </dl>
          </Panel.Body>
        </Panel>
      )}
      <Panel>
        <Panel.Title
          actions={<AccountSubscriptionActions subscription={subscription} />}
        >
          Account Subscription
        </Panel.Title>
        <Panel.Body summary>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-4">
            <DescriptionListItem title="Id" value={subscription?.id} />

            <DescriptionListItem
              title="Account"
              info="The account owner."
              value={subscription?.accountId}
              link={`/accounts/${subscription?.accountId}`}
            />

            <DescriptionListContainer title="Created">
              <Timestamp
                timestamp={subscription?.createdAt}
                format="long"
                popover
              />
            </DescriptionListContainer>

            <DescriptionListContainer title="Last Modified">
              <Timestamp
                timestamp={subscription?.modifiedAt}
                format="long"
                popover
              />
            </DescriptionListContainer>

            <DescriptionListItem
              title="Purchase Store"
              info="The store that created and owns this product record."
              value={subscription?.purchaseStore}
            />
            {subscription?.purchaseStore === "STRIPE" && (
              <DescriptionListItem
                title="Purchase Store Id"
                info="The unique identifier at the purchase store for this subscription"
                value={subscription?.purchaseStoreId}
                link={`https://dashboard.stripe.com/subscriptions/${subscription.purchaseStoreId}`}
              />
            )}
            {!(subscription?.purchaseStore === "STRIPE") && (
              <DescriptionListItem
                title="Purchase Store Id"
                info="The unique identifier at the purchase store for this subscription"
                value={subscription?.purchaseStoreId}
              />
            )}

            <DescriptionListContainer
              title="Source Last Modified"
              info="The last time we received an update from the Purchase Store"
            >
              <Timestamp
                timestamp={subscription?.sourceModifiedAt}
                format="long"
                popover
              />
            </DescriptionListContainer>

            <DescriptionListItem
              title="Product"
              value={subscription?.productId}
              link={`/products/${subscription?.productId}`}
            />

            <DescriptionListItem
              title="Status"
              value={subscription?.status}
              info="The status of this subscription."
            />

            <DescriptionListContainer
              title="Cancel Valid Until"
              info="This value is set when an active subscription is canceled with a future date."
            >
              <Timestamp
                timestamp={subscription?.cancelValidUntil}
                format="long"
                popover
              />
            </DescriptionListContainer>
            <DescriptionListItem
              title="Product Offering"
              info="The source product offering, if one exists."
              value={subscription?.productOfferingId}
              link={`/product_offerings/${subscription?.productOfferingId}`}
            />
            <DescriptionListItem
              title="Entity Type"
              info="The type of entity associated with this subscription."
              value={subscription?.entityType}
            />
          </dl>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default AccountSubscriptionSummary;
