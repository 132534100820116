import { Transition } from "@headlessui/react";
import React from "react";

const Loading: React.FC<{ show: boolean }> = ({ show }) => {
  return (
    <Transition
      show={show}
      appear
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Loading...
            </h2>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Loading;
