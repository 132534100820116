import React from "react";
import { distanceDateFormat } from "../../common/utils/dateformat";
import {
  EventType,
  EventHistogramInterval,
  EventHistogramRange,
  EventHistogramUnique,
  useEventSummaryQuery,
} from "../../graphql/generated";
import { Line } from "react-chartjs-2";
import { ChartData } from "chart.js";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import Loading from "../../common/components/loading";
import { Transition } from "@headlessui/react";
import Error from "../../common/components/error";

interface CombinedData {
  [key: string]: CombinedValues;
}

interface CombinedValues {
  bucketDate: string;
  memberTotal: number;
  memberUnique: number;
  agentTotal: number;
  agentUnique: number;
}

const DashboardUserSessions: React.FC<{
  range: EventHistogramRange;
  interval: EventHistogramInterval;
}> = ({ range, interval }) => {
  const [lastUpdated, setLastUpdated] = React.useState(new Date());

  const [{ data, error, fetching }, executeQuery] = useEventSummaryQuery({
    variables: {
      eventType: EventType.AccountNewSession,
      interval: interval,
      range: range,
      uniqueBy: EventHistogramUnique.User,
    },
  });

  const combinedData = React.useMemo(() => {
    if (data) {
      let combined = data.eventSummary.member.reduce(
        (obj: CombinedData, item) => {
          obj[item.bucketDate] = obj[item.bucketDate] ?? {
            bucketDate: item.bucketDate,
            agentTotal: 0,
            agentUnique: 0,
            memberTotal: 0,
            memberUnique: 0,
          };
          obj[item.bucketDate].memberTotal = item.totalCount;
          obj[item.bucketDate].memberUnique = item.uniqueCount;
          return obj;
        },
        {}
      );
      combined = data.eventSummary.agent.reduce((obj, item) => {
        obj[item.bucketDate] = obj[item.bucketDate] ?? {
          bucketDate: item.bucketDate,
          agentTotal: 0,
          agentUnique: 0,
          memberTotal: 0,
          memberUnique: 0,
        };
        obj[item.bucketDate].agentTotal = item.totalCount;
        obj[item.bucketDate].agentUnique = item.uniqueCount;
        return obj;
      }, combined);

      return Object.values(combined).sort((a, b) =>
        a.bucketDate < b.bucketDate ? -1 : 1
      );
    }
    return [];
  }, [data]);

  const chartData = React.useMemo<ChartData<"line", number[], Date>>(() => {
    return {
      labels: combinedData.map((m) => new Date(m.bucketDate)),
      datasets: [
        {
          label: "Client(Total)",
          data: combinedData.map((m) => m.memberTotal),
          borderColor: "rgb(239, 68, 68)",
          backgroundColor: "rgba(239, 68, 68, 0.5)",
          yAxisID: "y1",
        },
        {
          label: "Agent(Total)",
          data: combinedData.map((m) => m.agentTotal),
          borderColor: "rgb(52, 211, 153)",
          backgroundColor: "rgba(52, 211, 153, 0.5)",
          yAxisID: "y",
        },
        {
          label: "Combined(Total)",
          data: combinedData.map((m) => m.agentTotal + m.memberTotal),
          borderColor: "rgb(14, 165, 233)",
          backgroundColor: "rgba(14, 165, 233, 0.5)",
          yAxisID: "y1",
          hidden: true,
        },

        {
          label: "Client(Unique)",
          data: combinedData.map((m) => m.memberUnique),
          borderColor: "rgb(239, 68, 68, 0.75)",
          backgroundColor: "rgba(239, 68, 68, 0.5)",
          yAxisID: "y1",
        },
        {
          label: "Agent(Unique)",
          data: combinedData.map((m) => m.agentUnique),
          borderColor: "rgb(52, 211, 153, 0.75)",
          backgroundColor: "rgba(52, 211, 153, 0.5)",
          yAxisID: "y",
        },
        {
          label: "Combined(Unique)",
          data: combinedData.map((m) => m.agentUnique + m.memberUnique),
          borderColor: "rgb(14, 165, 233, 0.75)",
          backgroundColor: "rgba(14, 165, 233, 0.5)",
          yAxisID: "y1",
          hidden: true,
        },
      ],
    };
  }, [combinedData]);

  React.useEffect(() => {
    if (!fetching) {
      const id = setTimeout(() => {
        setLastUpdated(new Date());
        executeQuery({ requestPolicy: "network-only" });
      }, 30000);
      return () => clearTimeout(id);
    }
  }, [executeQuery, fetching]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="px-2 my-2 sm:px-6 flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 grow">
              User Sessions
              <span className="text-xs text-gray-500">
                {" · "}
                Updated {distanceDateFormat(lastUpdated.toISOString())}
              </span>
            </h3>
          </div>
          <div className="p-2 border-t border-gray-200 grid grid-cols-1 gap-1">
            {data && (
              <div className="col-span-3 h-56">
                <Line
                  options={{
                    maintainAspectRatio: false,
                    showLine: true,
                    interaction: { mode: "index" },
                    scales: {
                      x: {
                        type: "time",
                      },
                      y: { position: "left" },
                      y1: { position: "right" },
                    },
                  }}
                  data={chartData}
                />
              </div>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
};

export default DashboardUserSessions;
