import React from "react";
import { classNames } from "../utils/classnames";

import {
  AccountFragment,
  AccountState,
  AgentFragment,
  EntitlementFragment,
  EvaluatedEntitlementState,
  GroupFragment,
  GroupState,
  IdentityFragment,
  IdentityState,
  IngestTaskFragment,
  IngestTaskStatus,
  InternalListingBucketFragment,
  LeadFragment,
  LeadState,
  ListingStandardStatus,
  MlsFragment,
  ReprocessDataTaskFragment,
  ReprocessDataTaskStatus,
  SearchedListingFragment,
} from "../../graphql/generated";

type AvailableColors = "green" | "yellow" | "orange" | "red" | "blue" | "gray";
export class StatusColor {
  private color: AvailableColors;
  static green: StatusColor = new StatusColor("green");
  static yellow: StatusColor = new StatusColor("yellow");
  static orange: StatusColor = new StatusColor("orange");
  static red: StatusColor = new StatusColor("red");
  static blue: StatusColor = new StatusColor("blue");
  static unknown: StatusColor = new StatusColor("gray");

  constructor(color: AvailableColors) {
    this.color = color;
  }

  picClasses(): string {
    switch (this.color) {
      case "green":
        return "bg-green-100 border-green-300";
      case "yellow":
        return "bg-yellow-100 border-yellow-300";
      case "orange":
        return "bg-orange-100 border-orange-300";
      case "red":
        return "bg-red-100 border-red-300";
      case "blue":
        return "bg-blue-100 border-blue-300";
      case "gray":
        return "bg-gray-100 border-gray-300";
    }
  }

  indicatorClasses(): string {
    switch (this.color) {
      case "green":
        return "bg-green-200 border-green-400";
      case "yellow":
        return "bg-yellow-200 border-yellow-400";
      case "orange":
        return "bg-orange-200 border-orange-400";
      case "red":
        return "bg-red-200 border-red-400";
      case "blue":
        return "bg-blue-200 border-blue-400";
      case "gray":
        return "bg-gray-200 border-gray-400";
    }
  }
}

export const StatusIndicator: React.FC<{ statusColor?: StatusColor }> = ({
  statusColor,
}) => {
  const color = statusColor ?? StatusColor.unknown;
  return (
    <span
      className={classNames(
        color.indicatorClasses(),
        "inline-block w-3 h-3 rounded-full border"
      )}
    ></span>
  );
};

type Statusable =
  | AccountFragment
  | AgentFragment
  | GroupFragment
  | EntitlementFragment
  | IdentityFragment
  | LeadFragment
  | IngestTaskFragment
  | InternalListingBucketFragment
  | SearchedListingFragment
  | ReprocessDataTaskFragment
  | MlsFragment;

export const StatusComponent: React.FC<{
  entity?: Statusable;
  status?: Status;
}> = ({ entity, status }) => {
  let actualStatus = status;
  if (!actualStatus) {
    actualStatus = Status.for(entity);
  }
  return (
    <>
      <StatusIndicator statusColor={actualStatus.color} /> {actualStatus.text}
    </>
  );
};

export class Status {
  color: StatusColor;
  text: string;
  static unknown: Status = new Status(StatusColor.unknown, "Unknown");
  constructor(color: StatusColor, text: string) {
    this.color = color;
    this.text = text;
  }

  static for(input?: Statusable): Status {
    if (!input) {
      return Status.unknown;
    }

    switch (input.__typename) {
      case "Account":
        return Status.forAccount(input);
      case "Agent":
        return Status.forAgent(input);
      case "Group":
        return Status.forGroup(input);
      case "Identity":
        return Status.forIdentity(input);
      case "Lead":
        return Status.forLead(input);
      case "IngestTask":
        return Status.forIngestTask(input);
      case "InternalListingBucket":
        return Status.forInternalListingBucket(input);
      case "Entitlement":
        return Status.forEntitlement(input);
      case "Listing":
        return Status.forSearchedListing(input);
      case "ReprocessDataTask":
        return Status.forReprocessDataTask(input);
      case "Mls":
        return Status.forMls(input);
    }
  }

  static forAccount(account: AccountFragment): Status {
    switch (account.accountState) {
      case AccountState.Active:
        return new Status(StatusColor.green, "Active");
      case AccountState.Deactivating:
        return new Status(StatusColor.yellow, "Deactivating");
      case AccountState.Unlinked:
        return new Status(StatusColor.red, "Unlinked");
      default:
        return Status.unknown;
    }
  }

  static forAgent(agent: AgentFragment): Status {
    switch (agent.active) {
      case true:
        return new Status(StatusColor.green, "Active");
      case false:
        return new Status(StatusColor.yellow, "Inactive");
      default:
        return Status.unknown;
    }
  }

  static forGroup(group: GroupFragment): Status {
    switch (group.groupState) {
      case GroupState.Active:
        return new Status(StatusColor.green, "Active");
      case GroupState.Pending:
        return new Status(StatusColor.yellow, "Pending");
      case GroupState.Archived:
        return new Status(StatusColor.orange, "Archived");
      case GroupState.Deleted:
        return new Status(StatusColor.red, "Deleted");
      case GroupState.Personal:
        return new Status(StatusColor.blue, "Personal");
    }
  }

  static forEntitlement(entitlement: EntitlementFragment): Status {
    switch (entitlement.evaluationState) {
      case EvaluatedEntitlementState.Allowed:
        return new Status(StatusColor.green, "Allowed");
      case EvaluatedEntitlementState.NotAllowed:
        return new Status(StatusColor.yellow, "Not Allowed");
      case EvaluatedEntitlementState.BlockAccess:
        return new Status(StatusColor.red, "Blocked");
    }
  }

  static forIdentity(identity: IdentityFragment): Status {
    switch (identity.identityState) {
      case IdentityState.Active:
        return new Status(StatusColor.green, "Active");
      case IdentityState.Disabled:
        return new Status(StatusColor.red, "Disabled");
      case IdentityState.EmailVerificationPending:
        return new Status(StatusColor.yellow, "Email Verification Pending");
    }
  }

  static forLead(lead: LeadFragment): Status {
    switch (lead.state) {
      case LeadState.Accepted:
        return new Status(StatusColor.green, "Accepted");
      case LeadState.Pending:
        return new Status(StatusColor.yellow, "Pending");
      case LeadState.Cancelled:
        return new Status(StatusColor.orange, "Cancelled");
      case LeadState.Denied:
        return new Status(StatusColor.red, "Denied");
    }
  }

  static forIngestTask(ingestTask: IngestTaskFragment): Status {
    switch (ingestTask.status) {
      case IngestTaskStatus.Active:
        return new Status(StatusColor.green, "Active");
      case IngestTaskStatus.Paused:
        return new Status(StatusColor.orange, "Paused");
      case IngestTaskStatus.Finished:
        return new Status(StatusColor.blue, "Finished");
    }
  }

  static forInternalListingBucket(
    internalListingBucket: InternalListingBucketFragment
  ): Status {
    if (internalListingBucket.deletedAt) {
      return new Status(StatusColor.red, "Deleted");
    } else {
      return new Status(StatusColor.green, "Active");
    }
  }

  static forSearchedListing(listing: SearchedListingFragment): Status {
    switch (listing.standardStatus) {
      case ListingStandardStatus.Active:
        return new Status(StatusColor.green, "Active");
      case ListingStandardStatus.ActiveUnderContract:
        return new Status(StatusColor.yellow, "Active Under Contract");
      case ListingStandardStatus.Canceled:
        return new Status(StatusColor.orange, "Canceled");
      case ListingStandardStatus.Closed:
        return new Status(StatusColor.orange, "Closed");
      case ListingStandardStatus.ComingSoon:
        return new Status(StatusColor.blue, "Coming Soon");
      case ListingStandardStatus.Delete:
        return new Status(StatusColor.red, "Delete");
      case ListingStandardStatus.Expired:
        return new Status(StatusColor.orange, "Expired");
      case ListingStandardStatus.Hold:
        return new Status(StatusColor.yellow, "Hold");
      case ListingStandardStatus.Incomplete:
        return new Status(StatusColor.red, "Incomplete");
      case ListingStandardStatus.Pending:
        return new Status(StatusColor.yellow, "Pending");
      case ListingStandardStatus.Withdrawn:
        return new Status(StatusColor.yellow, "Withdrawn");
    }
  }

  static forReprocessDataTask(task: ReprocessDataTaskFragment): Status {
    switch (task.status) {
      case ReprocessDataTaskStatus.Ready:
        return new Status(StatusColor.green, "Ready");
      case ReprocessDataTaskStatus.WaitingOnCache:
        return new Status(StatusColor.green, "Waiting on cache");
      case ReprocessDataTaskStatus.Running:
        return new Status(StatusColor.green, "Running");
      case ReprocessDataTaskStatus.Pausing:
        return new Status(StatusColor.orange, "Pausing");
      case ReprocessDataTaskStatus.Paused:
        return new Status(StatusColor.orange, "Paused");
      case ReprocessDataTaskStatus.Finished:
        return new Status(StatusColor.blue, "Finished");
      case ReprocessDataTaskStatus.Abandoned:
        return new Status(StatusColor.red, "Abandoned");
    }
  }

  static forMls(task: MlsFragment): Status {
    if (task.allowOnboarding) {
      return new Status(StatusColor.green, "Onboarding allowed");
    } else {
      return new Status(StatusColor.red, "Onboarding disabled");
    }
  }
}
