import React from "react";
import AccountCard from "../../account/components/card";
import Panel from "../../common/components/panel";
import { AccountFragment } from "../../graphql/generated";

const GroupAgents: React.FC<{
  accounts?: AccountFragment[];
  linked?: boolean;
}> = ({ accounts }) => {
  return (
    <Panel>
      <Panel.Title>
        Collaborating Agents{" "}
        <span className="text-xs text-gray-500">{accounts?.length}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {accounts &&
            accounts.map((account) => (
              <AccountCard account={account} key={account.id} />
            ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default GroupAgents;
