import React from "react";
import { googleLogout } from "@react-oauth/google";
import NavigationItems from "./items";
import { AuthContext } from "../../auth/context/auth";

const NavigationBigScreen: React.FC = () => {
  const { updateAuthorized } = React.useContext(AuthContext);
  const logOut = React.useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    googleLogout();
    updateAuthorized(false);
  }, []);

  const googleName = React.useMemo(() => {
    return localStorage.getItem("auth_name");
  }, []);

  const googlePic = React.useMemo(() => {
    return localStorage.getItem("auth_picture");
  }, []);

  return (
    <>
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex flex-col flex-grow pt-5 bg-zenlist-500 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img className="h-8 w-auto" src="/logo.svg" alt="Zenlist" />
            <h1 className="text-1xl font-semibold text-white pl-2">
              Zenlist Admin
            </h1>
          </div>
          <div className="mt-5 flex-1 flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-1">
              <NavigationItems />
            </nav>
          </div>
          <div className="flex-shrink-0 flex border-t border-zenlist-300 p-4">
            <div className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-10 w-10 rounded-full"
                    src={googlePic ?? ""}
                    alt={googleName ?? ""}
                  />
                </div>
                <div className="ml-3">
                  <p className="text-base font-medium text-white">
                    {googleName ?? "Unknown"}
                  </p>
                  <p
                    onClick={logOut}
                    className="text-sm font-medium text-indigo-200 group-hover:text-white"
                  >
                    Log Out
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationBigScreen;
