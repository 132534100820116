import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import { MlsFragment } from "../../graphql/generated";
import MlsProfilePic from "./pic";

const MlsCard: React.FC<{ mls?: MlsFragment }> = ({ mls }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <MlsProfilePic mls={mls} />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/mlses/${mls?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {mls?.name}
            <span className="ml-1 text-xs text-gray-500">{mls?.shortName}</span>
          </p>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div
              className={classNames(
                "flex items-center text-sm text-xs text-gray-500"
              )}
            >
              Showing Time:{" "}
              {mls?.showingTimeConfig ? (
                <CheckCircleIcon
                  className="flex-shrink-0 mr-1.5 h-3 w-3 ml-1 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <MinusCircleIcon
                  className="flex-shrink-0 mr-1.5 h-3 w-3 ml-1 text-red-500"
                  aria-hidden="true"
                />
              )}
            </div>
            <div
              className={classNames(
                "flex items-center text-sm text-xs text-gray-500"
              )}
            >
              Disclosure:{" "}
              {mls?.disclosure ? (
                <CheckCircleIcon
                  className="flex-shrink-0 mr-1.5 h-3 w-3 ml-1 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <MinusCircleIcon
                  className="flex-shrink-0 mr-1.5 h-3 w-3 ml-1 text-red-500"
                  aria-hidden="true"
                />
              )}
            </div>
            <div
              className={classNames(
                "flex items-center text-sm text-xs text-gray-500"
              )}
            >
              Signup:{" "}
              {mls?.enabled ? (
                <CheckCircleIcon
                  className="flex-shrink-0 mr-1.5 h-3 w-3 ml-1 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <MinusCircleIcon
                  className="flex-shrink-0 mr-1.5 h-3 w-3 ml-1 text-red-500"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MlsCard;
