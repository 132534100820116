import React from "react";

interface IAuthContext {
  authorized: boolean;
  updateAuthorized: (authorized: boolean) => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  authorized: false,
  updateAuthorized: () => null,
});

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [authorized, setAuthorized] = React.useState(
    !!localStorage.getItem("refreshToken")
  );

  const updateAuthorized = React.useCallback(
    (authorized: boolean) => setAuthorized(authorized),
    []
  );
  return (
    <AuthContext.Provider value={{ authorized, updateAuthorized }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
