import React from "react";
import { Link } from "react-router-dom";
import { InternalListingBucketFragment } from "../../graphql/generated";
import InternalListingBucketPic from "./pic";

const InternalListingBucketCard: React.FC<{
  bucket?: InternalListingBucketFragment;
}> = ({ bucket }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <InternalListingBucketPic bucket={bucket} />
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/internal_listing_buckets/${bucket?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{bucket?.name}</p>
        </Link>
      </div>
    </div>
  );
};

export default InternalListingBucketCard;
