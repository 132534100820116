import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { ShowingTimeFragment } from "../../graphql/generated";

const MlsShowingtime: React.FC<{ showingTimeConfig?: ShowingTimeFragment }> = ({
  showingTimeConfig,
}) => {
  return (
    <Panel>
      <Panel.Title>Showing Time Config</Panel.Title>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem
            title="Site ID"
            value={showingTimeConfig?.siteId}
          />
          <DescriptionListItem
            title="Secret Key"
            value={showingTimeConfig?.secretKey}
          />
          <DescriptionListItem
            title="MLS ID"
            value={showingTimeConfig?.stMlsId}
          />
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default MlsShowingtime;
