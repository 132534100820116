import React from "react";

const ResourceMappingTitle: React.FC<
  { name?: string; actions?: React.ReactChild } & React.PropsWithChildren
> = ({ name, actions, children }) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {name}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                {children}
              </div>
            </div>
          </div>
          {actions && (
            <div className="mt-5 flex sm:mt-0 sm:mr-4">{actions}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResourceMappingTitle;
