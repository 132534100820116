import React from "react";
import { Link } from "react-router-dom";
import { AgentFragment } from "../../graphql/generated";
import AgentPic from "./pic";

const AgentCard: React.FC<{ agent?: AgentFragment }> = ({ agent }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <AgentPic agent={agent} />
      </div>
      <div className="flex-1 min-w-0">
        <Link to={`/agents/${agent?.id}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">
            {agent?.name.trim() === "" ? "Empty Name" : agent?.name} ·{" "}
            {agent?.mls?.shortName}: {agent?.mlsId}
          </p>
          <p className="text-sm text-gray-500 truncate">
            {agent?.office?.name}
          </p>
          <p className="text-sm text-gray-500 truncate">
            {agent?.office?.address?.city}, {agent?.office?.address?.state}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default AgentCard;
