import React from "react";
import Panel from "../../common/components/panel";
import { SavedFilterFragment } from "../../graphql/generated";
import SavedFilterHistoryItem from "./history-item";

const SavedFilterHistory: React.FC<{ filter?: SavedFilterFragment }> = ({
  filter,
}) => {
  return (
    <Panel>
      <Panel.Title>Change History</Panel.Title>
      <Panel.Body>
        <div className="flow-root px-4 my-2 sm:px-6">
          <ul className="-mb-8">
            {filter && <SavedFilterHistoryItem id={filter.id} first />}
          </ul>
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default SavedFilterHistory;
