import React from "react";
import Panel from "../../common/components/panel";
import {
  ListingFragment,
  useListingHistoryItemsQuery,
} from "../../graphql/generated";
import ListingHistoryHiddenStatus from "./listing-history-hidden-status";
import LoadingIcon from "../../common/components/loadingicon";

const ListingHistoryItemPanel: React.FC<{ listing?: ListingFragment }> = ({
  listing,
}) => {
  const [{ fetching, data }] = useListingHistoryItemsQuery({
    variables: {
      id: listing?.id ?? "",
    },
    pause: !listing,
  });


  return (
    <Panel>
      <Panel.Title>
        Listing History Items
      </Panel.Title>
      <Panel.Body>
        {fetching && <LoadingIcon />}
        {!fetching && (
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">ID</th>
                <th className="p-1">Date</th>
                <th className="p-1">Status</th>
                <th className="p-1">Price</th>
                <th className="p-1">Showing</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.historyItems &&
                data.historyItems.map((item) => (
                  <tr className="border-t" key={item.id}>
                    <td className="p-1">{item.id}</td>
                    <td className="p-1">{item.displayDate}</td>
                    <td className="p-1">{item.displayStatus}</td>
                    <td className="p-1">{item.displayPrice}</td>
                    <td className="p-1">
                      <ListingHistoryHiddenStatus historyItem={item} />
                    </td>

                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Panel.Body>
    </Panel>
  );
};





export default ListingHistoryItemPanel;
