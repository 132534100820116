import {
  HashtagIcon,
  InformationCircleIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../../common/utils/dateformat";
import { RecipientQuery } from "../../../graphql/generated";
import RecipientActions from "./actions";
import RecipientPic from "./pic";

const RecipientTitle: React.FC<{ recipient?: RecipientQuery["recipient"] }> = ({
  recipient,
}) => {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <RecipientPic recipient={recipient} large />
          </div>
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {recipient?.email}
            </h2>
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
              {recipient?.pauseAt && (
                <>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <PauseIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                      aria-hidden="true"
                    />
                    {longDateFormat(recipient.pauseAt)}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <InformationCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                      aria-hidden="true"
                    />
                    {recipient.pauseReason}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <InformationCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                      aria-hidden="true"
                    />
                    <Link
                      to={`/subetha/events/${recipient?.pauseEvent?.id}`}
                      className="text-blue-900 hover:underline"
                    >
                      Pause Event
                    </Link>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <HashtagIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                      aria-hidden="true"
                    />
                    Pause Count: {recipient.pauseCount}
                  </div>
                </>
              )}
              {!recipient?.pauseAt && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <PlayIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
                    aria-hidden="true"
                  />
                  Active
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 flex sm:mt-0 sm:mr-4">
            <RecipientActions recipient={recipient} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipientTitle;
