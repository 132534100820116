import React from "react";
import { useClient } from "urql";
import Panel from "../../common/components/panel";
import { classNames } from "../../common/utils/classnames";
import {
  OfficeQuery,
  OfficesDocument,
  useOfficesQuery,
} from "../../graphql/generated";
import OfficeCard from "../../office/components/card";

const OfficeOffices: React.FC<{ office?: OfficeQuery["office"] }> = ({
  office,
}) => {
  const [loading, setLoading] = React.useState(false);
  const graphqlClient = useClient();

  const [{ data }] = useOfficesQuery({
    variables: {
      first: 10,
      mlsId: office?.mls.id ?? "",
      parentOfficeId: office?.id ?? "",
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.offices.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(OfficesDocument, {
          first: 20,
          after: data.offices.pageInfo.endCursor,
          mlsId: office?.mls.id ?? "",
          parentOfficeId: office?.id ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, office]);

  return (
    <Panel>
      <Panel.Title>
        Child Offices{" "}
        <span className="text-xs text-gray-500">{data?.offices.total}</span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.offices.edges?.map((office) => (
            <OfficeCard office={office?.node} key={office?.node.id} />
          ))}
        </div>
      </Panel.Body>

      {data && data?.offices.total > (data?.offices.edges?.length ?? 0) && (
        <Panel.Footer>
          <button
            className={classNames(
              loading
                ? "bg-gray-500 text-white"
                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
              "font-bold py-2 px-4 rounded"
            )}
            onClick={() => fetchMore()}
            disabled={loading}
          >
            Fetch More
          </button>
        </Panel.Footer>
      )}
    </Panel>
  );
};

export default OfficeOffices;
